import { getRandomItem } from '../../lib/mathUtils'
import { toTitleCase } from '../../lib/textUtils'
import { getPilotRank } from '../../lib/pilot'
import { findPlanet } from '../../lib/starMap'

const items = [
    'duranium plates',
    'processor cores',
    'signal tuners',
    'crystal matrices',
    'heat sinks',
    'focusing lenses',
    'plasma nozzles'
]

const destinations = [
    'listening post',
    'training facility',
    'dig site',
    'survey camp',
    'science lab',
    'observatory'
]

export const delivery = {
    name: 'Delivery',
    repeatable: true,
    modalSize: 'small',
    stages: [
        {
            id: 0,
            location: ['factory'],
            data: (e) => {
                const p = e.pilot
                const starMap = e.starMap
                const danger = getPilotRank(p)
                
                const planet = findPlanet(starMap, { minJumps: 3, maxJumps: 6, danger } )

                const storyData = {
                    deliveryItems: getRandomItem(items),
                    deliveryLocation: getRandomItem(destinations),
                    danger 
                }
                                
                return {
                    title: `DELIVERY: ${toTitleCase(storyData.deliveryItems)}`,
                    description: [
                        `The Chief Technician summons PNAME to her office. "We have a request for a shipment of ${storyData.deliveryItems} to be delivered to a ${storyData.deliveryLocation} on ${planet.name}. It's a hot zone, so we'll need to clear the way with a Gauntlet team first."`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    planet,
                    storyData,
                    options: [
                        { 
                            attr: 'wis',
                            skill: 'per',
                            dc: 15,
                            level: 'hard',
                            text: `Study the intelligence and try to plan for the assault`,
                            success: {
                                text: [
                                    `PNAME looks up the planet coordinates in the database. PZE cross references recent reports from the area and develops a probable threat assessment.`,
                                    `While it's impossible to know exactly what they'll be up against, PNAME feels confident that pze won't find any big surprises when they reach the drop zone.`
                                ],
                                attr: 'wis',
                                attrValue: 20,
                                markPlanet: true,
                                nextStage: 1
                            },
                            fail: {
                                text: [
                                    `PNAME opens the star charts and tries to get an idea of what will be waiting for them on ${planet.name}. Between the contradictory reports and missing logs, there's just not enough for pzir to make any useful predictions about the danger on the planet.`,
                                    `"Well, what do you think?" asks the Chief Technician.`,
                                    `PNAME scratches pzis head. "I'm not entirely sure."`,
                                    `The Chief sighs. "Just go do the job," she says, and waves PNAME out of her office.`
                                ],
                                metric: 'fame',
                                metricValue: -10,
                                markPlanet: true,
                                nextStage: 1
                            }
                        },
                        { 
                            attr: 'reas',
                            skill: 'repair',
                            dc: 10,
                            level: 'med',
                            text: `Try to optimize the payload for safety and security`,
                            success: {
                                text: [
                                    `A Gauntlet isn't designed to carry cargo, so the on board storage space is limited. PNAME studies the spec sheet on the ${storyData.deliveryItems} and pulls up a schematic of pzis Gauntlet.`,
                                    `A few minutes' work allows pzir to determine the best arrangement of containers to distribute weight evenly and keep them protected by the thickest armor. It's not a guarantee of safety, but it should increase the margin of error.`
                                ],
                                attr: 'reas',
                                attrValue: 10,
                                markPlanet: true,
                                nextStage: 1
                            },
                            fail: {
                                text: [
                                    `PNAME looks at the spec sheet for the cargo and tries to determine the best way to load it into pzis Gauntlet. PZE isn't able to find an  arrangement that seems better than any other. After a little while pze gives up and decides to load it however it will fit.`
                                ],
                                markPlanet: true,
                                nextStage: 1
                            }
                        },
                        { 
                            text: `Accept the delivery and prepare to launch`,
                            level: 'easy',
                            success: {
                                text: [
                                    `There doesn't seem to be any need to overthink a simple delivery mission. PNAME downloads the specs for the shipment to pzis datapad and heads to the launch bay to oversee the loading.`
                                ],
                                attr: 'hum',
                                attrValue: 5,
                                markPlanet: true,
                                nextStage: 1
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 1,
            location: ['planet'],
            hint: `PNAME has been tasked to make a delivery to PLANETNAME.`,
            data: (e) => {
                const p = e.pilot
                const planet = e.planet
                const storyData = e.storyData
                
                return {
                    title: `DELIVERY: ${toTitleCase(storyData.deliveryItems)}`,
                    description: [
                        `Now that the major threats on ${planet.name} have been eliminated, PNAME has to deliver the ${storyData.deliveryItems} to the ${storyData.deliveryLocation}. There are still lesser dangers between pzir and the destination, however.`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    options: [
                        { 
                            attr: 'prow',
                            skill: 'pilot',
                            dc: 15,
                            level: 'hard',
                            text: `Plot a course to the ${storyData.deliveryLocation} that avoids the dangers`,
                            success: {
                                text: [
                                    `PNAME calls up a view of the area generated from the Academy ship's active scanner data. Watching the shifting conditions and analyzing the terrain, pze guides the team deftly through the spaces between the most treacherous places.`,
                                    `Soon the Gauntlet reaches the ${storyData.deliveryLocation}, having suffered only superficial damage during the journey. The ${storyData.deliveryItems} arrive in pristine condition, and the recipient is happy to pay a premium for excellent service.`
                                ],
                                attr: 'prow',
                                attrValue: 20,
                                xenos: storyData.danger + 1,
                                nextStage: 'reset'
                            },
                            fail: {
                                text: [
                                    `Looking at the route to the ${storyData.deliveryLocation}, PNAME tries to find the safest course through the remaining dangers and treacherous terrain.`,
                                    `Every turn seems to lead to a dead end, and unexpected perils plague the journey. Though the Gauntlet itself only sustains superficial damage, the cargo of ${storyData.deliveryItems}is battered by the rough trip. The recipient is less than pleased with the condition of their goods, and reduces their payment accordingly.`
                                ],
                                xenos: storyData.danger - 1,
                                nextStage: 'reset'
                            }
                        },
                        { 
                            attr: 'conf',
                            skill: 'reaction',
                            dc: 15,
                            level: 'hard',
                            text: `Take the straightest path and avoid the dangers as they come`,
                            success: {
                                text: [
                                    `PNAME gives the rest of the team the plan: forge ahead and power through anything that comes their way. The crew of the Gauntlet works together in perfect sync, driving the enormous war machine forward across the landscape.`,
                                    `Though there are still dangers and treacherous terrain to navigate on the way to the ${storyData.deliveryLocation}, they weave their way through with skill and panache.`,
                                    `The cargo of ${storyData.deliveryItems} arrives unscathed, and the recipient is pleased to pay a premium for good service.`
                                ],
                                attr: 'conf',
                                attrValue: 20,
                                xenos: storyData.danger + 1,
                                nextStage: 'reset'
                            },
                            fail: {
                                text: [
                                    `PNAME convinces the team that they should drive straight through to the ${storyData.deliveryLocation} along the straightest path and deal with the challenges as they arrive. Everyone is on board, but not entirely convinced.`,
                                    `Every slip on bad terrain and unexpected danger erodes the morale of the Gauntlet's crew. The cargo is battered by the seemingly endless series of missteps and sustains some damage by the time it arraives at the destination.`,
                                    `The recipient is happy to receive their ${storyData.deliveryItems}, but would have paid more if they were in better condition.` 
                                ],
                                xenos: storyData.danger - 1,
                                nextStage: 'reset'
                            }
                        },
                        { 
                            attr: 'emp',
                            skill: 'comm',
                            dc: 10,
                            level: 'med',
                            text: `Cover the remaining distance carefully and explain to the recipient that it's better to be safe than fast`,
                            success: {
                                text: [
                                    `PNAME and rest of the Gauntlet crew forge ahead carefully, making sure to detour around any treacherous terrain or dangerous situations. While it takes a little longer to proceed with caution, the ${storyData.deliveryItems} arrive at their destination undamaged.`,
                                    `As the cargo is being unloaded at the ${storyData.deliveryLocation}, PNAME chats with the recipient, recounting the day's battles and tribulations. Impressed by pzis dedication, they add a tip onto the agreed delivery fee.`
                                ],
                                attr: 'emp',
                                attrValue: 10,
                                xenos: storyData.danger,
                                credits: storyData.danger * 100,
                                nextStage: 'reset'
                            },
                            fail: {
                                text: [
                                    `The team picks their way carefully across the landscape, avoiding dangers and treacherous terrain. Eventually they arrive at the ${storyData.deliveryLocation} with the cargo intact.`,
                                    `As the crates are being unloaded, PNAME tries to engage with the recipient and explain the reasons for pzis cautious approach, but they don't seem to be interested in any such explanations. They are happy to pay the agreed upon fee, but no more.` 
                                ],
                                xenos: storyData.danger,
                                nextStage: 'reset'
                            }
                        }
                    ]
                }        
            }
        }
    ]
}
