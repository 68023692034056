export const mechList = {
    Triton: {
        notes: 'One of the first producers of Gauntlet systems, Triton sets the standard for others to follow. Their combat systems focus on denying enemy actions while making decisive counters.',
        special: {
            head: 'stun',       // delay next enemy action
            arm: 'powerShot',   // max damage on attack
            leg: 'staticPulse'  // drain charge on one enemy weapon
        },
        models: {
            Kraken: {
                speed: 0.7,
                armor: {
                    head:       25,
                    leftArm:    25,
                    rightArm:   25,
                    leftLeg:    25,
                    rightLeg:   25,
                    body:       45    
                }
            },
            Argonaut: {
                speed: 1.0,
                armor: {
                    head:       30,
                    leftArm:    30,
                    rightArm:   30,
                    leftLeg:    30,
                    rightLeg:   30,
                    body:       50    
                }
            },
            Nautilus: {
                speed: 1.2,
                armor: {
                    head:       40,
                    leftArm:    40,
                    rightArm:   40,
                    leftLeg:    40,
                    rightLeg:   40,
                    body:       60    
                }
            },
        }
    },
    Orbus: {
        notes: 'Orbus designs are clean and efficient, with exceptionally resilient infrastructure. While not as heavily armored as other Gauntlet types, the distributed repair systems allow for fast recovery from superficial damage.',
        special: {
            head: 'rush',   // bolster all limb pilots
            arm: 'pierce',  // automatic critical hit
            leg: 'overhaul' // repair to all armor locations
        },
        models: {
            Photon: {
                speed: 0.7,
                armor: {
                    head:       20,
                    leftArm:    20,
                    rightArm:   20,
                    leftLeg:    20,
                    rightLeg:   20,
                    body:       60    
                }
            },
            Lightspeed: {
                speed: 1.0,
                armor: {
                    head:       25,
                    leftArm:    25,
                    rightArm:   25,
                    leftLeg:    25,
                    rightLeg:   25,
                    body:       70    
                }
            },
            Quasar: {
                speed: 1.2,
                armor: {
                    head:       35,
                    leftArm:    35,
                    rightArm:   35,
                    leftLeg:    35,
                    rightLeg:   35,
                    body:       80    
                }
            }
        }
    },
    Diablo: {
        notes: 'The Diablo line of Gauntlets is aggressive in both style and function, focusing on dominating the offensive side of a conflict. A unique internal regeneration system offsets the relatively delicate core structure.',
        special: {
            head: 'distract',   // cause next enemy attack to miss
            arm: 'fork',        // attack two locations simultaneously
            leg: 'coreRepair'   // repair body damage
        },
        models: {
            Nemesis: {
                speed: 0.7,
                armor: {
                    head:       30,
                    leftArm:    30,
                    rightArm:   30,
                    leftLeg:    30,
                    rightLeg:   30,
                    body:       35    
                }
            },
            Hellfire: {
                speed: 1.0,
                armor: {
                    head:       35,
                    leftArm:    35,
                    rightArm:   35,
                    leftLeg:    35,
                    rightLeg:   35,
                    body:       40    
                }
            },
            Leviathan: {
                speed: 1.2,
                armor: {
                    head:       45,
                    leftArm:    45,
                    rightArm:   45,
                    leftLeg:    45,
                    rightLeg:   45,
                    body:       50    
                }
            }
        }
    },
    unique: {
        blackNemesis: {
            notes: 'A prototype Gauntlet built using exotic xenomaterials, the Black Nemesis is wickedly fast and uses stealth technology to evade attacks. Its signature thermal scythe is deadly at close range, while a particle beam cannon offers precision fire from a distance.',
            make: 'Diablo',
            model: 'Nemesis',
            name: 'Black Nemesis',
            special: {
                head: 'distract',
                arm: 'pierce',
                leg: 'overhaul'
            },
            speed: 0.6,
            armor: {
                head:       30,
                leftArm:    30,
                rightArm:   30,
                leftLeg:    30,
                rightLeg:   30,
                body:       40    
            },
            leftWeapon: 'particleBeam',
            rightWeapon: 'thermalScythe'
        }
    }
}

export const mechSpecials = {
    distract: `The Gauntlet projects a holographic image to distract and confuse its opponents. This will cause the enemy's next attack to miss completely.`,
    fork: `This ability directs two simultaneous attacks at different areas of the enemy.`,
    coreRepair: `Using advanced nanobots, repairs to the Gauntlet body may be done while in combat.`,
    rush: `Multi-channel communication systems allow the head pilot to coordinate all positions simultaneously, allowing for actions to be synchronized faster.`,
    pierce: `A focused attack able to break through the enemy's defenses.`,
    overhaul: `Networked repair systems are able to fix damage in all areas simultaneously.`,
    stun: `A burst of focused sonic energy scrambles the enemy's neural pathways, slowing their next action.`,
    powerShot: `Optimized energy conversion allows for a single attack at maximum power.`,
    staticPulse: `Using a pulse of charged ions, one of the enemy's weapon systems is temporarily neutralized.`

}