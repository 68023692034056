import { attrList, attrQuirkEffects }from '../lib/constants'

export default function AttrBlock({ pilot }) {
    const getQuirkModIcon = (pilot, attr) => {
        if (!pilot.quirks) {
            return ''
        } else if (pilot.quirks[attrQuirkEffects[attr].bonus]) {
            return '+'
        } else if (pilot.quirks[attrQuirkEffects[attr].malus]) {
            return '-'
        } else {
            return ''
        }
    }

    return <div className="attrBlock">
        <div className="attrValues">
            { attrList.map((attr, i) => <div key={i} className="attrBar">
                    <div className={`attrFill bg-attr-${attr}`} style={{ height: `${pilot.attributes[attr] * 10}%`}}>

                    </div>
                </div>
            )}
        </div>
        <div className="attrIcons">
            { attrList.map((attr, i) => <div key={i} className={`attrIcon color-attr-${attr}`}>
                    { attr[0].toUpperCase() }
                </div>
            )}
        </div>
        <div className="attrIcons">
            { attrList.map((attr, i) => <div key={i} className={`attrIcon bonusMalus color-attr-${attr}`}>
                    { getQuirkModIcon(pilot, attr) }
                </div>
            )}
        </div>
    </div>
}