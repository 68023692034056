import { Image } from 'semantic-ui-react'
import { getSkillIcon, getMetricIcon } from '../../lib/pilot'

export const gauntlets = {
    key: 'gauntlets',
    title: 'Gauntlets',
    body: <div>
            <p>Gauntlets are big.</p>
        </div>,
    items: [
        {
            key: 'armor',
            title: 'Armor',
            body: <div>
                <p>
                    Armor
                </p>
            </div>
        },
        {
            key: 'weapons',
            title: 'Weapons',
            body: <div>
                <p>
                    Weapons
                </p>
            </div>
        },
        {
            key: 'specials',
            title: 'Special Abilities',
            body: <div>
                <p>
                    Special abilities
                </p>
            </div>
        },
    ]
}