import React, { Component } from 'react';
import PilotRow from './pilotRow'

export class PilotList extends Component {
    render() {
        const { emptySlots } = this.props

        return <div className="pilotList">
            {this.props.pilots.map((p, i) => (
                <PilotRow 
                    db={this.props.db}
                    user={this.props.user}
                    key={i} 
                    pilot={p}
                    selectPilot={this.props.selectPilot}
                />
            ))}
            { emptySlots > 0 && Array(emptySlots).fill().map((a, i) => (
                <div key={i} className="pilotRow empty"/>
            ))}
        </div>
    }
  }