import { Grid, Icon } from 'semantic-ui-react'
import { numerals } from '../../lib/constants'
import { camelCaseToTitleCase } from '../../lib/textUtils'
import HealthBar from '../healthBar'

export default function FactoryQueue({ academy, showFactoryQueue }) {
    const job = academy.data.factoryOrders[0]

    return (
        <Grid.Row className="academyRoom factoryQueue" onClick={() => showFactoryQueue()}>
            <Icon name="list"/>
            { job ? <div className="jobName">
                { `${camelCaseToTitleCase(job.type)} ${numerals[job.rank - 1]}`}
            </div> :
            <div className="jobName empty">
                Factory queue is empty
            </div> }
            <div className="jobProgress">
                { job && <HealthBar
                    className="progressBar"
                    curr={job.progress}
                    max={job.cost}
                /> }
            </div>
            {/* <pre>{JSON.stringify(job, null, 2)}</pre> */}
        </Grid.Row>
    )
}