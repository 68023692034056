import { ref, update,  onValue } from 'firebase/database';

export default class Entity {
    db = null
    id = null
    unsub = null
    data = null

    constructor(db, user, id, type, data) {
        this.db = db
        this.user = user
        this.id = id
        this.type = type

        if (type === 'academy') {
            const academyRef = ref(db, `academy/${user.uid}`);
        
            this.unsub = onValue(academyRef, async (snapshot) => {
              if (!snapshot.exists()) {
                await this.generateData()
              } else {
                let data = snapshot.val();

                const defaults = {
                    tutorials: {},
                    stories: {},
                    upgrades: {},
                    modules: {},
                    schematics: [],
                    factoryOrders: [],
                    settings: {
                        playMusic: true,
                        playSound: true,
                        volume: 0.5,
                        minigames: 'sometimes',
                        battleSpeed: 'normal',
                    }                            
                }

                data = Object.assign(defaults, data)
                this.setData(data);
              }
            });      
        } else if (type === 'starMap') {
            const starMapRef = ref(db, `starMap/${user.uid}`);
        
            this.unsub = onValue(starMapRef, async (snapshot) => {
              if (!snapshot.exists()) {
                await this.generateData()
              } else {
                let data = snapshot.val();
                this.setData(data);
              }
            });      
        } else if (id) {
            // we want to persist this in the DB (player pilots and mechs)
            // const docRef = doc(db, type, id)
        
            // this.unsub = onSnapshot(docRef, (doc) => {
            //     this.setData(doc.data())
            // });
            const basePath = `${this.type}/${user.uid}/${this.id}`

            const docRef = ref(this.db, basePath)
            this.unsub = onValue(docRef, (doc) => {
                this.setData(doc.val())
            });
        } else {
            // initialize from data passed in (kaijus, PVP pilots and mechs)
            this.setData(data)
        }
    }

    setData(data) {
        const needsInit = !this.data

        if (this.data) {
            // make sure we retain any unstored defaults
            this.data = Object.assign(this.data, data)
        } else {
            this.data = data
        }

        if (needsInit) {
            this.initData()
        }
    }

    // override this
    initData() {
        // console.log(`entity (${this.type}) initData`)
        return
    }

    // override this
    generateData() {
        // console.log(`entity (${this.type}) generateData`)
        return
    }

    async updateData(payload) {
        if (this.type === 'academy' || this.type === 'starMap' || this.id) {
            // manage data from DB
            const basePath = `${this.type}/${this.user.uid}${this.id ? `/${this.id}` : ''}`
                const docRef = ref(this.db, basePath)
            update(docRef, payload)
        } else {
            // directly update the data
            Object.assign(this.data, payload)
        }
    }

    cleanup() {
        if (this.unsub) {
            this.unsub()
        }
    }
}