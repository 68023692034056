import { get, ref, set, push, update, remove } from 'firebase/database';
import Combatant from './combatant'
import { randInt, getRankedValue, getRandomItem } from './mathUtils';
import { slotTypes } from './constants';
import { weaponList } from '../data/weapon';
import { mechList } from '../data/mech';

export const mechDefaults = {
    stats: {}
}

export const generateMech = (db, user, make, model, rank, leftWeapon = 'boltCannon', rightWeapon = 'plasmaBeam') => {
    let mechModel = null
    let special = null
    let unique = null
    let name = ''

    if (!make) {
        // get a random make/model
        const allMakes = Object.keys(mechList).filter((m) => m !== 'unique')
        make = getRandomItem(allMakes)
        
        const allModels = Object.keys(mechList[make].models)
        model = getRandomItem(allModels)
    } 
    
    if (make === 'unique') {
        unique = model
        
        mechModel = mechList.unique[model]

        name = mechModel.name

        special = { ...mechModel.special }

        if (mechModel.leftWeapon) {
            leftWeapon = mechModel.leftWeapon
        }
        if (mechModel.rightWeapon) {
            rightWeapon = mechModel.rightWeapon
        }
    } else {
        const mechMake = mechList[make]
        special = { ...mechMake.special }
        mechModel = mechMake.models[model]
    }
    
    const armor = {}
    for (const slot in mechModel.armor) {
        armor[slot] = getRankedArmor(mechModel.armor[slot], rank)
    }

    const mechData = {
        type: 'mech',
        make: unique ? mechModel.make : make,
        model: unique ? mechModel.model : model,
        unique,
        color: 0,
        name,
        team: 0,
        rank,
        speed: mechModel.speed,
        special,
        armor: { ...armor },
        maxArmor: { ...armor },
        leftWeapon: {
            type: leftWeapon,
            status: 'online',
            ...weaponList[leftWeapon]
        },
        rightWeapon: {
            type: rightWeapon,
            status: 'online',
            ...weaponList[rightWeapon]
        },
        created: Date.now(),
        lastBattle: Date.now(),
        user: user?.uid,
        stats: {}
    }

    if (db) {
        // Add a new document with a generated id
        const mechListRef = ref(db, `mechs/${user.uid}`)
        const newMechRef = push(mechListRef)

        mechData.id = newMechRef.key

        set(newMechRef, mechData)
    } else {
        // non-persistent data for purchase modal, etc.
        return mechData
    }
}

export const getRankedArmor = (baseArmor, rank) => {
    const rankedArmor = getRankedValue(baseArmor, rank)
    return Math.round(rankedArmor / 5) * 5
}

export const getSpeedDesc = (speed) => {
    if (speed <= 0.6) {
        return 'very fast'
    } else if (speed < 1) {
        return 'fast'
    } else if (speed >= 1.3) {
        return 'very slow'
    } else if (speed > 1) {
        return 'slow'
    } else {
        return 'average'
    }
}

export const loadMech = async (db, user, id) => {
    const mechRef = ref(db, `mechs/${user.uid}/${id}`)

    const data = await get(mechRef);

    return Object.assign({}, mechDefaults, data.val())
}

export const changeMechTeam = async (db, user, id, team) => {
    const mechRef = ref(db, `mechs/${user.uid}/${id}`)
    await update(mechRef, { team })
}

export const deleteMech = async (db, user, id) => {
    const mechRef = ref(db, `mechs/${user.uid}/${id}`)
    await remove(mechRef)
}

export const moveMechToLocation = async (db, user, id, location) => {
    const mechRef = ref(db, `mechs/${user.uid}/${id}`)
    await update(mechRef, { location })
}

export const isMechDamaged = (mechData) => (
    mechData.armor.body < mechData.maxArmor.body 
    || mechData.armor.head < mechData.maxArmor.head
    || mechData.armor.leftArm < mechData.maxArmor.leftArm
    || mechData.armor.rightArm < mechData.maxArmor.rightArm
    || mechData.armor.leftLeg < mechData.maxArmor.leftLeg
    || mechData.armor.rightLeg < mechData.maxArmor.rightLeg
)

export const getWorstMechDamage = (mechData) => {
    let maxDamage = 0
    let worstSlot = ''

    for (const slot of [ 'body', ...slotTypes ]) {
        const damage = mechData.maxArmor[slot] - mechData.armor[slot]
        if (damage > maxDamage) {
            maxDamage = damage
            worstSlot = slot
        }
    }

    return { slot: worstSlot, damage: maxDamage }
}

export const repairMech = async (db, user, id, amount, slot) => {
    const mechRef = ref(db, `mechs/${user.uid}/${id}`)
    const mechDoc = await get(mechRef)
    const data = mechDoc.val()

    const newArmor = { ...data.armor }

    const newValue = Math.min(data.armor[slot] + amount, data.maxArmor[slot])

    newArmor[slot] = newValue

    if (slot && newValue) {
        await update(mechRef, { armor: newArmor })
    }

    return newValue
}

export const pickSlot = (exclude = 'none') => {
    let slot = ''

    while (!slot || slot === exclude) {
        slot = slotTypes[randInt(0, 4)]
    }

    return slot
}

export const applyStatsToMech = async (db, user, mechData, stats) => {
    const updateData = { lastBattle: Date.now() }

    // stats
    if (!mechData.stats) {
        updateData.stats = stats
    } else {
        const newStats = { ...mechData.stats }

        for (const statType in stats) {
            if (newStats[statType]) {
                newStats[statType] += stats[statType]
            } else {
                newStats[statType] = stats[statType]
            }
        }

        updateData.stats = newStats
    }

    const mechRef = ref(db, `mechs/${user.uid}/${mechData.id}`)
    await update(mechRef, updateData)

}

export class Mech extends Combatant {
    constructor(db, user, id, data) {
        super(db, user, id, 'mechs', data)
    }

    initData() {
        if (!this.status) {
            this.status = {}
        }

        this.data = Object.assign({}, mechDefaults, this.data)
    }

    applyStats(stats) { // combine these to reduce db calls
        applyStatsToMech(this.db, this.user, { id: this.id, ...this.data }, stats)
    }

    applyStatus(name, value) {
        if (!this.status) {
            this.status = {}
        }

        if (!this.status[name]) {
            this.status[name] = value
        } else {
            this.status[name] += value
        }
    }

    setWeaponStatus(weaponName, value) {
        this.data[weaponName].status = value
    }
}