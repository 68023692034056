/* eslint-disable react-hooks/exhaustive-deps */
import * as d3 from "d3"
import React, { useEffect, useReducer } from "react"
import { sortByKey } from '../arrayUtils'
import { numerals } from '../constants'
import starLinksData from '../../data/starLinks.csv'

const width = 1080
const height = 600

const initialState = { 
  starMap: null,        // the starMap object
  starLinks: null,      // link data from csv
  nodes: null,          // the graph nodes
  links: null,          // the graph links
  selectedNode: null,   // the currently selected star
  orbitNode: null,      // the star currently orbiting
  orbitLoc: null,       // the spinning gear marker
  storyPlanets: {},
  currZoom: 4,          // the current zoom level
  currCenter: null,     // the current view center
  showDanger: false,    // show the danger overlay
  showStory: false,    // show the story markers
  reload: 0,    // show the danger overlay
  initialized: false    // are we initialized?
}

function reducer(state, action) {
  // console.log(action)
  switch(action.type) {
    case 'clearGraphData':
      return { ...state, nodes: null, links: null, selectedNode: null, orbitNode: null }
    case 'reload':
      return { ...state, reload: state.reload + 1}
    case 'initStarMap':
    case 'setGraphData':
    case 'setShowDanger':
    case 'setShowStory':
    case 'setOrbitNode':
      return { ...state, ...action.data }
    default:
      throw new Error()
  }
}

let pathLinks = []
let currCenter = { x: width / 2, y: height/ 2 }
let currZoom = 4

export default function Graph (props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // console.log('init graph')
      async function getStarData() {
          const linkRows = await d3.csv(starLinksData)

          const storyPlanets = {}
          const stories = props.academy.data.stories
          for (const s in stories) {
            const theStory = stories[s]

            if (theStory.planet && theStory.stageId !== 'complete') {
              const thePlanet = theStory.planet

              if (!storyPlanets[thePlanet.starId]) {
                storyPlanets[thePlanet.starId] = [ thePlanet.planetId ]
              } else {
                storyPlanets[thePlanet.starId].push(thePlanet.planetId)
              }
            }
          }
          
          dispatch({ type: 'initStarMap', data: { starLinks: linkRows, starMap: props.starMap, storyPlanets }})
      }
      getStarData()
  }, []) // [] means just do this once, after initial render

  const calcGraphData = () => {
    // console.log('calcGraphData')
    if (!state.starLinks || !state.starMap) { 
      setTimeout(() => dispatch({ type: 'reload' }), 100)
      return
    }

    const knownStars = []
    const knownStarData = {}
    
    for (const sKey in state.starMap.data.stars) {
      const star = state.starMap.data.stars[sKey]

      if (star.known) {
          knownStars.push(star)
          knownStarData[star.id] = star
      }
    }

    const nodes = knownStars.map((s, i) => ({
      key: s.name,
      id: s.id, 
      hasRaiders: s.hasRaiders, 
      index: s.id,
      x: parseInt(s.x, 10),
      y: parseInt(s.y, 10),
      size: parseInt(s.magnitude, 10) + 1,
      color: parseInt(s.color, 10),
      routes: state.starLinks.filter((l) => l.source === s.id),
      data: s
    })) 

    const links = []

    for (const link of state.starLinks) {
        if (knownStarData[link.source] && knownStarData[link.target]) {
            links.push({
                source: knownStarData[link.source],
                target: knownStarData[link.target],
                size: 2
            })
        }
    }

    const graphData = { nodes, links }

    if (!state.selectedNode) {
      // this is our first render, so set the selected and orbit nodes
      graphData.selectedNode = state.starMap.data.stars[state.starMap.data.orbitStarId]
      graphData.orbitNode = graphData.selectedNode
      currCenter = { x: graphData.selectedNode.x, y: graphData.selectedNode.y }
    }
    dispatch({ type: 'setGraphData', data: graphData })
  }

  if (!state.nodes) {
    // console.log('no nodes')
    // do the inital node and link calculation
    calcGraphData()
    return null
  }

  d3.select('#theGraph > svg').remove()

  const svg = d3.select('#theGraph').append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")      
  
  svg.append("rect")
    .attr("width", width)
    .attr("height", height)
    .style("fill", "none")
    .style("pointer-events", "all")
  

  // Zoom definition
  var zoom = d3.zoom()
    .scaleExtent([1, 7])
    .clickDistance(4)
    .on("zoom", zoomed);
  
  var linkContainer = svg.append("g");
  var nodeContainer = svg.append("g").attr("class", "nodeContainer");

  const getLinkCode = (a, b) => {
    return parseInt(a, 10) < parseInt(b, 10) ? `${a}-${b}` : `${b}-${a}`
  }

  function zoomed(event) {
    const transform = event.transform
    linkContainer.attr("transform", "translate(" + transform.x + "," + transform.y + ") scale(" + transform.k + ")");
    nodeContainer.attr("transform", "translate(" + transform.x + "," + transform.y + ") scale(" + transform.k + ")");

    // const zoomData = { currZoom: transform.k }
    currZoom = transform.k

    if (currCenter) {
      // zoomData.currCenter = { x: state.currCenter.x + transform.x, y: state.currCenter.y + transform.y }
      currCenter = { x: currCenter.x + transform.x, y: currCenter.y + transform.y }
    }

    node.select('.starNode.core')
      .attr('r', (d) => {
        return (d.size / 2) / (Math.sqrt(transform.k) )
      })

    node.select('.starNode.glow')
      .attr('r', (d) => {
        return d.size / (Math.sqrt(transform.k) )
      })

    node.select('.selectedStar')
      .attr('r', (d) => {
        return (d.size + 2) / (Math.sqrt(transform.k) )
      })

    node.select('.dangerZone')
      .attr('r', (d) => {
        return 40 / (Math.sqrt(transform.k) )
      })

    node.select('.hitBox')
      .attr('r', (d) => {
        return 6 / (Math.sqrt(transform.k) )
      })

    node.select('text')
      .style('font-size', `${14 / transform.k}px`)
      .attr("opacity", (d) => transform.k > 3 ? 1 : 0)

    link.attr('stroke-width', (d) => 2 / transform.k)

    const orbitWidth = 30 / (Math.sqrt(transform.k))
    const raiderWidth = 20 / (Math.sqrt(transform.k))

    svg.select('.orbitStar')
      .attr('x', state.orbitNode.x - (orbitWidth / 2))
      .attr('y', state.orbitNode.y - (orbitWidth / 2))
      .attr('width', orbitWidth)
      .attr('height', orbitWidth)

    svg.selectAll('.raiderStar')
      .attr('x', (d) => d.x - (raiderWidth / 2))
      .attr('y', (d) => d.y - (raiderWidth / 2))
      .attr('width', raiderWidth)
      .attr('height', raiderWidth)
  }
  
  svg.call(zoom)
  
  // build the links 

  var link = linkContainer.append("g")
    .attr("class", "links")
    .selectAll(".links")
    .data(state.links)
    .enter().append("line")
    .attr("class", "link")
    .attr("x1", (d) => d.source.x)
    .attr("y1", (d) => d.source.y)
    .attr("x2", (d) => d.target.x)
    .attr("y2", (d) => d.target.y)
    .attr("stroke-width", 2)

  var node = nodeContainer.append("g")
    .attr("class", "nodes")
    .selectAll(".node")
    .data(state.nodes)
    .enter().append("g")
    .attr("class", (d) => `node ${state.storyPlanets[d.id] ? 'story' : ''} ${d.hasRaiders ? 'raiders' : ''}`)
    .attr("x", (d) => {
      return d.x
    })
    .attr("y", (d) => d.y)

  node.append('circle') 
    .attr("cx", (d) => d.x)
    .attr("cy", (d) => d.y)
    .attr("r", (d) => 40)
    .attr('class', (d) => `dangerZone danger-${d.data.maxDanger} ${ state.showDanger ? 'visible' : ''}`)
  node.append('circle') 
    .attr("cx", (d) => d.x)
    .attr("cy", (d) => d.y)
    .attr("r", (d) => d.size + 2)
    .attr('class', (d) => `selectedStar`)
  node.append('circle')
    .attr("cx", (d) => d.x)
    .attr("cy", (d) => d.y)
    .attr("r", (d) => d.size / 2)
    .attr('class', (d) => `starNode core temp-${d.color}`)
  node.append('circle')
    .attr("cx", (d) => d.x)
    .attr("cy", (d) => d.y)
    .attr("r", (d) => d.size)
    .attr('class', (d) => `starNode glow temp-${d.color}`)
  node.append('circle')
    .attr("cx", (d) => d.x)
    .attr("cy", (d) => d.y)
    .attr("r", 6)
    .attr('class', (d) => `hitBox`)
    .on('click', (e, d) => {    
      state.selectedNode = d.data
      clearSelectedStar()
    
      zoomToSelected(1000)
      setTimeout(() => {
        markSelectedStar()
        updateLinks()
      }, 1000)
    })   

  d3.selectAll('.raiders')
    .append('svg:image')
    .lower()
    .attr('class', 'raiderStar spinning')
    .style('-webkit-transform-origin', (d) => `${d.x}px ${d.y}px`)
    .attr('width', 20)
    .attr('height', 20)
    .attr('x', (d) => d.x - 10)
    .attr('y', (d) => d.y - 10)
    .attr("xlink:href", "/assets/images/raiderGear.svg")      
  
  d3.selectAll('.story')
  .append('circle')
  .lower()
  .attr("cx", (d) => d?.x)
  .attr("cy", (d) => d?.y)
  .attr("r", (d) => d?.size)
  .attr('class', (d) => `storyNode ${ state.showStory ? 'animate-flicker' : ''}`)
        
  const getLinkClass = (d) => {
    const linkCode = getLinkCode(d.source.id, d.target.id)

    if (pathLinks.includes(linkCode)) {
      return 'path'
    } else if (d.source.id === state.orbitNode.id || d.target.id === state.orbitNode.id) {
      return 'local'
    } else {
      return ''
    }
  }

  const travelToSelected = () => {
    props.academy.removeCredits(getJumpCost())
    props.playSoundEffect('jumpDrive')

    zoomToSelected(500)
    const speedFactor = Math.min(pathLinks.length, 4)
    setTimeout(() => {
      moveOrbit(state.orbitNode.id, 1000 / speedFactor)
    }, 500)
  }

  const disableTravel = () => {
    d3.select('.starInfo')
      .remove()
  }

  const getJumpCost = () => {
    return pathLinks.length * props.academy.jumpCost
  }

  const canAffordTravel = () => {
    const cost = getJumpCost()
    return props.academy.credits >= cost
  }

  const isSafeToTravel = () => {
    const lastLink = pathLinks[pathLinks.length - 1]
    const tokens = lastLink.split('-')
    const selectedId = state.selectedNode.id
    const penultimateId = tokens[0] === selectedId ? tokens[1] : tokens[0]
    const penultimateStar = state.starMap.data.stars[penultimateId]
    const selectedStar = state.starMap.data.stars[selectedId]
    return penultimateStar.beacon || selectedStar.beacon
  }

  const drawPlanetList = () => {
    d3.select('.starInfo').remove()

    const infoPanel = d3.select('#theGraph')
      .append('div')
        .attr('class', 'starInfo')

    infoPanel.append('div')
      .attr('class', 'starName')
      .html(state.selectedNode.name)
    infoPanel.append('div')
      .attr('class', 'planetList')
      .append('table')
        .attr('class', 'planetTable')

    let orderedPlanets = []

    for (let pKey in state.selectedNode.planets) {
      orderedPlanets.push({ ...state.selectedNode.planets[pKey], id: pKey })
    }

    orderedPlanets = sortByKey(orderedPlanets, 'position')

    orderedPlanets.forEach((p) => {
      const theRow = d3.select('.planetTable')
        .append('tr')
          .attr('class', 'planetInfo')

      theRow.append('td')
        .attr('class', 'planetIconCell')
        .append('div')
          .attr('class', `planetIcon ${p.type}`)
          .append('div')
            .attr('class', 'planetHighlight')

      theRow.append('td')
        .append('div')
          .attr('class', `planetName danger-${p.danger}`)
          .html(`${state.selectedNode.name} ${numerals[p.position - 1]}`)

      if (state.selectedNode.id === state.orbitNode.id) {
        const thePlanets = state.storyPlanets[state.orbitNode.id]

        theRow.append('td')
          .on('click', () => props.launchBattle(state.orbitNode.id, p.id))
          .attr('class', 'launchIconCell')
          .append('i')
            .attr('class', `rocket large icon iconBtn  launchBtn ${p.danger === 0 ? 'display-none' : ''} ${ (thePlanets && thePlanets.includes(p.id)) || p.raiders ? 'story' : ''}`)
      }
    })

    if (pathLinks.length > 0 && isSafeToTravel()) {
      const travelBtn = infoPanel.append('div')
        .attr('class', 'travelBtn')

      travelBtn.append('button')
          .attr('class', `ui button floatLeft ${canAffordTravel() ? '' : 'disabled'}`)
          .html('TRAVEL')
          .on('click', () => travelToSelected())

      const costDiv = travelBtn.append('div')
          .attr('class', `jumpCost floatRight`)

      costDiv.append('i')
            .attr('class', `cube icon iconBtn `)

      costDiv.append('span')
            .html(`${getJumpCost()}`)
    }
  }

  const markSelectedStar = async () => {
    node.select('.selectedStar')
      .attr("class", (d) => `selectedStar ${d.id === state.selectedNode.id ? 'animate-flicker' : ''}`)
      
    const path = state.starMap.findPathToStar(state.selectedNode.id, state.orbitNode.id)
    
    pathLinks = []
    
    if (path.length > 0) {
      for (let i = 0; i < path.length - 1; i++) {
        pathLinks.push(getLinkCode(path[i], path[i + 1]))
      }
    }

    drawPlanetList()
  }

  const updateLinks = () => {
    link.attr("class", (d) => `links ${getLinkClass(d)}`)
  }

  const markOrbitStar = () => {
    svg.select('.orbitStar').remove()

    nodeContainer.append('svg:image')
      .lower()
      .attr('class', 'orbitStar')
      .attr('width', 40)
      .attr('height', 40)
      .attr('x', state.orbitNode.x - 20)
      .attr('y', state.orbitNode.y - 20)
      .attr("xlink:href", "/assets/logo/gear_green.svg")

    var interpol_rotate = d3.interpolateString( `rotate(0,${state.orbitNode.x},${state.orbitNode.y})`, `rotate(360,${state.orbitNode.x},${state.orbitNode.y})` )

    function revolveOrbit(){
        svg.select(".orbitStar")
            .transition()
            .duration(10000)
            .ease(d3.easeLinear)
            .attrTween('transform' , function(d,i,a){ return interpol_rotate } )
                            
            .on("end", revolveOrbit) ;  //at end, call it again to create infinite loop
    }

    //And just call the revolveOrbit function now
    revolveOrbit()
  }

  const clearSelectedStar = () => {
    node.select('.selectedStar')
    .attr("class", (d) => `selectedStar`)
  }

  node.append('text')
      .attr("x", (d) => d.x)
      .attr("y", (d) => d.y - 4)
      .attr("class", (d) => `starText ${d.data.visited ? 'visited' : ''} ${d.data.beacon ? 'beacon' : ''}`)
      .attr("opacity", 0)
      .style("text-anchor", "middle")
      .text((d) => d.key)
    
  node.append('text')
      .attr("x", 0)
      .attr("dy", -25)
      .attr("class", "nodeText percentage")
      .style("text-anchor", "middle")
      .text('')
  
  const zoomToSelected = (duration = 500) => {
    const newScale = Math.max(state.currZoom, 4)
    svg.transition().duration(duration).call(
      zoom.transform,
      d3.zoomIdentity.translate(width / 2, height / 2).scale(newScale).translate(-1 * state.selectedNode.x, -1 * state.selectedNode.y)
    );
    currCenter = { x: state.selectedNode.x, y: state.selectedNode.y }
  }

  const toggleDangerView = () => {
    const dangerVal = !state.showDanger

    node.select('.dangerZone') 
      .attr('class', (d) => `dangerZone danger-${d.data.maxDanger} ${ dangerVal ? 'visible' : ''}`)

    svg.select('.dangerToggle')
      .attr("xlink:href", `/assets/images/toggleDanger${dangerVal ? 'Off' : 'On'}.png`)      
      
    dispatch({ type: 'setShowDanger', data: { showDanger: dangerVal }})
  }

  const toggleStoryView = () => {
    const storyVal = !state.showStory

    // d3.select('.storyNode')
    // .attr('class', (d) => `storyNode ${ storyVal ? 'animate-flicker' : ''}`)
  
    svg.select('.storyToggle')
      .attr("xlink:href", `/assets/images/toggleStory${storyVal ? 'Off' : 'On'}.png`)      
      
    dispatch({ type: 'setShowStory', data: { showStory: storyVal }})
  }

  const finishMoveOrbit = () => {
    state.starMap.setOrbitStar(state.selectedNode.id)
    dispatch({ type: 'clearGraphData' })
  } 

  const moveOrbit = (startId, speed) => {
    disableTravel()

    const theLink = pathLinks.shift()

    if (!theLink) {
      // done moving 
      finishMoveOrbit()
    } else {
      const points = theLink.split('-')

      const stars  = {}

      points.forEach((p) => {
        const theStar = state.starMap.data.stars[p]

        if (p === startId) {
          stars.start = theStar
        } else {
          stars.end = theStar
        }
      })

      const orbitImg = d3.select(".orbitStar")
    
      const dx = stars.end.x - stars.start.x
      const dy = stars.end.y - stars.start.y
  
      const orbitWidth = 30 / (Math.sqrt(state.currZoom))

      var interpol_translate = d3.interpolateString( `translate(0,0)`, `translate(${dx},${dy})` )
  
      orbitImg.transition()
        .duration(speed)
        .attrTween('transform' , function(d,i,a){ return interpol_translate } )
        .on("end", () => {
          // move to the next star
          orbitImg.attr('x', stars.end.x - orbitWidth / 2).attr('y', stars.end.y - orbitWidth / 2)
          moveOrbit(stars.end.id, speed)
        }) 
    }
  }
  
  const graphControls = svg.append("g")
    .attr('class', 'graphControls')

  graphControls.append("clipPath")  // define a clip path
    .attr("id", "danger-clip")      // give the clipPath an ID
    .append("rect")          
      .attr("rx", 10)
      .attr("ry", 10)
      .attr('x', 10)
      .attr('y', 10)
      .attr('width', 40)
      .attr('height', 40)

  const dangerBtn = graphControls.append('g')
    .attr('class', 'graphDangerBtn tooltip')

  dangerBtn.append('svg:image')
    .attr('class', 'dangerToggle')
    .attr("xlink:href", `/assets/images/toggleDanger${state.showDanger ? 'Off' : 'On'}.png`)        
    .attr("rx", 10)
    .attr("ry", 10)
    .attr('x', 10)
    .attr('y', 10)
    .attr('width', 40)
    .attr('height', 40)
    .attr("clip-path", "url(#danger-clip)") // clip the rectangle
  dangerBtn.append("rect")
    .attr('class', 'btnBorder')
    .attr("rx", 10)
    .attr("ry", 10)
    .attr('x', 10)
    .attr('y', 10)
    .attr('width', 40)
    .attr('height', 40)
    .on('click', toggleDangerView)
  dangerBtn.append('text')
  .attr("x", 60)
  .attr("y", 35)
  .attr("class", 'tooltiptext')
  .text('Danger Zones')

  graphControls.append("clipPath")  // define a clip path
    .attr("id", "story-clip")      // give the clipPath an ID
    .append("rect")          
      .attr("rx", 10)
      .attr("ry", 10)
      .attr('x', 10)
      .attr('y', 60)
      .attr('width', 40)
      .attr('height', 40)

  const storyBtn = graphControls.append('g')
    .attr('class', 'graphStoryBtn tooltip')

  storyBtn.append('svg:image')
    .attr('class', 'storyToggle')
    .attr("xlink:href", `/assets/images/toggleStory${state.showStory ? 'Off' : 'On'}.png`)        
    .attr("rx", 10)
    .attr("ry", 10)
    .attr('x', 10)
    .attr('y', 60)
    .attr('width', 40)
    .attr('height', 40)
    .attr("clip-path", "url(#story-clip)") // clip the rectangle
  storyBtn.append("rect")
    .attr('class', 'btnBorder')
    .attr("rx", 10)
    .attr("ry", 10)
    .attr('x', 10)
    .attr('y', 60)
    .attr('width', 40)
    .attr('height', 40)
    .on('click', toggleStoryView)
  storyBtn.append('text')
  .attr("x", 60)
  .attr("y", 85)
  .attr("class", 'tooltiptext')
  .text('Story Markers')

  // set initial state
  markSelectedStar()
  markOrbitStar()
  updateLinks()
  zoom.scaleTo(svg, currZoom)
  zoom.translateTo(svg, state.selectedNode.x, state.selectedNode.y)

  return <div id="theGraph"/>
}