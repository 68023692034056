import { randInt, flipCoin, getRandomItem } from './mathUtils'
import { toTitleCase } from './textUtils'
import { get, ref, set, push, update, remove, query, orderByChild, equalTo } from 'firebase/database';
import { namey } from './namey';
import { nameParts } from '../data/nameParts'
import { commChatter } from '../data/commChatter'
import { Icon } from 'semantic-ui-react'
import { skillIcons, metricIcons, attrList, skillList, skillAttrs, attrQuirkEffects, quirkAttrEffects, quirkLevel, skillSlotClass, slotClass } from './constants'
import Entity from './entity'

export const attrGoal = 30
export const skillGoal = 100

export const pilotDefaults = {
    quirks: {},
    stats: {},
    abilities: {},
    canAdvance: {},
    relationships: {}                    
}

export const generatePortrait = (gender) => {
        const overall = Math.random()
  
        const skin = {
            lightness: overall * 1.3 + 0.3,
            tone: Math.random() * -60 * (overall / 3)
        }
  
        let head = randInt(1, 6)
        let lips = randInt(1, 4)
        let eyes = randInt(1, 6)
        let nose = randInt(1, 4)
        let brows = randInt(1, 7)
        let hair = {
            style: `h${randInt(1, 9)}`,
            lightness: Math.random() * 1.8,
            color: randInt(0, 180) * (flipCoin ? 1 : -1),
            saturation: randInt(60, 100)
        }

        switch (gender) {
            case 'M':
                head = `m${head}`
                lips = `m${lips}`
                break
            case 'F':
                head = `f${head}`
                lips = `f${lips}`
                break
            default:
                const genderPrefix = flipCoin() ? 'm' : 'f'
                head = `${genderPrefix}${head}`
                lips = `${genderPrefix}${lips}`
        }

        return {
          head,
          eyes,
          hair,
          skin,
          nose,
          brows,
          lips,
          suitColor: randInt(0, 11)
        }
    }

export const generateAttributes = () => {
    let viable = false 
    let attributes = null
    let skills = null

    const calcStartingSkill = ([a, b, c]) => {
        return (attributes[a] + attributes[b] + attributes[c] >= 12) ? 2 : 1
    }
    
    while (!viable) {
        attributes = {
            conf:   randInt(1, 5),
            prow:   randInt(1, 5),
            reas:   randInt(1, 5),
            fort:   randInt(1, 5),
            wis:    randInt(1, 5),
            dar:    randInt(1, 5),
            emp:    randInt(1, 5),
            hum:    randInt(1, 5),
        }

        skills = {
            comm:       calcStartingSkill(['hum', 'emp', 'conf']),
            combat:     calcStartingSkill(['prow', 'fort', 'dar']),
            reaction:   calcStartingSkill(['prow', 'conf', 'wis']),
            repair:     calcStartingSkill(['wis', 'hum', 'reas']),
            per:        calcStartingSkill(['reas', 'emp', 'conf']),
            pilot:      calcStartingSkill(['reas', 'fort', 'dar']),
        }

        let goodSkills = 0

        for (const skill in skills) {
            if (skills[skill] > 1) {
                goodSkills++
            }
        }

        if (goodSkills >=2) {
            viable = true
        }
    }

    // progress toward improving attributes
    const progress = {
        conf:   0,
        prow:   0,
        reas:   0,
        fort:   0,
        wis:    0,
        dar:    0,
        emp:    0,
        hum:    0,
    }

    const relationships = {}

    const metrics = {
        xp: 0,
        totalXp: 0,
        fame: 0,
        hap: 50,
        hp: 10 + attributes.fort,
        maxHp: 10 + attributes.fort,
        energy: 10 + Math.ceil(attributes.fort / 2),
        maxEnergy: 10 + Math.ceil(attributes.fort / 2),
        rank: 1
    }

    // create quirks
    const quirks = {}

    for (const quirk in quirkAttrEffects) {
        const bonusAttr = quirkAttrEffects[quirk].bonus
        const malusAttr = quirkAttrEffects[quirk].malus
        if (attributes[bonusAttr] >= 3 && attributes[malusAttr] < 3) {
            const diff = attributes[bonusAttr] - attributes[malusAttr]
            quirks[quirk] = randInt(1, 6) + diff
        }
    }

    // prune quirks to a max of 2
    let quirkTypes = Object.keys(quirks)

    while (quirkTypes.length > 2) {
        // get a random quirk and remove it
        const toPrune = quirkTypes[randInt(0, quirkTypes.length - 1)]
        delete quirks[toPrune]
        quirkTypes = Object.keys(quirks)
    }

    return { attributes, skills, metrics, progress, relationships, quirks, canAdvance: {}, stats: {} }
}

export const generateRankedAttributes = (options) => {
    let { rank, slot } = options

    let viable = false 
    const skills = {}
    const pilotClass = slotClass[slot]

    // we need to generate skills sufficient for the given rank
    // i.e. at least 3 skills at the desired rank or higher
    // const skillRange = [ -1, -1, -1, 0, 0, 0, +1 ]

    while (!viable) {
        for (const s of skillList) {
            skills[s] = randInt(1, rank)
        }

        let goodSkills = 0
        let classSkills = 0

        for (const skill in skills) {
            if (skills[skill] >= rank) {
                goodSkills++

                if (skillSlotClass[skill] === pilotClass) {
                    classSkills++
                }
            }
        }

        if (goodSkills >=3 && classSkills >= 1) {
            viable = true
        } else {
            console.log(`not viable for rank ${rank} ${pilotClass}`)
            console.log(skills)
        }
    }

    // now set attributes at the minimum level to support the skill ranks
    const attributes = {
        conf:   0,
        prow:   0,
        reas:   0,
        fort:   0,
        wis:    0,
        dar:    0,
        emp:    0,
        hum:    0,
    }

    for (const s of skillList) {
        for (const a of skillAttrs[s]) {
            attributes[a] = Math.max(attributes[a], skills[s] * 2)
        }
    }

    // set all progress at 0; these won't get used, but just to be safe
    const progress = {
        conf:   0,
        prow:   0,
        reas:   0,
        fort:   0,
        wis:    0,
        dar:    0,
        emp:    0,
        hum:    0,
    }
    const relationships = {}
    const quirks = {}

    // set only the metrics we need
    const metrics = {
        xp: 0,
        totalXp: 0,
        fame: 0,
        hap: 50,
        hp: 10 + attributes.fort,
        maxHp: 10 + attributes.fort,
        energy: 0,
        maxEnergy: 0,
        rank
    }

    return { attributes, skills, metrics, progress, relationships, quirks, canAdvance: {}, stats: {} }
}

export const generatePilot = async (db, user, options = {}) => {
    const genderRoll = randInt(0, 9)
    const genderLUT = ['M', 'M', 'M', 'M', 'F', 'F', 'F', 'F', 'NB', 'NB']
    const gender = genderLUT[genderRoll]

    const nameOptions = {
        min_freq: 40,
        max_freq: 75
    }

    switch (gender) {
        case 'M':
            nameOptions.type = 'male'
            // nameOptions.with_surname = true
            break
        case 'F':
            nameOptions.type = 'female'
            // nameOptions.with_surname = true
            break
        case 'NB':
            nameOptions.type = 'male' // we will throw this name out later
            break
        default:
    }

    let firstName = ''
    let lastName = ''
    let name = ''
    
    while (!name || name.length > 25) {
        const nameRes = await namey(nameOptions)

        if (gender === 'NB') {
            firstName = nameParts.enby[randInt(0,nameParts.enby.length - 1)]
        } else {
            firstName = nameRes[0]
        }

        lastName = nameRes[1]

        name = `${firstName} ${lastName}`
    }

    const pilotValues = options.rank ? generateRankedAttributes(options) : generateAttributes()

    const pilotData = {
        firstName,
        lastName,
        name,
        callsign: '',
        gender,
        ...pilotValues,
        location: 'barracks',
        portrait: generatePortrait(gender),
        created: Date.now(),
        lastBattle: 0,
        user: user?.uid
    }

    if (db) {
        // Add a new document with a generated id
        const pilotListRef = ref(db, `pilots/${user.uid}`)
        const newPilotRef = push(pilotListRef)

        pilotData.id = newPilotRef.key

        set(newPilotRef, pilotData)
    } else {
        // generating a temporary pilot (e.g. raider)
        return pilotData
    }
}

export const loadPilot = async (db, user, id) => {
    const pilotRef = ref(db, `pilots/${user.uid}/${id}`)

    const data = await get(pilotRef);

    return Object.assign({}, pilotDefaults, data.val())
}

export const getTeammates = async (db, user, pilotData) => {
    const pilotListRef = query(ref(db, `pilots/${user.uid}`), orderByChild('team'), equalTo(pilotData.team))

    const data = await get(pilotListRef);

    const out = []

    data.forEach((p) => {
        const pData = p.val()

        if (pData.id !== pilotData.id) {
            out.push(Object.assign({}, pilotDefaults, p.val()))
        }
    })

    return out
}

export const deletePilot = async (db, user, id) => {
    const pilotRef = ref(db, `pilots/${user.uid}/${id}`)
    await remove(pilotRef)
}

export const getGenderIcon = (pilotData) => {
    let name = ''

    switch (pilotData.gender) {
        case 'M':
            name = 'mars'
            break 
        case 'F':
            name = 'venus'
            break 
        case 'NB':
            name = 'genderless'
            break 
        default:
    }

    return <Icon className="genderIcon" name={name}/>
}

export const getQuirkList = (pilotData) => {
    const quirks = pilotData.quirks

    const labels = []

    for (const quirk in quirks) {
        labels.push(toTitleCase(`${quirkLevel[quirks[quirk] - 1]} ${quirk}`))
    }

    return labels
}

export const getSkillIcon = (skill, size = 'small') => {
    return <Icon name={skillIcons[skill]} size={ size }/>
}

export const getMetricIcon = (metric, size = 'small') => {
    return <Icon name={metricIcons[metric]} size={ size }/>
}

export const getMetricDisplay = (pilotData, metric) => {
    switch (metric) {
        case 'hap':
            return `${pilotData.metrics.hap}%`
        case 'hp':
            return `${pilotData.metrics.hp} / ${pilotData.metrics.maxHp}`
        case 'energy':
            return `${pilotData.metrics.energy} / ${pilotData.metrics.maxEnergy}`
        case 'rank':
            return getPilotRank(pilotData)
        default:
            return pilotData.metrics[metric]
    }
}

export const movePilotToLocation = async (db, user, id, location) => {
    const pilotRef = ref(db, `pilots/${user.uid}/${id}`)
    await update(pilotRef, { location })
}

export const healPilot = async (db, user, id, amount) => {
    const pilotRef = ref(db, `pilots/${user.uid}/${id}`)
    const pilotDoc = await get(pilotRef)
    const data = pilotDoc.val()

    const newMetrics = { ...data.metrics }

    const newHp = Math.min(data.metrics.hp + amount, data.metrics.maxHp)

    newMetrics.hp = newHp

    await update(pilotRef, { metrics: newMetrics })

    return newHp
}

export const changePilotEnergy = async (db, user, id, amount) => {
    const pilotRef = ref(db, `pilots/${user.uid}/${id}`)
    const pilotDoc = await get(pilotRef)
    const data = pilotDoc.val()

    const newMetrics = { ...data.metrics }

    const newEnergy = amount > 0 ? Math.min(data.metrics.energy + amount, data.metrics.maxEnergy) : Math.max(data.metrics.energy + amount, 0)

    newMetrics.energy = newEnergy

    await update(pilotRef, { metrics: newMetrics })

    return newEnergy
}

export const changePilotMetric = async (db, user, id, metric, amount) => {
    const pilotRef = ref(db, `pilots/${user.uid}/${id}`)
    const pilotDoc = await get(pilotRef)
    const data = pilotDoc.val()

    const newMetrics = { ...data.metrics }

    let maxMetric = 0

    switch (metric) {
        case 'hp':
            maxMetric = data.metrics.maxHp
            break
        case 'energy':
            maxMetric = data.metrics.maxEnergy
            break
        case 'hap':
            maxMetric = 100
            break
        default:
    }

    let newValue = 0

    if (maxMetric > 0) {
        newValue = amount > 0 ? Math.min(data.metrics[metric] + amount, maxMetric) : Math.max(data.metrics[metric] + amount, 0)
    } else {
        newValue = Math.max(data.metrics[metric] + amount, 0)
    }

    newMetrics[metric] = newValue

    await update(pilotRef, { metrics: newMetrics })

    return newValue
}

export const changePilotTeamSlots = async (db, user, assignments) => {
    const data = {}

    const pilotListRef = ref(db, `pilots/${user.uid}`)

    for (const a of assignments) {
        data[`${a.pilotId}/team`] = a.team
        data[`${a.pilotId}/slot`] = a.slot
    }

    update(pilotListRef, data)
}

const adjustProgressForQuirks = (pilotData, attr, amount) => {
    const quirkBonusFactor = pilotData.quirks[attrQuirkEffects[attr].bonus]
    const quirkMalusFactor = pilotData.quirks[attrQuirkEffects[attr].malus]

    if (quirkBonusFactor) {
        return amount * (1 + (quirkBonusFactor * .025))
    } else if (quirkMalusFactor) {
        return amount * (1 - (quirkMalusFactor * .075))
    } else {
        return amount
    }
}

export const improvePilotQuirk = async (db, user, id, quirk) => {
    const pilotRef = ref(db, `pilots/${user.uid}/${id}`)
    const pilotDoc = await get(pilotRef)
    const data = pilotDoc.val()

    const newQuirks = { ...data.quirks }

    const newValue = data.quirks[quirk] - 1

    if (newValue === 0) {
        delete newQuirks[quirk]
    } else {
        newQuirks[quirk] = newValue
    }

    data.quirks = newQuirks

    await update(pilotRef, { quirks: newQuirks })

    return newValue
}

export const getPilotAttrProgressPercent = (pilotData, attr) => {
    const goal = (pilotData.attributes[attr] + 1) * attrGoal
    const curr = pilotData.progress[attr]

    return Math.round((curr / goal) * 100)
}

export const applyStatsToPilot = async (db, user, pilotData, stats, metrics, progress, relationships, options = {}) => {
    const updateData = {}

    // stats
    if (stats) {
        if (!pilotData.stats) {
            updateData.stats = stats
        } else {
            const newStats = { ...pilotData.stats }

            for (const statType in stats) {
                if (newStats[statType]) {
                    newStats[statType] += stats[statType]
                } else {
                    newStats[statType] = stats[statType]
                }
            }

            updateData.stats = newStats
        }
    }

    // metrics
    if (metrics) {
        updateData.metrics = { ...pilotData.metrics }

        for (const metricKey in metrics) {
            switch (metricKey) {
                case 'xp':
                    updateData.metrics.xp += metrics.xp
                    updateData.metrics.totalXp += metrics.xp
                    break
                case 'fame':
                    updateData.metrics.fame += metrics.fame
                    break
                case 'energy':
                    if (metrics.energy > 0) {
                        updateData.metrics.energy = Math.min(pilotData.metrics.maxEnergy, updateData.metrics.energy + metrics.energy)
                    } else {
                        updateData.metrics.energy = Math.max(0, updateData.metrics.energy + metrics.energy)
                    }
                    break
                case 'hap':
                    if (metrics.hap > 0) {
                        updateData.metrics.hap = Math.min(100, updateData.metrics.hap + metrics.hap)
                    } else {
                        updateData.metrics.hap = Math.max(0, updateData.metrics.hap + metrics.hap)
                    }
                    break
                default:
            }
        }
    }

    // progress
    if (progress) {
        updateData.progress = { ...pilotData.progress }

        updateData.progress[progress.attr] += adjustProgressForQuirks(pilotData, progress.attr, progress.value)
    }

    // relationships
    if (relationships) {
        if (!pilotData.relationships) {
            pilotData.relationships = {}
        }

        const newRels = { ...pilotData.relationships }
        const otherRels = {}

        for (const relId in relationships) {
            if (newRels[relId]) {
                if (relationships[relId] > 0) {
                    newRels[relId] = Math.min(newRels[relId] + relationships[relId], options.maxRel || 100)
                } else {
                    newRels[relId] = Math.max(newRels[relId] + relationships[relId], options.minRel || 0)
                }
            } else {
                newRels[relId] = 50 + relationships[relId]
            }

            otherRels[`${relId}/relationships/${pilotData.id}`] = newRels[relId]
        }

        updateData.relationships = newRels

        const otherRelsRef = ref(db, `pilots/${user.uid}`)
        await update(otherRelsRef, otherRels)
    }

    Object.assign(pilotData, updateData)
    
    updateData.canAdvance = getCanAdvance(pilotData)

    pilotData.canAdvance = updateData.canAdvance

    if (pilotData.energy <= 0 && pilotData.location !== 'barracks') {
        updateData.location = 'barracks'
    }

    // last battle
    if (options.lastBattle) {
        updateData.lastBattle = options.lastBattle
    }
    
    const pilotRef = ref(db, `pilots/${user.uid}/${pilotData.id}`)
    await update(pilotRef, updateData)
}

export const getAttrToProgress = (pilotData, attrOptions) => {
    let attrToProgress = ''
    let currProgress = 0
    let currLevel = 0

    for (const attr of attrOptions) {
        // if nothing picked yet, always pick this one
        let thisAttr = currLevel === 0

        const attrLevel = pilotData.attributes[attr]
        const attrProgress = pilotData.progress[attr]
        
        if (!thisAttr && attrLevel < 12) {
            if (attrLevel < currLevel) {
                // always progress the lowest current level
                thisAttr = true
            } else if (attrLevel === currLevel && attrProgress < currProgress) {
                // if the same level as another, pick the one with less progress
                thisAttr = true
            }
        }

        if (thisAttr) {
            attrToProgress = attr
            currProgress = attrProgress
            currLevel = attrLevel
        }
    }

    return attrToProgress
}

export const isPilotTired = (pilotData) => {
    return pilotData.metrics.energy < pilotData.metrics.maxEnergy
}

export const getPronoun = (pilotData, form = 'sub', caps = false) => {
    switch (pilotData.gender) {
        case 'M':
            switch (form) {
                case 'sub': return caps ? 'He' : 'he'
                case 'obj': return 'him'
                case 'pos': return caps ? 'His' : 'his'
                case 'ref': return caps ? 'Himself' : 'himself'
                default:
            }
            break
        case 'F':
            switch (form) {
                case 'sub': return caps ? 'She' : 'she'
                case 'obj': return 'her'
                case 'pos': return caps ? 'Her' : 'her'
                case 'ref': return caps ? 'Herself' : 'herself'
                default:
            }
            break
        case 'NB':
            switch (form) {
                case 'sub': return caps ? 'Ze' : 'ze'
                case 'obj': return 'zir'
                case 'pos': return caps ? 'Zir' : 'zir'
                case 'ref': return caps ? 'Zirself' : 'zirself'
                default:
            }
            break
        default:
    }
}

export const getFirstName = (pilotData) => {
    return pilotData ? pilotData.firstName || pilotData.name.split(' ')[0] : ''
}

export const getLastName = (pilotData) => {
    if (pilotData.lastName) {
        return pilotData.lastName
    } else {
        const spaceIndex = pilotData.name.indexOf(' ')
        const lastName = pilotData.name.slice(spaceIndex + 1)    
        return lastName
    }
}

export const checkSkillSorties = (pilotData, skill) => {
    const currLevel = pilotData.skills[skill]

    const skillSlot = skillSlotClass[skill]
    const statName = `${skillSlot}Wins`
    const slotWins = pilotData.stats ? pilotData.stats[statName] : 0
    return slotWins >= 5 * currLevel
}

const getCanAdvance = (data) => {
    const canAdvance = { any: false }

    // check for attribute progress
    // requires progress value of attrGoal * next level
    for (const attr of attrList) {
        canAdvance[attr] = data.progress[attr] >= (data.attributes[attr] + 1) * attrGoal
        if (canAdvance[attr]) {
            canAdvance.any = true
        }
    }

    // check for skill prerequisites
    // requires avg of attributes / 2 = next rank and skillGoal * next rank
    for (const skill of skillList) {
        let attrTotal = 0

        for (const attr of skillAttrs[skill]) {
            attrTotal += data.attributes[attr]
        }

        const attrAvg = attrTotal / 6
        const nextLevel = data.skills[skill] + 1

        const attrsCheck = attrAvg >= nextLevel
        const xpCheck = data.metrics.xp >= skillGoal * nextLevel

        const slotCheck = checkSkillSorties(data, skill)

        canAdvance[skill] = attrsCheck && xpCheck && slotCheck

        if (canAdvance[skill]) {
            canAdvance.any = true
        }
    }

    return canAdvance
}

export const getPilotRank = (pilotData) => {
    if (!pilotData) { return 0 }
    
    let skillTotal = 0

    for (const skill of skillList) {
        skillTotal += pilotData.skills[skill]
    }

    return Math.round(skillTotal / 6)
}

export const getCommChatter = (message, specific = false) => {
    if (specific) {
        return message
    } else {
        return getRandomItem(commChatter[message])
    }
}

export class Pilot extends Entity {
    nextAction = 0
    nextSecondary = 0
    nextSpecial = 0

    constructor(db, user, id, context, slot, data) {
        if (id) {
            // persist changes to db
            super(db, user, id, 'pilots')
        } else {
            // initialize with data - for PVP
            super(null, null, null, null, data)
        }

        this.context = context
        this.slot = slot
        this.defaults = pilotDefaults
    }
    
    initData() {
        this.status = {}

        this.data = Object.assign({}, pilotDefaults, this.data)
}

    incrementNextAction(amount = 0) {
        this.nextAction += amount || this.speed
    }

    reduceNextAction(amount) {
        this.nextAction -= amount
    }

    incrementNextSecondary(amount = 0) {
        this.nextSecondary += amount || this.speed * 2
    }

    reduceNextSecondary(amount) {
        this.nextSecondary -= amount
    }

    incrementNextSpecial() {
        this.nextSpecial += Math.min(Math.ceil(this.data.attributes.dar / 3), 10)
    }

    reduceNextSpecial(amount) {
        this.nextSpecial -= amount
    }

    advanceAttr(attr) {
        const cost = (this.data.attributes[attr] + 1) * attrGoal

        if (this.data.progress[attr] >= cost) {
            const newProgress = { ...this.data.progress }
            const newAttrs = { ...this.data.attributes }

            newProgress[attr] -= cost
            newAttrs[attr] += 1

            const metrics = {}

            if (attr === 'fort') {
                metrics.maxHp = 10 + newAttrs.fort
                metrics.maxEnergy = 10 + Math.ceil(newAttrs.fort / 2)
            }

            const update = { 
                progress: newProgress, 
                attributes: newAttrs
            }

            Object.assign(this.data, update)

            update.canAdvance = getCanAdvance(this.data)

            this.updateData(update)
        }
    }

    advanceSkill(skill) {
        const cost = (this.data.skills[skill] + 1) * skillGoal

        if (this.data.metrics.xp >= cost) {
            const newMetrics = { ...this.data.metrics }
            const newSkills = { ...this.data.skills }

            newMetrics.xp -= cost
            newSkills[skill] += 1

            const update = { 
                metrics: newMetrics, 
                skills: newSkills
            }

            Object.assign(this.data, update)

            update.canAdvance = getCanAdvance(this.data)

            this.updateData(update)
        }
    }

    calculateSpeed(mechData) {
        if (this.context === 'combat' || this.context === 'sim') {
            let speed = 20

            switch (this.slot) {
                case 'head':
                    speed = speed - this.data.skills.comm - this.data.skills.per
                    break
                case 'leftArm':
                case 'rightArm':
                    speed = speed - this.data.skills.combat - this.data.skills.reaction
                    break
                case 'leftLeg':
                case 'rightLeg':
                    speed = speed - this.data.skills.repair - this.data.skills.pilot
                    break
                default:
            }

            this.speed = Math.ceil(speed * mechData.speed)
            this.nextAction = this.speed
            this.nextSecondary = this.speed * 2
        }
    }

    applyStats(stats, metrics, progress, relationships, options) { // combine these to reduce db calls
        applyStatsToPilot(this.db, this.user, { id: this.id, ...this.data }, stats, metrics, progress, relationships, options)
    }

    getRank() {
        return getPilotRank(this.data)
    }
}