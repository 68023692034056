import { getRoomIcon } from '../../lib/academy'
import { camelCaseToLowerCase } from '../../lib/textUtils'
import { flipCoin, getRandomItem } from '../../lib/mathUtils'
import { slotClassSkills } from '../../lib/constants'

export const simulator = [
    (e) => {
        const p = e.pilot
        const s = e.slot

        return {
            title: <div>{ getRoomIcon('simulator') }SIMULATOR: The Red Switch</div>,
            description: `PNAME is trying to get familiar with the more advanced systems in the ${camelCaseToLowerCase(s)} position. In the middle of a heated battle, an unfamiliar switch on the console starts flashing red. What should pze do?`,
            pilot: p,
            options: [
                { 
                    attr: 'conf',
                    skill: 'reaction',
                    dc: 15,
                    level: 'hard',
                    text: `Trust pzis gut and flip the switch`,
                    success: {
                        text: `PNAME waits for what seems like a good moment and hits the switch. Green indicators light across the console as the ${camelCaseToLowerCase(s)} systems engage extra power.`,
                        attr: 'conf',
                        attrValue: 20
                    },
                    fail: {
                        text: `As PNAME hits the switch, half the console goes dark as systems restart; this was clearly not the right move. PZE can hear people in the spectator lounge laughing.`,
                        metric: 'fame',
                        metricValue: -20
                    }
                },
                { 
                    attr: 'reas',
                    skill: 'per',
                    dc: 10,
                    level: 'medium',
                    text: `Try to figure out what the switch does`,
                    success: {
                        text: `After a quick look at the panel, PNAME determines that pze should ${flipCoin() ? 'hit the switch. After it flips, it turns green and the humming sounds in the cockpit start harmonizing.' : 'not hit the switch. After a moment it stops flashing red and turns green again.'}`,
                        attr: 'reas',
                        attrValue: 10
                    },
                    fail: {
                        text: `PNAME tries to puzzle out the switch's function based on the other things around it. After a moment, pze gives up and turns pzis attention back to the battle.`
                    }
                },
                { 
                    text: `Check the instructions`,
                    level: 'easy',
                    success: {
                        text: `Taking this as an opportunity to learn, PNAME touches the tutorial icon hovering near the switch. It gives pzir a brief description of the switch's purpose.`,
                        attr: 'hum',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot
        const o = e.otherPilot
        const s = e.slot

        if (!o) { return null }

        return {
            title: <div>{ getRoomIcon('simulator') }SIMULATOR: The Good Loser</div>,
            description: `PNAME is head to head in the simulator practicing their ${camelCaseToLowerCase(s)} piloting against ONAME. All damage indicators are red, and energy levels are critical. There's only the slimmest chance of snatching victory from the jaws of defeat. What should pze do?`,
            pilot: p,
            otherPerson: o,
            options: [
                { 
                    attr: 'hum',
                    skill: 'comm',
                    dc: 15,
                    level: 'hard',
                    text: `Congratulate ONAME on ozis win`,
                    success: {
                        text: `As they emerge from the simulator pods, PNAME clasps ONAME's arm. "Great job in there, I didn't stand a chance." ONAME smiles. "Ready for a rematch?"`,
                        attr: 'hum',
                        attrValue: 20,
                        relationship: o.id,
                        relValue: 1
                    },
                    fail: {
                        text: `PNAME opens the simulator pod and grimaces. "Good job or whatever" pze mutters as pze walks past ONAME.`,
                        relationship: o.id,
                        relValue: -2
                    }
                },
                { 
                    attr: 'dar',
                    skill: getRandomItem(slotClassSkills[s]),
                    dc: 10,
                    level: 'medium',
                    text: `Try a desperate maneuver and hope for the best`,
                    success: {
                        text: `Gritting pzis teeth, PNAME pushes pzis Gauntlet to the limit in a last ditch effort to survive. ${ flipCoin() ? `Amazingly, pze is able to barely manage a victory, if a Pyrrhic one.` : `Unfortunately, pze still goes down in flames. At least it was a fun ride.`}`,
                        attr: 'dar',
                        attrValue: 10,
                        relationship: o.id,
                        relValue: flipCoin() ? 1 : 0
                    },
                    fail: {
                        text: `All the tricks and gambits in the world are not enough to save PNAME from defeat. It was a good try, but just not enough.`
                    }
                },
                { 
                    text: `Practice damage control procedures`,
                    level: 'easy',
                    success: {
                        text: `There's no way to turn this defeat into a victory, but it never hurts to practice defeat. PNAME engages the crash restraints as the Gauntlet simulates falling to the ground. It's never easy, but done right it can be safe.`,
                        attr: 'fort',
                        attrValue: 5
                    }
                }
            ]
        }
    },
]