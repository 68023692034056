import React from 'react'
import { ItemTypes } from '../../lib/constants'
import { useDrag } from 'react-dnd'
import HealthBar from '../healthBar'
import { MechView } from '../mechView'
import { slotTypes, numerals } from '../../lib/constants'
import { camelCaseToTitleCase } from '../../lib/textUtils'
import { Popup } from 'semantic-ui-react'
import { isMechDamaged } from '../../lib/mech'

function DragMechCard({mech, location, selectMech, number, enabled = false}) {
  const [{isDragging}, drag] = useDrag(() => {
    return {
    type: ItemTypes.MECH,
    canDrag: enabled,
    item: { id: mech?.id, mechData: mech, location },
    collect: (monitor, props) => {
      return {
        isDragging: !!monitor.isDragging(),
      }
    }
  }}, [enabled, mech])

  const card = <div 
    ref={drag}
    className="mechCard"
    style={{
      pointerEvents: mech ? 'auto' : 'none',
      opacity: isDragging ? 0.5 : 1
    }}
    onClick={() => selectMech(mech.id)}
  >
    <div className="portrait">
      <MechView size={60} subject={mech} context={ isMechDamaged(mech) ? 'damage' : 'list' }/> 
    </div>
    <div className="rankBadge">
      { numerals[mech.rank - 1] }
    </div>
    { mech.name ? mech.name : <>{ mech.make }<br/>{ mech.model }</> }
    <div className="armorBars">
      <HealthBar 
          curr={mech.armor.body}
          max={mech.maxArmor.body}
          dir="vert"
          className={`mechHealth ${mech.armor.body === mech.maxArmor.body && 'full'}`}
      />
      <HealthBar 
          curr={mech.armor.head}
          max={mech.maxArmor.head}
          dir="vert"
          className={`mechHealth ${mech.armor.head === mech.maxArmor.head && 'full'}`}
      />
      <HealthBar 
          curr={mech.armor.leftArm}
          max={mech.maxArmor.leftArm}
          dir="vert"
          className={`mechHealth ${mech.armor.leftArm === mech.maxArmor.leftArm && 'full'}`}
      />
      <HealthBar 
          curr={mech.armor.rightArm}
          max={mech.maxArmor.rightArm}
          dir="vert"
          className={`mechHealth ${mech.armor.rightArm === mech.maxArmor.rightArm && 'full'}`}
      />
      <HealthBar 
          curr={mech.armor.leftLeg}
          max={mech.maxArmor.leftLeg}
          dir="vert"
          className={`mechHealth ${mech.armor.leftLeg === mech.maxArmor.leftLeg && 'full'}`}
      />
      <HealthBar 
          curr={mech.armor.rightLeg}
          max={mech.maxArmor.rightLeg}
          dir="vert"
          className={`mechHealth ${mech.armor.rightLeg === mech.maxArmor.rightLeg && 'full'}`}
      />
      <div className={`teamBadge bg-team-${mech.team}`}>
        { numerals[mech.team - 1] }
      </div>
    </div>
  </div>

  // return card

  return <Popup
  key={number} 
  trigger={ card }
  basic
  mouseEnterDelay={300}
  position="right center"
  offset={[0, -15]}
  >
  <div>
    <table className="mechStatsTable">
      <tbody>
          <tr>
            <td className="mechStatLabel">Body</td>
            <td className={`mechStatValue ${mech.armor.body < mech.maxArmor.body && 'damaged'}`}>{ `${mech.armor.body}/${mech.maxArmor.body}` }</td>
          </tr>
        { slotTypes.map((slot, i) => (
          <tr key={i}>
            <td className="mechStatLabel">{ camelCaseToTitleCase(slot) }</td>
            <td className={`mechStatValue ${mech.armor[slot] < mech.maxArmor[slot] && 'damaged'}`}>{ `${mech.armor[slot]}/${mech.maxArmor[slot]}` }</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</Popup>
}

export default DragMechCard