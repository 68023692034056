import { infirmary } from './events/infirmary'
import { repairBay } from './events/repairBay'
import { simulator } from './events/simulator'
import { recRoom } from './events/recRoom'
import { factory } from './events/factory'
import { planet } from './events/planet'

export const eventList = {
    infirmary,
    repairBay,
    simulator,
    recRoom,
    factory,
    planet
}