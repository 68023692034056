/* eslint-disable react-hooks/exhaustive-deps */

import React, { useReducer, useEffect } from 'react'
import { ref, onValue } from 'firebase/database';
import { DateTime } from 'luxon'
import { Container, Grid, Icon, Button, Image } from 'semantic-ui-react'
import PilotDropRoom from '../components/academy/pilotDropRoom';
import MechDropRoom from '../components/academy/mechDropRoom';
import FactoryQueue from '../components/academy/factoryQueue';
import { StarMapModal } from '../components/modals/starMapModal'
import { UpgradesModal } from '../components/modals/upgradesModal'
import { FactoryQueueModal } from '../components/modals/factoryQueueModal'
import { SettingsModal } from '../components/modals/settingsModal'
import { Logo } from '../components/logo';
import { movePilotToLocation, pilotDefaults } from '../lib/pilot';
import { moveMechToLocation, isMechDamaged, mechDefaults } from '../lib/mech';
import { academyAction } from '../lib/academyAction';
import { getRoomIcon } from '../lib/academy';
import { findStory, getCurrentStoryList } from '../lib/story';
import { roomAttrs, attrNames } from '../lib/constants';
import { getRandomItem } from '../lib/mathUtils';
import { sortByKey } from '../lib/arrayUtils';

const initialState = { 
  unsubscribePilots: null,
  unsubscribeMechs: null,
  pilots: null,
  fetchingPilots: false,
  mechs: null,
  fetchingMechs: false,
  paused: true,
  isStarMapModalOpen: false,
  isUpgradesModalOpen: false,
  isFactoryQueueModalOpen: false,
  isSettingsModalOpen: false,
  tickInterval: null,
  time: null,
  credits: null,
  xenos: null,
  initialized: false
}

function reducer(state, action) {
  switch(action.type) {
    case 'setPilots':
      return { ...state, pilots: action.data, fetchingPilots: false }
    case 'setUnsubscribePilots':
      return { ...state, unsubscribePilots: action.data }
    case 'setMechs':
        return { ...state, mechs: action.data, fetchingMechs: false }
    case 'setUnsubscribeMechs':
      return { ...state, unsubscribeMechs: action.data }
    case 'setTime':
      return { ...state, time: action.data}
    case 'addCredits':
      return { ...state, credits: state.credits + action.data}
    case 'addXenos':
      return { ...state, xenos: state.xenos + action.data}
    case 'setXenos':
      return { ...state, xenos: action.data}
    case 'incrementTime':
      return { ...state, time: state.time + 1}
    case 'initialize':
      return { ...state, initialized: true }
    case 'toggleUpgradesOpen':
      return { ...state, isUpgradesModalOpen: !state.isUpgradesModalOpen }
    case 'toggleFactoryQueueOpen':
      return { ...state, isFactoryQueueModalOpen: !state.isFactoryQueueModalOpen }
    case 'toggleSettingsOpen':
      return { ...state, isSettingsModalOpen: !state.isSettingsModalOpen }
    case 'togglePlayPause':
    case 'toggleStarMapOpen':
    case 'setUpdate':
    case 'setFetchingPilots':
    case 'setFetchingMechs':
    case 'setAcademyValues':
    case 'setCash':
      return { ...state, ...action.data }
    default:
      throw new Error()
  }
}

// put this outside the function so we can emergency interrupt the timer
let doingActions = false

// export default class AcademyView extends React.Component {
export default function AcademyView(props) {

  const [state, dispatch] = useReducer(reducer, initialState);

  // function disp(type, data) {
  //   dispatch({ type, data })
  // }

  function handleKeydown(e) {
    e.stopPropagation()

    switch( e.keyCode ) {
      case 32: //space
      togglePlayPause()
          break
      default: 
          break
    }
  }

  // initialize the view -- replaces componentDidMount()
    useEffect(() => {
      document.addEventListener("keydown", handleKeydown)
            // dispatch({ type: 'setTime', data: props.academy.data.time })
      const academyValues = {
        time: props.academy.data.time,
        credits: props.academy.data.credits,
        xenos: props.academy.data.xenos
      }

      dispatch({ type: 'setAcademyValues', data: academyValues })

      props.setMusicTrack("/assets/audio/Emerging-Alien-Civilizations_Looping.mp3")

      // check tutorials
      if (!props.showTutorial('introduction')) {
        if (!props.showTutorial('guide', 'academy')) {
          if (props.academy.data.schematics.length > 0) {
            props.showTutorial('guide', 'academy', 'factoryQueue')
          }
        }
      }

      // returned function will be called on component unmount 
      return () => {
        if (state.unsubscribeMechs) {
          state.unsubscribeMechs()
        }
        if (state.unsubscribePilots) {
          state.unsubscribePilots()
        }    
        document.removeEventListener("keydown", handleKeydown)  
      }
    }, [])

    // restart timer when event finishes
    useEffect(() => togglePlayPause('play'), [ props.eventsResolved ])

    function fetchPilots() {
      if (state.fetchingPilots) { return }

      dispatch({ type: 'setFetchingPilots', data: { fetchingPilots: true }})

      const pilotListRef = ref(props.db, `pilots/${props.user.uid}`);
        
      const unsubscribe = onValue(pilotListRef, async (querySnapshot) => {
        const pilots = [];
        querySnapshot.forEach((doc) => {
          const data = Object.assign({}, pilotDefaults, doc.val())

          pilots.push(data);    
        });    

        pilots.sort(function(a, b) {
            if (a.created < b.created) return -1;
            if (a.created > b.created) return 1;
            return 0;
        });

        dispatch({ type: 'setPilots', data: pilots })
      })

      dispatch({ type: 'setUnsubscribePilots', data: unsubscribe })
    }    

    function fetchMechs() {
      if (state.fetchingMechs) { return }

      dispatch({ type: 'setFetchingMechs', data: { fetchingMechs: true }})

      const mechListRef = ref(props.db, `mechs/${props.user.uid}`);
        
      const unsubscribe = onValue(mechListRef, async (querySnapshot) => {
        let mechs = [];
        querySnapshot.forEach((doc) => {
          const data = Object.assign({}, mechDefaults, doc.val())

          if (!data.location) {
            data.location = 'hangar'
          }
          mechs.push(data);    
        });    

        mechs = sortByKey(mechs, 'lastBattle', true)
              
        dispatch({ type: 'setMechs', data: mechs })
      });    

      dispatch({ type: 'setUnsubscribeMechs', data: unsubscribe })
    }    

    if (!state.pilots) {
      fetchPilots()
    }
    if (!state.mechs) {
      fetchMechs()
    }

    function getPilots(location) {
      return state.pilots ? state.pilots.filter((p) => p.location === location) : []
    }

    function getMechs(location) {
      return state.mechs ? state.mechs.filter((m) => m.location === location) : []
    }

    function movePilot(pilotId, location) {
      movePilotToLocation(props.db, props.user, pilotId, location)
    }

    function moveMech(mechId, location) {
      moveMechToLocation(props.db, props.user, mechId, location)
    }

    function togglePlayPause(newState) {
      const { paused } = state
      let { tickInterval } = state

      const newValue = newState ? newState === 'pause' ? true : false : !paused

      if (newValue) {
        clearInterval(tickInterval)
        tickInterval = null
      } else {
        tickInterval = setInterval(() => incrementTime(), 1000)
        doingActions = false
      }


      dispatch({ type: 'togglePlayPause', data: { paused: newValue, tickInterval} })
    }

    function showEvent(event) {
      props.showEvent(event)
    }

    function showMessage(message) {
      togglePlayPause('pause')
      
      props.showMessage(message)
    }
  
    function showSettings() {
      props.playSoundEffect(state.isSettingsModalOpen ? 'closeBeep' : 'openBeep')

      togglePlayPause('pause')
      
      dispatch({ type: 'toggleSettingsOpen' })      
    }

    function showUpgrades() {
      props.playSoundEffect(state.isUpgradesModalOpen ? 'closeBeep' : 'openBeep')
      
      togglePlayPause('pause')
      
      dispatch({ type: 'toggleUpgradesOpen' })
    }

    function showFactoryQueue() {
      props.playSoundEffect(state.isFactoryQueueModalOpen ? 'closeBeep' : 'openBeep')
      
      togglePlayPause('pause')
      
      dispatch({ type: 'toggleFactoryQueueOpen' })
    }

    function showStarMap() {
      props.playSoundEffect(state.isStarMapModalOpen ? 'closeBeep' : 'openBeep')
      
      const { isStarMapModalOpen: isOpen } = state

      togglePlayPause('pause')

      if (isOpen) {
        dispatch({ type: 'toggleStarMapOpen', data: {
          isStarMapModalOpen: false
        }})
      } else {
        // opening the modal
        dispatch({ type: 'toggleStarMapOpen', data: {
          isStarMapModalOpen: true
        }})
      }
    }

    function doAcademyAction(actionData) {
      return academyAction(props.db, props.user, actionData, props.academy, movePilot.bind(this), moveMech.bind(this))
    }

    function incrementTime(amount = 1) {
      if (doingActions) {
        // console.log('DOING ACTIONS, SO NO TICKS')
        return
      }

      dispatch({ type: 'incrementTime' })
      props.academy.incrementTime(amount)
    }

    useEffect(() => {
      const doTickActions = async () => {
        // if all pilots are in the barracks and fully rested, stop the timer
        const barracksPilots = getPilots('barracks')
        const patients = getPilots('infirmaryPatients')
        if (barracksPilots.length + patients.length === state.pilots.length) {
          let allRested = true

          barracksPilots.forEach((p) => {
            if (p.metrics.energy < p.metrics.maxEnergy) {
              allRested = false
            }
          })

          if (allRested) {
            togglePlayPause('pause')
            return
          }
        }

        doingActions = true

        const promises = []

        promises.push(doAcademyAction({
          location: 'infirmary',
          workers: getPilots('infirmaryWorkers'),
          patients: getPilots('infirmaryPatients')
        }))

        promises.push(doAcademyAction({
          location: 'repairBay',
          workers: getPilots('repairBayWorkers'),
          mechs: getMechs('repairBayMechs')
        }))

        promises.push(doAcademyAction({
          location: 'barracks',
          pilots: getPilots('barracks')
        }))

        promises.push(doAcademyAction({
          location: 'simulator',
          pilots: getPilots('simulator')
        }))

        promises.push(doAcademyAction({
          location: 'recRoom',
          pilots: getPilots('recRoom')
        }))

        promises.push(doAcademyAction({
          location: 'factory',
          pilots: getPilots('factory')
        }))

        Promise.all(promises).then(
          (eventGroups) => {
            const events = []

            for (let eg of eventGroups) {
              events.push(...eg)
            }

            if (events.length > 0) {
              const theEvent = getRandomItem(events)
    
              Object.assign(theEvent, { starMap: props.starMap.data })
    
              togglePlayPause('pause')
    
              const story = findStory(props.academy, theEvent)
    
              if (story) {
                showEvent(story)
              } else {
                showEvent(theEvent)
              }
            } else {
              doingActions = false
            }          
          }
        )
      }

      if (state.initialized) {
        doTickActions()
      }
    }, [ state.time ])

    useEffect(() => {
      if (!state.initialized) {

        const finishedLoading = state.time !== null && !state.fetchingPilots && !state.fetchingMechs

        if (finishedLoading) {
          dispatch({ type: 'initialize' })
        }
      }
    }, [ state.time, state.fetchingPilots, state.fetchingMechs ])

    useEffect(() => {
      // recalculate upgraded values
      props.academy.initData()

      // retrieve the values from the academy object
      const xenos = props.academy.data.xenos
      const credits = props.academy.data.credits

      dispatch({ type: 'setCash', data: { xenos, credits } })
    }, [ state.isUpgradesModalOpen, state.isStarMapModalOpen, props.academy.credits ])

    function displayTime() {
      const { time } = state

      // each tick is 10 minutes added to 2168-07-18 08:00:00
      const timestamp = time * 600 + 6265339200

      const theDateTime = DateTime.fromSeconds(timestamp)
      const theDate = theDateTime.toFormat('yyyy-MM-dd')
      const theTime = theDateTime.toFormat('HH:mm')

      return (
        <div className="timeDisplay">
          <div className="datePart">{ theDate }</div>
          <div className="timePart">{ theTime }</div>
          {/* <div className="timePart">{ time }</div> */}
        </div>
      )
    }

    function showRoomInfo(location) {
      props.showGuide('academy', location)
    }

    function getRoomAttrIcons(location) {
      return <div className="roomAttrIcons floatRight">
        { roomAttrs[location].map((a, i) => (
            <span key={i} className={`roomAttrIcon color-attr-${a}`}>
              {attrNames[a].toUpperCase()[0]}
            </span>
            )
          )
        }
      </div>
    }

    function selectPilot(pilotId, pilotIds) {
      doingActions = true
      togglePlayPause('pause')
      props.selectPilot(pilotId, pilotIds)
    }
    
    function selectMech(mechId) {
      doingActions = true
      togglePlayPause('pause')
      props.selectMech(mechId)
    }

    function hasUpgrade(type) {
      return props.academy.data.upgrades[type]
    }

    const roomProps = {
      db: props.db,
      time: props.academy.time,
      selectPilot: selectPilot.bind(this),
      selectMech: selectMech.bind(this),
      movePilot: movePilot.bind(this),
      moveMech: moveMech.bind(this),
      playSoundEffect: props.playSoundEffect,
      enabled: state.paused
    }

    const modalProps = {
      user: props.user,
      db: props.db,
      academy: props.academy,
      playSoundEffect: props.playSoundEffect,
      showGuide: props.showMessage
    }

    return <Container className="academyView">
        <Grid className="topGrid">
          <Grid.Column width={1} className="sidebarColumn">
            <Button className="sidebarBtn" onClick={ () => {
              props.playSoundEffect('buttonPress')
              togglePlayPause() 
            }}>
              { state.paused ? <Icon className="centered" name="play"/> : <Icon className="centered" name="pause"/> }
            </Button>

            <div className="sidebarLogo">
              <Logo 
                size={40}
                color={'med'}
                spinning={!state.paused}
              />
            </div>

            { displayTime() }

            <div className="credits">
              <Icon name="cube"/>{ state.credits }
              { props.isAdmin && false && <Icon className="iconBtn" name="add circle" onClick={() => {
                dispatch({ type: 'addCredits', data: 1000 })
                props.academy.addCredits(1000)
              }}/> }
            </div>

            <div className="credits">
              <Icon name="gem outline"/>{ state.xenos }
              { props.isAdmin && false && <Icon className="iconBtn" name="add circle" onClick={() => {
                dispatch({ type: 'addXenos', data: 1 })
                props.academy.addXenos(1)
              }}/> }
            </div>

            {/* <Button className="sidebarBtn resetTutBtn" onClick={() => props.academy.resetTutorials()}>
              Reset Tutorials
            </Button> */}

            <Button className="sidebarBtn settingsBtn" onClick={() => showSettings()}>
              Settings
            </Button>

            <Button className={`sidebarBtn starMapBtn ${ props.starMap ? '' : 'disabled' }`} onClick={() => showStarMap()}>
              Star Map
              <Image className="starMapBtnImg" src="/assets/images/starMapBtn.png"/>
            </Button>

            <Button className="sidebarBtn storyBtn" onClick={() => showMessage(getCurrentStoryList(props.academy))}>
              <Icon name="bookmark"/>Story
            </Button>

            <Button className="sidebarBtn storyBtn" onClick={() => showUpgrades()}>
              Upgrades
            </Button>

          </Grid.Column>
          <Grid.Column width={5}>
            <Grid.Row className="academyRoom barracks">
              <div className="roomName">
                { getRoomIcon('barracks') }BARRACKS
                <Icon className="iconBtn roomIcon floatRight" name="info circle" onClick={() => showRoomInfo('barracks')}/>
              </div>
              <PilotDropRoom 
                { ...roomProps }
                pilots={ getPilots('barracks') }
                location={'barracks'}
              />
            </Grid.Row>
            <Grid.Row className="academyRoom hangar">
              <div className="roomName">
              { getRoomIcon('hangar') }HANGAR
              <Icon className="iconBtn roomIcon floatRight" name="info circle" onClick={() => showRoomInfo('hangar')}/>
              </div>
              <MechDropRoom 
                { ...roomProps }
                mechs={ getMechs('hangar') }
                location={'hangar'}
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width="5">
            <Grid.Row className={`academyRoom infirmary ${hasUpgrade('infirmary') ? 'expanded' : ''}`}>
              <div className="roomName">
              { getRoomIcon('infirmary') }INFIRMARY
              <Icon className="iconBtn roomIcon floatRight" name="info circle" onClick={() => showRoomInfo('infirmary')}/>
              { getRoomAttrIcons('infirmary') }
              </div>
              <div className="roomName subhead">
                WORKERS
                <div className="roomNote">MAX 2</div>
              </div>
              <PilotDropRoom 
                { ...roomProps }
                className="infirmaryWorkers"
                pilots={ getPilots('infirmaryWorkers') }
                location={'infirmaryWorkers'}
                emptyMsg={'Add workers to treat patients'}
                maxPilots={2}
                />
              <div className="roomName subhead">
                PATIENTS
                <div className="roomNote">WOUNDED ONLY - MAX {props.academy.medBays}</div>
              </div>
              <PilotDropRoom 
                { ...roomProps }
                className={`infirmaryPatients ${hasUpgrade('infirmary') ? 'expanded' : ''}`}
                pilots={ getPilots('infirmaryPatients') }
                location={'infirmaryPatients'}
                emptyMsg={'Drag wounded pilots here'}
                maxPilots={props.academy.medBays}
                filter={(item) => item.pilotData.metrics.hp < item.pilotData.metrics.maxHp }
              />
            </Grid.Row>
            <Grid.Row className={`academyRoom repairBay ${hasUpgrade('repairBays') ? 'expanded' : ''}`}>
              <div className="roomName">
              { getRoomIcon('repairBay') }REPAIR BAY
              <Icon className="iconBtn roomIcon floatRight" name="info circle" onClick={() => showRoomInfo('repairBay')}/>
              { getRoomAttrIcons('repairBay') }
              </div>
              <div className="roomName subhead">
                WORKERS
                <div className="roomNote">MAX 2</div>
              </div>
              <PilotDropRoom 
                { ...roomProps }
                className="repairBayWorkers"
                pilots={ getPilots('repairBayWorkers') }
                location={'repairBayWorkers'}
                emptyMsg={'Add workers to repair Gauntlets'}
                maxPilots={2}
                />
              <div className="roomName subhead">
                GAUNTLETS
                <div className="roomNote">DAMAGED ONLY - MAX {props.academy.repairBays}</div>
              </div>
              <MechDropRoom 
                { ...roomProps }
                className={`repairBayMechs ${hasUpgrade('repairBays') ? 'expanded' : ''}`}
                mechs={ getMechs('repairBayMechs') }
                location={'repairBayMechs'}
                emptyMsg={'Drag damaged Gauntlets here'}
                maxMechs={props.academy.repairBays}
                filter={(item) => isMechDamaged(item.mechData)
                }
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width="5">
            <Grid.Row className={`academyRoom simulator ${hasUpgrade('simulator') ? 'expanded' : ''}`}>
              <div className="roomName">
                { getRoomIcon('simulator') }SIMULATOR
                <Icon className="iconBtn roomIcon floatRight" name="info circle" onClick={() => showRoomInfo('simulator')}/>
                <div className="roomNote">MAX {props.academy.simPods}</div>
              </div>
              <PilotDropRoom 
                { ...roomProps }
                className={`simulator ${hasUpgrade('simulator') ? 'expanded' : ''}`}
                pilots={ getPilots('simulator') }
                location={'simulator'}
                maxPilots={props.academy.simPods}
                />
            </Grid.Row>

            <Grid.Row className={`academyRoom recRoom ${hasUpgrade('recRoom') ? 'expanded' : ''}`}>
              <div className="roomName">
                { getRoomIcon('recRoom') }REC ROOM
                <Icon className="iconBtn roomIcon floatRight" name="info circle" onClick={() => showRoomInfo('recRoom')}/>
                <div className="roomNote">MAX {props.academy.recRooms}</div>
                { getRoomAttrIcons('recRoom') }
              </div>
              <PilotDropRoom 
                { ...roomProps }
                className={`recRoom ${hasUpgrade('recRoom') ? 'expanded' : ''}`}
                pilots={ getPilots('recRoom') }
                location={'recRoom'}
                maxPilots={props.academy.recRooms}
                />
            </Grid.Row>

            <Grid.Row className={`academyRoom factory ${hasUpgrade('factory') ? 'expanded' : ''}`}>
              <div className="roomName">
                { getRoomIcon('factory') }FACTORY
                <Icon className="iconBtn roomIcon floatRight" name="info circle" onClick={() => showRoomInfo('factory')}/>
                <div className="roomNote">MAX {props.academy.factoryWorkers}</div>
                { getRoomAttrIcons('factory') }
              </div>
              <PilotDropRoom 
                { ...roomProps }
                className={`factory ${hasUpgrade('factory') ? 'expanded' : ''}`}
                pilots={ getPilots('factory') }
                location={'factory'}
                emptyMsg={'Add workers to generate resources'}
                maxPilots={props.academy.factoryWorkers}
                />
            </Grid.Row>
            { props.academy.data?.schematics?.length > 0 && (
                  <FactoryQueue
                    academy={props.academy}
                    showFactoryQueue={showFactoryQueue.bind(this)}
                  />
                )}

          </Grid.Column>
        </Grid>
        <StarMapModal
          { ...modalProps }
          starMap={ props.starMap }
          isOpen={ state.isStarMapModalOpen }
          toggleModal={ showStarMap.bind(this) }
        />
        <UpgradesModal 
          { ...modalProps }
          isOpen={ state.isUpgradesModalOpen }
          toggleModal={ showUpgrades.bind(this) }
        />
        <FactoryQueueModal 
          { ...modalProps }
          isOpen={ state.isFactoryQueueModalOpen }
          toggleModal={ showFactoryQueue.bind(this) }
        />
        <SettingsModal 
          { ...modalProps }
          setMusicVolume={ props.setMusicVolume }
          isOpen={ state.isSettingsModalOpen }
          toggleModal={ showSettings.bind(this) }
        />
      </Container>
}