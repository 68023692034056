export const ItemTypes = {
    PILOT: 'pilot',
    MECH: 'mech'
}

export const slotTypes = [
    'head',
    'leftArm',
    'rightArm',
    'leftLeg',
    'rightLeg'
]

export const slotNames = {
    head:       'Head',
    leftArm:    'Left Arm',
    rightArm:   'Right Arm',
    leftLeg:    'Left Leg',
    rightLeg:   'Right Leg'
}

export const slotClass = {
    head:       'head',
    leftArm:    'arm',
    rightArm:   'arm',
    leftLeg:    'leg',
    rightLeg:   'leg'
}

export const slotClassSkills = {
    head: [ 'comm', 'per' ],
    arm: [ 'combat', 'reaction' ],
    leg: [ 'repair', 'pilot' ]
}

export const skillSlotClass = {
    comm:       'head',
    combat:     'arm',
    repair:     'leg',
    reaction:   'arm',
    per:        'head',
    pilot:      'leg'
}

export const attrList = [
    'conf',
    'prow',
    'reas',
    'fort',
    'wis',
    'dar',
    'emp',
    'hum'
]

export const attrNames = {
    conf:   'Confidence',
    prow:   'Prowess',
    reas:   'Reason',
    fort:   'Fortitude',
    wis:    'Wisdom',
    dar:    'Daring',
    emp:    'Empathy',
    hum:    'Humility'
}

export const skillList = [
    'comm',
    'per',
    'combat',
    'reaction',
    'repair',
    'pilot'
]

export const skillNames = {
    comm:       'Communication',
    combat:     'Combat',
    repair:     'Repair',
    reaction:   'Reaction',
    per:        'Perception',
    pilot:      'Pilot'
}

export const skillAttrs = {
    comm:       ['hum', 'emp', 'conf'],
    combat:     ['prow', 'fort', 'dar'],
    reaction:   ['prow', 'conf', 'wis'],
    repair:     ['wis', 'hum', 'reas'],
    per:        ['reas', 'emp', 'conf'],
    pilot:      ['reas', 'fort', 'dar']
}

export const skillIcons = {
    comm: 'comments',
    combat: 'crosshairs',
    repair: 'wrench',
    reaction: 'angle double right',
    per: 'eye',
    pilot: 'rocket'
}

export const roomIcons = {
    barracks: 'bed',
    hangar: 'warehouse',
    infirmary: 'heartbeat',
    repairBay: 'wrench',
    simulator: 'microchip',
    recRoom: 'coffee',
    factory: 'factory',
    factoryQueue: 'list'
}

export const roomList = [
    'barracks',
    'hangar',
    'infirmary',
    'repairBay',
    'simulator',
    'recRoom',
    'factory',
    'factoryQueue'
]

export const metricList = [
    'xp',
    'fame',
    'hap',
    'hp',
    'energy',
    'rank'
]

export const metricNames = {
    xp: 'Expertise',
    fame: 'Fame',
    hap: 'Happiness',
    hp: 'Health',
    energy: 'Energy',
    rank: 'Rank'
}

export const metricIcons = {
    xp: 'chess',
    fame: 'trophy',
    hap: 'smile',
    hp: 'heart',
    energy: 'lightning',
    rank: 'star'
}

// which attributes are improved by spending time in a room
export const roomAttrs = {
    infirmary: ['reas', 'wis', 'emp', 'hum'],
    repairBay: ['conf', 'reas', 'fort', 'wis'],
    recRoom: ['prow', 'dar', 'emp'],
    factory: ['conf', 'fort', 'hum']
}

export const numerals = [ 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII' ]

export const statList = [
    "wins",
    "losses",
    "draws",
    "headSorties",
    "armSorties",
    "legSorties",
    "specials",
    "bolstered",
    "targetsFound",
    "damageDone",
    "crits",
    "attacksBlocked",
    "damageRepaired",
    "damageTaken"
]

export const quirkList = [
    'arrogant',     // +conf -hum
    'timid',        // +hum -conf
    'reckless',     // +dar -wis
    'cautious',     // +wis -dar
    'bookish',      // +reas -fort
    'stubborn',     // +fort -reas
    'thoughtless',  // +prow -emp
    'perfectionist' // +emp -prow
]

export const attrQuirkEffects = {
    conf: {
        bonus: 'arrogant',
        malus: 'timid'
    },
    hum: {
        bonus: 'timid',
        malus: 'arrogant'
    },
    dar: {
        bonus: 'reckless',
        malus: 'cautious'
    },
    wis: {
        bonus: 'cautious',
        malus: 'reckless'
    },
    reas: {
        bonus: 'bookish',
        malus: 'stubborn'
    },
    fort: {
        bonus: 'stubborn',
        malus: 'bookish'
    },
    prow: {
        bonus: 'thoughtless',
        malus: 'perfectionist'
    },
    emp: {
        bonus: 'perfectionist',
        malus: 'thoughtless'
    }
}

export const quirkAttrEffects = {
    arrogant: {
        bonus: 'conf',
        malus: 'hum'
    },
    timid: {
        bonus: 'hum',
        malus: 'conf'
    },
    reckless: {
        bonus: 'dar',
        malus: 'wis'
    },
    cautious: {
        bonus: 'wis',
        malus: 'dar'
    },
    bookish: {
        bonus: 'reas',
        malus: 'fort'
    },
    stubborn: {
        bonus: 'fort',
        malus: 'reas'
    },
    thoughtless: {
        bonus: 'prow',
        malus: 'emp'
    },
    perfectionist: {
        bonus: 'emp',
        malus: 'prow'
    }
}

export const quirkLevel = [
    'slightly',
    'mildly',
    'mildly',
    'moderately',
    'moderately',
    'quite',
    'quite',
    'very',
    'very',
    'extremely'
]

export const relPairs = [
    ['head', 'leftArm'],
    ['head', 'rightArm'],
    ['head', 'leftLeg'],
    ['head', 'rightLeg'],
    ['leftArm', 'rightArm'],
    ['leftArm', 'leftLeg'],
    ['leftArm', 'rightLeg'],
    ['rightArm', 'leftLeg'],
    ['rightArm', 'rightLeg'],
    ['leftLeg', 'rightLeg']
]

export const planetTypes = [
    'lava',
    'airless',
    'desert',
    'temperate',
    'jungle',
    'swamp',
    'water',
    'ice',
]

export const upgradeIcons = {
    barracks: 'bed',
    hangar: 'warehouse',
    infirmary: 'heartbeat',
    launchBays: 'rocket',
    repairBays: 'wrench',
    simulator: 'microchip',
    recRoom: 'coffee',
    factory: 'factory',
    factoryQueue: 'list',
    jumpEngines: 'map',
    repairTools: { main: 'wrench', corner: 'add' },
    resourceStorage: 'cubes'
}

export const minigames = {
    holoSync: {
        title: `Synchronize The Holographic Projectors`,
        instructions: `Click to align each emitter`,
        control: 'mouse'
    },
    centerTarget: {
        title: `Line Up The Target`,
        instructions: `Move the mouse until the target is lined up`,
        control: 'mouse'
    },
    waveMatch: {
        title: `Align The Waveforms`,
        instructions: `Adjust the waveform until it matches the pattern`,
        control: 'keyboard'
    },
    circuitMaze: {
        title: `Route The Circuit`,
        instructions: `Route power through available circuits`,
        control: 'keyboard'
    }
}

export const soundFile = {
    openBeep: `beep-027.mp3`,
    closeBeep: `beep-29.mp3`,
    buttonPress: `beep-28.mp3`,
    cardDrop: `button-46.mp3`,
    success: `button-37a.mp3`,
    failure: `button-33a.mp3`,
    pew: `zapsplat_multimedia_laser_weapon_fire_001_25877.mp3`,
    pewPew: `zapsplat_multimedia_laser_weapon_fire_002_25878.mp3`,
    gunBurst: `audio_hero_Ak21MachineGunBurs_PE401802_309.mp3`,
    kaboom: `zapsplat_science_fiction_explosion_short_and_sharp_002_62577.mp3`,
    slash: `zapsplat_impact_sword_swipe_into_large_wood_hit_003_43693.mp3`,
    win: `zapsplat_multimedia_game_sound_synth_brass_fanfare_success_musical_005_40710.mp3`,
    lose: `zapsplat_cartoon_wrong_answer_fail_descending_tone_002_69416.mp3`,
    zap: `zapsplat_multimedia_laser_weapon_fire_004_25880.mp3`,
    zapZap: `zapsplat_multimedia_laser_weapon_fire_005_25881.mp3`,
    jumpDrive: `zapsplat_sound_design_whoosh_airy_into_impact_electricity_001_26481.mp3`,
    whoosh: `zapsplat_sound_design_cinematic_short_whoosh_003_62959.mp3`
}