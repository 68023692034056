import React from 'react'
import { camelCaseToTitleCase } from '../../lib/textUtils';
import { MechView } from '../mechView';
import { PilotFrames } from './pilotFrames';

export function TeamCombatView({ team, planet, inCombat, combatOver, tick, comms, recallTeam }) {
    return (
        <div className="mechContainer">
            <div className="mechFrame leftFrame">
                <MechView
                    size={420}
                    subject={ team.mech.data }
                    options={ { ablativeArmor: team.mech.status.ablativeArmor } }
                    context={'damage'}
                />
                <PilotFrames 
                    team={ team } 
                    inCombat={ inCombat }
                />
            </div>
            <div className={`mechFrame leftFrame ${inCombat ? 'collapsed' : ''}`}>
                <MechView
                    size={420}
                    subject={ team.mech.data }
                    context={'display'}
                    environment={ planet.type }
                />
            </div>
            
            <div className={`recallBtn ${inCombat && !combatOver && tick > 100 ? 'active' : ''}`} onClick={() => recallTeam()}>
                RECALL
            </div>

            <div className={`commLog ${inCombat ? 'active' : ''}`}>
                { comms.map((c, i) => {
                    return <div className="commItem" key={i}>
                        <div className="commHeader">
                            { c.name }
                            <div className="floatRight">
                                { camelCaseToTitleCase(c.slot) }
                            </div>
                        </div>
                        <div className="commBody">
                            { c.message }    
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}