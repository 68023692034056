import React, { useState, useEffect } from 'react'
import { ItemTypes } from '../../lib/constants'
import { useDrop } from 'react-dnd'
import DragPilotCard from './dragPilotCard';

function PilotDropRoom({ pilots, className, location, emptyMsg, movePilot, selectPilot, playSoundEffect, enabled = false , maxPilots, filter = (p) => true, time}) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.PILOT,
    canDrop: () => enabled && (!maxPilots || pilots.length < maxPilots),
    drop: async (item, monitor) => {
        if (filter(item)) {
          playSoundEffect('cardDrop')
          movePilot(item.id, location)
        }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  }), [ enabled, pilots ])

  const [roomPilots, setRoomPilots] = useState(pilots)
  useEffect(() => {
    setRoomPilots(pilots)
  }, [ pilots ])

  function selectPilotInRoom(pilotId) {
    const pilotIds = pilots.map((p) => p.id)
    selectPilot(pilotId, pilotIds)
  }

  return (
    <div 
      className={`roomContents ${className || ''}`}
      ref={ drop }
    >
        { roomPilots.map((p, i) => (
          <DragPilotCard key={i} pilot={p} number={i} location={location} selectPilot={selectPilotInRoom} enabled={enabled}/>
        ))}
        { pilots.length === 0 && <div className="emptyMsg">{emptyMsg}</div>}
      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'yellow',
          }}
        />
      )}
    </div>
  )
}

export default PilotDropRoom