import { toast, cssTransition } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';

export const showToast = (message, className = '') => {
    const transition = cssTransition({
        enter: "animate__animated animate__fadeInDown",
        exit: "animate__animated animate__fadeOutDown"
    });
    
    toast(message, {
        className: `myToast ${ className }`,
        bodyClassName: 'myToastBody',
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition
    });
}