export const sortByKey = (array, key, reverse = false) => {
    // this method sorts an array of objects by a given key, and always sorts null/undefined values to the bottom, regardless of sort order requested
    
    const factor = reverse ? -1 : 1

    const hasProp = []
    const lacksProp = []

    array.forEach((item) => {
        if (item.hasOwnProperty(key) && item[key] !== null && item[key] !== undefined) {
            hasProp.push(item)
        } else {
            lacksProp.push(item)
        }
    })
    
    const sorted = hasProp.sort((a, b) => (a[key] > b[key]) ? 1 * factor : -1 * factor)
    const combined = sorted.concat(lacksProp)

    return combined
}