import * as d3 from "d3"
import React, { useEffect } from "react"

export default function Graph ({ data }) {
    useEffect(() => {
      function isConnected(a, b) {
        return a.rels[b.id] || a.id === b.id
      }
                
      var width = 1000
      var height = 600
      
      const picSize = 40

      var force = d3.forceSimulation(data.nodes)
        .force('link', d3.forceLink(data.links).distance(50))
        .force("charge", d3.forceManyBody().strength(-2000))
        .force('center', d3.forceCenter(width / 2, height / 2));
      
      // Zoom definition
      var zoom = d3.zoom()
        .scaleExtent([1, 10])
        .on("zoom", zoomed);
      
      function zoomed(event) {
        const transform = event.transform
        container.attr("transform", "translate(" + transform.x + "," + transform.y + ") scale(" + transform.k + ")");
      }
      
      d3.select('#theGraph > svg').remove()
    
      var svg = d3.select('#theGraph').append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g");
      
      
      svg.append("rect")
        .attr("width", width)
        .attr("height", height)
        .style("fill", "none")
        .style("pointer-events", "all")
        .call(zoom);
      
      var container = svg.append("g");
      
      const buildPortrait = (node) => {
        node.append("image")
            .attr("x", -0.5 * picSize)
            .attr("y", -0.5 * picSize)
            .attr("xlink:href", (d) => `/assets/backgrounds/indoor.png` )
            .attr("width", picSize)
            .attr("height", picSize)
        node.append("image")
            .attr("x", -0.5 * picSize)
            .attr("y", -0.5 * picSize)
            .attr("xlink:href", (d) => '/assets/faces/heads/neck.svg' )
            .attr("width", picSize)
            .attr("height", picSize)
            .style("filter", (d) => `brightness(${d.portrait.skin.lightness - 0.1}) hue-rotate(${d.portrait.skin.tone}deg)`)
        node.append("image")
            .attr("x", -0.5 * picSize)
            .attr("y", -0.5 * picSize)
            .attr("xlink:href", (d) => `/assets/clothing/u1.svg` )
            .attr("width", picSize)
            .attr("height", picSize)
        node.append("image")
            .attr("x", -0.5 * picSize)
            .attr("y", -0.5 * picSize)
            .attr("xlink:href", (d) => `/assets/faces/heads/${d.portrait.head}.svg` )
            .attr("width", picSize)
            .attr("height", picSize)
            .style("filter", (d) => `brightness(${d.portrait.skin.lightness - 0.1}) hue-rotate(${d.portrait.skin.tone}deg)`)
        node.append("image")
            .attr("x", -0.5 * picSize)
            .attr("y", -0.5 * picSize)
            .attr("xlink:href", (d) => `/assets/faces/eyes/e${d.portrait.eyes}.svg` )
            .attr("width", picSize)
            .attr("height", picSize)
            .style("filter", (d) => `hue-rotate(${d.portrait.skin.tone}deg)`)
        node.append("image")
            .attr("x", -0.5 * picSize)
            .attr("y", -0.5 * picSize)
            .attr("xlink:href", (d) => `/assets/faces/brows/b${d.portrait.brows}.svg` )
            .attr("width", picSize)
            .attr("height", picSize)
        node.append("image")
            .attr("x", -0.5 * picSize)
            .attr("y", -0.5 * picSize)
            .attr("xlink:href", (d) => `/assets/faces/noses/n${d.portrait.nose}.svg` )
            .attr("width", picSize)
            .attr("height", picSize)
        node.append("image")
            .attr("x", -0.5 * picSize)
            .attr("y", -0.5 * picSize)
            .attr("xlink:href", (d) => `/assets/faces/lips/${d.portrait.lips}.svg` )
            .attr("width", picSize)
            .attr("height", picSize)
        node.append("image")
            .attr("x", -0.5 * picSize)
            .attr("y", -0.5 * picSize)
            .attr("xlink:href", (d) => `/assets/faces/hair/${d.portrait.hair.style}.svg` )
            .attr("width", picSize)
            .attr("height", picSize)
            .style("filter", (d) => `brightness(${d.portrait.hair.lightness}) hue-rotate(${d.portrait.hair.color}deg) saturate(${d.portrait.hair.saturation}%)`)
      }
              
      var link = container.append("g")
        .attr("class", "links")
        .selectAll(".links")
        .data(data.links)
        .enter().append("line")
        .attr("class", "link")
        .attr("marker-end", "url(#end)");
      
      var node = container.append("g")
        .attr("class", "nodes")
        .selectAll(".node")
        .data(data.nodes)
        .enter().append("g")
        .attr("class", "node")
        .on("mouseover", mouseover)
        .on("mouseout", mouseout)
        // .call(force.drag);
        .call(d3.drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended));

      function dragstarted(e, d) {
        if (!e.active) force.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }
      
      function dragged(e, d) {
        d.fx = e.x;
        d.fy = e.y;
      }
      
      function dragended(e, d) {
        if (!e.active) force.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }

      node.append('rect')
          .attr("x", -0.5 * picSize)
          .attr("y", -0.5 * picSize)
          .attr("width", picSize)
          .attr("height", picSize)
          .on("mouseover", mouseover)
          .on("mouseout", mouseout)      
      buildPortrait(node)

      node.append('text')
          .attr("x", 0)
          .attr("dy", (picSize / 2) + 15)
          .attr("class", "nodeText")
          .style("text-anchor", "middle")
          .text((d) => d.key)
        
      node.append('text')
          .attr("x", 0)
          .attr("dy", -25)
          .attr("class", "nodeText percentage")
          .style("text-anchor", "middle")
          .text('')
      
      force.on("tick", function(f) {
      
        link
          .attr("x1", function(d) {
            return d.source.x;
          })
          .attr("y1", function(d) {
            return d.source.y;
          })
          .attr("x2", function(d) {
            return d.target.x;
          })
          .attr("y2", function(d) {
            return d.target.y;
          })
          .attr("stroke", (d) => {
            return d.size > 0 ? 'blue' : d.size < 0 ? 'red' : 'gray'
          })
          .attr("stroke-width", (d) => d.size ? Math.abs(d.size) : 1)
    
        node.attr("transform", function(d) {
          return "translate(" + d.x + "," + d.y + ")";
        });
      });
      
      function mouseover(e, node) {
        // d3.select(this).select("circle").transition()
        //   .duration(750)
        //   .attr("r", 8);
        set_highlight(node);
      }
      
      function mouseout(e, node) {
        // d3.select(this).select("circle").transition()
        //   .duration(750)
        //   .attr("r", 5);
        exit_highlight(node)
      }
      
      function set_highlight(d) {
        node.select("text")
            .attr("class", function(o) {
                return isConnected(d, o) ? "nodeText active" : "nodeText inactive";
            })
        node.select(".percentage")
            .text(function(o) {
                return d.rels[o.id] ? `${d.rels[o.id]}%` : "";
            })
        node.attr("class", function(o) {
          return isConnected(d, o) ? "node active" : "node inactive";
        });
        link.attr("class", function(o) {
          return o.source.index === d.index || o.target.index === d.index ? "link active" : "link inactive";
        });
      }
      
      function exit_highlight(d) {
        node.attr("class", "node");
        link.attr("class", "link");
        node.select("text").attr("class", "nodeText")
        node.select(".percentage").text('')
      }
    }, [ data ])
    return <div id="theGraph"/>
}