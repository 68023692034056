import React from 'react';
import { ref, onValue } from 'firebase/database';
import { Container, Grid, Menu, Button } from 'semantic-ui-react'
import { PilotList } from '../components/roster/pilotList'
import { MechList } from '../components/roster/mechList'
import { TeamList } from '../components/roster/teamList'
import { MechPurchaseModal } from '../components/modals/mechPurchaseModal'
import { generatePilot, pilotDefaults } from '../lib/pilot'
import { generateMech, mechDefaults } from '../lib/mech'

export default class RosterView extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            list: 'pilots',
            pilots: [],
            pilotUnsubscribe: null,
            mechs: [],
            mechUnsubscribe: null,
            isMechPurchaseModalOpen: false
        };
    }

    componentDidMount() {
        this.props.showTutorial('rosterIntro')
        this.props.setMusicTrack("/assets/audio/Emerging-Alien-Civilizations_Looping.mp3")
        this.fetchPilots()
        this.fetchMechs()
    }

    fetchPilots() {
        const pilotListRef = ref(this.props.db, `pilots/${this.props.user.uid}`);
            
        const unsubscribe = onValue(pilotListRef, async (querySnapshot) => {
            const pilots = [];
            querySnapshot.forEach((doc) => {
                const data = Object.assign({}, pilotDefaults, doc.val())
        
                pilots.push(data);    
            });    

            pilots.sort(function(a, b) {
                if (a.created < b.created) return -1;
                if (a.created > b.created) return 1;
                return 0;
            });

            this.setState({ pilots })
        })

        this.setState({ pilotUnsubscribe: unsubscribe })
    }    

    fetchMechs() {
        const mechListRef = ref(this.props.db, `mechs/${this.props.user.uid}`);
            
        const unsubscribe = onValue(mechListRef, async (querySnapshot) => {
            const mechs = [];
            querySnapshot.forEach((doc) => {
                const data = Object.assign({}, mechDefaults, doc.val())
        
                mechs.push(data);    
            });    

            mechs.sort(function(a, b) {
                if (a.created < b.created) return -1;
                if (a.created > b.created) return 1;
                return 0;
            });

            this.setState({ mechs })
        })

        this.setState({ mechUnsubscribe: unsubscribe })
    }    

    toggleMechPurchaseModal(open = true, data) {
        if (data) {
            const { price, mech } = data

            this.props.academy.removeCredits(price.credits)
            this.props.academy.removeXenos(price.xenos)

            generateMech(this.props.db, this.props.user, mech.make, mech.model, mech.rank, mech.leftWeapon.type, mech.rightWeapon.type)
        }

        this.setState({ isMechPurchaseModalOpen: open })
    }

    createPilot() {
        generatePilot(this.props.db, this.props.user)
    }

    selectMenu(list) {
        this.setState({ list })
    }

    recruitPilotButton() {
        return (
            <Button className="createBtn" onClick={this.createPilot.bind(this)}> Recruit Pilot </Button>
        )
    }

    purchaseMechButton() {
        return (
            <Button className="createBtn" onClick={() => this.toggleMechPurchaseModal()}> Purchase Gauntlet </Button>
        )
    }

    render() {
        const emptyPilotSlots = this.props.academy.maxPilots - this.state.pilots.length
        const emptyMechSlots = this.props.academy.maxMechs - this.state.mechs.length

        const listProps = {
            db: this.props.db,
            user: this.props.user,
            academy: this.props.academy,
            showGuide: this.props.showGuide
        }

        return (
            <Container>
                <Grid className="topGrid">
                    <Grid.Row>
                        <Grid.Column width={9}>
                            <Menu className="rosterMenu">
                                <Menu.Item 
                                    className="menuItem"
                                    active={ this.state.list === 'pilots' }
                                    onClick={ () => this.selectMenu('pilots') }
                                >
                                    Pilots
                                </Menu.Item>
                                <Menu.Item 
                                    className="menuItem"
                                    active={ this.state.list === 'mechs' }
                                    onClick={ () => this.selectMenu('mechs') }
                                >
                                    Gauntlets
                                </Menu.Item>
                                <Menu.Menu position="right">
                                    { this.state.list === 'pilots' && <Menu.Item>
                                        <Button className="createBtn" onClick={() => this.props.showRelationships()}> Relationships </Button>                                    
                                    </Menu.Item> }
                                    <Menu.Item>
                                        { this.state.list === 'pilots' ? emptyPilotSlots > 0 && this.recruitPilotButton() : emptyMechSlots > 0 && this.purchaseMechButton() }
                                    </Menu.Item>
                                </Menu.Menu>
                            </Menu>
                            <div>
                                { this.state.list === 'pilots' ? <PilotList
                                    { ...listProps } 
                                    selectPilot={ this.props.selectPilot }
                                    pilots={ this.state.pilots }
                                    emptySlots={ emptyPilotSlots }
                                /> : <MechList
                                    { ...listProps } 
                                    selectMech={ this.props.selectMech }
                                    mechs={ this.state.mechs }
                                    emptySlots={ emptyMechSlots }
                                /> }
                            </div>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <div>
                                <TeamList
                                    { ...listProps } 
                                    selectPilot={ this.props.selectPilot }
                                    selectMech={ this.props.selectMech }
                                /> 
                            </div>
                        </Grid.Column>
                        <MechPurchaseModal
                            isOpen={this.state.isMechPurchaseModalOpen}
                            academy={ this.props.academy }
                            toggleModal={this.toggleMechPurchaseModal.bind(this)}
                        />
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }
}