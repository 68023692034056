import React, { Component } from 'react';
// import { collection, query, where, onSnapshot } from "firebase/firestore";
import { ref, onValue, query, orderByChild, equalTo } from 'firebase/database';
import { Grid, Icon, Menu, Button, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import PilotSlot from './pilotSlot'
import MechSlot from './mechSlot'
import { changePilotTeamSlots, getPilotRank, pilotDefaults } from '../../lib/pilot'
import { changeMechTeam } from '../../lib/mech'
import { showToast } from '../../lib/toast'
import { camelCaseToTitleCase } from '../../lib/textUtils';
import { numerals } from '../../lib/constants';

export class TeamList extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            head: null,
            leftArm: null,
            rightArm: null,
            leftLeg: null,
            rightLeg: null,
            mechId: null,
            mechRank: 0,
            mechCoreDamage: 0,
            minPilotRank: 0,
            selectedTeam: 1,
            selectedModule: '',
            unsubscribePilots: null,
            unsubscribeMech: null,
            creating: false
        };
      }
      
    componentDidMount() {
        this.selectTeam(1)
    }

    fetchPilots() {
        const pilotListRef = query(ref(this.props.db, `pilots/${this.props.user.uid}`), orderByChild('team'), equalTo(this.state.selectedTeam));
            
        const unsubscribe = onValue(pilotListRef, async (querySnapshot) => {
            const update = {
                // pilots: [],
                head: null,
                leftArm: null,
                rightArm: null,
                leftLeg: null,
                rightLeg: null,
                minPilotRank: 0
            }

            querySnapshot.forEach((doc) => {
                const pilotData = Object.assign({}, pilotDefaults, doc.val())

                if (pilotData.slot) {
                    update[pilotData.slot] = pilotData.id
                    const pilotRank = getPilotRank(pilotData)
                    update.minPilotRank = update.minPilotRank ? Math.min(pilotRank, update.minPilotRank) : pilotRank
                }

            });    

            this.setState(update)
        })

        this.setState({ unsubscribePilots: unsubscribe })
    }    

    fetchMech() {
        const mechListRef = query(ref(this.props.db, `mechs/${this.props.user.uid}`), orderByChild('team'), equalTo(this.state.selectedTeam));
            
        const unsubscribe = onValue(mechListRef, async (querySnapshot) => {
            const update = {
                mechId: null,
                mechRank: 0,
                mechCoreDamage: 0
            }

            querySnapshot.forEach((doc) => {
                const data = doc.val()
                update.mechId = data.id
                update.mechRank = data.rank

                if (this.state.selectedModule) {
                    // clear the selected module if it's too high for the mech
                    const moduleRank = this.state.selectedModule.split('-')[1]
                    if (moduleRank > data.rank) {
                        update.selectedModule = ''
                    }
                }
                const currBody = data.armor.body
                const maxBody = data.maxArmor.body

                update.mechCoreDamage = currBody / maxBody

                update.mechUpdated = data.lastModified
            });    

            this.setState(update)
        })

        this.setState({ unsubscribeMech: unsubscribe })
    }    

    getTeam() {
        this.fetchPilots()
        this.fetchMech()
    }
    
    selectTeam(number) {
        this.setState({ selectedTeam: number }, () => this.getTeam())
    }

    updateMech(mechId, rank) {
        const { db, user } = this.props
        const { selectedTeam } = this.state

        if (!mechId) {
            // removing existing mech
            if (this.state.mechId) {
                changeMechTeam(db, user, this.state.mechId, null)
            }    

            this.setState({ mechId })
        } else {
            // check for rank match
            let rankMatch = rank <= this.state.minPilotRank

            if (!rankMatch) {
                showToast(`THIS GAUNTLET REQUIRES PILOTS OF RANK ${rank} OR ABOVE`, 'warning')
            } else {
                if (this.state.mechId) {
                    changeMechTeam(db, user, this.state.mechId, null)
                }
                changeMechTeam(db, user, mechId, selectedTeam)

                this.setState({ mechId })
            }
        }
    }

    updatePilots(update) {
        const { db, user } = this.props
        const { selectedTeam, mechRank } = this.state

        const newState = {}

        const assignments = []

        switch (update.action) {
            case 'add':
                // check for rank match
                let rankMatch = mechRank <= update.rank

                if (!rankMatch) {
                    showToast(`THIS GAUNTLET REQUIRES PILOTS OF RANK ${mechRank} OR ABOVE`, 'warning')
                } else {
                    if (this.state[update.slotType]) {
                        // remove existing pilot
                        assignments.push({ pilotId: this.state[update.slotType], team: null, slot: null })
                    }
                    assignments.push({ pilotId: update.pilotId, team: selectedTeam, slot: update.slotType })

                    changePilotTeamSlots(db, user, assignments)

                    newState[update.slotType] = update.pilotId
                }
                break
            case 'remove':
                assignments.push({ pilotId: this.state[update.slotType], team: null, slot: null })

                changePilotTeamSlots(db, user, assignments)
                break
            case 'swap':

                const toB = this.state[update.slotA]
                const toA = this.state[update.slotB]
                if (toA) {
                    assignments.push({ pilotId: toA, team: selectedTeam, slot: update.slotA })
                }
                if (toB) {
                    assignments.push({ pilotId: toB, team: selectedTeam, slot: update.slotB })
                }

                newState[update.slotA] = toA
                newState[update.slotB] = toB

                changePilotTeamSlots(db, user, assignments)
                break
            default:
        }

        this.setState(newState)
    }

    confirmTeam() {
        if (this.props.confirmTeam) {
            const { mechId, head, leftArm, rightArm, leftLeg, rightLeg } = this.state
            const teamData = { 
                number: this.state.selectedTeam, 
                mechId, 
                head, 
                leftArm, 
                rightArm, 
                leftLeg, 
                rightLeg 
            }

            this.props.playSoundEffect('buttonPress')

            // we don't need to track changes anymore, so unsubscribe
            this.state.unsubscribeMech()
            this.state.unsubscribePilots()

            this.props.confirmTeam(teamData, this.state.selectedModule)
        }
    }

    isTeamReady() {
        const teamReady = this.state.mechId 
        && this.state.head 
        && this.state.leftArm 
        && this.state.rightArm 
        && this.state.leftLeg 
        && this.state.rightLeg
        && this.state.mechCoreDamage > 0.35

        let alreadyFought = false

        if (this.props.theEnemy) {
            alreadyFought = this.props.theEnemy.teamsFought.includes(this.state.selectedTeam)
        }

        return teamReady && !alreadyFought
    }

    handleModuleSelect(e, { value }) {
        this.setState({ selectedModule: value })
    }

    getModuleDropdown() {
        const { academy } = this.props

        const options = []
        
        Object.keys(academy.data.modules).sort().forEach((mKey, i) => {
            const mGroup = academy.data.modules[mKey]

            Object.keys(mGroup).sort().forEach((iKey, j) => {
                if (iKey <= this.state.mechRank) {
                    const count = mGroup[iKey]

                    if (count > 0) {
                        const tag = `${mKey}-${iKey}`
                        options.push({
                            value: tag,
                            key: tag,
                            text: `${camelCaseToTitleCase(mKey)} ${numerals[iKey - 1]}`
                        })
                    }
                }
            })
        })

        return options.length > 0 ? <Dropdown
            className="moduleDropdown"
            value={this.state.selectedModule}
            selection
            clearable
            placeholder="No Module Selected"
            onChange={this.handleModuleSelect.bind(this)}
            options={options}
        /> : null
    }

    render() {
        const { selectedTeam } = this.state

        const pilotSlotProps = (slotType) => ({
            db: this.props.db,
            user: this.props.user,
            slotType,
            pilotId: this.state[slotType],
            context: this.props.context,
            selectPilot: this.props.selectPilot,
            updatePilots: this.updatePilots.bind(this)
        })

        return (
            <div className="teamList">
                <div className="teamListHeader">
                    <h1 className="ui">
                        Teams 
                    </h1>
                    { this.props.context === 'combat' ? <>
                            <Button disabled={!this.isTeamReady()} className="floatRight" onClick={() => this.confirmTeam()}>CONFIRM</Button>
                            <Button className="floatRight dangerBtn" as={Link} to={'/academy'}>ABORT</Button>
                            { this.getModuleDropdown() }
                        </> : 
                        <Icon className="iconBtn floatRight" name="question circle" onClick={() => this.props.showGuide('pilots', 'assigning')}/>}
                </div>
                <Menu className="teamMenu">
                    { Array(6).fill().map((n, i) => <Menu.Item
                        key={i}
                        className={`teamItem ${ selectedTeam === i + 1 && 'selected' }`}
                        disabled={ i + 1 > this.props.academy?.teamSlots }
                        onClick={() => this.selectTeam(i + 1)}
                        >
                        <div className={`teamNum bg-team-${i + 1}`}>{ numerals[i] }</div>
                    </Menu.Item>
                    )}
                </Menu>

                { selectedTeam && <div>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <MechSlot
                                    className="floatLeft"
                                    db={this.props.db}
                                    user={this.props.user}
                                    mechId={this.state.mechId}
                                    context={this.props.context}
                                    selectMech={this.props.selectMech}
                                    mechUpdated={this.state.mechUpdated}
                                    assignMech={this.updateMech.bind(this)}
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <PilotSlot 
                                    className="floatRight"
                                    { ...pilotSlotProps('head') }
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <PilotSlot 
                                    className="floatLeft"
                                    { ...pilotSlotProps('rightArm') }
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <PilotSlot 
                                    className="floatRight"
                                    { ...pilotSlotProps('leftArm') }
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <PilotSlot 
                                    className="floatLeft"
                                    { ...pilotSlotProps('rightLeg') }
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <PilotSlot 
                                    className="floatRight"
                                    { ...pilotSlotProps('leftLeg') }
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div> }

            </div>
        )
    }
  }