import { pickSlot } from './mech'
import { randInt, checkRoll, getRankedValue, oneIn, getRandomItem } from './mathUtils'
import { camelCaseToTitleCase } from './textUtils'
import { slotNames, slotClass } from './constants'

export const doKaijuAction = async (kaiju, team, tick, timeFactor, addStats, addComm) => {
    if (kaiju.status.stun) {
        kaiju.status.stun = 0
    }

    const { mech } = team

    const kaijuUpdate = { nextAction: kaiju.nextAction + kaiju.speed }

    let actionDesc = 'did nothing'
    let actionSkill = ''

    const rankFactor = 1.0

    const repairDamage = async (slot) => {
        // repair damage
        const maxRepairAmount = getRankedValue(10, kaiju.data.rank, rankFactor)
        const repairAmount = randInt(Math.ceil(maxRepairAmount / 2), maxRepairAmount)
        await kaiju.fixDamage(slot, repairAmount)
        actionDesc = `Healed ${slotNames[slot]} for ${repairAmount}`  
        actionSkill = 'repair'      
    }

    let allWeaponsReady = true
    let anyWeaponsReady = false

    const isActive = (weapon) => {
        return weapon.nextAttack <= tick
    }

    for (const w of kaiju.weapons) {
        if (isActive(w)) {
            anyWeaponsReady = true
        } else {
            allWeaponsReady = false
        }
    }

    const getSlowestWeapon = (findActive = true) => {
       let slowWeapon = null
       let slowestSpeed = 0
       
        for (const w of kaiju.weapons) {
            const valid = findActive ? isActive(w) : !isActive(w)
            if (valid && w.speed > slowestSpeed) {
                slowestSpeed = w.speed
                slowWeapon = w
            }
        }

        return slowWeapon
    }

    const getRandomWeapon = () => {
       return getRandomItem(kaiju.weapons)
    }

    const incrementNextAttack = (weapon) => {
        weapon.nextAttack += weapon.speed
    }
    
    const chargeWeapon = (weapon) => {
        weapon.nextAttack -= Math.ceil(weapon.speed / 2)
    }

    const checkForBlock = (weapon) => {
        const blockSlots = [ 'leftArm', 'rightArm', 'leftLeg', 'rightLeg' ]

        let blocker = null 
        let slot = null

        for (const s of blockSlots) {
            const b = team.pilots[s]

            if (b.nextSpecial >= 5) { // if the pilot has enough energy to block/dodge
                if (!blocker || b.nextSecondary <= tick) { // if they will go first
                    blocker = b
                    slot = s
                }
            }
        }

        if (blocker) {
            const attrs = blocker.data.attributes
            const skills = blocker.data.skills

            const blockDC = weapon.acc + Math.ceil(kaiju.data.rank * 1.5)
      
            blocker.incrementNextSecondary()

            if (slotClass[slot] === 'arm') {
                let blockBonus = Math.ceil((attrs.wis / 2) + skills.reaction)
                if (mech.status.speedBoost) {
                    blockBonus += mech.status.speedBoost
                }        
                const blockResult = checkRoll(blockDC, blockBonus)
                if (blockResult.success) {
                    actionDesc = `Attacked with ${weapon.name} but it was blocked by ${blocker.data.name}!`   
                    actionSkill = 'reaction' 

                    addStats(blocker.id, 'attacksBlocked', 1)

                    if (blockResult.crit) {
                        if (oneIn(3)) {
                            addComm(blocker, 'block')
                        }
                        actionDesc += ' [PERFECT BLOCK]'
                        addStats(blocker.id, 'perfectBlocks', 1)
                    }

                    return true
                } else {
                    // block failed!
                    return false
                }
            } else {
                const dodgeBonus = Math.ceil((attrs.dar / 2) + skills.pilot)
                const dodgeResult = checkRoll(blockDC, dodgeBonus)
                
                if (dodgeResult.success) {
                    actionDesc = `Attacked with ${weapon.name} but it was dodged by ${blocker.data.name}!`   
                    actionSkill = 'pilot' 

                    addStats(blocker.id, 'attacksDodged', 1)

                    if (dodgeResult.crit) {
                        if (oneIn(3)) {
                            addComm(blocker, 'dodge')
                        }
                        actionDesc += ' [PERFECT DODGE]'
                        addStats(blocker.id, 'perfectDodges', 1)
                    }

                    return true
                } else {
                    // dodge failed!
                    return false
                }
            }
        } else {
            // no one is available to block
            return false
        }
    }

    const fireWeapon = async (weapon, free = false) => {
        if (kaiju.status.distract) {
            kaiju.status.distract -= 1
            actionDesc = `Tried to use ${weapon.name} but was distracted!`   
            actionSkill = 'combat' 
        } else if (!checkForBlock(weapon)){
            // try to find the weak spot
            const weakSpot = mech.getWorstDamage()
            const findWeakSpotDC = 20 - (10 * weakSpot.damage)
            const findWeakSpotBonus = kaiju.data.rank
            const findWeakSpotResult = checkRoll(findWeakSpotDC, findWeakSpotBonus)
            const attackSlot = findWeakSpotResult.success && weakSpot.slot ? weakSpot.slot : pickSlot()

            const maxDamage = getRankedValue(weapon.dam, kaiju.data.rank, rankFactor)
            const attackDamage = Math.round(randInt(Math.ceil(maxDamage / 4), maxDamage) * timeFactor)
            const blowthrough = await mech.applyDamage(attackSlot, attackDamage)
            if (blowthrough) {
                const pilotAngry = await team.damagePilot(attackSlot, 1, addStats)
                if (pilotAngry) {
                    addComm(team.pilots[attackSlot], `retreat`)
                }
            }
            actionDesc = `Attacked ${slotNames[attackSlot]} with ${weapon.name} for ${attackDamage}`   
            actionSkill = 'combat' 
        }

        if (!free) {
            incrementNextAttack(weapon)
        }
    }

    const repairSlot = kaiju.getWorstDamage()

    if (kaiju.rage >= kaiju.maxRage) {
        const specials = kaiju.data.specials || {}
        const specialKey = getRandomItem(Object.keys(specials))
 
        if (!specialKey) {
            // kaiju has no specials
            await fireWeapon(getRandomWeapon(), true)
        } else {
            console.log(specialKey)
        }

        kaiju.rage = 0
    } else if (allWeaponsReady) {
        // if all weapons are ready, fire one - prevent heal locking
        const weaponToFire = getSlowestWeapon()
        await fireWeapon(weaponToFire)
    } else if (repairSlot && repairSlot.damage > 0.5) {
        // repair critical damage
        await repairDamage(repairSlot.slot)
    } else if (anyWeaponsReady) {
        // attack
        let madeAttack = false

        for (const weapon of kaiju.weapons) {
            if (!madeAttack && isActive(weapon)) {
                await fireWeapon(weapon)
                madeAttack = true
            }
        }
    } else if (repairSlot && repairSlot.damage > 0.25) {
        // repair minor damage
        await repairDamage(repairSlot.slot)
    } else {
        // no minor damge, so charge an attack
        const weapon = getSlowestWeapon(false)
        chargeWeapon(weapon)
        actionDesc = `Charged its ${weapon.name}`    
        actionSkill = 'combat'
    }
    
    await kaiju.updateData(kaijuUpdate)

    return {
        source: 'enemy',
        skill: actionSkill,
        name: camelCaseToTitleCase(kaiju.data.name),
        tick,
        text: actionDesc
    }
}

