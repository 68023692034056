import { getGenderIcon, deletePilot } from '../../lib/pilot'
import { numerals, ItemTypes } from '../../lib/constants'
import SkillBlock from '../skillBlock'
import AttrBlock from '../attrBlock'
import RankPips from '../rankPips'
import { Icon } from 'semantic-ui-react'
import { camelCaseToTitleCase } from '../../lib/textUtils';
import { useDrag } from 'react-dnd'
import { Portrait } from '../portrait'
import { getPilotRank } from '../../lib/pilot'

export default function PilotRow({ db, user, pilot, slotType, selectPilot }) {
    const [{isDragging}, drag] = useDrag(() => {
        return {
        type: ItemTypes.PILOT,
        item: { id: pilot?.id, slotType, rank: getPilotRank(pilot) },
        collect: monitor => ({
          isDragging: !!monitor.isDragging(),
        })
      }})
    
    const removePilot = async (e, id) => {
        if (e) {
            e.stopPropagation()
        }
        deletePilot(db, user, id);
    }

    return (
        <div 
            ref={drag}
            className="pilotRow"
            onClick={() => selectPilot(pilot.id)}
            style={{
                pointerEvents: pilot ? 'auto' : 'none',
                opacity: isDragging ? 0.5 : 1
            }}
        >
            <div className="pilotInfo">
                <div className="pilotName">
                    { getGenderIcon(pilot)}{ pilot.name }
                </div>
                <div className="pilotData">
                    { db && <div className="deletePilot">
                        <Icon className="iconBtn deleteFromListBtn"  name="ban" onClick={(e) => removePilot(e, pilot.id)}/>
                    </div> }
                    <RankPips pilot={pilot}/>
                    { pilot.slot && (
                        <div className={`assignment`}>
                            <div className="slot">{camelCaseToTitleCase(pilot.slot)}</div>
                            <div className={`teamId bg-team-${pilot.team} border-team-${pilot.team}`}>{numerals[pilot.team - 1]}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="pilotPortrait">
                <Portrait subject={pilot?.portrait} size={44}/>
            </div>
            <div className="pilotSkills">
                <SkillBlock pilot={ pilot }/>
            </div>
            <div className="pilotAttrs">
                <AttrBlock pilot={ pilot }/>
            </div>
        </div>
    )
}
