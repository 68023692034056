/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Modal, Icon } from 'semantic-ui-react'
import Graph from '../../lib/graphs/starMapGraph';

export function StarMapModal({ db, user, academy, starMap, isOpen, toggleModal, showGuide, playSoundEffect }) {
    let history = useHistory();

    const [recenter, setRecenter] = useState(0)

    const launchBattle = (starId, planetId) => {
        history.push('/battle', { starId, planetId })
    }

    useEffect(() => {
        // console.log('opened')
    }, [ isOpen ])
    
    useEffect(() => {
        // console.log('starMap loaded')
    }, [ starMap ])

    return (
        <Modal
            className="starMapModal"
            size="large"
            open={isOpen}
            onClose={() => {
                toggleModal()
            }}>
            <Modal.Header>
                <h1 className="ui">
                    <Icon className="iconBtn floatRight" name="question circle" onClick={() => showGuide('starMap')}/>
                    <Icon className="iconBtn floatRight" name="cog" 
                        onClick={() => {
                            // setSelectedNode(orbitNode)
                            setRecenter(recenter + 1)
                        }}
                    />
                    <Icon name="map"/>STAR MAP
                </h1>
            </Modal.Header>
            <Modal.Content>
                <Graph 
                    starMap={ starMap }
                    academy={ academy }
                    recenter={ recenter }
                    launchBattle={ launchBattle }
                    playSoundEffect={ playSoundEffect }
                />
            </Modal.Content>
            <Modal.Actions>
            <Button positive onClick={() => toggleModal()}>
                Close
            </Button>
            </Modal.Actions>
        </Modal>
     )
}