import { deleteMech } from '../../lib/mech'
import { numerals, ItemTypes } from '../../lib/constants'
import { Icon, Image } from 'semantic-ui-react'
import { useDrag } from 'react-dnd'
import { MechView } from '../mechView'

export default function MechRow({ db, user, mech, selectMech }) {
    const [{isDragging}, drag] = useDrag(() => ({
        type: ItemTypes.MECH,
        item: { id: mech?.id, rank: mech?.rank },
        collect: monitor => ({
          isDragging: !!monitor.isDragging(),
        }),
    }))
        
    const removeMech = async (e, mech) => {
        if (e) {
            e.stopPropagation()
        }
        deleteMech(db, user, mech.id)
    }

    return (
        <div 
            ref={drag}
            className="mechRow"
            onClick={() => selectMech(mech.id)}
            style={{
                pointerEvents: mech ? 'auto' : 'none',
                opacity: isDragging ? 0.5 : 1
            }}
        >
            <div className="mechInfo">
                <div className="mechName">
                    <Image 
                        className="mechIcon"
                        height={24}
                        src={`/assets/mechs/${mech.make}/logo/color.svg`}
                    />
                    { mech.make } { mech.model } { numerals[mech.rank - 1] } { mech.name && `"${mech.name}"` }
                </div>
                <div className="weaponInfo">
                    <div className="mechWeapon">
                        Left:  { mech.leftWeapon.name }
                    </div>
                    <div className="mechWeapon">
                        Right: { mech.rightWeapon.name }
                    </div>
                </div>
            </div>
            <div className="mechView">
                <MechView subject={mech} size={80} context="list"/>
            </div>
            <Icon className="iconBtn deleteFromListBtn"  name="ban" onClick={(e) => removeMech(e, mech)}/>
        </div>
    )
}
