import React, { useEffect, useState } from 'react'
import { Button, Modal, Grid, Icon } from 'semantic-ui-react'

export function SettingsModal ({ academy, isOpen, playSoundEffect, setMusicVolume, toggleModal }) {
    const [ playMusic, setPlayMusic ] = useState(academy.data?.settings.playMusic)
    const [ playSound, setPlaySound ] = useState(academy.data?.settings.playSound)
    const [ volume, setVolume ] = useState(academy.data?.settings.volume * 10)
    const [ minigames, setMinigames ] = useState(academy.data?.settings.minigames)
    const [ battleSpeed, setBattleSpeed ] = useState(academy.data?.settings.battleSpeed)
    
    useEffect(() => {
        console.log('settings changed')
        console.log(academy.data.settings)
    }, [ isOpen, academy.data?.settings ])

    const updateSettings = (name, value) => {
        playSoundEffect('buttonPress')

        const settings = {}

        switch (name) {
            case 'playMusic':
                setPlayMusic(value)
                settings[name] = value
                break
            case 'playSound':
                setPlaySound(value)
                settings[name] = value
                break
            case 'volume':
                if (value >= 0 && value <= 10) {
                    setVolume(value)
                    setMusicVolume(value / 10)
                    settings[name] = value / 10
                }
                break
            case 'minigames':
                setMinigames(value)
                settings[name] = value
                break
            case 'battleSpeed':
                setBattleSpeed(value)
                settings[name] = value
                break
            default:
                return
        }

        academy.updateSettings(settings)
    }

    return academy.data ? <Modal
        className="settingsModal"
        size={ 'small' }
        open={ isOpen }
        onClose={() => {
            toggleModal()
        }}>
        <Modal.Header><h1 className="ui">SETTINGS</h1></Modal.Header>
        <Modal.Content>
            <Grid>
                <Grid.Column width={10}>
                    <div className="settingItem">
                        <div className="settingLabel">
                            Music
                        </div>
                        <div className="settingControl">
                        <Button.Group className="settingBtnGrp">
                            <Button 
                                className={ `settingBtn ${playMusic ? 'active' : ''}`}
                                onClick={ () => updateSettings( 'playMusic', true )}
                            >
                                On
                            </Button>
                            <Button 
                                className={ `settingBtn ${!playMusic ? 'active' : ''}`}
                                onClick={ () => updateSettings( 'playMusic', false )}
                            >
                                Off
                            </Button>
                        </Button.Group>
                        </div>
                    </div>

                    <div className="settingItem">
                        <div className="settingLabel">
                            Sounds
                        </div>
                        <div className="settingControl">
                        <Button.Group className="settingBtnGrp">
                            <Button 
                                className={ `settingBtn ${playSound ? 'active' : ''}`}
                                onClick={ () => updateSettings( 'playSound', true )}
                            >
                                On
                            </Button>
                            <Button 
                                className={ `settingBtn ${!playSound ? 'active' : ''}`}
                                onClick={ () => updateSettings( 'playSound', false )}
                            >
                                Off
                            </Button>
                        </Button.Group>
                        </div>
                    </div>

                    <div className="settingItem">
                        <div className="settingLabel">
                            Volume
                        </div>
                        <div className="settingControl">
                        <Button.Group className="settingBtnGrp">
                            <Button 
                                className={ `settingBtn volumeDown`}
                                onClick={ () => updateSettings( 'volume', volume - 1 )}
                            >
                                <Icon name="angle left"/>
                            </Button>
                            <Button 
                                className={ `settingBtn volumeDisplay`}
                            >
                                { volume }
                            </Button>
                            <Button 
                                className={ `settingBtn volumeUp`}
                                onClick={ () => updateSettings( 'volume', volume + 1 )}
                            >
                                <Icon name="angle right"/>
                            </Button>
                        </Button.Group>
                        </div>
                    </div>

                    <div className="settingItem">
                        <div className="settingLabel">
                            Minigames
                        </div>
                        <div className="settingControl">
                        <Button.Group className="settingBtnGrp">
                            <Button 
                                className={ `settingBtn ${minigames === 'never' ? 'active' : ''}`}
                                onClick={ () => updateSettings( 'minigames', 'never' )}
                            >
                                Never
                            </Button>
                            <Button 
                                className={ `settingBtn ${minigames === 'sometimes' ? 'active' : ''}`}
                                onClick={ () => updateSettings( 'minigames', 'sometimes' )}
                            >
                                Sometimes
                            </Button>
                            <Button 
                                className={ `settingBtn ${minigames === 'often' ? 'active' : ''}`}
                                onClick={ () => updateSettings( 'minigames', 'often' )}
                            >
                                Often
                            </Button>
                        </Button.Group>
                        </div>
                    </div>

                    <div className="settingItem">
                        <div className="settingLabel">
                            Battle Speed
                        </div>
                        <div className="settingControl">
                        <Button.Group className="settingBtnGrp">
                            <Button 
                                className={ `settingBtn ${battleSpeed === 'normal' ? 'active' : ''}`}
                                onClick={ () => updateSettings( 'battleSpeed', 'normal' )}
                            >
                                Normal
                            </Button>
                            <Button 
                                className={ `settingBtn ${battleSpeed === 'fast' ? 'active' : ''}`}
                                onClick={ () => updateSettings( 'battleSpeed', 'fast' )}
                            >
                                Fast
                            </Button>
                            <Button 
                                className={ `settingBtn ${battleSpeed === 'ultra' ? 'active' : ''}`}
                                onClick={ () => updateSettings( 'battleSpeed', 'ultra' )}
                            >
                                Ultra
                            </Button>
                        </Button.Group>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Button onClick={() => academy.resetTutorials()}>
                        Reset Tutorials
                    </Button>
                    { academy.data.isAdmin && <pre>
                        { JSON.stringify(academy.data?.settings, null, 2)}
                    </pre> }
                </Grid.Column>
            </Grid>
        </Modal.Content>
        <Modal.Actions>
            <Button positive onClick={() => toggleModal()}>
                Close
            </Button>
        </Modal.Actions>
    </Modal> : null
}