import { Icon } from 'semantic-ui-react'
import { findPlanet } from '../../lib/starMap'
import { getPilotRank } from '../../lib/pilot'

export const planet = [
    (e) => {
        const p = e.pilot
        const planet = e.planet

        if (planet.type !== 'ice' && planet.type !== 'test') { return null }

        const eventTitle = `${planet.name.toUpperCase()}: Beneath The Ice`

        return {
            title: <div><Icon name="globe"/>{ eventTitle }</div>,
            description: `In the aftermath of the battle, PNAME sees a strange shadow underneath a nearby ice shelf. It looks like something interesting may be down there. What should pze do?`,
            pilot: p,
            planet,
            options: [
                { 
                    attr: 'prow',
                    skill: 'combat',
                    dc: 15,
                    level: 'hard',
                    text: `Blast the ice around the shadow to uncover it`,
                    success: {
                        text: `Taking careful aim, PNAME uses the Gauntlet's weapon systems to carve out the ice around the shadow. Nestled amongst the debris is part of an alien spacecraft. There are likely some recoverable xenomaterials inside.`,
                        attr: 'prow',
                        attrValue: 20,
                        xenos: planet.danger
                    },
                    fail: {
                        text: `As the rest of the team watches, PNAME fires up the Gauntlet's weapons and blasts the whole area. Whatever was there before is nothing but radioactive ash now.`,
                        metric: 'fame',
                        metricValue: -30
                    }
                },
                { 
                    attr: 'conf',
                    skill: 'pilot',
                    dc: 10,
                    level: 'medium',
                    text: `Dig through the ice with the Gauntlet`,
                    success: {
                        text: `PNAME convinces the rest of the team to give pzir temporary control of all the systems. Using the Gauntlet's hands, pze carefully digs through the ice. Beneath pze finds part of an alien spacecraft. With luck, there are some xenomaterials to be found inside.`,
                        attr: 'conf',
                        attrValue: 10,
                        xenos: Math.ceil(planet.danger / 2)
                    },
                    fail: {
                        text: `PNAME tries to convince the rest of the team to let pzir dig through the ice, but nobody else seems interested. The mystery goes uninvestigated as they return to the Academy ship.`
                    }
                },
                { 
                    text: `Mark the spot for later recovery`,
                    level: 'easy',
                    success: {
                        text: `There may be something interesting under the ice, but it's probably best for an exploration team to find it. PNAME relays the coordinates to the Academy ship and the team gets ready to return.`,
                        attr: 'hum',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot
        const planet = e.planet

        if (planet.type !== 'jungle' && planet.type !== 'test') { return null }

        const eventTitle = `${planet.name.toUpperCase()}: The Fairy Ring`

        return {
            title: <div><Icon name="globe"/>{eventTitle}</div>,
            description: `As the battle ends, PNAME notices there is still a blinking indicator on pzis console. Looking closer, it appears to be a distress signal. PZE sends out a radio message, but gets no response.  The vegetation is too dense to go out in the Gauntlet without risking stepping on the source of the signal. Instead, PNAME investigates on foot, following the direction of the signal with a handheld scanner. Soon, pze comes across a small clearing ringed by vibrant purple mushrooms the size of a grown human. In the middle of the ring is a small camp of what look like explorers, all laying around the camp as though dead or unconscious. What should PNAME do?`,
            pilot: p,
            planet,
            options: [
                { 
                    attr: 'reas',
                    skill: 'per',
                    dc: 15,
                    level: 'hard',
                    text: `Analyze the mushrooms to find a solution`,
                    success: {
                        text: `Using the handheld scanner's environmental sensors, PNAME samples the air near the mushroom ring. The spores appear to contain a neurotoxin that is dangerous after long exposure. PZE uses pzis suit's emergency module to administer a compound that protects against similar toxins. Working quickly, pze drags the explorers clear of the ring and calls in a rescue team.`,
                        attr: 'reas',
                        attrValue: 20,
                        metric: 'fame',
                        metricValue: 15
                    },
                    fail: {
                        text: `PNAME samples the air around the mushrooms, trying to analyze the readings. There's definitely something there, but pze can't get come up with any definite conclusions. After some time, pze decides to call in a rescue team. When the team arrives and starts pulling the explorers clear, the captain admonishes PNAME for wasting time instead of calling in experts.`,
                        metric: 'fame',
                        metricValue: -15
                    }
                },
                { 
                    attr: 'fort',
                    skill: 'reaction',
                    dc: 10,
                    level: 'medium',
                    text: `Hold pzis breath and drag the explorers out`,
                    success: {
                        text: `PNAME doesn't like the look of those mushrooms at all. Taking a deep breath, pze runs in and grabs one of the explorers, pulling them clear of the ring. Again and again pze repeats this, eventually rescuing all of them.`,
                        attr: 'fort',
                        attrValue: 10,
                        metric: 'fame',
                        metricValue: 10
                    },
                    fail: {
                        text: `PNAME takes a deep breath and runs into the mushroom ring. Before pze is able to get to the first explorer, pzis vision starts to blur. PZE dashes back to safety and calls in a resue team instead.`
                    }
                },
                { 
                    text: `Call in a rescue team`,
                    level: 'easy',
                    success: {
                        text: `Whatever dangers the explorers ran afoul of, it's clearly a matter for experts. PNAME wastes no time in calling in a rescue team to extract them.`,
                        attr: 'wis',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot
        const planet = e.planet
        const starMap = e.starMap
        const danger = getPilotRank(p)

        const raiderPlanet = findPlanet(starMap, { minJumps: 2, danger } )

        const eventTitle = `${planet.name.toUpperCase()}: Ghost Town`

        return {
            title: <div><Icon name="globe"/>{eventTitle}</div>,
            description: `After the battle on ${planet.name}, PNAME scouts the area. The orbital scanners show that there should be a small settlement nearby. Soon pze reaches the location and finds a scene of utter destruction. Buildings are blasted apart, and bodies lie strewn about. What should PNAME do?`,
            pilot: p,
            planet,
            options: [
                { 
                    attr: 'reas',
                    skill: 'per',
                    dc: 10,
                    level: 'medium',
                    text: `Scan the debris and look for evidence of what happened`,
                    success: {
                        text: `PNAME walks through the ruins of the settlement, examining the debris with pzis hand scanner. The energy signatures and spall patterns are consistent with plasma weapons found on Gauntlets. It seems likely that this was the work of raiders operating nearby. PZE relays this information to the Academy ship. Soon the ship's sensors are able to detect an ion trail from a recently departed vessel. A calculation of likely wormhole vectors points to the raiders being on ${raiderPlanet.name}.`,
                        attr: 'reas',
                        attrValue: 10,
                        markRaiders: { planet: raiderPlanet, rank: danger }
                    },
                    fail: {
                        text: `PNAME walks through the ruins of the settlement, trying to determine what might have occurred there. Despite spending some time sifting through the rubble, pze isn't able to turn up any useful evidence. Whatever happened will have to remain a mystery.`
                    }
                },
                { 
                    attr: 'emp',
                    skill: 'comm',
                    dc: 10,
                    level: 'medium',
                    text: `Search for survivors`,
                    success: {
                        text: [`PNAME searches through the rubble, calling out for anyone still alive. After a few minutes pze hears a faint response from a collapsed building. PZE signals the rest of the team, and they use the Gauntlet to carefuly shift the debris. An old man, his face streaked with ash and blood, crawls out from where he was trapped. PNAME wraps a blanket around him and hands him a canteen of water. "It's going to be okay," pze says. "Do you know who might have done this?"`,
                        `The old man nods shakily. "It was raiders," he says. "I could hear them walking through looking for loot. I think they mentioned going back to ${raiderPlanet.name}."`
                        ],
                        attr: 'emp',
                        attrValue: 10,
                        markRaiders: { planet: raiderPlanet, rank: danger }
                    },
                    fail: {
                        text: [`PNAME searches through the rubble, calling out for anyone still alive. After a few minutes pze hears a faint response from a collapsed building. PZE signals the rest of the team, and they use the Gauntlet to carefuly shift the debris. An small child, her face streaked with ash and blood, crawls out from where she was trapped. PNAME kneels down in front of her. "Who did this? What happened here?"`,
                        `The little girl bursts into tears, crying for her mother and father. If she knows anything, PNAME isn't going to be able to learn it from her.`
                        ]
                    }
                },
                { 
                    attr: 'fort',
                    skill: 'repair',
                    dc: 10,
                    level: 'medium',
                    text: `Dig through the rubble and try to find a working computer`,
                    success: {
                        text: `PNAME starts shifting debris, occasionally signaling the rest of the team to use the Gauntlet to move large pieces of metal and concrete. After some time, pze is able to unearth a computer core that managed to take only minor damage. PZE hooks pzis datapad into the core and finds recorded video of the assault. Scrubbing through the feed, pze discovers footage of raiders combing through the rubble looking for valuables. Through the static, pze is able to make out the raiders mentioning a base on ${raiderPlanet.name}.`,
                        attr: 'fort',
                        attrValue: 10,
                        markRaiders: { planet: raiderPlanet, rank: danger }
                    },
                    fail: {
                        text: `PNAME starts shifting debris, occasionally signaling the rest of the team to use the Gauntlet to move large pieces of metal and concrete. After some time, pze is able to unearth a computer core that managed to take only minor damage. PZE tries to access the data, but only manages to crash the unstable system. Whatever clues may have been stored on the core are lost.`
                    }
                }
            ]
        }
    },
]