/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { HoloSync } from '../minigames/holoSync'
import { WaveMatch } from '../minigames/waveMatch'
import { CenterTarget } from '../minigames/centerTarget'
import { CircuitMaze } from '../minigames/circuitMaze'
import { minigames } from '../../lib/constants'

export function MinigameModal({ gameType, pilotData, playSoundEffect, finishGame }) {
    const [ phase, setPhase ] = useState(0)

    useEffect(() => {
        playSoundEffect('openBeep')
    }, [])
    /** GAME PHASES 
     * 0: Instructions
     * 1: Game Play
     * 2: Success
     * 3: Failure
    */

    useEffect(() => {
        // console.log(phase)
    }, [ phase ])

    const gameOver = (outcome) => {
        setTimeout(() => {
            finishGame(outcome)
        }, 1000)        

        playSoundEffect(outcome ? 'win' : 'failure')

        setPhase(outcome ? 2 : 3)
    }

    const getMinigame = () => {
        const gameProps = { pilotData, playSoundEffect, gameOver }

        switch (gameType) {
            case 'holoSync':
                return <HoloSync { ...gameProps } />
            case 'waveMatch':
                return <WaveMatch { ...gameProps } />
            case 'centerTarget':
                return <CenterTarget { ...gameProps } />
            case 'circuitMaze':
                return <CircuitMaze { ...gameProps } />
            default:
                return null
        }
    }

    setTimeout(() => {
        if (phase === 0) {
            setPhase(1)
        }
    }, 1000)

    const skipInstructions = () => {
        setPhase(1)
    }

    const skipResults = () => {
        finishGame(phase === 2)
    }

    return <div className="minigameDimmer">
        <div className="minigameModal">
            <div className="minigameHeader">
                { minigames[gameType].title }
            </div>
            <div className={`body instructions ${phase > 0 ? 'hidden' : ''}`} onClick={() => skipInstructions()}>
                { minigames[gameType].instructions }<br/>
                <img className="controlIcon" alt='' src={`/assets/images/${minigames[gameType].control}.png`}/>
                
            </div>
            <div className={`body result ${phase > 1 ? 'visible' : ''} ${phase === 2 ? 'success' : 'failure'}`} onClick={() => skipResults()}>
                { phase === 2 ? 'SUCCESS' : 'FAILURE' }
            </div>
            { phase === 1 && <div className={`body player`}>
                { getMinigame() }
            </div> }
        </div>
    </div>
}