import React, { Component } from 'react';
import {
    Link,
    withRouter
} from "react-router-dom";
import { Icon, Menu } from 'semantic-ui-react'
import { Logo } from './logo'
import { getEventList, getUserList, getPilotList } from '../lib/admin'
// import { generateStarMap } from '../lib/starMap'
// import { generateMech } from '../lib/mech';
  
const MainMenu = withRouter(props => <MainMenuComp {...props}/>);

class MainMenuComp extends Component {
    render() {
        const { 
            db
            // , user 
        } = this.props

        const location = this.props.location.pathname

        return (
        <Menu className="mainMenu">
            <Menu.Item>
            <Logo className="mainMenuLogo" size={40} color={ 'dark' } spinning={ false }/>
            <div className="menuTitle">RUN THE GAUNTLET</div>
            </Menu.Item>

            { location !== '/battle' && [
            <Menu.Item
            key={0}
            className="menuLink"
            as={ Link }
            to='/academy'
            name='academy'
            active={location === '/academy'}
            >
            Academy
            </Menu.Item>,

            <Menu.Item
            key={1}
            className="menuLink"
            as={ Link }
            to='/roster'
            name='roster'
            active={location === '/roster' || location === '/'}
            >
            Roster
            </Menu.Item>
            ]}

            <Menu.Item position="right">
                {/* <Icon className={`iconBtn ${this.props.playMusic ? 'toggleBtnOn' : 'toggleBtnOff'}`} name="music" onClick={() => this.props.toggleMusic()}/>
                <Icon className={`iconBtn ${this.props.playSound ? 'toggleBtnOn' : 'toggleBtnOff'}`} name="volume up" onClick={() => this.props.toggleSound()}/> */}

                { this.props.academy?.data?.isEditor && <>
                    <div style={{ width: '50px' }}></div>
                    <Icon className="iconBtn" name="warning circle" onClick={() => this.props.showMessage(getEventList(this.props.starMap.data, this.props.showMessage))}/>
                </> }
                { this.props.academy?.data?.isAdmin && <>
                    <div style={{ width: '50px' }}></div>
                    <Icon className="iconBtn" name="users" onClick={async () => this.props.showMessage(await getUserList(db))}/>
                    <Icon className="iconBtn" name="rocket" onClick={async () => this.props.showMessage(await getPilotList(db))}/>
                    {/* <Icon className="iconBtn" name="user" onClick={async () => this.props.showMessage(await getRandomPilot())}/>
                    <Icon className="iconBtn" name="wrench" onClick={async () => this.props.showMessage(await getRandomTeam())}/> */}
                    {/* <div style={{ width: '50px' }}></div>
                    <Icon className="iconBtn" name="wrench" onClick={() => {
                        generateMech(db, user, 'unique', 'blackNemesis', 2)
                    }
                    }/>
                    <Icon className="iconBtn" name="map" onClick={async () => {
                        if (this.props.starMap) {
                            console.log('regenerating star map...')
                            const newStarMap = await generateStarMap() 
                            this.props.starMap.updateData(newStarMap)
                        } else {
                            console.log('starMap not ready yet...')
                        }
                    }}
                    /> */}
                </> }
            </Menu.Item>
        </Menu>
        )
    }
}

export default MainMenu