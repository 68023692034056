import { flipCoin } from '../../lib/mathUtils'
import { getPilotRank } from '../../lib/pilot'
import { findPlanet } from '../../lib/starMap'

export const puzzleOfStone = {
    name: 'Puzzle of Stone',
    completeText: `Investigating a mysterious stone led to a new scientific discovery. The Factory can now produce Ablative Armor modules to protect Gauntlets.`,
    modalSize: 'large',
    stages: [
        {
            id: 0,
            location: ['repairBay'],
            data: (e) => {
                const p = e.pilot
                
                if (getPilotRank(p) < 2) {
                    return null
                }
                
                return {
                    title: 'PUZZLE OF STONE: Look What the Gauntlet Dragged In',
                    description: [
                        `It's been a long shift in the Repair Bay, and PNAME is looking forward to some rack time soon. There have been some heavily damaged Gauntlets coming through, so the work has been constant and demanding. In addition to their usual training Gauntlets, there is also a heavy Triton unit painted in the red and blue of the Dionysus Syndicate.`,
                        `As pze is pushing a mag-cart of components into the store room and contemplating the comforts of bed, PNAME is struck by an unusual sight. Ayonna, one of the journeyman repair techs, is hunched over a work table covered in shiny blue rocks and unfamiliar pieces of equipment.`,
                        `Ayonna waves pzir over and excitedly says, "Hey, come take a look at this!"`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    options: [
                        { 
                            attr: 'reas',
                            skill: 'per',
                            dc: 15,
                            level: 'hard',
                            text: `Try to figure out what is special about the blue rocks`,
                            success: {
                                text: [
                                    `PNAME parks the cart and goes to see what Ayonna has on the table. PZE can see some directed magnetic field generators similar to what is used for focusing plasma beams, but configured in a different way. The blue rocks seem to be reacting to the magnetic fields, changing shape and moving toward each other.`,
                                    `As PNAME describes what pze's seeing, Ayonna pounds the table excitedly. "Yes! The stones aren't magnetic in the way we normally think of, but they definitely react to the fields. Watch this."`,
                                    `Ayonna adjusts two of the field generators at opposite corners of the table, and the stones start moving faster, melding together in the center of the table. She adjusts the generators on the othe corners, and the melded pile of stone flattens out into a rippling surface across the table.`,
                                    `"I have to know where this stuff came from," she says. "I dug it out of the feet of that Dionysus Triton. Massimo is on the crew, maybe he would know."`,
                                    `At PNAME's confused look, Ayonna elaborates: "Oh yeah, he's probably from before your time. Massimo Lastrada graduated from this Academy about five years back. If I know him, he'll be in the Rec Room."`
                                ],
                                attr: 'reas',
                                attrValue: 20,
                                nextStage: 1
                            },
                            fail: {
                                text: [
                                    `PNAME goes to look at the asemblage of rocks and equipment on the table, but none of it looks remotely familiar. "These are shaped magnetic field generators, like you find in plasma containment units," explains Ayonna. PNAME can see now that the stones seem to be vibrating, especially near the generators at the corners of the table. Ayonna continues, "Watch this."`,
                                    `Ayonna adjusts two of the field generators at opposite corners of the table, and the stones start moving faster, melding together in the center of the table. She adjusts the generators on the othe corners, and the melded pile of stone flattens out into a rippling surface across the table.`,
                                    `"I have to know where this stuff came from," she says. "I dug it out of the feet of that Dionysus Triton. Massimo is on the crew, maybe he would know."`,
                                    `At PNAME's confused look, Ayonna elaborates: "Oh yeah, he's probably from before your time. Massimo Lastrada graduated from this Academy about five years back. If I know him, he'll be in the Rec Room."`
                                ],
                                nextStage: 1
                            }
                        },
                        { 
                            attr: 'emp',
                            skill: 'comm',
                            dc: 10,
                            level: 'med',
                            text: `Ask Ayonna about what has gotten her so excited`,
                            success: {
                                text: [
                                    `"This looks fascinating," says PNAME as pze approaches the work table. "What's all this for?" Ayonna's face lights up as she starts arranging the blue stones in the middle of the table. "These are shaped magnetic field generators, like you find in plasma containment units," she explains. PNAME can see now that the stones seem to be vibrating, especially near the generators at the corners of the table. Ayonna continues, "Watch this."`,
                                    `Ayonna adjusts two of the field generators at opposite corners of the table, and the stones start moving faster, melding together in the center of the table. She adjusts the generators on the othe corners, and the melded pile of stone flattens out into a rippling surface across the table.`,
                                    `"I have to know where this stuff came from," she says. "I dug it out of the feet of that Dionysus Triton. Massimo is on the crew, maybe he would know."`,
                                    `At PNAME's confused look, Ayonna elaborates: "Oh yeah, he's probably from before your time. Massimo Lastrada graduated from this Academy about five years back. If I know him, he'll be in the Rec Room."`
                                ],
                                attr: 'emp',
                                attrValue: 10,
                                nextStage: 1
                            },
                            fail: {
                                text: [
                                    `"What's all this junk?" asks PNAME as pze approaches the work table. Despite PNAME's apparent disinterest, Ayonna's face lights up as she starts arranging the blue stones in the middle of the table. "These are shaped magnetic field generators, like you find in plasma containment units," she explains. PNAME can see now that the stones seem to be vibrating, especially near the generators at the corners of the table. Ayonna continues, "Watch this."`,
                                    `Ayonna adjusts two of the field generators at opposite corners of the table, and the stones start moving faster, melding together in the center of the table. She adjusts the generators on the othe corners, and the melded pile of stone flattens out into a rippling surface across the table.`,
                                    `"I have to know where this stuff came from," she says. "I dug it out of the feet of that Dionysus Triton. Massimo is on the crew, maybe he would know."`,
                                    `At PNAME's confused look, Ayonna elaborates: "Oh yeah, he's probably from before your time. Massimo Lastrada graduated from this Academy about five years back. If I know him, he'll be in the Rec Room."`
                                ],
                                nextStage: 1
                            }
                        },
                        { 
                            text: `Wait for Ayonna to tell pzir the story`,
                            level: 'easy',
                            success: {
                                text: [
                                    `It's clear that Ayonna doesn't need a lot of prodding to talk about what has her so excited.As soon as PNAME comes near, Ayonna's face lights up and she starts arranging the blue stones in the middle of the table. "These are shaped magnetic field generators, like you find in plasma containment units," she explains. PNAME can see now that the stones seem to be vibrating, especially near the generators at the corners of the table. Ayonna continues, "Watch this."`,
                                    `Ayonna adjusts two of the field generators at opposite corners of the table, and the stones start moving faster, melding together in the center of the table. She adjusts the generators on the othe corners, and the melded pile of stone flattens out into a rippling surface across the table.`,
                                    `"I have to know where this stuff came from," she says. "I dug it out of the feet of that Dionysus Triton. Massimo is on the crew, maybe he would know."`,
                                    `At PNAME's confused look, Ayonna elaborates: "Oh yeah, he's probably from before your time. Massimo Lastrada graduated from this Academy about five years back. If I know him, he'll be in the Rec Room."`
                                ],
                                attr: 'wis',
                                attrValue: 5,
                                nextStage: 1
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 1,
            location: ['recRoom'],
            hint: 'A repair tech named Ayonna has found some blue stones with mysterious properties on the feet of a strange Gauntlet. Massimo Lastrada, one of its pilots, can probably be found in the Rec Room.',
            data: (e) => {
                const p = e.pilot
                const starMap = e.starMap

                const planet = findPlanet(starMap, { type: 'lava', danger: getPilotRank(p) + 1, minJumps: 3, closest: true })

                return {
                    title: 'THE PUZZLE OF STONE: Larger Than Life',
                    description: [
                        `It doesn't take long for PNAME to figure out who this Massimo Lastrada is. There is a crowd of young pilots clustered around the dart board as someone in a red and blue Dionysus uniform is doing trick shots. He is also doing shots of Supernova, which is equally impressive since he is still standing upright.`,
                        `Massimo is a slim man with a broad smile and a halo of brown curly hair that he must somehow fit inside a helmet. He is clearly enjoying being the center of attention. The other members of his crew are sitting at a table nearby. It's going to be hard to get a word in while Massimo is holding court without first making an impression on him somehow.`,
                        `What should PNAME do?`
                    ],
                    pilot: p,
                    planet,
                    options: [
                        { 
                            attr: 'prow',
                            skill: 'combat',
                            dc: 15,
                            level: 'hard',
                            text: `Challenge Massimo to a game of darts`,
                            success: {
                                text: [
                                    `PNAME walks up and grabs a set of darts from the table. "Think you can take me?"`,
                                    `Massimo looks pzir over and gives that shining grin. "Of course I can!" The two pilots line up in front of the dartboard and begin throwing. As each dart lands, the crowd of onlookers cheers.`,
                                    `The score goes back and forth between the two, and in the end ${flipCoin() ? 'Massimo' : 'PNAME'} edges out the slimmest of victories. The Dionysus pilot laughs and slaps PNAME on the back. "That was great!"`,
                                    `As they sit down to share a drink, PNAME asks Massimo about his recent exploits. Never one to turn down an opportunity to tell stories of his adventures, he spins tales of jungles and ice fields, moons and oceans, all with a brilliant victory at the end.`,
                                    `One story in particular stands out, involving a battle with giant rock creatures with glowing blue eyes on ${planet.name}. PNAME takes note of this and starts planning a future visit.`
                                ],
                                attr: 'prow',
                                attrValue: 20,
                                metric: 'fame',
                                metricValue: 20,
                                markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `PNAME walks up and grabs a set of darts from the table. "Think you can take me?"`,
                                    `Massimo looks pzir over and gives that shining grin. "Of course I can!" The two pilots line up in front of the dartboard and begin throwing. As each dart lands, the crowd of onlookers cheers.`,
                                    `As the game goes on, it becomes clear that PNAME is way out of pzis league. The cheers turn to snickers, and PNAME is relieved when the game finally draws to an end.`,
                                    `"Who's next?" shouts Massimo, as PNAME quietly makes pzis way out of the crowd. PZE will have to find more information another way.`
                                ],
                                metric: 'fame',
                                metricValue: -20,
                                nextStage: 2
                            }
                        },
                        { 
                            attr: 'fort',
                            skill: 'reaction',
                            dc: 15,
                            level: 'hard',
                            text: `Get drunk with Massimo`,
                            success: {
                                text: [
                                    `PNAME gets a bottle of Rigellian rum from the bar and brings it over to the crowd at the dart board. PZE pours two shots and hands one to Massimo. "Supernova's okay, but how about something good?" Massimo smiles, locks eyes with PNAME, and they both knock back their shots.`,
                                    `The bottle quickly drains as the two pilots toast the Academy, and Dionysus, and each other. As they drink, PNAME asks Massimo about his recent exploits. Never one to turn down an opportunity to tell stories of his adventures, he spins tales of jungles and ice fields, moons and oceans, all with a brilliant victory at the end.`,
                                    `One story in particular stands out, involving a battle with giant rock creatures with glowing blue eyes on ${planet.name}. PNAME takes note of this and starts planning a future visit.`
                                ],
                                attr: 'fort',
                                attrValue: 20,
                                metric: 'fame',
                                metricValue: 20,
                                markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `PNAME gets a bottle of Rigellian rum from the bar and brings it over to the crowd at the dart board. PZE pours two shots and hands one to Massimo. "Supernova's okay, but how about something good?" Massimo smiles, locks eyes with PNAME, and they both knock back their shots.`,
                                    `While PNAME tries valiantly to keep up with Massimo, the room starts to spin dangerously before pze is able to start trying to pry information from the other pilot.`,
                                    `PNAME excuses pzirself and makes a beeline for the head. PZE will have to find more information another way.`
                                ],
                                metric: 'fame',
                                metricValue: -10,
                                nextStage: 2
                            }
                        },
                        { 
                            attr: 'conf',
                            skill: 'comm',
                            dc: 15,
                            level: 'hard',
                            text: `Chat Massimo up and ask him about the blue stones`,
                            success: {
                                text: [
                                    `PNAME walks up and sticks out pzis hand. "You must be the legendary Massimo. I'm PNAME; you'll want to remember that so you can say you knew me before I was famous."`,
                                    `Massimo raises his eyebrow and looks PNAME up and down for a moment, then laughs and clasps pzis arm in a warrior's grip. "I like your style, kid!"`,
                                    `The two start swapping battle stories, and Massimo gives some good advice from his years of experience. Eventually, PNAME asks Massimo about his most recent exploits.`,
                                    `One story in particular stands out, involving a battle with giant rock creatures with glowing blue eyes on ${planet.name}. PNAME takes note of this and starts planning a future visit.`
                                ],
                                attr: 'conf',
                                attrValue: 20,
                                xenos: 1,
                                markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `PNAME walks up and sticks out pzis hand. "You must be the legendary Massimo. I'm PNAME; you'll want to remember that so you can say you knew me before I was famous."`,
                                    `Massimo raises his eyebrow and looks PNAME up and down for a moment, then bursts out laughing. "That's a good one kid."`,
                                    `It's clear that this approach isn't coing to work. PNAME will have to find more information another way.` 
                                ],
                                metric: 'fame',
                                metricValue: -10,
                                nextStage: 2
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 2,
            location: ['recRoom'],
            hint: `Massimo Lastrada was not forthcoming about the source of the blue stones. Maybe there's another way for PNAME to get the information while he's still in the Rec Room.`,
            data: (e) => {
                const p = e.pilot
                const planet = e.planet
                
                return {
                    title: 'PUZZLE OF STONE: Find Another Way',
                    description: [
                        `PNAME wasn't able to get the location of the blue stones from Massimo Lastrada directly. Perhaps there's another way to find out what pze needs to know.`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    planet,
                    options: [
                        { 
                            attr: 'emp',
                            skill: 'comm',
                            dc: 15,
                            level: 'hard',
                            text: `Ask the other members of Massimo's crew`,
                            success: {
                                text: [
                                    `PNAME makes his way over to the table where the other Dionysus pilots are sitting. "Wow, that guy is something," pze says. "You guys must have some stories to tell."`,
                                    `A stout, dark-skinned woman smiles, showing brilliant white teeth. "He likes to be in the spotlight. It helps him, and we don't mind being able to get some peace and quiet."`,
                                    `"We do have stories, though," says a lanky man with heavy-lidded eyes. "If you want to hear what really happened."`,
                                    `PNAME sits with the pilots and trades stories for a while. Among them is a recounting of a battle they had on ${planet.name} with giant rock creatures with glowing blue eyes. This seems like a good clue to follow up on.`
                                ],
                                attr: 'emp',
                                attrValue: 20,
                                markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `PNAME makes his way over to the table where the other Dionysus pilots are sitting. "Wow, that guy is something," pze says. "I don't know how you guys put up with him."`,
                                    `They all bristle at this. A broad shouldered pilot with a shiny shaved head leaps to zir feet. "You better watch your mouth, kid. We don't look kindly on people who throw shade at our friends."`,
                                    `As PNAME sputters and starts to back away, A woman with bright red curls reaches out and gently grabs pzis wrist. She regards pzir for a moment and says, "You came here looking for something. What is it?"`,
                                    `PNAME explains the situation with the blue stones and how pze is trying to find out more information. The bald pilot huffs. "Next time just be straight with us. You don't have what it takes to be subtle."`,
                                    `The pilots tell PNAME about a recent battle on ${planet.name} involving giant rock creatures with glowing blue eyes. PZE thanks them for the information and starts making plans for a visit.`
                                ],
                                metric: 'fame',
                                metricValue: -10,
                                markPlanet: true,
                                nextStage: 3
                            }
                        },
                        { 
                            attr: 'dar',
                            skill: 'per',
                            dc: 15,
                            level: 'hard',
                            text: `Sneak a look at Massimo's datapad to find answers`,
                            success: {
                                text: [
                                    `PNAME watches for a while and sees Massimo pick up a datapad at a nearby empty table, check it, and then put it back down. PNAME sits down at the table, flipping through his own pad. Checking around to see if anyone is paying attention, pze quickly swaps the two devices.`,
                                    `Scrolling quickly through the recent logs, PNAME finds a record of a battle on ${planet.name} involving giant rock creatures with glowing blue eyes. PZE makes a note of this before switching the pads again and walking away.`
                                ],
                                attr: 'dar',
                                attrValue: 20,
                                markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `PNAME watches for a while and sees Massimo pick up a datapad at a nearby empty table, check it, and then put it back down. PNAME sits down at the table, flipping through his own pad. Checking around to see if anyone is paying attention, pze tries to swap the two devices.`,
                                    `In pzis haste, pze instead knocks Massimo's datapad on the ground, where it clatters loudly. Massimo turns and comes over to the table. "What the hell, kid?" he says as he picks the pad up.`,
                                    `Chagrined, PNAME explains the situation with the blue stones and how pze is trying to find out more information about them. Massimo shakes his head. "That's a weird thing to get all worked up about," he says, "but I can understand weird obsessions."`,
                                    `"It was probably ${planet.name}, where we saw those big rock things with the glowing blue eyes. Yeah, I think I remember there being a bunch of blue rocks around there. Now beat it."` 
                                ],
                                metric: 'fame',
                                metricValue: -20,
                                markPlanet: true,
                                nextStage: 3
                            }
                        },
                        { 
                            level: 'easy',
                            text: `Ask Massimo again very nicely`,
                            success: {
                                text: [
                                    `PNAME waits until Massimo finishes throwing darts and the crowd begins to disperse. PZE approaches and asks if he remembers any recent battles where there might have been blue stones around.`,
                                    `"That's a weird question," says Massimo, but he cocks his head and thinks for a moment. "Now that you mention it, I think there were a bunch of blue rocks on ${planet.name} like you're talking about."`,
                                    `PNAME thanks him for his time and goes to make preparations for an expedition.`
                                ],
                                attr: 'hum',
                                attrValue: 5,
                                markPlanet: true,
                                nextStage: 3
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 3,
            location: ['planet'],
            hint: `PNAME has learned that the mysterious blue stones came from PLANETNAME. Hopefully there will be more of them there.`,
            data: (e) => {
                const p = e.pilot
                const planet = e.planet
                
                return {
                    title: 'PUZZLE OF STONE: The Blue Cavern',
                    description: [
                        `PNAME has arrived at ${planet.name} to search for the mysterious blue stones. Using readings Ayonna provided, pze is able to track down the site of Massimo's earlier battle. Broken spires of basalt and recent blast craters are proof enough that this is the correct spot. Small chunks of blue stone litter the area, but nothing much larger than what Ayonna already has to work with.`,
                        `Consulting the scanners, PNAME finds stronger readings leading away from the battle site. Moving in that direction, pze soon comes to a lava tube with a distinctive blue hue.`,
                        `Dismounting the Gauntlet and wearing a heat-resistant environment suit, PNAME descends into the passage. The suit's sensors read high heat levels, but nothing outside of its tolerances. After some time carfeully walking down the smooth, sloping passage, pze emerges into a large dome-shaped cavern. The suit's lights cast long shadows of basalt columns and glitter off of obsidian formations.`,
                        `A flow of glowing lava bisects the cavern, and on the other side is a massive deposit of the blue stone.`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    planet,
                    options: [
                        { 
                            attr: 'prow',
                            skill: 'combat',
                            dc: 15,
                            level: 'hard',
                            text: `Knock over one of the basalt columns to form a bridge over the lava`,
                            success: {
                                text: [
                                    `PNAME gauges the angles of the columns, then picks one near the edge of the lava flow. Drawing pzis sidearm, pze fires at the base of the column several times.`,
                                    `With a deep cracking sound, the column breaks free from the floor and falls away, landing squarely across the lava with a loud crash.`,
                                    `PNAME carefully makes pzis way across the makeshift bridge and begins collecting large chunks of the blue stone. Soon pze has a sizeable load to take back to Ayonna.`,
                                    `Her last message said pze should bring any new samples to her in the Factory.`
                                ],
                                attr: 'prow',
                                attrValue: 20,
                                nextStage: 4
                            },
                            fail: {
                                text: [
                                    `PNAME picks one of the basalt columns and draws pzis sidearm. Taking aim, pze fires several times at the column's base. Instead of falling over as expected, the entire column shatters, sending shrapnel flying all around the cavern.`,
                                    `Rather than making another attempt at this dangerous plan, PNAME goes back to the battle site and collects the small fragments of blue stone strewn about. It takes a lot of time and effort, but hopefully it will be enough for Ayonna to experiment with until a proper extraction team can be sent for the larger deposit.`,
                                    `Ayonna's last message said to bring any new samples to her in the Factory.`
                                ],
                                nextStage: 4
                            }
                        },
                        { 
                            attr: 'dar',
                            skill: 'reaction',
                            dc: 15,
                            level: 'hard',
                            text: `Leap over the lava flow`,
                            success: {
                                text: [
                                    `PNAME walks the length of the lava flow, looking for the narrowest section. PZE walks back from the edge, then with a running start leaps across to the other side!`,
                                    `PZE lands with several inches to spare, and quickly begins collecting large chunks of the blue stone, tossing each one across the lava to the other side. Once the pile seems large enough, pze leaps back across to safety.`,
                                    `PNAME carries the collected stones back out to the waiting Gauntlet. Ayonna's last message said to bring any new samples to her in the Factory.`
                                ],
                                attr: 'dar',
                                attrValue: 20,
                                nextStage: 4
                            },
                            fail: {
                                text: [
                                    `PNAME walks the length of the lava flow, looking for the narrowest section. PZE walks back then runs toward the edge, but stops just shy of the lava. Something in pzis gut tells pzir that the jump just isn't possible.`, 
                                    `Rather than making another attempt at this dangerous plan, PNAME goes back to the battle site and collects the small fragments of blue stone strewn about. It takes a lot of time and effort, but hopefully it will be enough for Ayonna to experiment with until a proper extraction team can be sent for the larger deposit.`,
                                    `Ayonna's last message said to bring any new samples to her in the Factory.`
                                ],
                                nextStage: 4
                            }
                        },
                        { 
                            attr: 'fort',
                            skill: 'per',
                            dc: 15,
                            level: 'hard',
                            text: `Climb across the wall of the cavern`,
                            success: {
                                text: [
                                    `PNAME walks to the edge of the cavern and examines the wall. While it looks smooth from a distance, there are many small bubbles that should make good handholds.`,
                                    `Reaching out, pze gets a grip in one bubble, then sets the toes of a foot in another. Slowly and carefully, pze inches pzis way across the wall. It's only about four meters, but over a river of molten lava it feels like a kilometer.`,
                                    `Finally pze reaches the far side of the lava and begins collecting large chunks of the blue stone, tossing each one across the lava to the other side. Once the pile seems large enough, pze climbs back across to safety.`,
                                    `PNAME carries the collected stones back out to the waiting Gauntlet. Ayonna's last message said to bring any new samples to her in the Factory.`
                                ],
                                attr: 'fort',
                                attrValue: 20,
                                nextStage: 4
                            },
                            fail: {
                                text: [
                                    `PNAME walks to the edge of the cavern and examines the wall. While it looks smooth from a distance, there are many small bubbles that should make good handholds.`,
                                    `Reaching out, pze gets a grip in one bubble, then sets the toes of a foot in another. The climb starts well enough, but after about a meter pzis hands start cramping. The whole distance is only about four meters, but it doesn't seem like pze will be able to make it that far. PZE heads back and manages to return to safety.`,
                                    `Rather than making another attempt at this dangerous plan, PNAME goes back to the battle site and collects the small fragments of blue stone strewn about. It takes a lot of time and effort, but hopefully it will be enough for Ayonna to experiment with until a proper extraction team can be sent for the larger deposit.`,
                                    `Ayonna's last message said to bring any new samples to her in the Factory.`
                                ],
                                nextStage: 4
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 4,
            location: ['factory'],
            hint: `After retrieving samples of the blue stone from PLANETNAME, PNAME returns to Ayonna in the Academy ship's Factory.`,
            data: (e) => {
                const p = e.pilot
                const planet = e.planet
                
                return {
                    title: 'PUZZLE OF STONE: Practical Applications',
                    description: [
                        `After traveling to ${planet.name} and braving the dangers of the lava-filled cavern, PNAME has returned to Ayonna with a large supply of the mysterious blue stone. She has set up a more elaborate experimental apparatus, and is excited to see PNAME arrive.`,
                        `"Wow, you really did find a lot of it!" she exclaims, eagerly taking the stone and arranging it on the work table. More magnetic generators are spaced around the edges of the table, and a piece of armor plating sits at the center, surrounded now by chunks of the blue stone.`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    planet,
                    options: [
                        { 
                            attr: 'reas',
                            skill: 'repair',
                            dc: 15,
                            level: 'hard',
                            text: `Help Ayonna run her experiment`,
                            success: {
                                text: [
                                    `PNAME examines Ayonna's setup and asks a few questions to verify pzis assumptions. The rig seems straightforward enough. PNAME and Ayonna take up positions on opposite sides of the table, then start powering up the generators.`,
                                    `The blue stones begin vibrating as before, moving toward the armor plate at the center of the table. As the stones come together, they melt into each other and become a single rippling mass.`,
                                    `As they fine tune the frequencies of the generators, the rippling liquid stone flattens out, flowing across the surface of the armor plate. When they shut off the generators, the stone solidifies once more, creating a smooth, seamless coating.`,
                                    `Ayonna picks up the armor and examines the crystalline layer on its surface. She grabs a nearby hammer and gives the plate a solid strike. She turns it around to show PNAME, and there is only a slight scuff where the hammer blow landed.`,
                                    `"I think we may have found a new armor enhancement," she says, her eyes gleaming with delight. "It'll take some more testing to determine the effectiveness, and application will be expensive, but it may be enough to give you an edge on the battlefield."`,
                                    `She sets the plate down and runs her hand across the smooth surface. "I couldn't have done it without you," she says. "I'll be sure to give you a prototype to test out."`
                                ],
                                attr: 'reas',
                                attrValue: 20,
                                module: { type: 'ablativeArmor', rank: getPilotRank(p)},
                                schematic: 'ablativeArmor',
                                nextStage: 'complete'
                            },
                            fail: {
                                text: [
                                    `PNAME looks over the machinery Ayonna has set up, and it looks simple enough. They take up positions on opposite sides of the table and start powering up the generators.`,
                                    `The blue stones begin vibrating as before, moving toward the armor plate at the center of the table. As the stones come together, they melt into each other and become a single rippling mass.`,
                                    `As they begin fine tuning the frequencies of the generators, the liquid stone begins expanding and contracting rapidly, throwing off small bits that solidify as they hit the surface of the table.`,
                                    `"No, not like that!" shouts Ayonna. She runs to PNAME's side of the table and readjusts the generators. The stone begins moving smoothly again. As she continues adjusting each generator in turn, the mass flattens out, flowing across the surface of the armor plate. When she shuts off the generators, the stone solidifies once more, creating a smooth, seamless coating.`,
                                    `Ayonna picks up the armor and examines the crystalline layer on its surface. She grabs a nearby hammer and gives the plate a solid strike. She turns it around to show PNAME, and there is only a slight scuff where the hammer blow landed.`,
                                    `"I think we may have found a new armor enhancement," she says, her eyes gleaming with delight. "It'll take some more testing to determine the effectiveness, and application will be expensive, but it may be enough to give you an edge on the battlefield."`
                                ],
                                schematic: 'ablativeArmor',
                                nextStage: 'complete'
                            }
                        },
                        { 
                            attr: 'hum',
                            skill: 'comm',
                            dc: 10,
                            level: 'med',
                            text: `Ask if there is anything pze can do`,
                            success: {
                                text: [
                                    `"Can I do anything to help?" asks PNAME. Ayonna directs pzir to take up a position on the opposite side of the table and power up the generators.`,
                                    `The blue stones begin vibrating as before, moving toward the armor plate at the center of the table. As the stones come together, they melt into each other and become a single rippling mass.`,
                                    `Following Ayonna's instuctions, PNAME helps fine tune the frequencies of the generators. The rippling liquid stone flattens out, flowing across the surface of the armor plate. When they shut off the generators, the stone solidifies once more, creating a smooth, seamless coating.`,
                                    `Ayonna picks up the armor and examines the crystalline layer on its surface. She grabs a nearby hammer and gives the plate a solid strike. She turns it around to show PNAME, and there is only a slight scuff where the hammer blow landed.`,
                                    `"I think we may have found a new armor enhancement," she says, her eyes gleaming with delight. "It'll take some more testing to determine the effectiveness, and application will be expensive, but it may be enough to give you an edge on the battlefield."`,
                                    `She sets the plate down and runs her hand across the smooth surface. "I couldn't have done it without you," she says. "I'll be sure to give you a prototype to test out."`
                                ],
                                attr: 'hum',
                                attrValue: 10,
                                module: { type: 'ablativeArmor', rank: 1},
                                schematic: 'ablativeArmor',
                                nextStage: 'complete'
                            },
                            fail: {
                                text: [
                                    `"Can I do anything to help?" asks PNAME. Ayonna directs pzir to take up a position on the opposite side of the table and power up the generators.`,
                                    `The blue stones begin vibrating as before, moving toward the armor plate at the center of the table. As the stones come together, they melt into each other and become a single rippling mass.`,
                                    `As they begin fine tuning the frequencies of the generators, the liquid stone starts expanding and contracting rapidly, throwing off small bits that solidify as they hit the surface of the table.`,
                                    `"No, not like that!" shouts Ayonna. She runs to PNAME's side of the table and readjusts the generators. The stone begins moving smoothly again. As she continues adjusting each generator in turn, the mass flattens out, flowing across the surface of the armor plate. When she shuts off the generators, the stone solidifies once more, creating a smooth, seamless coating.`,
                                    `Ayonna picks up the armor and examines the crystalline layer on its surface. She grabs a nearby hammer and gives the plate a solid strike. She turns it around to show PNAME, and there is only a slight scuff where the hammer blow landed.`,
                                    `"I think we may have found a new armor enhancement," she says, her eyes gleaming with delight. "It'll take some more testing to determine the effectiveness, and application will be expensive, but it may be enough to give you an edge on the battlefield."`
                                ],
                                schematic: 'ablativeArmor',
                                nextStage: 'complete'
                            }
                        },
                        { 
                            level: 'easy',
                            text: `Stay out of the way and watch her work`,
                            success: {
                                text: [
                                    `PNAME stands back and lets Ayonna run her experiment. She moves around the table, powering up each of the generators in turn.`,
                                    `The blue stones begin vibrating as before, moving toward the armor plate at the center of the table. As the stones come together, they melt into each other and become a single rippling mass.`,
                                    `As she fine tunes the frequencies of the generators, the rippling liquid stone flattens out, flowing across the surface of the armor plate. When she shuts off the generators, the stone solidifies once more, creating a smooth, seamless coating.`,
                                    `Ayonna picks up the armor and examines the crystalline layer on its surface. She grabs a nearby hammer and gives the plate a solid strike. She turns it around to show PNAME, and there is only a slight scuff where the hammer blow landed.`,
                                    `"I think we may have found a new armor enhancement," she says, her eyes gleaming with delight. "It'll take some more testing to determine the effectiveness, and application will be expensive, but it may be enough to give you an edge on the battlefield."`
                                ],
                                attr: 'wis',
                                attrValue: 5,
                                schematic: 'ablativeArmor',
                                nextStage: 'complete'
                            }
                        }
                    ]
                }        
            }
        }
    ]
}
