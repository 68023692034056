import { roomAttrs, skillAttrs } from "./constants"
import { getBonus, checkRoll, getRandomItem, getRankedValue } from "./mathUtils"
import { getWorstMechDamage, isMechDamaged, repairMech } from "./mech"
import { healPilot, changePilotEnergy, changePilotMetric, isPilotTired, applyStatsToPilot } from "./pilot"

const completeAction = async (db, user, location, worker, progressAmount, progressAttr = null) => {
    if (!progressAttr) {
        progressAttr = getRandomItem(roomAttrs[location])
        // this version always progresses the worst attribute - too easy?
        // progressAttr = getAttrToProgress(worker, roomAttrs[location])
    } 

    const progressUpdate = { attr: progressAttr, value: progressAmount }

    await applyStatsToPilot(db, user, worker, null, { energy: -1 }, progressUpdate)

    const newEnergy = worker.metrics.energy

    return newEnergy <= 0
}

export const academyAction = async (db, user, data, academy, movePilot, moveMech) => {
    const actionLocation = data.location
    const events = []
    const currTime = academy.time

    const checkPilots = data.pilots || data.workers
    if (checkPilots) {
        for (const pilot of checkPilots) {
            const lastBattle = pilot.lastBattle

            const timeSince = currTime - lastBattle

            if (timeSince > 0 && timeSince % 40 === 0) {
                console.log(`${pilot.name} is bored`)
                changePilotMetric(db, user, pilot.id, 'hap', -1)
            }
        }
    }

    switch (actionLocation) {
        case 'barracks': {
            const { pilots } = data

            for (const pilot of pilots) {
                if (isPilotTired(pilot)) {
                    changePilotEnergy(db, user, pilot.id, 1)
                }
            }
            break
        }
        case 'infirmary': {
            const { patients, workers } = data

            let healAmount = 1

            // pick a random patient to heal
            const patient = getRandomItem(patients)

            // check for worker effects on healing
            for (const worker of workers) {
                const { attributes: attrs } = worker
                let progressAmount = 2

                const otherWorker = workers.find((w) => worker.id !== w.id)

                const healBonus = getBonus([attrs.reas, attrs.emp, attrs.hum], 3)
                const result = checkRoll(15, healBonus)
                if (result.crit) {
                    healAmount += 2
                    progressAmount = 4
                    events.push({ 
                        location: actionLocation,
                        pilot: worker,
                        otherWorker,
                        patient
                    })
                } else if (result.botch) {
                    healAmount -= 1
                    progressAmount = 1
                } else if (result.success) {
                    healAmount +=1
                }

                const outOfEnergy = await completeAction(db, user, actionLocation, worker, progressAmount)

                if (outOfEnergy) {
                    movePilot(worker.id, 'barracks')
                }
            }

            // only heal patients if workers are present!
            if (patient && workers.length > 0) {
                // heal the patient
                const newHp = await healPilot(db, user, patient.id, healAmount)
                if (newHp === patient.metrics.maxHp) {
                    /** allow workers to remain in infirmary even if
                     * there are no patients
                     */
                    // if (patients.length === 1) {
                    //     // this was the last patient, so move out any workers
                    //     for (const worker of workers) {
                    //         movePilot(worker.id, 'barracks')
                    //     }
                    // }

                    movePilot(patient.id, 'barracks')
                }
            }
            break
        }
        case 'repairBay': {
            const { mechs, workers } = data

            let repairCost = 50

            let repairAmount = 15

            // pick a random mech to repair
            const mech = getRandomItem(mechs)

            // pick the slot to repair
            const worstSlot = mech ? getWorstMechDamage(mech) : null

            // check for worker effects on repair
            for (const worker of workers) {
                const { attributes: attrs } = worker
                let progressAmount = 2

                const otherWorker = workers.find((w) => worker.id !== w.id)

                const repairBonus = getBonus([attrs.conf, attrs.wis, attrs.dar], 3)
                const result = checkRoll(15, repairBonus)
                if (result.crit) {
                    repairAmount += 15
                    repairCost -= 10
                    progressAmount = 4
                    events.push({ 
                        location: actionLocation,
                        pilot: worker,
                        otherWorker,
                        mech,
                        slot: worstSlot?.slot
                    })
                } else if (result.botch) {
                    repairAmount -= 5
                    progressAmount = 1
                } else if (result.success) {
                    repairAmount +=10
                    repairCost -= 5
                }

                const outOfEnergy = await completeAction(db, user, actionLocation, worker, progressAmount)

                if (outOfEnergy) {
                    movePilot(worker.id, 'barracks')
                }
            }

            // only repair mechs if there are workers present!
            if (mech && workers.length > 0) {
                academy.removeCredits(Math.ceil(getRankedValue(repairCost, mech.rank)))

                // apply upgrades
                repairAmount = repairAmount * academy.repairFactor

                // repair the mech
                const maxArmor = mech.maxArmor[worstSlot.slot]
                repairAmount = Math.ceil((repairAmount / 100) * maxArmor)
                const newArmor = await repairMech(db, user, mech.id, repairAmount, worstSlot.slot)
                mech.armor[worstSlot.slot] = newArmor

                /** allow workers to stay in the repair bay
                 * even if there are not damaged mechs
                 */
                if (!isMechDamaged(mech)) {
                //     if (mechs.length === 1) {
                //         // this was the last damaged mech, so move out any workers
                //         for (const worker of workers) {
                //             movePilot(worker.id, 'barracks')
                //         }
                //     }

                    moveMech(mech.id, 'hangar')
                }
            }
            break
        }
        case 'simulator': {
            const { pilots } = data

            if (pilots.length > 0) {
                // run pilot through simulator
                for (const pilot of pilots) {
                    const { attributes: attrs, skills } = pilot
                    let progressAmount = 2

                    // find the skill the pilot needs to work on most
                    let lowSkillNum = 0
                    let lowSkills = []

                    for (const skill in skills) {
                        if (lowSkillNum === 0 || skills[skill] < lowSkillNum) {
                            // found a new low
                            lowSkills = [ skill ]
                            lowSkillNum = skills[skill]
                        } else {
                            if (skills[skill] === lowSkillNum) {
                                lowSkills.push(skill)
                            }
                        }
                    }

                    const simSkill = getRandomItem(lowSkills)
                    let simSlotClass = ''

                    switch (simSkill) {
                        case 'comm':
                        case 'per':
                            simSlotClass = 'head'
                            break
                        case 'combat':
                        case 'reaction':
                            simSlotClass = 'arm'
                            break
                        case 'repair':
                        case 'pilot':
                            simSlotClass = 'leg'
                            break
                        default:
                    }

                    // attempt to use the skill in simulation
                    const attrValues = skillAttrs[simSkill].map((s) => attrs[s])
                    const simAttrBonus = getBonus(attrValues, 3)
                    const result = checkRoll(15, simAttrBonus + lowSkillNum)
                    
                    const otherPilot = pilots.find((p) => pilot.id !== p.id)

                    if (result.crit) {
                        progressAmount = 4
                        events.push({ 
                            location: actionLocation,
                            pilot: pilot,
                            otherPilot,
                            slot: simSlotClass
                        })
                    } else if (result.botch) {
                        progressAmount = 1
                    }

                    const outOfEnergy = await completeAction(db, user, actionLocation, pilot, progressAmount, getRandomItem(skillAttrs[simSkill]))

                    if (outOfEnergy) {
                        movePilot(pilot.id, 'barracks')
                    }
                }
            }
            break
        }
        case 'recRoom': {
            const { pilots } = data

            if (pilots.length > 0) {
                // run pilot through simulator
                for (const pilot of pilots) {
                    const { attributes: attrs, skills } = pilot
                    let progressAmount = 2

                    // find the attr the pilot needs to work on most
                    let lowAttrNum = 0
                    let lowAttrs = []

                    for (const attr in attrs) {
                        if (lowAttrNum === 0 || attrs[attr] < lowAttrNum) {
                            // found a new low
                            lowAttrs = [ attr ]
                            lowAttrNum = attrs[attr]
                        } else {
                            if (attrs[attr] === lowAttrNum) {
                                lowAttrs.push(attr)
                            }
                        }
                    }

                    const recAttr = getRandomItem(lowAttrs)

                    // make a check based on the room attributes and the Communication skill
                    const attrValues = roomAttrs['recRoom'].map((s) => attrs[s])
                    const simAttrBonus = getBonus(attrValues, roomAttrs['recRoom'].length)
                    const result = checkRoll(15, simAttrBonus + skills.comm)
                    
                    const otherPilot = getRandomItem(pilots.filter((p) => pilot.id !== p.id))

                    if (result.crit) {
                        progressAmount = 4
                        events.push({ 
                            location: actionLocation,
                            pilot: pilot,
                            otherPilot
                        })
                    } else if (result.botch) {
                        progressAmount = 1
                    }

                    const outOfEnergy = await completeAction(db, user, actionLocation, pilot, progressAmount, recAttr)

                    if (outOfEnergy) {
                        movePilot(pilot.id, 'barracks')
                    }
                }
            }
            break
        }
        case 'factory': {
            const { pilots } = data

            let totalCredits = 20

            if (pilots.length > 0) {
                // run pilot through simulator
                for (const pilot of pilots) {
                    const { attributes: attrs, skills } = pilot
                    let progressAmount = 2
                    let credits = 15

                    // find the attr the pilot needs to work on most
                    let lowAttrNum = 0
                    let lowAttrs = []

                    for (const attr in attrs) {
                        if (lowAttrNum === 0 || attrs[attr] < lowAttrNum) {
                            // found a new low
                            lowAttrs = [ attr ]
                            lowAttrNum = attrs[attr]
                        } else {
                            if (attrs[attr] === lowAttrNum) {
                                lowAttrs.push(attr)
                            }
                        }
                    }

                    const recAttr = getRandomItem(lowAttrs)

                    // make a check based on the room attributes and the Repair skill
                    const attrValues = roomAttrs['factory'].map((s) => attrs[s])
                    const simAttrBonus = getBonus(attrValues, roomAttrs['factory'].length)
                    const result = checkRoll(15, simAttrBonus + skills.repair)
                    
                    const otherPilot = getRandomItem(pilots.filter((p) => pilot.id !== p.id))

                    if (result.crit) {
                        progressAmount = 4
                        credits = 25
                        events.push({ 
                            location: actionLocation,
                            pilot: pilot,
                            otherPilot
                        })
                    } else if (result.botch) {
                        progressAmount = 1
                        credits = 5
                    }

                    totalCredits += credits
                    const outOfEnergy = await completeAction(db, user, actionLocation, pilot, progressAmount, recAttr)

                    if (outOfEnergy) {
                        movePilot(pilot.id, 'barracks')
                    }
                }

                // only add resources if workers are present!
                if (academy.data.factoryOrders.length > 0 && academy.credits > 0) {
                    const bankFull = academy.credits === academy.maxCredits

                    const factoryCredits = bankFull ? totalCredits : Math.ceil(totalCredits / 2)

                    academy.addFactoryOrderProgress(factoryCredits)

                    if (!bankFull) {
                        academy.addCredits(factoryCredits)
                    }
                } else {
                    academy.addCredits(totalCredits)
                }
            }
            break
        }
        default:
    }

    // return any spawned events
    return events
}