export const kaijuList = [
    {
        name: 'fireCrab',
        environments: [
            'temperate',
            'water',
            'desert',
            'lava',
            'swamp',
            'jungle',
        ],
        speed: 5,
        armor: {
            head:       50,
            leftArm:    50,
            rightArm:   50,
            leftLeg:    50,
            rightLeg:   50,
            body:       80
        },
        specials: [
            [
                'quickAttack'
            ],
            [
                'smokeScreen'
            ],
            [
                'magmaBurst'
            ]
        ],
        weapons: [
            {
                slot: 'head',
                name: 'Lava Spit',
                range: 'm',
                acc: 15,
                dam: 10,
                speed: 10,
                status: 'online'
            },
            {
                slot: 'rightArm',
                name: 'Fire Jet',
                range: 'l',
                acc: 18,
                dam: 15,
                speed: 20,
                status: 'online'
            }
        ]
    },
    {
        name: 'rockTyrant',
        environments: [
            'temperate',
            'desert',
            'lava',
            'swamp',
            'jungle',
            'airless',
            'ice',
        ],
        speed: 13,
        armor: {
            head:       80,
            leftArm:    80,
            rightArm:   80,
            leftLeg:    80,
            rightLeg:   80,
            body:       90
        },
        weapons: [
            {
                slot: 'leftArm',
                name: 'Crystal Smash',
                range: 's',
                acc: 15,
                dam: 16,
                speed: 10,
                status: 'online'
            },
            {
                slot: 'rightLeg',
                name: 'Earthquake',
                range: 'm',
                acc: 18,
                dam: 21,
                speed: 20,
                status: 'online'
            }
        ]
    },
    {
        name: 'deepLurker',
        environments: [
            'temperate',
            'jungle',
            'swamp',
            'water',
        ],
        speed: 8,
        armor: {
            head:       60,
            leftArm:    60,
            rightArm:   60,
            leftLeg:    60,
            rightLeg:   60,
            body:       100
        },
        weapons: [
            {
                slot: 'leftArm',
                name: 'Pummel',
                range: 's',
                acc: 15,
                dam: 14,
                speed: 10,
                status: 'online'
            },
            {
                slot: 'rightArm',
                name: 'Slap',
                range: 'm',
                acc: 15,
                dam: 14,
                speed: 10,
                status: 'online'
            },
            {
                slot: 'leftLeg',
                name: 'Constrict',
                range: 'm',
                acc: 15,
                dam: 14,
                speed: 10,
                status: 'online'
            },
            {
                slot: 'rightLeg',
                name: 'Crush',
                range: 'm',
                acc: 15,
                dam: 14,
                speed: 10,
                status: 'online'
            }
        ]
    },
    {
        name: 'scavenger',
        environments: [
            'lava',
            'airless',
            'desert',
            'temperate',
            'jungle',
            'swamp',
            'water',
            'ice',
        ],
        speed: 7,
        armor: {
            head:       60,
            leftArm:    50,
            rightArm:   60,
            leftLeg:    70,
            rightLeg:   70,
            body:       80
        },
        weapons: [
            {
                slot: 'leftArm',
                name: 'Slam',
                range: 's',
                acc: 15,
                dam: 9,
                speed: 10,
                status: 'online'
            },
            {
                slot: 'rightLeg',
                name: 'Crush',
                range: 'm',
                acc: 15,
                dam: 16,
                speed: 20,
                status: 'online'
            }
        ]
    },
    {
        name: 'magmorphous',
        environments: [
            'lava',
            'airless',
            'desert',
        ],
        speed: 9,
        armor: {
            head:       70,
            leftArm:    70,
            rightArm:   70,
            leftLeg:    70,
            rightLeg:   70,
            body:       70
        },
        weapons: [
            {
                slot: 'head',
                name: 'Eruption',
                range: 'l',
                acc: 15,
                dam: 18,
                speed: 20,
                status: 'online'
            },
            {
                slot: 'rightLeg',
                name: 'Lava Flow',
                range: 's',
                acc: 15,
                dam: 11,
                speed: 10,
                status: 'online'
            }
        ]
    },
    {
        name: 'adjudicator',
        environments: [
            'airless',
            'desert',
            'temperate',
            'jungle',
            'water',
        ],
        speed: 6,
        armor: {
            head:       60,
            leftArm:    60,
            rightArm:   60,
            leftLeg:    60,
            rightLeg:   60,
            body:       70
        },
        weapons: [
            {
                slot: 'leftArm',
                name: 'Sword of Justice',
                range: 's',
                acc: 15,
                dam: 15,
                speed: 20,
                status: 'online'
            },
            {
                slot: 'rightArm',
                name: 'Redemption Beam',
                range: 'l',
                acc: 15,
                dam: 11,
                speed: 10,
                status: 'online'
            }
        ]
    }
]