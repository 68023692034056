import React from 'react';
import { Image } from 'semantic-ui-react'

export const Logo = ({ size, color = 'green', spinning = false, className = '' }) => {
    const style = {}

    switch (color) {
        case 'dark': 
            style.WebkitFilter = 'brightness(0.2)'
            break
        case 'med': 
            style.WebkitFilter = 'brightness(0.6)'
            break
        default:
    }

    return <div className={`logo ${className}`} style={{ width: `${size}px`, height: `${size}px` }}>
        <Image
            className={ `logoLayer ${ spinning && 'spinning' }` }
            style={ style }
            src={`/assets/logo/gear_green.svg`}
        />
        <Image
            className="logoLayer"
            style={ style }
            src={`/assets/logo/gauntlet_green.svg`}
        />
    </div>
}