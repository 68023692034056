import React, { useState, useEffect } from 'react'
import { ItemTypes } from '../../lib/constants'
import { useDrop } from 'react-dnd'
import DragMechCard from './dragMechCard';

function MechDropRoom({ mechs, className, location, emptyMsg, moveMech, selectMech, playSoundEffect, enabled = false , maxMechs, filter = (p) => true}) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.MECH,
    canDrop: () => enabled && (!maxMechs || mechs.length < maxMechs),
    drop: async (item, monitor) => {
        if (filter(item)) {
          playSoundEffect('cardDrop')
          moveMech(item.id, location)
        }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  }), [ enabled, mechs ])

  const [roomMechs, setRoomMechs] = useState(mechs)
  useEffect(() => {
    setRoomMechs(mechs)
  }, [ mechs ])

  return (
    <div 
      className={`roomContents ${className || ''}`}
      ref={ drop }
    >
        { roomMechs.map((m, i) => (
          <DragMechCard key={i} mech={m} number={i} location={location} selectMech={selectMech} enabled={enabled}/>
        ))}
        { roomMechs.length === 0 && <div className="emptyMsg">{emptyMsg}</div>}
      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'yellow',
          }}
        />
      )}
    </div>
  )
}

export default MechDropRoom