import { ref, set } from 'firebase/database';

import { Icon } from 'semantic-ui-react'
import Entity from './entity'
import { roomIcons, upgradeIcons } from '../lib/constants'

export const generateAcademy = async (db, user) => {
    console.log('generateAcademy')
    // Add a new document with a generated id
    const newAcademyRef = ref(db, `academy/${user.uid}`);
    
    const academyData = {
        credits: 5000,
        xenos: 0,
        time: 0,
        tutorials: {},
        created: Date.now(),
        stories: {},
        upgrades: {},
        modules: {},
        schematics: [],
        factoryOrders: [],
        user: user.uid,
        settings: {
            playMusic: true,
            playSound: true,
            volume: 0.5,
            minigames: 'sometimes',
            battleSpeed: 'normal',
        },
        userName: user.displayName
    }
  
    set(newAcademyRef, academyData);

    console.log('academy data set')
}

export const getRoomIcon = (location, size = 'small') => {
    return <Icon className="roomIcon" name={roomIcons[location]} size={ size }/>
}

export const getUpgradeIcon = (upgrade, size = 'small') => {
    const theIcon = upgradeIcons[upgrade]

    if (!upgrade) {
        return null
    }
    
    if (typeof theIcon === 'string') {
        return <Icon className="upgradeIcon" name={theIcon} size={ size }/>
    } else {
        if (theIcon.main && theIcon.corner) {
            return <Icon.Group className="upgradeIcon" size={size}>
                <Icon name={theIcon.main} />
                <Icon corner name={theIcon.corner} />
            </Icon.Group>
        } else {
            return <Icon className="upgradeIcon" name="question" size={ size }/>
        }
    }
}

export class Academy extends Entity {
    constructor(db, user) {
        super(db, user, null, 'academy')
    }

    generateData() {
        generateAcademy(this.db, this.user)
    }

    initData() {
        this.credits = this.data.credits
        this.xenos = this.data.xenos || 0
        this.time = this.data.time
        this.maxCredits = 20000 + 10000 * (this.data.upgrades.resourceStorage || 0)
        this.maxPilots = 10 + 5 * (this.data.upgrades.barracks || 0)
        this.maxMechs = 4 + 2 * (this.data.upgrades.hangar || 0)
        this.teamSlots = 2 + (this.data.upgrades.launchBays || 0)
        this.repairBays = 2 + (this.data.upgrades.repairBays || 0)
        this.medBays = 4 + (this.data.upgrades.infirmary || 0)
        this.simPods = 2 + (this.data.upgrades.simulator || 0)
        this.recRooms = 4 + (this.data.upgrades.recRoom || 0)
        this.factoryWorkers = 4 + (this.data.upgrades.factory || 0)
        this.jumpCost = 500 - 100 * (this.data.upgrades.jumpEngines || 0)
        this.repairFactor = 1 + 0.1 * (this.data.upgrades.repairTools || 0)
    }

    incrementTime(amount = 1) {
        this.time = this.time + amount

        this.updateData({ time: this.time })
    }

    addCredits(amount) {
        this.credits = Math.min(this.credits + amount, this.maxCredits)

        this.updateData({ credits: this.credits })
    }

    removeCredits(amount) {
        this.credits = this.credits - amount

        this.updateData({ credits: this.credits })
    }

    addXenos(amount) {
        this.xenos = this.xenos + amount

        this.updateData({ xenos: this.xenos })
    }

    removeXenos(amount) {
        this.xenos = this.xenos - amount

        this.updateData({ xenos: this.xenos })
    }

    addSchematic(schematic) {
        if (!this.data.schematics.includes(schematic)) {
            const newSchematics = [ ...this.data.schematics, schematic ]
            newSchematics.sort()

            this.updateData({ schematics: newSchematics })
        }
    }

    addModule(type, rank) {
        if (!this.data.modules) {
            this.data.modules = {}
        }

        const newModules = { ...this.data.modules }

        if (!newModules[type]) {
            newModules[type] = {}
        }
        
        if (!newModules[type][rank]) {
            newModules[type][rank] = 1
        } else {
            newModules[type][rank] += 1
        }

        this.data.modules = newModules
        this.updateData({ modules: newModules })
    }

    removeModule(type, rank) {
        const newModules = { ...this.data.modules }

        newModules[type][rank] -= 1

        this.data.modules = newModules
        this.updateData({ modules: newModules })
    }

    addModuleOrder(type, rank) {
        if (!this.data.factoryOrders) {
            this.data.factoryOrders = []
        }

        this.data.factoryOrders.push({
            item: 'module',
            type,
            rank,
            cost: rank * 500,
            progress: 0
        })

        this.xenos -= rank

        this.updateData({ factoryOrders: this.data.factoryOrders, xenos: this.xenos })
    }

    addFactoryOrderProgress(amount) {
        const { factoryOrders } = this.data

        if (!factoryOrders) { return }

        const currOrder = factoryOrders[0]
        
        if (!currOrder) { return }

        currOrder.progress += amount

        if (currOrder.progress >= currOrder.cost) {
            switch (currOrder.item) {
                case 'module':
                    this.addModule(currOrder.type, currOrder.rank)
                    factoryOrders.shift()
                    this.updateData({ factoryOrders })
                    break
                default:
            }
        } else {
            this.updateData({ factoryOrders })
        }
    }

    markTutorialComplete(name) {
        const tutorials = { ...this.data.tutorials }

        tutorials[name] = true

        this.updateData({ tutorials })
    }

    setStoryState(storyName, storyState) {
        const stories = { ...this.data.stories }

        if (storyState.stageId === 'reset') {
            // reset the story so it can run again
            delete stories[storyName]
        } else {
            stories[storyName] = storyState
        }

        this.updateData({ stories })
    }

    resetTutorials() {
        this.updateData({ tutorials: {} })
    }

    buyUpgrade(upgrade, price) {
        const currLevel = this.data.upgrades[upgrade] || 0

        const payload = {
            [`upgrades.${upgrade}`]: currLevel + 1,
            xenos: this.data.xenos - price
        }

        this.updateData(payload)
    }

    updateSettings(newSettings) {
        const settings = Object.assign({}, this.data.settings, newSettings)

        this.updateData({ settings })
    }

    toggleMusic() {
        const newValue = !this.data.settings.playMusic

        this.updateSettings({ playMusic: newValue })

        return newValue
    }

    toggleSound() {
        const newValue = !this.data.playSound

        this.updateSettings({ playSound: newValue })

        return newValue
    }
}