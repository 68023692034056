import { Image } from 'semantic-ui-react'
import { getSkillIcon, getMetricIcon } from '../../lib/pilot'

export const pilots = {
    key: 'pilots',
    title: 'Pilots',
    body: <div>
            <p>Pilots are cool.</p>
        </div>,
    items: [
        {
            key: 'attributes',
            title: 'Attributes',
            body: <div>
                <p>
                    Attributes are innate qualities of pilots. They require insight and emotional labor to improve. While all Attributes are important, it is wise to find balance between them rather than focus on a few.
                </p>
                <ul>
                    <li><b className="color-attr-conf">Confidence</b>: This Attribute allows the pilot to make firm decisions and take action without delay.</li>
                    <li><b className="color-attr-prow">Prowess</b>: Skill at performing tasks is key to succees. Any action that requires specific training benefits from this Attribute.</li>
                    <li><b className="color-attr-reas">Reason</b>: Applying logic to a situation allows the pilot to determine the ideal solution.</li>
                    <li><b className="color-attr-fort">Fortitude</b>: With this Attribute a pilot is able to continue through adverse conditions and setbacks.</li>
                    <li><b className="color-attr-wis">Wisdom</b>: Knowing when to act and when to wait is important for avoiding mistakes.</li>
                    <li><b className="color-attr-dar">Daring</b>: When an opportunity presents itself, an effective pilot must be willing to take risks to capitalize on it.</li>
                    <li><b className="color-attr-emp">Empathy</b>: A pilot never acts alone; this Attribute helps them understand others and work in concert with them.</li>
                    <li><b className="color-attr-hum">Humility</b>: A Gauntlet is a team, with no one pilot more important than another. Being able to put one's ego aside for the good of the whole is a key Attribute.</li>
                </ul>
            </div>
        },
        {
            key: 'quirks',
            title: 'Quirks',
            body: <div>
                <p>
                    Each pilot may have one or more personality Quirks that affect the development of their attributes. Each Quirk will hamper one attribute while giving a small boost to another, though the net effect is always negative. Completing event challenges involving the hampered attribute will allow the pilot to eventually overcome these Quirks.
                </p>
                <ul>
                    <li><b>Arrogant:</b> The pilot thinks they are always right. This gives a boost to <b className="color-attr-conf">Confidence</b> at the expense of <b className="color-attr-hum">Humility</b>.</li>
                    <li><b>Timid:</b> A timid pilot tends to second guess their decisions. They are inclined to <b className="color-attr-hum">Humility</b> but lack <b className="color-attr-conf">Confidence</b>.</li>
                    <li><b>Bookish:</b> Some pilots prefer studying theory to practical application in the cockpit. <b className="color-attr-reas">Reason</b> is easy for them, while <b className="color-attr-fort">Fortitude</b> comes harder.</li>
                    <li><b>Stubborn:</b> When a pilot prefers to push through a problem rather than figure out a way around it, their <b className="color-attr-fort">Fortitude</b> outstrips their <b className="color-attr-reas">Reason</b>.</li>
                    <li><b>Thoughtless:</b> It is easy for these pilots to focus on their own perfomance at the expense of others. They are able to improve <b className="color-attr-prow">Prowess</b> because they ignore <b className="color-attr-emp">Empathy</b>.</li>
                    <li><b>Perfectionist:</b> Too much concern for others' opinions can impede progress. Attention to <b className="color-attr-emp">Empathy</b> takes focus away from developing <b className="color-attr-prow">Prowess</b>.</li>
                    <li><b>Reckless:</b> Always first into battle, these pilots let their <b className="color-attr-dar">Daring</b> get ahead of <b className="color-attr-wis">Wisdom</b>.</li>
                    <li><b>Cautious:</b> Thinking before acting is good, but sometimes <b className="color-attr-wis">Wisdom</b> must give way to <b className="color-attr-dar">Daring</b>.</li>
                </ul>
            </div>
        },
        {
            key: 'skills',
            title: 'Skills',
            body: <div>
                <p>
                    Skills govern the actions that a pilot uses while part of a Gauntlet team. They can be learned with practice and are enhanced by innate Attributes. In the field, a pilot may be called upon to fill any role in the Gauntlet, so a successful career requires a well rounded set of skills.
                </p>
                <p>
                    Increasing skills requires spending Expertise, and requires the relevant attributes to be at a minimum level. To advance to a given rank, a pilot must complete 5 successful sorties in the relevant Gauntlet position per level they wish to attain.
                </p>
                <ul>
                    <li><b>{getSkillIcon('comm')}Communication:</b> This skill is used to coordinate actions with other pilots. Is is affected by <b className="color-attr-hum">Humility</b>, <b className="color-attr-emp">Empathy</b>, and <b className="color-attr-conf">Confidence</b>.</li>
                    <li><b>{getSkillIcon('per')}Perception:</b> This skill is key to detecting threats and opportunities. Is is affected by <b className="color-attr-reas">Reason</b>, <b className="color-attr-emp">Empathy</b>, and <b className="color-attr-conf">Confidence</b>.</li>
                    <li><b>{getSkillIcon('combat')}Combat:</b> Making attacks with the Gauntlet weapons is governed by this skill. Is is affected by <b className="color-attr-prow">Prowess</b>, <b className="color-attr-fort">Fortitude</b>, and <b className="color-attr-dar">Daring</b>.</li>
                    <li><b>{getSkillIcon('reaction')}Reaction:</b> Blocking enemy attacks and other fast actions needs this skill. Is is affected by <b className="color-attr-conf">Confidence</b>, <b className="color-attr-prow">Prowess</b>, and <b className="color-attr-wis">Wisdom</b>.</li>
                    <li><b>{getSkillIcon('repair')}Repair:</b> Fixing damaged systems in the Gauntlet during combat requires use of this skill. Is is affected by <b className="color-attr-wis">Wisdom</b>, <b className="color-attr-hum">Humility</b>, and <b className="color-attr-reas">Reason</b>.</li>
                    <li><b>{getSkillIcon('pilot')}Pilot:</b> Moving the Gauntlet quickly and accurately requires this skill. Is is affected by <b className="color-attr-fort">Fortitude</b>, <b className="color-attr-dar">Daring</b>, and <b className="color-attr-reas">Reason</b>.</li>
                </ul>
            </div>
        },
        {
            key: 'metrics',
            title: 'Metrics',
            body: <div>
                <p>
                    A pilot's progress can be measured in many ways. These values are affected by a wide variety of different events and situations.
                </p>
                <ul>
                    <li><b>{getMetricIcon('xp')}Expertise:</b> There is no substitute for time spent in the cockpit. Every time a pilot goes into battle or spends time in the simulator they have an opportunity to increase Expertise. Expertise is spent to increase Skills.</li>
                    <li><b>{getMetricIcon('fame')}Fame:</b> A pilot's deeds can live forever in stories and legends. Great victories will add to a pilot's Fame, while defeats may reduce it. Extraordinary pilots can do extraordinary things, like learn special abilities or overcome seemingly impossible challenges.</li>
                    <li><b>{getMetricIcon('hap')}Happiness:</b> A happy pilot is a successful pilot. Emotional state and satisfaction contribute to combat effectiveness as well as life outside the cockpit. At a sufficiently low level, a pilot may quit rather than continue in the program.</li>
                    <li><b>{getMetricIcon('hp')}Health:</b> Battle takes a toll on the body. A pilot whose Health reaches 0 is at risk of serious injury or death.</li>
                    <li><b>{getMetricIcon('energy')}Energy:</b> Activities on board the Academy ship require a pilot to be well rested. Energy is used while a pilot is active in any part of the Academy except the Barracks, where it is replenished by rest.</li>
                    <li><b>{getMetricIcon('rank')}Rank:</b> A pilot's rank is based on their overall skill levels. Rank is calculated based on the average of all skills, rounded up. A pilot's rank must be at least equal to the rank of the Gauntlet they wish to be assigned to.</li>
                </ul>
            </div>
        },
        {
            key: 'relationships',
            title: 'Relationships',
            body: <div>
                <Image
                    className="guideImg floatRight"
                    src="/assets/images/guide/relationshipGraph.png"
                />
                <p>
                    Relationships between pilots are indicated by <span style={{color: 'blue'}}>blue</span> lines for good relationships and <span style={{color: 'red'}}>red</span> lines for bad relationships. The strength of the relationship is indicated by the line width. Hover over a pilot to focus on their relationships.
                </p>
                <p>
                    A relationship may be improved or harmed by events, or by the success or failure of a battle. Pilots with good relationships will work better together.
                </p>
            </div>
        },
        {
            key: 'assigning',
            title: 'Assigning to Teams',
            body: <div>
                <p>
                    Assign pilots to positions in the Gauntlet by dragging their portrait from the pilot list.
                </p>
                <p>
                    Each position in the Gauntlet serves a particular purpose and requires a pilot with specific skills:
                </p>
                <ul>
                    <li><b>Head:</b> The head is the leader of the Gauntlet, directing and encouraging the other pilots. Their key skills are {getSkillIcon('comm')}<b>Communication</b> and {getSkillIcon('per')}<b>Perception</b>.</li>
                    <li><b>Arms:</b> The arms provide the attack and defense power of the Gauntlet. Their key skills are {getSkillIcon('combat')}<b>Combat</b> and {getSkillIcon('reaction')}<b>Reaction</b>.</li>
                    <li><b>Legs:</b> The legs form the Gauntlet's solid base, repairing damage and moving everyone out of harm's way. Their key skills are {getSkillIcon('repair')}<b>Repair</b> and {getSkillIcon('pilot')}<b>Pilot</b>.</li>
                </ul>
            </div>
        },
        {
            key: 'advancement',
            title: 'Advancement',
            body: <div>
                <div className="floatRight">
                    <Image
                        className="guideImg"
                        src="/assets/images/guide/advancePilot.png"
                    />
                    <Image
                        className="guideImg"
                        src="/assets/images/guide/advanceAttr.png"
                    />
                </div> 
                <p>
                    Over time, pilots will make progress on improving their Attributes. Having Attributes at a sufficiently high level will allow advancement of Skills by spending Expertise points. 
                </p>
                <p>
                    Advancing an Attribute requires 30 progress points times the new level of the attribute. For example, going from Confidence 3 to Confidence 4 takes 120 points. A quirk may cause the pilot to gain progress in an attribute faster or slower than normal. For example, an Arrogant pilot gains Confidence a little faster, but a Timid pilot gains it much more slowly
                </p>
                <p>
                    Each Skill is dependent on three Attributes. To advance a Skill, the average of those Attributes must be at least equal to the new Skill level. For example, to reach Reaction 3, the pilot must have Prowess, Confidence, and Wisdom all at level 6, one at 4, one at 6, and one at 8, or any combination that will average to 6. Advancing a skill also requires spending 100 Expertise points times the new level (300 in this example).
                </p>
                <p>
                    A pilot's Rank is determined based on the average of their Skill levels, rounded up. For example, a pilot with three skills at rank 2 and three skills at rank 3 would be Rank 3. A pilot with two skills at rank 1, two at rank 3, and two at rank 4 would also be Rank 3.
                </p>      
                <p>
                    Rank determines what Gauntlets a pilot is rated to use. Also, a pilot of at least Rank 4 is eligible to graduate from the Academy, though they may advance as far as Rank 5.
                </p>              
            </div>
        }
    ]
}