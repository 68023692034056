export const randInt = (min, max) => {
    if (min === max) {
        return min
    }
    
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const oneIn = (chance) => {
    if (!chance) {
        return false
    }
    return randInt(0, chance) === 0
}

export const flipCoin = () => {
    return Math.random() < 0.5
}

export const weightedRandom = (min, max) => {
    return Math.round(max / (Math.random() * max + min));
}

export const checkRoll = (dc, bonus = 0) => {
    const roll = randInt(1, 20)
    const totalRoll = roll + bonus

    return {
        success: totalRoll >= dc,
        totalRoll,
        crit: roll === 20,
        botch: roll === 1
    }
}

export const getRankedValue = (baseValue, rank, factor = 0.5) => {
    return (1 + ((rank - 1) * factor)) * baseValue
}

export const getAverage = (array) => array.reduce((a, b) => a + b) / array.length

// calculate a check bonus based on the average of skills/attributes divided by some factor
export const getBonus = (array, factor) => Math.ceil(getAverage(array) / factor)

// return a random element of an array
export const getRandomItem = (array) => array[randInt(0, array.length - 1)]

export const calcDistance = (loc1, loc2) => {
    const x1 = loc1.x
    const y1 = loc1.y
    const x2 = loc2.x
    const y2 = loc2.y

    const dx = x2 - x1
    const dy = y2 - y1

    const dx2 = dx ** 2
    const dy2 = dy ** 2

    return Math.sqrt(dx2 + dy2)
}