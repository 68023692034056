import React, { useEffect, useState } from 'react'
import { ItemTypes } from '../lib/constants'
import { getPilotRank } from '../lib/pilot'
import { useDrag } from 'react-dnd'
import { Portrait } from './portrait'

function DragPortrait({pilot, slotType, size, context}) {
  const [{isDragging}, drag] = useDrag(() => {
    return {
    type: ItemTypes.PILOT,
    item: { id: pilot?.id, slotType, rank: getPilotRank(pilot) },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    })
  }})

  const [ thePilot, setThePilot ] = useState(pilot)
  useEffect(() => {
    setThePilot(pilot)
  }, [ pilot ])

  return (
    <div
      ref={drag}
      className="dragPortrait"
      style={{
        pointerEvents: thePilot ? 'auto' : 'none',
        opacity: isDragging ? 0.5 : 1,
        height: `${size}px`,
        width: `${size}px`,
      }}
    >
        <Portrait subject={thePilot ? thePilot.portrait : null} context={context} size={size}/>
    </div>
  )
}

export default DragPortrait