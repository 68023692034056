import { getPronoun, getFirstName } from '../lib/pilot'

export const replaceEventText = (eventData, text) => {
    if (Array.isArray(text)) {
        return text.map((t, i) => (
            <p key={i}>
                { replaceTextChunk(eventData, t) }
            </p>
        ))
    } else {
        return replaceTextChunk(eventData, text)
    }
}

const replaceTextChunk = (eventData, text) => {
    const p = eventData.pilot
    const o = eventData.otherPerson

    const needles = [
        'PNAME',
        'PZE',
        'PZIR',
        'PZIS',
        'pze',
        'pzir',
        'pzis',
        'pzirs',
        'pzirself',
        'ONAME',
        'OZE',
        'OZIR',
        'OZIS',
        'oze',
        'ozir',
        'ozis',
        'ozirs',
        'ozirself',
        'PLANETNAME',
    ]

    const n = {}

    for (const needle of needles) {
        n[needle] = new RegExp('\\b' + needle + '\\b', "g")
    }

    let out = text.replace(n.PNAME, getFirstName(p))
        .replace(n.PZE, getPronoun(p, 'sub', true))
        .replace(n.PZIR, getPronoun(p, 'obj', true))
        .replace(n.PZIS, getPronoun(p, 'pos', true))
        .replace(n.pze, getPronoun(p, 'sub'))
        .replace(n.pzir, getPronoun(p, 'obj'))
        .replace(n.pzis, getPronoun(p, 'pos'))
        .replace(n.pzirself, getPronoun(p, 'ref'))
        .replace(n.PLANETNAME, eventData.planet?.name)

    if (o) {
        out = out.replace(n.ONAME, getFirstName(o))
            .replace(n.OZE, getPronoun(o, 'sub', true))
            .replace(n.OZIR, getPronoun(o, 'obj', true))
            .replace(n.OZIS, getPronoun(o, 'pos', true))
            .replace(n.oze, getPronoun(o, 'sub'))
            .replace(n.ozir, getPronoun(o, 'obj'))
            .replace(n.ozis, getPronoun(o, 'pos'))
            .replace(n.ozirself, getPronoun(o, 'ref'))
    }

    return out
}