import { ref, set, update } from 'firebase/database';

import Entity from './entity'
import { DateTime, Interval } from 'luxon'
import starLocData from '../data/starLoc.csv'
import starLinksData from '../data/starLinks.csv'
import { csv } from 'd3'
import { getRandomItem, randInt, calcDistance } from "./mathUtils";
import { planetTypes, numerals } from "./constants";
import { sortByKey } from "./arrayUtils";

export const generateStarMap = async (db, user) => {
    const startTime = DateTime.now()
    console.log(`start: ${startTime.toString()}`)

    console.log()
    const starMapData = {
        stars: {},
        orbitStarId: null
    }

    if (user) {
        starMapData.user = user.uid
    }

    const generatePlanets = (theStar) => {
        const numPlanets = randInt(1, 3)

        const positions = new Set()

        const out = {}

        for (let i = 0; i < numPlanets; i++) {
            let position = 0

            while (!position || positions.has(position)) {
                position = randInt(1, 8)
            }

            const danger = i === 0 ? theStar.maxDanger : randInt(1, theStar.maxDanger)

            out[i] = {
                position,
                code: `${theStar.id}-${i}`,
                type: getRandomItem(planetTypes),
                danger,
                maxDanger: danger,
                visited: false
            }

            positions.add(position)
        }

        theStar.planets = out
    }

    const surveyStars = () => {
        const { stars } = starMapData

        // make an array so we can iterate
        const starArray = Object.keys(stars).map((sKey) => stars[sKey])

        const xMax = 1080
        const yMax = 600

        // set the starting star in the middle region of the map
        const validStartPoints = starArray.filter((s) => s.x < xMax * 0.65 && s.x > xMax * 0.35 && s.y < yMax * 0.65 && s.y > yMax * 0.35)

        // set the start point
        const starPointIndex = randInt(0, validStartPoints.length - 1)
        const originId = validStartPoints[starPointIndex].id
        starMapData.orbitStarId = originId

        const firstBatch = new Set()
        firstBatch.add(originId)
        let jumpDistance = 0

        const surveyBatch = (theBatch) => {
            console.log(`surveying batch ${jumpDistance}`)
            // initialize the next survey batch
            const nextBatch = new Set()

            // process the batch
            for (let sId of theBatch) {
                const theStar = stars[sId]

                // mark the jump distance of the star
                theStar.jumpsFromStart = jumpDistance

                // set initial knowledge
                if (jumpDistance === 0) {
                    theStar.visited = true
                    theStar.beacon = true
                }

                if (jumpDistance <= 1) {
                    theStar.known = true
                }

                // mark the max danger level based on jump distance
                theStar.maxDanger = Math.ceil(Math.sqrt(jumpDistance)) || 1

                // now that the max danger is set, we can generate planets
                generatePlanets(theStar)
            }

            // gather unprocessed neighbors for the next batch
            for (let sId of theBatch) {
                const theStar = stars[sId]

                for (let n of theStar.neighbors) {
                    // check to see if the star has been surveyed already
                    // if not, add it to the next batch to process
                    const nextStar = stars[n]

                    if (nextStar.jumpsFromStart === null) {
                        nextBatch.add(n)
                    }
                }
            }

            console.log(`next batch has ${nextBatch.size} items`)
            if (jumpDistance > 100) {
                console.log('something went wrong!')
            }
            if (nextBatch.size > 0) {
                // increment the distance
                jumpDistance++

                surveyBatch(nextBatch)
            }
        }
        
        surveyBatch(firstBatch)
    }
    
    const starLoc = await csv(starLocData)

    for (const star of starLoc) {
        starMapData.stars[star.id] = { 
            ...star,
            known: false,
            visited: false,
            maxDanger: 0,
            jumpsFromStart: null,
            planets: {},
            neighbors: []
        }
    }
  
    const starLinks = await csv(starLinksData)

    for (const link of starLinks) {
        const a = link.source
        const b = link.target

        starMapData.stars[a].neighbors.push(b)
        starMapData.stars[b].neighbors.push(a)
    }

    surveyStars()

    console.log(starMapData)

    const endTime = DateTime.now()
    console.log(`end: ${endTime.toString()}`)

    const i = Interval.fromDateTimes(startTime, endTime);

    console.log(`generation time: ${i.length('seconds').toFixed(2)}`)

    if (db && user) {
        // Add a new document
        const newStarMapRef = ref(db, `starMap/${user.uid}`);
        
        set(newStarMapRef, starMapData);

        console.log('star map data set')
    } else {
        return starMapData
    }
}

export const setPlanetDanger = (db, user, starId, planetId, newDanger) => {
    update(ref(db, `/starMap/${user.uid}/stars/${starId}/planets/${planetId}`), { danger: newDanger })
}

export const setStarBeacon = (db, user, starId) => {
    update(ref(db, `/starMap/${user.uid}/stars/${starId}`), { beacon: true })
}

export const findPlanet = (starMap, query) => {
    let planets = []

    if (query.code) {
        // we have the code, so just go grab it
        const tokens = query.code.split('-')
        const theStar = starMap.stars[tokens[0]]
        const thePlanet = theStar.planets[tokens[1]]

        return {
            starId: theStar.id,
            planetId: tokens[1],
            code: query.code,
            type: thePlanet.type,
            name: `${theStar.name} ${numerals[thePlanet.position - 1]}`
        }
    } else {
        const starLog = calcJumpDistances(starMap, starMap.orbitStarId)

        for (let sKey in starMap.stars) {
            const theStar = starMap.stars[sKey]
            for (let pKey in theStar.planets) {
                const thePlanet = theStar.planets[pKey]
                let exclude = false

                for (const facet in query) {
                    const value = query[facet]
                    const distance = starLog[sKey].distance

                    switch (facet) {
                        case 'type':
                            if (thePlanet.type !== value) {
                                exclude = true
                            }
                            break
                        case 'danger':
                            if (thePlanet.danger !== value) {
                                exclude = true
                            }
                            break
                        case 'minJumps':
                            if (distance < value) {
                                exclude = true
                            }
                            break
                        case 'maxJumps':
                            if (distance > value) {
                                exclude = true
                            }
                            break
                        default:
                    }
                }

                if (!exclude) {
                    planets.push({
                        starId: sKey,
                        planetId: pKey,
                        code: thePlanet.code,
                        type: thePlanet.type,
                        name: `${theStar.name} ${numerals[thePlanet.position - 1]}`,
                        distance: starLog[sKey].distance
                    })
                }
            }
        }

        if (query.closest) {
            planets = sortByKey(planets, 'distance')

            return planets[0]
        } else {
            return getRandomItem(planets)
        }
    }
}

const calcJumpDistances = (starMap, startId, knownOnly = false) => {
    const { stars } = starMap

    const starLog = {}

    // always calculate all stars in the map
    for (let id in starMap.stars) {
        starLog[id] = { distance: null }
    }

    const firstBatch = new Set()
    firstBatch.add(startId)
    let jumpDistance = 0

    const surveyBatch = (theBatch) => {
        // console.log(`surveying batch ${jumpDistance}`)
        // initialize the next survey batch
        const nextBatch = new Set()

        // process the batch
        for (let sId of theBatch) {
            const theStar = starLog[sId]

            // mark the jump distance of the star
            theStar.distance = jumpDistance
        }

        // gather unprocessed neighbors for the next batch
        for (let sId of theBatch) {
            const theStar = stars[sId]

            for (let n of theStar.neighbors) {
                let valid = true

                if (knownOnly && !stars[n].known) {
                    valid = false
                }

                // check to see if the star has been surveyed already
                // if not, add it to the next batch to process
                if (valid && starLog[n].distance === null) {
                    nextBatch.add(n)
                }
            }
        }

        // console.log(`next batch has ${nextBatch.size} items`)
        if (jumpDistance > 100) {
            console.log('something went wrong!')
        }
        if (nextBatch.size > 0) {
            // increment the distance
            jumpDistance++

            surveyBatch(nextBatch)
        }
    }
    
    surveyBatch(firstBatch)

    return starLog
}

export const findPathToStar = (starMapData, endId, startId) => {
    const { stars } = starMapData

    if (!startId) {
        startId = starMapData.orbitStarId
    }

    const startStar = stars[starMapData.orbitStarId]

    const starLog = calcJumpDistances(starMapData, startId, true)

    if (starLog[endId].distance === null) {
        // the end point is disconnected from the start point, so no path
        return []
    }
    
    // initialize path with the end point
    const path = [endId]
    const skipStars = new Set()
    let noPath = false

    const findNextStep = () => {
        const lastStar = stars[path[path.length - 1]]

        if (!lastStar) {
            console.log('no path')
            return
        }

        let bestJumps = 100
        let bestStars = []

        for (let n of lastStar.neighbors) {  
            if ((stars[n].beacon || n === starMapData.orbitStarId) && !skipStars.has(n)) { // only look for paths among stars with beacons
                const nDist = starLog[n].distance

                if (nDist < bestJumps) {
                    bestJumps = nDist
                    bestStars = [n]
                } else if (nDist === bestJumps) {
                    bestStars.push(n)
                }
            }
        }

        if (bestStars.length === 0) {
            noPath = true
        } else if (bestStars.length === 1) {
            path.push(bestStars[0])
        } else {            
            let closestDistance = 0
            let closestStar = null

            for (let b of bestStars) {
                const bStar = stars[b]
                const bDistance = calcDistance(startStar, bStar)

                if (!closestStar || bDistance < closestDistance) {
                    closestDistance = bDistance
                    closestStar = b
                }
            }

            path.push(closestStar)
        }

        bestStars.forEach((s) => skipStars.add(s))
    }

    while (!noPath && path[path.length - 1] !== startId) {
        findNextStep()
    }

    path.reverse()

    return path
}

export class StarMap extends Entity {
    constructor(db, user) {
        super(db, user, null, 'starMap')
    }

    // initData() {
    //     const orbitStar = this.data.stars[this.data.orbitStarId]
    //     if (!orbitStar.beacon) {
    //         setStarBeacon(this.db, this.id, this.data.orbitStarId)
    //     }
    // }

    generateData() {
        generateStarMap(this.db, this.user)
    }

    async setOrbitStar(starId) {
        const { stars } = this.data

        const update = { orbitStarId: starId }

        const theStar = stars[starId]
        theStar.known = true
        theStar.visited = true

        update[`stars/${starId}/known`] = true
        update[`stars/${starId}/visited`] = true

        for (const n of theStar.neighbors) {
            const nStar = stars[n]

            nStar.known = true
            update[`stars/${n}/known`] = true
        }

        this.data.orbitStarId = starId
        
        await this.updateData(update)
    }

    async markStarKnown(starId) {
        const { stars } = this.data

        const update = {}

        const theStar = stars[starId]

        if (!theStar.known) {
            theStar.known = true

            update[`stars/${starId}/known`] = true

            await this.updateData(update)

            return true
        } else {
            return false
        }
    }

    async markRaiders(starId, planetId, rank) {
        const { stars } = this.data

        const update = {}

        const theStar = stars[starId]
        const thePlanet = theStar.planets[planetId]

        thePlanet.raiders = rank || 0

        let starRaiders = false
        for (const p of theStar.planets) {
            if (p.raiders) {
                starRaiders = true
            }
        }

        update[`stars/${starId}/known`] = true
        update[`stars/${starId}/hasRaiders`] = starRaiders
        update[`stars/${starId}/planets/${planetId}/raiders`] = thePlanet.raiders

        await this.updateData(update)

    }

    findPathToStar(endId, startId) {
        return findPathToStar(this.data, endId, startId)
    }
}