import { DateTime } from 'luxon'
import { get, ref } from 'firebase/database';
import { eventList } from '../data/event'
import { sortByKey } from '../lib/arrayUtils'
import { generatePilot } from '../lib/pilot'
import { generateMech } from '../lib/mech'
import { loadTeam } from '../lib/team'
import { attrNames } from './constants'
import { PilotList } from '../components/roster/pilotList';

export const getEventList = (starMap, showMessage) => {
    const testPilotData = {
        name: 'Testy',
        gender: 'NB',
        skills: {
            comm:       5,
            combat:     5,
            repair:     5,
            reaction:   5,
            per:        5,
            pilot:      5
        },
        metrics: {
            energy: 5
        }
    }
    
    const testMechData = {
        make: 'Ford',
        model: 'Fiesta'
    }
    
    const testEventPayload = {
        pilot: testPilotData,
        patient: testPilotData,
        otherPilot: testPilotData,
        otherWorker: testPilotData,
        mech: testMechData,
        slot: 'head',
        planet: {
            name: 'Planet',
            type: 'test'  
        },
        raiderPlanet: {
            name: 'Mongo',
            type: 'test'  
        },
        starMap
    }

    let roomEvents = []

    for (const eventType in eventList) {
        roomEvents.push( ...(eventList[eventType].map((e) => {
            return e(testEventPayload)
        })))
    }

    const eventRows = roomEvents.map((e) => ({
        title: e.title.props.children[1],
        hard: <span className={`color-attr-${e.options[0].attr}`}>{ attrNames[e.options[0].attr] }</span>,
        med: <span className={`color-attr-${e.options[1].attr}`}>{ attrNames[e.options[1].attr] }</span>,
        easy: <span className={`color-attr-${e.options[2].success.attr}`}>{ attrNames[e.options[2].success.attr] }</span>,
        details: <pre>{JSON.stringify({ description: e.description, options: e.options }, null, 2)}</pre>
    }))

    return {
        title: 'EVENTS',
        body: <table cellPadding="4">
            <tbody>
                <tr>
                    <td>EVENT</td>
                    <td>HARD</td>
                    <td>MEDIUM</td>
                    <td>EASY</td>
                    <td>DETAILS</td>
                </tr>
                {eventRows.map((e, i) => (
                    <tr key={i}>
                        <td>{e.title}</td>
                        <td>{e.hard}</td>
                        <td>{e.med}</td>
                        <td>{e.easy}</td>
                        <td><span onClick={() => showMessage({ title: e.title, body: e.details })}>SHOW</span></td>
                    </tr>
                ))}
            </tbody>
        </table>
    }
}

export const getUserList = async (db) => {
    const academyListRef = ref(db, `academy`);
    const academyDocs = await get(academyListRef)
    
    let academies = []

    academyDocs.forEach((a) => {
        const data = a.val()
        academies.push(data)
    })

    academies = sortByKey(academies, 'lastLogin', true)

    return { 
        title: 'ACADEMIES',
        body: (
            <div>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>USER</th>
                            <th>LAST LOGIN</th>
                            <th>TICKS</th>
                        </tr>
                    </thead>
                    <tbody>
                        { academies.map((a, i) => (
                            <tr key={i}>
                                <td>{ a.userName || a.user }</td>
                                <td>{ DateTime.fromMillis(a.lastLogin || a.created).toLocaleString(DateTime.DATETIME_FULL) }</td>
                                <td>{ a.time }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

export const getPilotList = async (db) => {
    const pilotListRef = ref(db, `pilots`);
    const pilotDocs = await get(pilotListRef)
    
    const academyListRef = ref(db, `academy`);
    const academyDocs = await get(academyListRef)
    
    let academies = []

    academyDocs.forEach((a) => {
        const data = a.val()
        academies.push({ ...data, pilots: [] })
    })

    pilotDocs.forEach((p) => {
        const data = p.val()
        for (let key in data) {
            const pilotData = data[key]
            const theAcademy = academies.find((a) => a.user === pilotData.user)
            theAcademy.pilots.push(pilotData)
        }
    })

    return { 
        title: 'PILOTS',
        body: (
            <div>
                { academies.map((a, i) => <div key={i}>
                    <h3>{ a.userName }</h3>
                    <PilotList
                    pilots={ a.pilots }
                    emptySlots={ 0 }
                />
                </div>)}
            </div>
        )
    }
}

export const getRandomPilot = async () => {
    const pilotData = await generatePilot(null, null, { rank: 3, slot: 'leftArm'})

    return { 
        title: 'RANDOM PILOT',
        body: (
            <div>
                <pre>{ JSON.stringify(pilotData, null, 2) }</pre>
            </div>
        )
    }
}

export const getRandomMech = async () => {
    const pilotData = await generateMech(null, null, null, null, 3)

    return { 
        title: 'RANDOM PILOT',
        body: (
            <div>
                <pre>{ JSON.stringify(pilotData, null, 2) }</pre>
            </div>
        )
    }
}

export const getRandomTeam = async () => {
    const team = await loadTeam(null, null, { rank: 3 }, 'sim')

    return { 
        title: 'RANDOM PILOT',
        body: (
            <div>
                <pre>{ JSON.stringify(team.mech.data, null, 2) }</pre>
                <pre>{ JSON.stringify(team.pilots.head.data, null, 2) }</pre>
            </div>
        )
    }
}