import { getPilotRank } from '../../lib/pilot'
import { findPlanet } from '../../lib/starMap'

export const blackNemesis = {
    name: 'The Black Nemesis',
    completeText: `The mystery of the Black Nemesis led deep into the past and light years away. Finally, the advanced prototype Gauntlet was unearthed and returned to service.`,
    modalSize: 'large',
    stages: [
        {
            id: 0,
            location: ['simulator'],
            data: (e) => {
                const p = e.pilot
                
                if (getPilotRank(p) < 2) {
                    return null
                }
                
                return {
                    title: 'THE BLACK NEMESIS: First Contact',
                    description: [
                        `PNAME initiates a multi-position simulator session, setting the parameters to provide a randomly selected environment and opponent. As the screens light up, pze sees the dark green canopy of a dense jungle spread out around pzir. It's terrain that hampers movement somewhat, but with no targets visible pze decides to head toward a nearby river as a defensible position.`,
                        `Before pze is able to reach the river, the simulator jolts as the massive Leviathan is stuck from behind. PNAME turns the machine around, searching for the attacker as damage alerts flash in the cockpit. A section of trees is swaying, so pze unleashes a wide spread of particle bolts in the area. Trees explode into splinters, but nothing is revealed.`,
                        `As PNAME starts a broad scanner sweep, the cockpit shudders again from a hit on the left side. Pivoting as quickly as possible in the damaged Gauntlet, pze sees a strange image on the screens: a sleek Nemesis model Gauntlet, painted head to toe in matte black. It moves in quickly, raising a glowing scythe-like claw to strike a killing blow.`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    options: [
                        { 
                            attr: 'conf',
                            skill: 'combat',
                            dc: 15,
                            level: 'hard',
                            text: `Defend against the attack and try to defeat the Black Nemesis`,
                            success: {
                                text: [
                                    `Trusting to the thick armor of the Leviathan, PNAME braces for the claw's impact. It cuts through the armor but doesn't breach the Ganutlet's core. With the other arm, pze delivers a point blank plasma beam to the body of the Nemesis.`,
                                    `It staggers back, allowing PNAME to get a good scan of the unfamiliar chassis. It takes only a few seconds, but the Nemesis recovers quickly and surges forward again. The screens go black as the simulation ends.`,
                                    `PNAME scrolls through the readout of the scan. There are some strange components that pze doesn't quite recognize. Perhaps there are some records of them in the Factory archives.`
                                ],
                                attr: 'conf',
                                attrValue: 20,
                                nextStage: 2
                            },
                            fail: {
                                text: [
                                    `PNAME throws up the Leviathan's arms to defend against the blade, but the Nemesis drives its attack up below pzis guard and into the Gauntlet's torso. PZE gets only a glimpse of its glowing red eyes before the screens go blank.`,
                                    `There wasn't enough time to learn any more about the mysterious Nemesis. PNAME leaves the simulator frustrated, but vows to return and finish the job.`
                                ],
                                metric: 'hap',
                                metricValue: -5,
                                nextStage: 1
                            }
                        },
                        { 
                            attr: 'reas',
                            skill: 'repair',
                            dc: 15,
                            level: 'hard',
                            text: `Override the simulator to slow the enemy Gauntlet down`,
                            success: {
                                text: [
                                    `A quick gesture from PNAME brings up a virtual control panel. PZE swipes the speed control down, and the Nemesis drops into slow motion.`,
                                    `Slow is not stopped, however, so PNAME quickly trains the scanners on the enemy Gauntlet. The data logs fill rapidly until the claw strikes home and all the screens go blank.`,
                                    `PNAME scrolls through the readout of the scan. There are some strange components that pze doesn't quite recognize. Perhaps there are some records of them in the Factory archives.`
                                ],
                                attr: 'reas',
                                attrValue: 20,
                                nextStage: 2
                            },
                            fail: {
                                text: [
                                    `PNAME tries to simultaneously dodge the attack and invoke the simulator controls, but fails to do either in time. The cockpit lurches one last time as the screens go black.`,
                                    `There wasn't enough time to learn any more about the mysterious Nemesis. PNAME leaves the simulator frustrated, but vows to return and finish the job.`
                                ],
                                metric: 'hap',
                                metricValue: -5,
                                nextStage: 1
                            }
                        },
                        { 
                            text: `Log the encounter for further study`,
                            level: 'easy',
                            success: {
                                text: [
                                    `Seeing that this battle is not going to go pzis way, PNAME makes sure the log is being streamed to pzis personal data store. It's only a matter of few seconds before the final attack strikes home and the screens go black.`,
                                    `After leaving the simulator, pze scrolls through the log, but isn't able to see anything more than the incredible speed of the mysterious Nemesis unit. Maybe it will show up again later.`
                                ],
                                attr: 'wis',
                                attrValue: 5,
                                nextStage: 1
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 1,
            location: ['simulator'],
            hint: 'The Black Nemesis appeared in the simulator once. Maybe it will show up again.',
            data: (e) => {
                const p = e.pilot
                
                return {
                    title: 'THE BLACK NEMESIS: A Second Chance',
                    description: [
                        `Ever since pzis first encounter with the Black Nemesis, PNAME has been trying to recreate the conditions where pze first saw it. Since it was a randomly generated scenario, there is no sure fire way to make it appear again.`,
                        `Just hoping for the random number generator to roll pzis way isn't doing the trick. There must be a better strategy.`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    options: [
                        { 
                            attr: 'fort',
                            skill: 'pilot',
                            dc: 15,
                            level: 'hard',
                            text: `Program an endurance scenario against every Gauntlet in the system`,
                            success: {
                                text: [
                                    `PNAME loads up an endurance scenario that will pit pzir against every Gauntlet type the simulator has available. PZE chooses a matching Nemesis unit for its speed and ability to continue in the face of sustained damage.`,
                                    `Since the goal is to last long enough to see the Black Nemesis, PNAME pilots the leg position, hoping to dodge attacks long enough for it to make an appearance.`,
                                    `After a long series of grueling battles, an alert starts flashing on the screen. PNAME had set the scanners to watch for the Black Nemesis, and they had finally marked their target.`,
                                    `As the scanner continues gathering data, PNAME tries to stay out of range of the Nemesis. Between its raking claw and flashing particle beam, eventually the onslaught is too much and the match is over.`,
                                    `After the fight, PNAME scrolls through the readout of the scan. There are some strange components that pze doesn't quite recognize. Perhaps there are some records of them in the Factory archives.`
                                ],
                                attr: 'fort',
                                attrValue: 20,
                                nextStage: 2
                            },
                            fail: {
                                text: [
                                    `PNAME loads up an edurance scenario that will pit pzir against every Gauntlet type the simulator has available. PZE selects a heavy Quasar, hoping that the enhanced armor repair systems will allow pzir to survive the long battle.`,
                                    `A long parade of Gauntlets appears one after another, each taking its toll on PNAME's armor and stamina. In the end the repair systems aren't enough to keep up with the damage and the scenario ends without any sight of the Black Nemesis.`,
                                    `PNAME stretches pzis shoulders and sighs. "If at first you don't succeed..."`
                                ],
                                metric: 'energy',
                                metricValue: -1 * p.metrics.energy,
                                nextStage: 1
                            }
                        },
                        { 
                            attr: 'hum',
                            skill: 'comm',
                            dc: 15,
                            level: 'hard',
                            text: `Organize the other pilots to help flush it out`,
                            success: {
                                text: [
                                    `While PNAME is determined to unravel the secret of the Black Nemesis, pze has to admit that it may be a bigger job than one person can handle.`,
                                    `After gathering some of the other pilots and telling them about the encounter, they all agree to help out. They start cycling through random scenarios, competing to see who can win the fastest so they can move on to the next attempt.`,
                                    `Despite the odds, when the Nemesis finally shows up, it's in PNAME's simulator. The others rush to the external viewing screens and cheer, some of them logging the battle data from the scanners.`,
                                    `As quickly as the fight began, it's over. PNAME's comrades applaud as pze comes out of the simulator, and hand over the various scanner logs. PNAME scrolls through the readout of the scan. There are some strange components that pze doesn't quite recognize. Perhaps there are some records of them in the Factory archives.`
                                ],
                                attr: 'reas',
                                attrValue: 20,
                                nextStage: 2
                            },
                            fail: {
                                text: [
                                    `PNAME goes to several other pilots and tells them the tale of the Black Nemesis, hoping to get them on board with pzis project. Some of them look at pzir like pze's crazy, while others are just too busy with their own training to take any time out to chase ghosts.`,
                                    `PZE tries a few more random scenarios with no luck. Maybe next time will go better.` 
                                ],
                                metric: 'fame',
                                metricValue: -20,
                                nextStage: 1
                            }
                        },
                        { 
                            attr: 'dar',
                            skill: 'repair',
                            dc: 15,
                            level: 'hard',
                            text: `Hack the simulator and try to dig out the data files`,
                            success: {
                                text: [
                                    `While there are some simulator settings that are available for pilots to tweak, the core data files are strictly off limits both for safety reasons and to prevent cheating.`,
                                    `Since pze doesn't plan to change anything, PNAME figures that there's no safety issue. Besides, the Black Nemesis doesn't seem to be part of the normal simulator package so it shouldn't be against the rules to peek at it.`,
                                    `Ethical issues notwithstanding, the data files are locked down hard and take quite a bit of clandestine work to breach. Once in, it takes even more digging to locate the specific file for the Nemesis.`,
                                    `Finally, PNAME is able to isolate the stored telemetry from the initial encounter. There are some strange components in the Nemesis data that pze doesn't quite recognize. Perhaps there are some records of them in the Factory archives.`
                                ],
                                attr: 'dar',
                                attrValue: 20,
                                nextStage: 2
                            },
                            fail: {
                                text: [
                                    `PNAME decides that it won't hurt anything to take a peek at the core data files, even though they are strictly off limits to pilots.`,
                                    `As pze is engrossed in bypassing the simulator firewall, PNAME hears someone clearing their throat from the open hatch. "Care to explain what you're doing there, spacer?"`,
                                    `PNAME tries to talk their way out of the situation, but the Training Chief isn't interested in excuses. PNAME spends the rest of the day scrubbing the interiors of simulator pods.` 
                                ],
                                metric: 'fame',
                                metricValue: -20,
                                nextStage: 1
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 2,
            location: ['factory'],
            hint: 'Data about the Black Nemesis points to some unique components. Search for more information in the Factory.',
            data: (e) => {
                const p = e.pilot
                const starMap = e.starMap
                
                const planet = findPlanet(starMap, { type: 'jungle', danger: getPilotRank(p) + 1, closest: true })
                
                return {
                    title: 'THE BLACK NEMESIS: Pieces Of The Puzzle',
                    description: [
                        `So far the Black Nemesis has only appeared in the virtual world of the simulator, but PNAME suspects that it exists in the real world as well. Data from the simulator turned up references to some unfamiliar components. Perhaps there is some record of them in the Factory that produces parts for all the Gauntlets.`,
                        `What should PNAME do?`
                    ],
                    pilot: p,
                    planet,
                    options: [
                        { 
                            attr: 'reas',
                            skill: 'per',
                            dc: 15,
                            level: 'hard',
                            text: `Search through the Factory database for components that match the specifications`,
                            success: {
                                text: [
                                    `Using the simulator logs as a reference, PNAME uses a data terminal to search through the thousands of components for something that matches the material strength, power output, and other attributes displayed by the Black Nemesis.`,
                                    `It's a long process, with many circular paths and dead ends, but after many hours of analysis, a pattern begins to emerge. There are several components that are marked UNUSABLE that seem to match up with the ones PNAME is looking for.`,
                                    `Each of the components was rejected for regular use because they were devleoped using a very specific class of unstable xenomaterials that were too rare to be used for standard parts.`,
                                    `The source of the xenomaterials was a planet named ${planet.name}. PNAME makes a note of the planet's coordinates.`
                                ],
                                attr: 'reas',
                                attrValue: 20,
                                markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `PNAME begins digging through the Factory database, looking for any match to the components pze found described in the simulator log. There are many thousands of components on file, and none of them seem to be an exact match, or even close.`,
                                    `After several frustrating hours, pze gives up. Maybe pze'll have better luck later after some rest.`
                                ],
                                metric: 'energy',
                                metricValue: -1 * p.metrics.energy,
                                nextStage: 2
                            }
                        },
                        { 
                            attr: 'emp',
                            skill: 'comm',
                            dc: 15,
                            level: 'hard',
                            text: `Ask the technicians about the mysterious components`,
                            success: {
                                text: [
                                    `The technicians who run the Factory are brilliant, and they keep all the Gauntlets supplied with repair parts at a moment's notice. PNAME spends some time chatting with them, asking if they've seen anything like the component specs pze's looking for.`,
                                    `Most of them shake their heads, and some suggest that the measurements must be off. Finally one grizzled old tech nods as he peers at the logs.`,
                                    `"I think I've seen something like this before," he says, then begins running searches on his datapad. "Ah yes, I knew it!" He hands the datapad to PNAME.`,
                                    `PNAME looks at the schematic, which is marked UNUSABLE in large red letters at the top. "That was a design based on some exotic xenomats. Great performance, but too hard to get enough raw materials to put it in general production. You must have seen a sim of the prototype."`,
                                    `Looking at the highlighted list of materials, PNAME sees the ones the tech is talking about. All of them come from a planet called ${planet.name}. It's a long shot, but perhaps it might be worth a visit.`
                                ],
                                attr: 'emp',
                                attrValue: 20,
                                markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `PNAME walks around the Factory, interrupting technicians and asking them if they know anything about the components in the simulator logs. They glance at the data and shake their heads, quickly returning to work. Soon it becomes clear that the other techincians are avoiding pzir.`,
                                    `Perhaps this wasn't the best approach.` 
                                ],
                                metric: 'fame',
                                metricValue: -20,
                                nextStage: 2
                            }
                        },
                        { 
                            attr: 'prow',
                            skill: 'repair',
                            dc: 15,
                            level: 'hard',
                            text: `Try to recreate the components from scratch`,
                            success: {
                                text: [
                                    `PNAME sits down at one of the 3D prototyping stations and studies the readouts of the sensor logs. PZE searches the templates for something close to what the logs describe and begins tweaking the design to try to match the material strength, power output, and other attributes shown by the Black Nemesis.`,
                                    `After several failed attempts, PNAME starts getting close to the specs pze wants, but is still quite a bit off the mark. As pze gives it one last adjustment before scrapping it, the console lights up with a suggested schematic.`,
                                    `PNAME selects the offered component, and it displays a file marked UNUSABLE across the top in large red letters. Examining the details, pze learns that the high performance component used exotic xenomaterials that were too rare to be viable for general use.`,
                                    `The materials in question are marked as being found on ${planet.name}. It's a long shot, but perhaps it might be worth a visit.`
                                ],
                                attr: 'prow',
                                attrValue: 20,
                                markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `PNAME sits down at one of the 3D prototyping stations and starts trying to build components that match the specs described by the simulator logs. One attempt after another gets close, but not close enough.`,
                                    `Eventually there is a pile of failed components next to the prototyping station. One of the senior technicians comes over and shuts down the machine. "This isn't a toy," she says, and makes PNAME take all the failed components to the recycler.`,
                                    `Maybe there was a better way to go about this.`
                                ],
                                credits: true,
                                creditsValue: -300,
                                nextStage: 2
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 3,
            location: ['planet'],
            hint: `Clues about the Black Nemesis have led to PLANETNAME. Perhaps the answer to the mystery will be found there.`,
            data: (e) => {
                const p = e.pilot
                const planet = e.planet
                
                return {
                    title: 'THE BLACK NEMESIS: Into The Jungle',
                    description: [
                        `The mystery of the Black Nemesis has led PNAME to the surface of ${planet.name}. After clearing out the local dangers along with pzis fellow pilots, pze begins scanning the planet for energy signatures matching the exotic xenomaterials listed in the Facctory schematics.`,
                        `After a few passes, PNAME starts to worry that this might be the wrong place, but then an indicator lights up on the scanner. There's a faint energy signature that seems to match what pze's looking for.`,
                        `Taking a shuttle down to the surface, PNAME notices that the area looks strikingly similar to the environment in the original simulation.`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    planet,
                    options: [
                        { 
                            attr: 'wis',
                            skill: 'per',
                            dc: 15,
                            level: 'hard',
                            text: `Use the Academy ship's sensors to help triangulate the source of the readings`,
                            success: {
                                text: [
                                    `PNAME begins methodically circling the area, using the Acadmey ship's orbital scanners in conjunction with the shuttle's short range sensors. While the vast swath of jungle seems entirely homogenous, an area in the northern part of the area gives slightly stronger readings.`,
                                    `Setting the shuttle down in a nearby clearing, PNAME starts investigating the jungle. Soon pze discovers a large concrete building, overgrown with trees and vines. Massive steel doors stand ajar and pitted with rust.`,
                                    `The bunker is dark inside, and PNAME uses a portable light to investigate. In the center of the building is a large shaft dug deep into the rock below. An elevator straddles the top of the shaft. Clearly this is where the xenomaterials were once mined.`,
                                    `Moving deeper into the building, PNAME finds what appears to be a makeshift living area. Bedding and food containers are strewn about. What at first seems to be a pile of clothing turns out to be the desiccated remains of a human, wearing a familiar (if old fashioned) Academy uniform. Near the corpse's hand is a datapad, its charge depleted but otherwise intact.`,
                                    `Finally, PNAME shines pzis light into the far end of the structure. Between stacks of crates is the kneeling form of a matte black Gauntlet: the Black Nemesis!`,
                                    `A quick search of the crates reveals a cache of xenomaterials, no doubt the ones that were used in the prototype. Between these supplies and the information that hopefully remains in the dormant datapad, PNAME is sure that the Gauntlet can be refurbished and maintained for some time.`,
                                    `PZE hurries back out into the sunlight and signals the Academy ship. The Black Nemesis may not have been the first of a new Gauntlet line, but with some luck and hard work it should be able to see battle once again.`
                                ],
                                attr: 'wis',
                                attrValue: 20,
                                mech: 'blackNemesis',
                                nextStage: 'complete'
                            },
                            fail: {
                                text: [
                                    `PNAME attempts to narrow down the search area with sensor scans, but there's too much atmospheric interference to get a clear signal. PZE sets the shuttle down in a clearing and begins combing through the jungle on foot.`,
                                    `After many sweaty hours and bites from flying creatures of unknown species, PNAME is almost ready to give up. Just as pze is about to head back to the shuttle, pze sees what looks like a large concrete building, overgrown with trees and vines. Massive steel doors stand ajar and pitted with rust.`,
                                    `The bunker is dark inside, and PNAME uses a portable light to investigate. In the center of the building is a large shaft dug deep into the rock below. An elevator straddles the top of the shaft. Clearly this is where the xenomaterials were once mined.`,
                                    `Moving deeper into the building, PNAME finds what appears to be a makeshift living area. Bedding and food containers are strewn about. What at first seems to be a pile of clothing turns out to be the desiccated remains of a human, wearing a familiar (if old fashioned) Academy uniform. Near the corpse's hand is a datapad, its charge depleted but otherwise intact.`,
                                    `Finally, PNAME shines pzis light into the far end of the structure. Between stacks of crates is the kneeling form of a matte black Gauntlet: the Black Nemesis!`,
                                    `A quick search of the crates reveals a cache of xenomaterials, no doubt the ones that were used in the prototype. Between these supplies and the information that hopefully remains in the dormant datapad, PNAME is sure that the Gauntlet can be refurbished and maintained for some time.`,
                                    `PZE hurries back out into the sunlight and signals the Academy ship. The Black Nemesis may not have been the first of a new Gauntlet line, but with some luck and hard work it should be able to see battle once again.`
                                ],
                                mech: 'blackNemesis',
                                nextStage: 'complete'
                            }
                        },
                        { 
                            attr: 'dar',
                            skill: 'pilot',
                            dc: 15,
                            level: 'hard',
                            text: `Fly low over the jungle to search for clues`,
                            success: {
                                text: [
                                    `PNAME brings the shuttle down low, just skimming over the tops of the strange trees. From this height, pze is able to see brief glimpses of the gloomy undercanopy. After several passes back and forth over the area, pze sees something massice and blocky, very much out of place among the other organic shapes of the jungle.`,
                                    `Setting the shuttle down in a nearby clearing, PNAME backtracks toward the mysterious object. Soon pze discovers a large concrete building, overgrown with trees and vines. Massive steel doors stand ajar and pitted with rust.`,
                                    `The bunker is dark inside, and PNAME uses a portable light to investigate. In the center of the building is a large shaft dug deep into the rock below. An elevator straddles the top of the shaft. Clearly this is where the xenomaterials were once mined.`,
                                    `Moving deeper into the building, PNAME finds what appears to be a makeshift living area. Bedding and food containers are strewn about. What at first seems to be a pile of clothing turns out to be the desiccated remains of a human, wearing a familiar (if old fashioned) Academy uniform. Near the corpse's hand is a datapad, its charge depleted but otherwise intact.`,
                                    `Finally, PNAME shines pzis light into the far end of the structure. Between stacks of crates is the kneeling form of a matte black Gauntlet: the Black Nemesis!`,
                                    `A quick search of the crates reveals a cache of xenomaterials, no doubt the ones that were used in the prototype. Between these supplies and the information that hopefully remains in the dormant datapad, PNAME is sure that the Gauntlet can be refurbished and maintained for some time.`,
                                    `PZE hurries back out into the sunlight and signals the Academy ship. The Black Nemesis may not have been the first of a new Gauntlet line, but with some luck and hard work it should be able to see battle once again.`
                                ],
                                attr: 'dar',
                                attrValue: 20,
                                mech: 'blackNemesis',
                                nextStage: 'complete'
                            },
                            fail: {
                                text: [
                                    `PNAME brings the shuttle down low, trying to get as close as possible to the treetops so pze can see what lies beneath. Several times pze misjudges the altitude and clips the tops of the trees, causing warning klaxons to sound in the shuttle cockpit. Rather than risk the shuttle further, pze sets it down in a clearing and begins combing through the jungle on foot.`,
                                    `After many sweaty hours and bites from flying creatures of unknown species, PNAME is almost ready to give up. Just as pze is about to head back to the shuttle, pze sees what looks like a large concrete building, overgrown with trees and vines. Massive steel doors stand ajar and pitted with rust.`,
                                    `The bunker is dark inside, and PNAME uses a portable light to investigate. In the center of the building is a large shaft dug deep into the rock below. An elevator straddles the top of the shaft. Clearly this is where the xenomaterials were once mined.`,
                                    `Moving deeper into the building, PNAME finds what appears to be a makeshift living area. Bedding and food containers are strewn about. What at first seems to be a pile of clothing turns out to be the desiccated remains of a human, wearing a familiar (if old fashioned) Academy uniform. Near the corpse's hand is a datapad, its charge depleted but otherwise intact.`,
                                    `Finally, PNAME shines pzis light into the far end of the structure. Between stacks of crates is the kneeling form of a matte black Gauntlet: the Black Nemesis!`,
                                    `A quick search of the crates reveals a cache of xenomaterials, no doubt the ones that were used in the prototype. Between these supplies and the information that hopefully remains in the dormant datapad, PNAME is sure that the Gauntlet can be refurbished and maintained for some time.`,
                                    `PZE hurries back out into the sunlight and signals the Academy ship. The Black Nemesis may not have been the first of a new Gauntlet line, but with some luck and hard work it should be able to see battle once again.` 
                                ],
                                mech: 'blackNemesis',
                                nextStage: 'complete'
                            }
                        },
                        { 
                            attr: 'fort',
                            skill: 'reaction',
                            dc: 15,
                            level: 'hard',
                            text: `Explore the jungle on foot`,
                            success: {
                                text: [
                                    `PNAME lands the shuttle next to the river that pze remembers from the simulation. Gathering up equipment from the shuttle's emergency locker, pze pulls up an aerial scan of the area and plans out a search grid. While thorough, the search pattern will inevitably take quite a bit of time. PNAME quickly falls into a steady rhythm, taking in the sights and sounds of an alien planet. Midway through the planned search pattern, PNAME discovers a large concrete building, overgrown with trees and vines. Massive steel doors stand ajar and pitted with rust.`,
                                    `The bunker is dark inside, and PNAME uses a portable light to investigate. In the center of the building is a large shaft dug deep into the rock below. An elevator straddles the top of the shaft. Clearly this is where the xenomaterials were once mined.`,
                                    `Moving deeper into the building, PNAME finds what appears to be a makeshift living area. Bedding and food containers are strewn about. What at first seems to be a pile of clothing turns out to be the desiccated remains of a human, wearing a familiar (if old fashioned) Academy uniform. Near the corpse's hand is a datapad, its charge depleted but otherwise intact.`,
                                    `Finally, PNAME shines pzis light into the far end of the structure. Between stacks of crates is the kneeling form of a matte black Gauntlet: the Black Nemesis!`,
                                    `A quick search of the crates reveals a cache of xenomaterials, no doubt the ones that were used in the prototype. Between these supplies and the information that hopefully remains in the dormant datapad, PNAME is sure that the Gauntlet can be refurbished and maintained for some time.`,
                                    `PZE hurries back out into the sunlight and signals the Academy ship. The Black Nemesis may not have been the first of a new Gauntlet line, but with some luck and hard work it should be able to see battle once again.`
                                ],
                                attr: 'fort',
                                attrValue: 20,
                                mech: 'blackNemesis',
                                nextStage: 'complete'
                            },
                            fail: {
                                text: [
                                    `PNAME finds a clearing large enough to land the shuttle in and strikes off into the jungle. Without any real plan or appropriate gear, the quick hike turns into what feels like an endless slog through muddy ravines and thorny vines.`,
                                    `After many sweaty hours and bites from flying creatures of unknown species, PNAME is almost ready to give up. Just as pze is about to head back to the shuttle, pze sees what looks like a large concrete building, overgrown with trees and vines. Massive steel doors stand ajar and pitted with rust.`,
                                    `The bunker is dark inside, and PNAME uses a portable light to investigate. In the center of the building is a large shaft dug deep into the rock below. An elevator straddles the top of the shaft. Clearly this is where the xenomaterials were once mined.`,
                                    `Moving deeper into the building, PNAME finds what appears to be a makeshift living area. Bedding and food containers are strewn about. What at first seems to be a pile of clothing turns out to be the desiccated remains of a human, wearing a familiar (if old fashioned) Academy uniform. Near the corpse's hand is a datapad, its charge depleted but otherwise intact.`,
                                    `Finally, PNAME shines pzis light into the far end of the structure. Between stacks of crates is the kneeling form of a matte black Gauntlet: the Black Nemesis!`,
                                    `A quick search of the crates reveals a cache of xenomaterials, no doubt the ones that were used in the prototype. Between these supplies and the information that hopefully remains in the dormant datapad, PNAME is sure that the Gauntlet can be refurbished and maintained for some time.`,
                                    `PZE hurries back out into the sunlight and signals the Academy ship. The Black Nemesis may not have been the first of a new Gauntlet line, but with some luck and hard work it should be able to see battle once again.`
                                ],
                                mech: 'blackNemesis',
                                nextStage: 'complete'
                            }
                        }
                    ]
                }        
            }
        }
    ]
}
