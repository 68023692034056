import React from 'react'
import { Icon } from 'semantic-ui-react';

export function MessageLogHeader ({ team, inCombat, combatOver, messageView, speed, timeFactor, setMessageView, resetBattle, startTick, startCombat }) {
    if (inCombat) {
        if (combatOver) {
            switch (messageView) {
                case 'log':
                    return (
                        <div className="iconBtn" onClick={() => setMessageView('rewards')}>REWARDS</div>
                    )
                case 'rewards':
                    return (
                        <div className="iconBtn" onClick={() => setMessageView('stats')}>STATS</div>
                    )
                case 'stats':
                    return (
                        // <Link className="iconBtn" to="/academy">RETURN</Link>
                        <div className="iconBtn" onClick={() => resetBattle()}>CONTINUE</div>
                    )
                default:
            }
        } else {
            // speed controls during combat
            return [
                timeFactor > 1 && <div key={0} className="timeWarning animate-flicker">WARNING</div>,
                <Icon key={1} 
                    name="pause" 
                    className={`iconBtn battleControl ${speed === 'stop' ? 'active' : ''}`} 
                    onClick={() => startTick('stop')}/>,
                <Icon key={2} 
                    name="play" 
                    className={`iconBtn battleControl ${speed === 'normal' ? 'active' : ''}`} 
                    onClick={() => startTick('normal')}/>,
                <Icon key={3} 
                    name="forward" 
                    className={`iconBtn battleControl ${speed === 'fast' ? 'active' : ''}`} 
                    onClick={() => startTick('fast')}/>,
                <Icon key={4} 
                    name="fast forward" 
                    className={`iconBtn battleControl ${speed === 'ultra' ? 'active' : ''}`} 
                    onClick={() => startTick('ultra')}/>,
                timeFactor > 1 && <div key={5} className="timeWarning animate-flicker">WARNING</div>
            ]
        }
    } else {
        // start button before combat
        return (
            <div className={`iconBtn battleControl ${team ? '' : 'disabled'}`} onClick={() => startCombat()}>START</div>
        )    
    }
}
