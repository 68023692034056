import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'semantic-ui-react'

export class MessageModal extends Component {
    render() {
        const { message, isOpen } = this.props

        return message ? <Modal
        className="messageModal"
        size={ message.size || 'small' }
        open={ isOpen }
        onClose={() => {
            if (!message.destination) {
                // don't allow closing without the button for tutorials
                this.props.toggleModal()
            }
        }}>
        <Modal.Header><h1 className="ui">{ message.title }</h1></Modal.Header>
        <Modal.Content>
            { message.body }
        </Modal.Content>
        <Modal.Actions>
        {/* <Button negative onClick={() => toggleModal()}>
            No
        </Button> */}
        { message.destination ? (
            <Button positive as={ Link } to={ message.destination } onClick={() => this.props.toggleModal()}>
                { message.buttonText || 'Continue' }
            </Button>        
        ) : (
            <Button positive onClick={() => this.props.toggleModal()}>
                Close
            </Button>
        )
        }
        </Modal.Actions>
    </Modal> : null
    }
}