import React, { useReducer, useEffect } from 'react'
import { Button, Modal, Grid } from 'semantic-ui-react'
import { contents } from '../../data/guide/contents'

const getSection = (section) => {
    return contents.find((s) => s.key === section)
}

const getItem = (section, item) => {
    if (!item) {
        return null
    } else {
        const theSection = getSection(section)
        const theItem = theSection.items.find((i) => i.key === item)
        return theItem
    }
}

function reducer(state, action) {
    switch(action.type) {
        case 'selectSection': {
            const sectionKey = action.data.section
            const section = getSection(sectionKey)
            return { ...state, sectionKey, section, itemKey: '', item: null }
        }
        case 'selectItem': {
            const sectionKey = action.data.section
            const section = getSection(sectionKey)

            const newState = { ...state }

            if (sectionKey !== state.sectionKey) {
                newState.sectionKey = sectionKey
                newState.section = section
            }

            const itemKey = action.data.item
            const item = getItem(sectionKey, itemKey)

            newState.itemKey = itemKey
            newState.item = item

            return newState
        }
        case 'setIsOpen':
            return { ...state, ...action.data }
        default:
            throw new Error()
    }
}
  
export function GuideModal(props) {
    
    const getInitalState = () => ({ 
        sectionKey: props.selectedGuide.section,
        section: getSection(props.selectedGuide.section),
        itemKey: props.selectedGuide.item,
        item: getItem(props.selectedGuide.section, props.selectedGuide.item),
        isOpen: false
    })

    const [state, dispatch] = useReducer(reducer, getInitalState());

    function disp(type, data) {
      dispatch({ type, data })
    }

    useEffect(() => {
        if (props.selectedGuide.item) {
            disp('selectItem', props.selectedGuide)
        } else {
            disp('selectSection', props.selectedGuide)
        }
    }, [ props.selectedGuide, props.selectedGuide.section, props.selectedGuide.item ])

    useEffect(() => {
        disp('setIsOpen', { isOpen: props.isOpen })
    }, [ props.isOpen ])

    const closeModal = () => {
        disp('selectSection', '')
        props.toggleModal()
    }

    if (state.sectionKey) {
        return (
            <Modal
                className="guideModal"
                size={ 'large' }
                open={ state.isOpen }
                onClose={() => closeModal()}>
                <Modal.Header><h1 className="ui">Briefings</h1></Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={4}>
                            <div className="guideMenu">
                            { contents.map((s, k1) => [
                                <div className={`sectionTab ${ state.sectionKey === s.key ? 'active' : ''}`}
                                    key={ k1 }
                                    onClick={() => {
                                        props.playSoundEffect('buttonPress')
                                        disp('selectSection', { section: s.key })
                                    }}
                                >{ s.title }</div>,
                                <div key={`${k1}-items`}>
                                    { state.sectionKey === s.key && s.items.map((i, k2) => (
                                        <div className={`itemTab ${ state.itemKey === i.key ? 'active' : ''}`}
                                            key={ k2 }
                                            onClick={ () => {
                                                props.playSoundEffect('buttonPress')
                                                disp('selectItem', { section: s.key, item: i.key })
                                            }}
                                        >{i.title}</div>
                                    ))}
                                </div>
                            ]
                        )}
                            </div>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <div className="guideHeader">{ state.item ? state.item.title : state.section.title }</div>
                            { state.item ? state.item.body : state.section.body}
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => closeModal()}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    } else {
        return null
    }
}