import React from 'react'
import { Icon, Button } from 'semantic-ui-react'
import { Link } from "react-router-dom";

export function PlanetCleared({ bonuses, playSoundEffect }) {
    return (
        <div className="planetCleared">
            <h2>
                PLANET CLEARED
            </h2>
            {/* { bonuses.danger && <><div className="rewardRow">
                <div className="rewardLabel">
                    <Icon name="warning sign"/> Planetary Danger Level
                </div>
                <div className="rewardValue">
                    { bonuses.danger } (-1)
                </div>
            </div></> } */}
            <div className="rewardRow">
                <div className="rewardLabel">
                    <Icon name="gem outline"/> Xenomaterials
                </div>
                <div className="rewardValue">
                    +{ bonuses.xenos }
                </div>
            </div>
            <div className="rewardRow">
                <div className="rewardLabel">
                    <Icon name="chess"/> Expertise
                </div>
                <div className="rewardValue">
                    +{ bonuses.xp }
                </div>
            </div>
            { bonuses.beacon && <><div className="rewardRow">
                <div className="rewardLabel">
                    <Icon name="flag"/> Navigation Beacon Placed
                </div>
            </div></> }
            <Button className="returnBtn" as={Link} to={'/academy'}>RETURN</Button>
        </div>
    )
}