import React, { useState, useEffect } from 'react'
import { Button, Modal, Icon, Grid } from 'semantic-ui-react'
import { camelCaseToTitleCase } from '../../lib/textUtils';
import { getUpgradeIcon } from '../../lib/academy';

const messages = {
    info: {
        title: 'Upgrades',
        body: <div>
            <p>
                Upgrade the Academy ship.
            </p>
        </div>
    }
}

const upgradeTypes = [
    {
        name: 'barracks',
        notes: 'Each upgrade adds 5 more beds to the Barracks.',
        baseCost: 5,
        max: 5
    },
    {
        name: 'hangar',
        baseCost: 5,
        notes: 'Each upgrade adds space for 2 more Gauntlets to the Hangar.'
    },
    {
        name: 'launchBays',
        notes: 'Each additional Launch Bay allows you to field one more team.',
        baseCost: 10,
        max: 4
    },
    {
        name: 'repairBays',
        notes: 'Each additional Repair Bay allows an extra Gauntlet to be repaired at the same time.',
        baseCost: 10,
        max: 2
    },
    {
        name: 'infirmary',
        notes: 'Each upgrade adds space for one more patient in the Infirmary.',
        baseCost: 10,
        max: 2
    },
    {
        name: 'simulator',
        notes: 'Each upgrade adds an extra pod to the Simulator.',
        baseCost: 10,
        max: 2
    },
    {
        name: 'recRoom',
        notes: 'Each upgrade adds space for one extra pilot to use the Rec Room.',
        baseCost: 10,
        max: 2
    },
    {
        name: 'factory',
        notes: 'Each upgrade adds space for one extra pilot to work in the Factory.',
        baseCost: 10,
        max: 2
    },
    {
        name: 'jumpEngines',
        notes: 'Each upgrade to the Jump Engines reduces the Resource cost of a single jump by 100.',
        baseCost: 20,
        max: 2
    },
    {
        name: 'repairTools',
        notes: 'Each Repair Tools upgrade increases the efficiency of workers in the Repair Bay by 10%.',
        baseCost: 20,
        max: 3
    },
    {
        name: 'resourceStorage',
        notes: 'Each Resource Storage upgrade increases the maximum resource level by 10000.',
        baseCost: 10,
        max: 5
    },
]

export function UpgradesModal({ db, user, isOpen, toggleModal, academy, showMessage, playSoundEffect }) {
    const [currNotes, setCurrNotes] = useState({ title: '', body: '' })
    const [available, setAvailable] = useState(academy?.data?.xenos || 0)
    const [upgrades, setUpgrades] = useState(academy?.data?.upgrades || {})
  
    useEffect(() => {
        if (academy?.data) {
            setUpgrades(academy.data.upgrades || {})
            setAvailable(academy.data.xenos || 0)
        }
    }, [ academy?.data ])

    const makePurchase = (type, price) => {
        console.log({ type, price })
        
        playSoundEffect('success')

        upgrades[type] = upgrades[type] ? upgrades[type] + 1 : 1

        const payload = {
            upgrades,
            xenos: academy.data.xenos - price
        }
        
        console.log(payload)
        
        academy.updateData(payload)
        
        setUpgrades(upgrades)
        setAvailable(available - price)
    }

    const canAfford = (price) => {
        return academy.data.xenos >= price
    }

    return academy && academy.data ? (
        <Modal
            className="upgradesModal"
            size="small"
            open={isOpen}
            onClose={() => {
                toggleModal()
            }}>
            <Modal.Header>
                <h1 className="ui"><Icon className="iconBtn floatRight" name="question circle" onClick={() => showMessage(messages.info)}/><Icon name="angle double up"/>UPGRADES</h1>
            </Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Column width={10}>
                        <table className="upgradesTable">
                            <tbody>
                                { upgradeTypes.map((u, i) => {
                                    const currLevel = upgrades[u.name] || 0
                                    const nextLevel = currLevel + 1
                                    const price = u.baseCost * nextLevel

                                    return (
                                    <tr key={i} className="upgradeInfo" onMouseEnter={() => setCurrNotes({ name: u.name, title: camelCaseToTitleCase(u.name), body: u.notes }) }>                             
                                        <td className="upgradeName">
                                            {getUpgradeIcon(u.name)}{ camelCaseToTitleCase(u.name) }
                                        </td>
                                        <td className="upgradeCount">
                                            { currLevel }{ u.max ? `/${u.max}` : ''}
                                        </td>
                                        <td className="upgradePrice">
                                            <Icon name="gem outline"/><div className="upgradePriceValue">{ price }</div>
                                        </td>
                                        <td className="upgradePurchase">
                                            { (!u.max || currLevel < u.max) && <Button className={ canAfford(price) ? '' : 'disabled' } onClick={() => makePurchase(u.name, price)}>Purchase</Button> }
                                        </td>
                                    </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <div className="upgradeNotes">
                            <div className="upgradeNotesHeader">
                                {getUpgradeIcon(currNotes.name)}{ currNotes.title }
                            </div>
                            <div className="upgradeNotesBody">
                                { currNotes.body }
                            </div>
                            <div className="availableXenos">
                                Available: <Icon name="gem outline"/>{ available }
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>                
            </Modal.Content>
            <Modal.Actions>
            {/* <Button negative onClick={() => toggleModal()}>
                No
            </Button> */}
            <Button positive onClick={() => toggleModal()}>
                Close
            </Button>
            </Modal.Actions>
        </Modal>
     ) : null
}