import React from 'react';
import { Container, Grid } from 'semantic-ui-react'
import { MechView } from '../components/mechView'
import { Kaiju, generateKaiju } from '../lib/kaiju'
import { doPilotAction } from '../lib/pilotAction'
import { doKaijuAction } from '../lib/kaijuAction'
import { loadTeam } from '../lib/team'
import { applyStatsToPilot, getCommChatter } from '../lib/pilot'
import { findPlanet, setStarBeacon } from '../lib/starMap'
import { slotTypes, slotClass, relPairs } from '../lib/constants'
import { storyList } from '../data/story'
import { Loading } from '../components/loading'
import { TeamList } from '../components/roster/teamList'
import HealthBar from '../components/healthBar'
import { PlanetCleared } from '../components/battle/planetCleared'
import { TeamCombatView } from '../components/battle/teamCombatView'
import { MessageLogHeader } from '../components/battle/messageLogHeader'
import { MessageLog } from '../components/battle/messageLog'
import { StatsDisplay } from '../components/battle/statsDisplay'
import { RewardsDisplay } from '../components/battle/rewardsDisplay'
import { TitleCard } from '../components/battle/titleCard'
import { camelCaseToTitleCase } from '../lib/textUtils';
import { checkRoll, getRandomItem, randInt, oneIn } from '../lib/mathUtils';
  
export default class BattleView extends React.Component {
    speedValue = {
        stop: 0,
        normal: 250,
        fast: 100,
        ultra: 10
    }

    stats = {}
    metrics = {}
    progress = {}
    rewards = {}
    bonuses = {}

    pilotsInvolved = {}

    doingActions = false

    constructor(props) {
        super(props);

        // Don't call this.setState() here!
        this.state = { 
            initialized: false,
            inCombat: false,
            combatOver: false,
            messageView: 'log',
            team: null,
            mech: null,
            star: null,
            planet: null,
            enemies: [],
            enemy: null,
            planetCleared: false,
            messages: [],
            comms: [],
            tick: 0,
            timeFactor: 1,
            speed: 'normal',
            tickVar: null,
            titleStage: 0,
            recall: false,
            update: Date.now()
        };
    }

    async componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/academy')
        } else {
            const { starId, planetId } = this.props.location.state

            const mapLoadInterval = setInterval(() => {
                if (this.props.starMap?.data) {
                    const star = this.props.starMap.data.stars[starId]
                    const planet = star.planets[planetId]

                    clearInterval(mapLoadInterval)

                    this.setState({ star, planet }, () => this.generateEnemies())
                }
            }, 100)

            this.props.setMusicTrack("/assets/audio/World-of-Automatons_Looping.mp3")

            this.setState({ speed: this.props.academy.data.settings.battleSpeed })
        }
    }

    async generateEnemies() {
        const { planet } = this.state        
        
        // if (planet.danger === 0) {
        //     this.props.history.push('/academy')
        //     return
        // }

        const raiders = planet.raiders

        const numEnemies = planet.raiders ? 3 : planet.danger || 1 ? randInt(1, 3) : 0

        const enemies = []

        for (let i = 0; i < numEnemies; i++) {
            const minRank = Math.max(1, planet.danger - 2)

            // generate a random kaiju
            // always generate at least one at the planet danger level
            const maxRank = raiders || planet.danger
            const rank = i === 0 ? maxRank : randInt(minRank, maxRank)

            let enemyData = null
            let enemyObj = null

            if (raiders) {
                enemyObj = await loadTeam(null, null, { rank }, 'sim')
                enemyData = enemyObj.mech.data 
                enemyData.id = i
            } else {
                enemyData = generateKaiju(rank, planet.type)
                enemyData.id = i

                enemyObj = new Kaiju(enemyData)
            }
    


            enemies.push({
                data: enemyData,
                obj: enemyObj,
                defeated: false,
                teamsFought: [],
                id: i
            })
            
        }  
        
        this.setState({ enemies, enemy: enemies[0].obj })
    }

    selectEnemy(index) {
        this.setState({ enemy: this.state.enemies[index].obj })
    }

    getEnemyName() {
        const { enemy } = this.state

        if (enemy.data) {
            return camelCaseToTitleCase(enemy.data.name)
        } else {
            return camelCaseToTitleCase(enemy.mech.data.name)
        }
    }

    checkForEvents() {
        const { starId, planetId } = this.props.location.state

        const planetCode = `${starId}-${planetId}`

        const stories = this.props.academy.data.stories || {}

        const planetStories = []

        for (let storyId in stories) {
            const theStory = { ...stories[storyId], storyId }

            if (theStory.location.includes('planet') && theStory.planet?.code === planetCode && this.pilotsInvolved[theStory.pilotId]) {
                planetStories.push(theStory)
            }
        }

        const activeStory = planetStories[0]

        const thePlanet = findPlanet(this.props.starMap.data, { code: this.state.planet.code })

        if (activeStory) {
            const pilot = this.pilotsInvolved[activeStory.pilotId]
            
            const teammates = []
            for (const p in this.pilotsInvolved) {
                const t = this.pilotsInvolved[p]
                if (t.team === pilot.team && t.id !== pilot.id) {
                    teammates.push(t)
                }
            }

            const event = { 
                location: 'planet',
                planet: thePlanet,
                storyData: activeStory.storyData,
                pilot,
                teammates
            }

            const theStory = storyList[activeStory.storyId]
            const storyData = theStory.stages[activeStory.stageId]
            const eventData = storyData.data(event)

            if (eventData) {
                const eventPayload = {
                    ...event,
                    id: activeStory.storyId,
                    name: theStory.name,
                    modalSize: theStory.modalSize,
                    completeText: theStory.completeText,
                    isStory: true,
                    data: eventData
                }
                this.props.showEvent(eventPayload)
            }
        } else if (oneIn(5)) {
            const eventPilotId = getRandomItem(Object.keys(this.pilotsInvolved))
            const eventPilot = this.pilotsInvolved[eventPilotId]

            const event = { 
                location: 'planet',
                planet: thePlanet,
                pilot: eventPilot,
                starMap: this.props.starMap.data,
                mech: this.state.mech
            }

            this.props.showEvent(event)
        }
    }

    resetBattle() {
        const { db, user } = this.props
        // const { starMap } = this.props
        const { enemies } = this.state
        const { starId } = this.props.location.state
        const { planetId } = this.props.location.state

        let theEnemy = this.getSelectedEnemy()
        theEnemy.teamsFought.push(this.state.team.number)

        if (theEnemy.defeated) {
            theEnemy = enemies.find((e) => !e.defeated)
        }
        
        if (theEnemy && !theEnemy.defeated) {
            if (theEnemy.obj.calculateStats) {
                theEnemy.obj.calculateStats()
            } else {
                theEnemy.obj.resetPilotTimers()
            }
        }

        this.stats = {}
        this.metrics = {}
        this.progress = {}
        this.rewards = {}
    
        const planetCleared = !theEnemy

        if (planetCleared) {
            let maxEnemyRank = 0

            for (const e of enemies) {
                maxEnemyRank = Math.max(maxEnemyRank, e.data.rank)
            }

            this.bonuses = {
                xp: maxEnemyRank * 10,
                xenos: maxEnemyRank
            } 

            /** removing changing planet danger, not sure if it serves any useful purpose */
            // don't let planet danger go to 0
            // const currDanger = this.state.planet.danger
            // const newDanger = Math.max(currDanger - 1, 1)

            if (!this.state.star.beacon) {
                // backfill old planets with no maxDanger field
                setStarBeacon(db, user, starId)
                this.bonuses.beacon = true
            }

            if (this.state.planet.raiders) {
                // clear out the raiders
                this.props.starMap.markRaiders(starId, planetId, 0)

                console.log('need raider loot')
            }

            // if (newDanger !== currDanger) {
            //     this.bonuses.danger = newDanger
            //     setPlanetDanger(db, user, starId, planetId, newDanger)
            // }

            for (let pilotId in this.pilotsInvolved) {
                const pilotData = this.pilotsInvolved[pilotId]
                pilotData.id = pilotId
                const metrics = { xp: this.bonuses.xp }
                applyStatsToPilot(db, user, pilotData, null, metrics)
            }

            this.props.academy.addXenos(this.bonuses.xenos)

            this.checkForEvents()
        }

        const newState = {
            inCombat: false,
            combatOver: false,
            messageView: 'log',
            tick: 0,
            timeFactor: 1,
            speed: this.props.academy.data.settings.battleSpeed,
            titleStage: 0,
            messages: [],
            comms: [],
            enemy: theEnemy ? theEnemy.obj : null,
            team: null,
            mech: null,
            recall: false,
            planetCleared
        }

        this.setState(newState)
    }

    getSelectedEnemy() {
        const { enemies, enemy } = this.state

        return enemies.find((e) => e.id === (enemy.data?.type === 'kaiju' ? enemy.data.id : enemy.mech.data.id))
    }

    componentWillUnmount() {
        if (this.state.tickVar && typeof this.state.tickVar === 'function') {
            clearInterval(this.state.tickVar)
        }
    }

    recallTeam() {
        this.setState({ recall: true })
    }

    startTick(speedVal = this.props.academy.data.settings.battleSpeed) {
        this.props.playSoundEffect('buttonPress')

        const thisClass = this
        const speed = this.speedValue[speedVal]

        if (this.state.tickVar) {
            clearInterval(this.state.tickVar)
        }

        let tickVar = null

        if (speed > 0) {
            tickVar = setInterval(async () => {
                const tick = thisClass.state.tick + 1

                // if the battle has gone on too long, make it more deadly
                let { timeFactor } = this.state

                if (tick > 300) {
                    timeFactor = 1 + (Math.ceil((tick - 300) / 100) * 0.2)
                    if (timeFactor !== this.state.timeFactor) {
                        this.props.setMusicSpeed(timeFactor)
                    }
                }

                if (!this.doingActions) {
                    this.setState({ tick, timeFactor }, () => this.doActions())
                }
            }, speed)
        }

        this.setState({ tickVar, speed: speedVal })
    }

    addStats(pilotId, stat, value) {
        if (!this.stats[pilotId]) {
            this.stats[pilotId] = {}
        }

        const pilotStats = this.stats[pilotId]
        if (!pilotStats[stat]) {
            pilotStats[stat] = value
        } else {
            pilotStats[stat] += value
        }
    }

    addMechStats(stat, value) {
        if (!this.stats.mech) {
            this.stats.mech = {}
        }

        const mechStats = this.stats.mech

        if (!mechStats[stat]) {
            mechStats[stat] = value
        } else {
            mechStats[stat] += value
        }
    }

    startCombat() {
        const avgRel = this.state.team.getAverageRelationship()
        const avgHap = this.state.team.getAverageHappiness()
        const avgMood = ((avgRel - 50) + (avgHap - 50)) / 2
        const moodFactor = 1 + (avgMood * 0.001)
        
        this.setState({
            tick: 0,
            inCombat: true,
            moodFactor
        }, () => this.startTick())
    }

    addComm(pilot, message) {
        const { comms } = this.state

        comms.unshift({ 
            name: pilot.data.name,
            slot: pilot.slot,
            message: getCommChatter(message)
        })

        this.setState({ comms })
    }

    isBattleOver(retreat = false) {
        const { messages, team, enemy, enemies } = this.state

        let message = ''
        const mech = team.mech

        let enemyDead = false
        let mechDead = false
        let theEnemy = null

        const newEnemies = [ ...enemies ]

        if (enemy.data) {
            // kaiju
            enemyDead = enemy.data.armor.body === 0
            theEnemy = newEnemies.find((e) => e.id === enemy.data.id)
        } else {
            // mech
            enemyDead = enemy.mech.data.armor.body === 0
            theEnemy = newEnemies.find((e) => e.id === enemy.mech.data.id)
        }

        let outcome  = ''
        let metricFactor = 0

        if (this.state.recall) {
            message = 'The team was recalled!'
            outcome = 'recalls'
            metricFactor = 1
            this.props.playSoundEffect('lose')
        } else if (mechDead && enemyDead) {
            message = 'Both are defeated!'
            outcome = 'draws'
            metricFactor = 1.5
            theEnemy.defeated = true
            this.props.playSoundEffect('kaboom')
        } else if (mechDead || retreat) {
            message = `${this.getEnemyName()} is victorious!`
            outcome = 'losses'
            metricFactor = 1
            this.props.playSoundEffect('lose')
        } else if (enemyDead) {
            message = `Team ${team.number} is victorious!`
            outcome = 'wins'
            metricFactor = 2
            theEnemy.defeated = true
            this.props.playSoundEffect('win')
        }

        if (outcome === 'draws' || outcome === 'recalls') {
            // reset timers for next fight
            if (enemy.calculateStats) {
                enemy.calculateStats() 
            } else if (enemy.mech) {
                const mechData = enemy.mech.data
                for (const slot of slotTypes) {
                    enemy.pilots[slot].calculateSpeed(mechData)
                }
            }            
        }

        if (outcome) {
            this.props.setMusicSpeed(1.0)

            let trainingFactor = 1
            if (mech.status.trainingBonus) {
                trainingFactor = 1 + (0.2 * mech.status.trainingBonus)
            }

            const leader = this.state.team.pilots.head.data
            const leadershipBonus = Math.ceil(leader.attributes.conf / 2) + leader.skills.comm
            const leadershipResult = checkRoll(15, leadershipBonus)
        
            let hapValue = 1

            switch(outcome) {
                case 'wins': {        
                    if (leadershipResult.crit) {
                        hapValue += 2
                    } else if (leadershipResult.success) {
                        hapValue += 1
                    }    
                    break
                }
                case 'draws': {        
                    if (leadershipResult.crit) {
                        hapValue += 1
                    }    
                    break
                }
                case 'losses': {        
                    if (leadershipResult.success) {
                        hapValue -= 1
                    } else {
                        hapValue -= 2
                    }
                    break
                }
                case 'recalls': {        
                    hapValue = 0
                    break
                }
                default:
            }
    
            this.rewards = this.calculateRewards(outcome, leadershipResult)
            const enemyRank = enemy.data ? enemy.data.rank : enemy.mech.data.rank

            for (const slot of slotTypes) {
                const pilot = this.state.team.pilots[slot]
                const pilotId = pilot.id

                // calculate and apply final stats
                this.addStats(pilotId, outcome, 1)
                this.addStats(pilotId, `${slotClass[slot]}Sorties`, 1)
                if (outcome === 'wins') {
                    this.addStats(pilotId, `${slotClass[slot]}Wins`, 1)
                }
                
                const stats = this.stats[pilotId]

                // calculate and apply metrics and progress
                let fameStat = 0

                this.metrics[pilotId] = {
                    xp: Math.ceil(10 * enemyRank * metricFactor * trainingFactor)
                }

                let progressAttr = ''

                switch (slotClass[slot]) {
                    case 'head': {
                        fameStat = (stats.bolstered || 0) + ((stats.targetsFound || 0) * 10)
                        progressAttr = getRandomItem(['hum', 'reas', 'emp', 'conf'])
                        break
                    }
                    case 'arm': {
                        fameStat = (stats.damageDone || 0) + ((stats.attacksBlocked || 0) * 5) + ((stats.perfectBlocks || 0) * 5)
                        progressAttr = getRandomItem(['prow', 'dar', 'fort', 'conf', 'wis'])
                        break
                    }
                    case 'leg': {
                        fameStat = (stats.damageRepaired || 0) + (stats.damageEvaded || 0) + ((stats.attacksDodged || 0) * 5) + ((stats.perfectDodges || 0) * 5)
                        progressAttr = getRandomItem(['hum', 'reas', 'wis', 'fort', 'dar'])
                        break
                    }
                    default:
                }

                this.progress[pilot.id] = {
                    attr: progressAttr,
                    value: Math.ceil(10 * enemyRank * metricFactor * trainingFactor)
                }

                this.metrics[pilotId].fame = Math.ceil(fameStat / 20) * metricFactor

                let pilotHap = hapValue

                if ((retreat || this.state.recall) && (pilot.data.quirks.arrogant || pilot.data.quirks.reckless || pilot.data.quirks.stubborn)) {
                    if (!leadershipResult.success) {
                        pilotHap--
                        this.addComm(pilot, `retreat`)
                    }
                }

                this.metrics[pilotId].hap = pilotHap

                pilot.applyStats(stats, this.metrics[pilotId], this.progress[pilotId], this.rewards.relationships[pilotId], { maxRel: 70, minRel: 30, lastBattle: this.props.academy.time })

                this.pilotsInvolved[pilot.id] = pilot.data 
            }
    
            this.addMechStats('sorties', 1)
            this.addMechStats(outcome, 1)

            mech.applyStats(this.stats.mech)

            this.props.academy.addCredits(this.rewards.credits)

            messages.unshift({
                source: 'end',
                text: message
            })
            this.startTick('stop')
            this.setState({
                combatOver: true,
                enemies: newEnemies
            })

            team.cleanup()
            
            return true
        } else {
            return false
        }
    }

    async doActions() {
        if (!this.state.inCombat) {
            return
        }

        this.doingActions = true 

        const { messages, team, enemy, tick, timeFactor, moodFactor } = this.state

        const addStatsFn = this.addStats.bind(this)
        const addCommFn = this.addComm.bind(this)

        const pilots = team.pilots
        let retreat = false

        if (!this.isBattleOver()) {
            if (enemy.data?.type === 'kaiju') {
                if (tick >= enemy.nextAction) {
                    enemy.incrementNextAction()
                    const enemyMessage = await doKaijuAction(enemy, team, tick, timeFactor, addStatsFn, addCommFn)
                    messages.unshift(enemyMessage)
                }
            } else {
                for (const slot of slotTypes) {
                    const pilot = enemy.pilots[slot]
    
                    if (tick >= pilot.nextAction && !retreat) {
                        pilot.incrementNextAction()
                        pilot.incrementNextSpecial()
                        const pilotMessage = await doPilotAction(pilot, enemy, team, tick, timeFactor, 1, addStatsFn, addCommFn, this.props.playSoundEffect, this.props.academy, true)
                        pilotMessage.source = 'enemy'
                        messages.unshift(pilotMessage)
    
                        if (pilotMessage.retreat) {
                            retreat = true
                            this.isBattleOver(true)
                        }
                    }
                }
            }

            for (const slot of slotTypes) {
                const pilot = pilots[slot]

                if (tick >= pilot.nextAction && !retreat) {
                    pilot.incrementNextAction()
                    pilot.incrementNextSpecial()
                    const pilotMessage = await doPilotAction(pilot, team, enemy, tick, timeFactor, moodFactor, addStatsFn, addCommFn, this.props.playSoundEffect, this.props.academy)
                    messages.unshift(pilotMessage)

                    if (pilotMessage.retreat) {
                        retreat = true
                        this.isBattleOver(true)
                    }
                }
            }
        }
   
        this.doingActions = false 

        this.setState({ messages })
    }

    addMessage(message) {
        const { messages } = this.state

        messages.unshift(message)

        this.setState({ messages })
    }

    applyModule(module) {
        if (module) {
            const theMech = this.state.team.mech

            console.log('applying modules to mech')
            console.log(theMech.status)

            const [moduleType, moduleRank] = module.split('-')

            console.log({ moduleType, moduleRank })

            this.props.academy.removeModule(moduleType, moduleRank)

            switch(moduleType) {
                case 'ablativeArmor':
                    theMech.applyStatus('ablativeArmor', 50 * moduleRank)
                    break
                case 'hydraulicCompressor':
                    theMech.applyStatus('speedBoost', moduleRank)
                    break
                case 'crystalMatrix':
                    theMech.applyStatus('computerBonus', moduleRank)
                    break
                case 'plasmaCharger':
                    theMech.applyStatus('weaponBoost', moduleRank)
                    break
                case 'neuralLink':
                    theMech.applyStatus('trainingBonus', moduleRank)
                    break
                default:
            }

            console.log(theMech.status)
        }
    }

    async selectTeam(teamData, mechModule) {
        const { db, user } = this.props

        const teamObj = await loadTeam(db, user, teamData, 'combat')

        this.setState({ 
            team: teamObj 
        }, () => this.applyModule(mechModule))

        const titleInterval = setInterval(() => {
            if (this.state.titleStage === 4) {
                if (!this.state.inCombat) {
                    this.startCombat()
                }

                clearInterval(titleInterval)
            }
            this.setState({ titleStage: this.state.titleStage + 1})
        }, 750)
    }

    setMessageView(messageView) {
        this.setState({ messageView })
    }

    calculateRewards(outcome, leadershipResult) {
        let credits = 0
        const relationships = {}
        const enemyRank = this.state.enemy.data?.rank || this.state.enemy.mech.data.rank
        let relValue = 1

        switch(outcome) {
            case 'wins': {
                credits = 500 * enemyRank   
                if (leadershipResult.crit) {
                    relValue += 2
                } else if (leadershipResult.success) {
                    relValue += 1
                }
                break
            }
            case 'draws': {
                credits = 250 * enemyRank   
                if (leadershipResult.crit) {
                    relValue += 1
                }
                break
            }
            case 'losses': {
                credits = 0   
                if (leadershipResult.success) {
                    relValue -= 1
                } else {
                    relValue -= 2
                }
                break
            }
            default:
        }

        for (const pair of relPairs) {
            const pilotId1 = this.state.team.pilots[pair[0]].id
            const pilotId2 = this.state.team.pilots[pair[1]].id
            
            if (!relationships[pilotId1]) {
                relationships[pilotId1] = {}
            }
            relationships[pilotId1][pilotId2] = relValue

            if (!relationships[pilotId2]) {
                relationships[pilotId2] = {}
            }
            relationships[pilotId2][pilotId1] = relValue
        }

        return {
            relValue,
            credits,
            relationships
        }
    }

    getLogView() {
        const { inCombat, enemy, messageView, team, titleStage } = this.state

        if (!inCombat && enemy) {
            return <TitleCard 
                team={ team }
                titleStage={ titleStage }
                getEnemyName={ this.getEnemyName.bind(this) }
            />
        } else {
            switch (messageView) {
                case 'stats':
                    return <StatsDisplay 
                        team={ team }
                        stats={ this.stats }
                        metrics={ this.metrics }
                        progress={ this.progress }
                    />
                case 'rewards':
                    return <RewardsDisplay 
                        rewards={ this.rewards }
                    />
                default:
                    return <MessageLog 
                        team={ team }
                        tick={ this.state.tick }
                        enemy={ this.state.enemy }
                        inCombat={ this.state.inCombat }
                        messageView={ this.state.messageView }
                        messages={ this.state.messages }
                        getEnemyName={ this.getEnemyName.bind(this) }
                    />
            }
        }
    }

    render() {
        const { enemies, enemy, tick } = this.state

        return (
            <Container className="battleView">
                { this.state.planetCleared ? <Grid className="topGrid">
                    <Grid.Column width={5}></Grid.Column>
                    <Grid.Column width={6}>
                        <PlanetCleared 
                            bonuses={ this.bonuses } 
                            playSoundEffect={ this.props.playSoundEffect }
                        />
                    </Grid.Column>
                    <Grid.Column width={5}></Grid.Column>
                </Grid> : <Grid className="topGrid">
                    <Grid.Row>
                    { this.state.team?.mech?.data ? [
                        <Grid.Column width={5} key={0}>
                            <TeamCombatView 
                                team={ this.state.team } 
                                planet={ this.state.planet } 
                                inCombat={ this.state.inCombat } 
                                combatOver={ this.state.combatOver } 
                                tick={ this.state.tick } 
                                comms={ this.state.comms }
                                recallTeam={ this.recallTeam.bind(this) }
                            /> 
                        </Grid.Column>,
                        <Grid.Column width={6} key={1}>
                            <div className="messageLogHeader">
                                <div className="ticker">{ tick }</div>
                                <MessageLogHeader 
                                    inCombat={ this.state.inCombat }
                                    combatOver={ this.state.combatOver }
                                    messageView={ this.state.messageView }
                                    timeFactor={ this.state.timeFactor }
                                    team={ this.state.team }
                                    speed={ this.state.speed }
                                    setMessageView={ this.setMessageView.bind(this) }
                                    resetBattle={ this.resetBattle.bind(this) }
                                    startTick={ this.startTick.bind(this) }
                                    startCombat={ this.startCombat.bind(this) }
                                />
                            </div>
                            { this.getLogView() } 
                        </Grid.Column> ]
                        :
                        [
                        <Grid.Column width={7} key={0}>
                            <div>
                                <TeamList
                                    user={ this.props.user }
                                    db={ this.props.db }
                                    academy={ this.props.academy }
                                    theEnemy={ this.getSelectedEnemy() }
                                    context="combat"
                                    selectPilot={ this.props.selectPilot }
                                    selectMech={ this.props.selectMech }
                                    playSoundEffect= { this.props.playSoundEffect }
                                    confirmTeam={ this.selectTeam.bind(this) }
                                /> 
                            </div>
                        </Grid.Column>,  
                        <Grid.Column width={4} key={1}>
                            { enemies.map((e, i) => (
                                e?.data ? 
                                    <div className={`enemyBattleStats ${enemy === enemies[i].obj ? 'selected' : ''} ${enemies[i].defeated ? 'defeated' : ''}`} key={i}
                                        onClick={() => this.selectEnemy(i)}
                                    >
                                        {/* <pre>{JSON.stringify(enemy.data, null, 2)}</pre>   */}
                                        <div className="header">
                                            {camelCaseToTitleCase(e.data.name)} [Rank { e.data.rank }]
                                        </div>
                                        <div className="subheader">
                                            Speed: { e.data.speed }
                                        </div>
                                        <div className="subheader">
                                            Attacks:
                                        </div>
                                        {
                                            e.data.weapons ? e.data.weapons.map((w, i) => (
                                                <div className="data" key={i}>
                                                    {w.name} ({ camelCaseToTitleCase(w.slot) })
                                                </div>
                                            ))
                                            : <>
                                                <div className="data" key={0}>
                                                    {e.data.leftWeapon.name} (Left Arm)
                                                </div>
                                                <div className="data" key={1}>
                                                    {e.data.rightWeapon.name} (Right Arm)
                                                </div>
                                            </>

                                        }
                                    </div>
                                    :
                                    <div className="enemyBattleStats">
                                        <Loading/>                                           
                                    </div>

                                )
                            )}
                        </Grid.Column>    
                        ]
                    }
                        <Grid.Column width={5}>
                            { this.state.enemy &&
                                <div className="mechContainer">
                                    <div className="mechFrame rightFrame" key={1}>
                                        <MechView
                                            size={420}
                                            subject={ this.state.enemy.data || this.state.enemy.mech?.data }
                                            context={'damage'}
                                        />
                                    </div>
                                    <div className={`mechFrame rightFrame ${this.state.inCombat && 'collapsed'}`} key={2}>
                                        <MechView
                                            size={420}
                                            subject={ this.state.enemy.data || this.state.enemy.mech?.data }
                                            context={'display'}
                                            environment={ this.state.planet.type }
                                        />
                                    </div>
                                    { this.state.enemy.data && <>
                                        <HealthBar 
                                            className={`rageBar ${this.state.inCombat && 'visible'} ${ this.state.enemy.getRageLevel() }`}
                                            curr={ this.state.enemy.rage }
                                            max={ this.state.enemy.maxRage }
                                        />
                                    </> }
                                </div>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            </Container>
        )
    }
}