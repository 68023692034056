/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Image } from 'semantic-ui-react'

export class Portrait extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        return JSON.stringify(this.props) !== JSON.stringify(prevProps)
    }

    render() {
        const defaults = {
            eyes: '1',
            lips: 'm1',
            brows: '1',
            nose: '1',
            skin: {
                lightness: 0,
                tone: 0
            },
            hair: {
                style: 'h1',
                lightness: 0,
                color: 0,
                saturation: 0
            },
            clothing: 'u1'
        }

        const data = Object.assign(defaults, this.props.subject)

        const frameStyle = this.props.size ? { width: `${this.props.size}px`, height: `${this.props.size}px`} : {}

        return this.props.subject ? <div className="portraitFrame" style={ frameStyle }>
            { this.props.context === 'combat' ? (
                <Image
                className="portraitLayer"
                src={`/assets/backgrounds/cockpit.png`}
                />
            ) : (
                <Image
                    className="portraitLayer"
                    src={`/assets/backgrounds/indoor.png`}
                />
            )}
            <Image
                className="portraitLayer"
                src={`/assets/faces/heads/neck.svg`}
                style={{ WebkitFilter: `brightness(${data.skin.lightness - 0.1}) hue-rotate(${data.skin.tone}deg)` }}
            />
            <Image
                className="portraitLayer"
                src={`/assets/clothing/${data.clothing}.svg`}
            />
            <Image
                className="portraitLayer"
                src={`/assets/faces/heads/${data.head}.svg`}
                style={{ WebkitFilter: `brightness(${data.skin.lightness}) hue-rotate(${data.skin.tone}deg)` }}
            />
            <Image
                className="portraitLayer"
                src={`/assets/faces/eyes/e${data.eyes}.svg`}
                style={{ WebkitFilter: `hue-rotate(${data.hair.color}deg)` }}
            />
            <Image
                className="portraitLayer"
                src={`/assets/faces/brows/b${data.brows}.svg`}
            />
            <Image
                className="portraitLayer"
                src={`/assets/faces/noses/n${data.nose}.svg`}
            />
            <Image
                className="portraitLayer"
                src={`/assets/faces/lips/${data.lips}.svg`}
            />
            { this.props.context === 'combat' ? (
                <Image
                    className="portraitLayer"
                    src={`/assets/clothing/flightSuit.svg`}
                    style={{ WebkitFilter: `hue-rotate(${data.suitColor * 30 || 0}deg)` }}
                />
            ) : (
                <Image
                    className="portraitLayer"
                    src={`/assets/faces/hair/${data.hair.style}.svg`}
                    style={{ WebkitFilter: `brightness(${data.hair.lightness}) hue-rotate(${data.hair.color}deg) saturate(${data.hair.saturation}%)` }}
                />
            )}
        </div> 
        :
        <div className="portraitFrame" style={ frameStyle }>
            <Image
                className="portraitLayer"
                src={`/assets/faces/heads/neck.svg`}
                style={{ WebkitFilter: `grayscale(100%)` }}
            />
            <Image
                className="portraitLayer"
                src={`/assets/faces/heads/m1.svg`}
                style={{ WebkitFilter: `grayscale(100%)` }}
            />
        </div>
    }
  }