import React, { Component } from 'react'
import { Button, Grid, Modal, Image, Popup, Icon, Menu } from 'semantic-ui-react'
import { Portrait } from '../portrait'
import { get, ref, query } from 'firebase/database';
import { attrList, attrNames, attrQuirkEffects, skillNames, skillList, statList, metricList, metricNames } from '../../lib/constants'
import { attrGoal, getSkillIcon, getMetricIcon, Pilot, getMetricDisplay, getQuirkList, checkSkillSorties } from '../../lib/pilot'
import { camelCaseToTitleCase } from '../../lib/textUtils'
import { sortByKey } from '../../lib/arrayUtils'

export class PilotInfoModal extends Component {
    constructor(props) {
        super(props)

        this.handleKeydown = this.handleKeydown.bind(this)

        this.state = {
            suitColorPickerOpen: false,
            pilot: null,
            relationships: [],
            update: Date.now(),
            portraitContext: 'indoor',
            view: 'profile'
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        const { pilotId, isOpen } = this.props

        if (isOpen && prevProps.isOpen !== isOpen && pilotId) {
            this.loadPilotData(pilotId)

            document.addEventListener("keydown", this.handleKeydown)
        }
    }

    loadPilotData(pilotId) {
        const { db, user } = this.props

        const thisClass = this
        
        const thePilot = new Pilot(db, user, pilotId)

        const loadInterval = setInterval(() => {
            if (thePilot.data) {
                if (thePilot.data.canAdvance.any) {
                    this.props.showTutorial('guide', 'pilots', 'advancement')
                }
                clearInterval(loadInterval)
                thisClass.setState({ pilot: thePilot }, () => {
                    this.loadRelationships()
                })
            }
        }, 300)
    }

    handleKeydown(e) {
        e.stopPropagation()
    
        switch( e.keyCode ) {
            case 37: // left arrow
                this.changePilot('prev')
                break
            case 39: // right arrow
                this.changePilot('next')
                break
            default: 
                break
        }
    }
        
    changePilot(dir) {
        const { pilotIds } = this.props

        const pilotIndex = pilotIds.findIndex((p) => p === this.state.pilot.id)

        if (dir === 'next') {
            const nextIndex = pilotIndex === pilotIds.length - 1 ? 0 : pilotIndex + 1
            this.loadPilotData(pilotIds[nextIndex])    
        } else {
            const prevIndex = pilotIndex === 0 ? pilotIds.length - 1 : pilotIndex - 1
            this.loadPilotData(pilotIds[prevIndex])    
        }
    }

    async loadRelationships() {
        const pilotId = this.state?.pilot?.id
    
        const pilotListRef = query(ref(this.props.db, `pilots/${this.props.user.uid}`));

        let rels = []

        const querySnapshot = await get(pilotListRef);
        querySnapshot.forEach((doc) => {
            const data = doc.val()
            const value = data.relationships[pilotId]
            if (value > 0) {
                rels.push({ name: data.name, value })
            }
        });
    
        this.setState({ relationships: sortByKey(rels, 'value', true) })
    }

    handleSuitColorPickerOpen() {
        this.setState({ suitColorPickerOpen: true })
    }
    
    handleSuitColorPickerClose() {
        this.setState({ suitColorPickerOpen: false })
    }
    
    chooseSuitColor(suitColor) {
        const { pilot } = this.state
        const thisClass = this

        const newPortrait = { ...pilot.data.portrait }
        newPortrait.suitColor = suitColor

        pilot.updateData({ portrait: newPortrait })

        setTimeout(() => {
            thisClass.setState({ suitColorPickerOpen: false })
        }, 300)
    }
    
    togglePortraitContext() {
        const { portraitContext } = this.state

        let newContext = ''

        switch(portraitContext) {
            case 'indoor':
                newContext = 'combat'
                break
            case 'combat':
                newContext = 'indoor'
                break
            default:
        }

        this.setState({ portraitContext: newContext })
    }

    setView(view) {
        this.props.playSoundEffect('buttonPress')
        this.setState({ view })
    }

    closeModal() {
        this.state.pilot.cleanup()

        document.removeEventListener("keydown", this.handleKeydown)
        
        this.setState({ pilot: null, view: 'profile' })
        this.props.toggleModal()
    }

    attrProgressBar(attr, value) {
        const { pilot } = this.state

        const attrValue = pilot.data.attributes[attr]
        const progress = pilot.data.progress[attr]

        const goal = (attrValue + 1) * attrGoal

        const percent = Math.min((progress / goal) * 100, 100)

        return (
        <div 
            className={`attrBarFill`}
        >
            <div             
                className={`progress bg-attr-${attr}`}
                style={{ height: `${percent}%`}}
            />
        </div>
        )
    }

    advanceAttr(attr) {
        this.props.playSoundEffect('success')
        this.state.pilot.advanceAttr(attr)

        setTimeout(() => {
            this.setState({ update: Date.now() })
        }, 300)
    }

    advanceSkill(attr) {
        this.props.playSoundEffect('success')
        this.state.pilot.advanceSkill(attr)

        setTimeout(() => {
            this.setState({ update: Date.now() })
        }, 300)
    }

    displayStats() {
        const stats = this.state.pilot.data.stats

        const getStatDetail = (statName) => {
            switch (statName) {
                case 'attacksDodged':
                    return (
                        <div className="statDetail">
                            ({ stats.perfectDodges || 0 } perfect)
                        </div>
                    )
                case 'attacksBlocked':
                    return (
                        <div className="statDetail">
                            ({ stats.perfectBlocks || 0 } perfect)
                        </div>
                    )
                case 'headSorties':
                    return (
                        <div className="statDetail">
                            ({ stats.headWins || 0 } wins)
                        </div>
                    )
                case 'armSorties':
                    return (
                        <div className="statDetail">
                            ({ stats.armWins || 0 } wins)
                        </div>
                    )
                case 'legSorties':
                    return (
                        <div className="statDetail">
                            ({ stats.legWins || 0 } wins)
                        </div>
                    )
                default:
                    return ''
            }
        }

        return statList.map((s, i) => stats[s] ? <div className="statItem" key={i}>
            <div className="statLabel">
                { camelCaseToTitleCase(s) }
            </div>
            <div className="statValue">
                { stats[s] }
                { getStatDetail(s) }
            </div>
        </div> : null )
    }

    getAttrQuirkIcon(attr) {
        const { pilot } = this.state

        if (pilot.data.quirks[attrQuirkEffects[attr].bonus]) {
            return '+'
        } else if (pilot.data.quirks[attrQuirkEffects[attr].malus]) {
            return '-'
        } else {
            return ''
        }
    }

    render() {
        const { pilot } = this.state
        const { isOpen } = this.props

        const skillBlocked = {}
        
        if (pilot) {
            for (const skill of skillList) {
                skillBlocked[skill] = !checkSkillSorties(pilot.data, skill)
            }
        }
    
        return pilot  ? <Modal
        className="pilotInfoModal"
        size={'large'}
        open={isOpen}
        onClose={() => this.closeModal()}
    >
        <Modal.Header>
            <h1 className="ui">Pilot: { pilot.data.name }</h1>
            { this.state.pilot?.id && this.props.pilotIds.length > 1 && <div className="floatRight">
                <Icon className="iconBtn" name="angle left" size="large" onClick={() => this.changePilot('prev')}/>
                <Icon className="iconBtn" name="angle right" size="large" onClick={() => this.changePilot('next')}/>
            </div> }
        </Modal.Header>
        <Modal.Content>
            <Menu className="modalMenu">
                <Menu.Item 
                    className="menuItem"
                    active={ this.state.view === 'profile' }
                    onClick={ () => this.setView('profile') }
                >
                    <Icon name="user"/> Profile
                </Menu.Item>
                <Menu.Item 
                    className="menuItem"
                    active={ this.state.view === 'stats' }
                    onClick={ () => this.setView('stats') }
                >
                    <Icon name="chart bar"/> Statistics
                </Menu.Item>
                <Menu.Item 
                    className="menuItem"
                    active={ this.state.view === 'relationships' }
                    onClick={ () => this.setView('relationships') }
                >
                    <Icon name="users"/> Relationships
                </Menu.Item>
            </Menu>

            { pilot.data && this.state.view === 'profile' && (
            <Grid>
                <Grid.Row>
                <Grid.Column width={4}>
                    <div className="sectionHeader">
                        Attributes                     
                        <Icon className="iconBtn" name="question circle" onClick={() => this.props.showGuide('pilots', 'attributes')}/>
                    </div>
                    { attrList.map((attr, j) => {
                        const attrValue = pilot.data.attributes[attr]
                        return <div className="attrRow" key={j}>
                            <div className={`attrLabel color-attr-${attr}`}>
                                <div className="floatRight">{ this.getAttrQuirkIcon(attr)}</div>
                                {attrNames[attr]}</div>
                            <div className="attrBar">
                                { [...Array(attrValue)].map((a, i) => <div key={i} className={`attrBarFill bg-attr-${attr}`}>
                                </div>)}
                                { attrValue < 12 && this.attrProgressBar(attr) }
                            </div>
                            { pilot.data.canAdvance[attr] && <Icon className="iconBtn" name="plus circle" onClick={() => this.advanceAttr(attr)}/> }
                        </div>
                    })}
                </Grid.Column>
                <Grid.Column width={4}>
                    <div className="sectionHeader">
                        Skills                     
                        <Icon className="iconBtn" name="question circle" onClick={() => this.props.showGuide('pilots', 'skills')}/>
                    </div>
                    { skillList.map((skill, j) => {
                        return <div className="skillRow" key={j}>
                            <div className="skillLabel">{getSkillIcon(skill)} {skillNames[skill]}</div>
                            <div className="skillBar">
                                { [...Array(pilot.data.skills[skill])].map((a, i) => <div key={i} className={`skillBarFill ${ skillBlocked[skill] ? 'blocked' : ''}`}>
                                </div>)}
                            </div>
                            { pilot.data.canAdvance[skill] && <Icon className="iconBtn" name="plus circle" onClick={() => this.advanceSkill(skill)}/> }
                       </div>
                    })}
                </Grid.Column>
                <Grid.Column width={4}>
                    <div className="sectionHeader">
                        Metrics                     
                        <Icon className="iconBtn" name="question circle" onClick={() => this.props.showGuide('pilots', 'metrics')}/>
                    </div>
                    { metricList.map((metric, j) => {
                        return <div className="metricRow" key={j}>
                            <div className="metricLabel">{getMetricIcon(metric)} {metricNames[metric]}</div>
                            <div className="metricValue">{getMetricDisplay(pilot.data, metric)}</div>                            
                       </div>
                    })}
                </Grid.Column>
                <Grid.Column width={4}>
                    <div className="pilotInfoPortrait">
                        <Portrait subject={pilot.data.portrait} size={200} context={this.state.portraitContext}/>
                    </div>
                    <div className="portraitControls">
                        <div className="suitColorControl">
                            <div className="suitColorLabel">Suit Color</div>
                            <div className="suitColorPicker">
                                <Popup
                                    content={<div>
                                        {Array(12).fill().map((x, i) => <div 
                                            className="suitColorChoice"
                                            key={i}>
                                                <Image
                                                    className={ pilot.data.portrait.suitColor === i ? 'selected' : '' }
                                                    width={30}
                                                    height={30}
                                                    src={`/assets/clothing/colorSwatch.svg`}
                                                    style={{ WebkitFilter: `hue-rotate(${i * 30 || 0}deg)` }}
                                                    onClick={() => this.chooseSuitColor(i)}
                                                />
                                            </div>
                                        )}
                                        </div>
                                    }
                                    on="click"
                                    open={ this.state.suitColorPickerOpen }
                                    onOpen={() => this.handleSuitColorPickerOpen()}
                                    onClose={() => this.handleSuitColorPickerClose()}
                                    style={{
                                        width: '162px',
                                        padding: '4px'
                                    }}
                                    trigger={<Image
                                        width={30}
                                        height={30}
                                        src={`/assets/clothing/colorSwatch.svg`}
                                        style={{ WebkitFilter: `hue-rotate(${pilot.data.portrait.suitColor * 30 || 0}deg)` }}
                                    />}
                                />
                            </div>
                        </div>
                        <div className="floatRight">
                            <Button icon="eye" onClick={() => this.togglePortraitContext()}/>
                        </div>
                    </div> 
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <div className="sectionHeader">
                            Quirks                     
                            <Icon className="iconBtn" name="question circle" onClick={() => this.props.showGuide('pilots', 'quirks')}/>
                        </div>
                        <div className="quirkList">{ getQuirkList(pilot.data).map((q, i) => <div key={i}>{ q }</div>) }</div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            )}
            { pilot.data && this.state.view === 'stats' && (
                <div className="statsContainer">
                    { pilot.data?.stats ? this.displayStats() : null}    
                </div>
            )}
            { pilot.data && this.state.view === 'relationships' && (<>
                <div className="statsContainer">
                    { this.state.relationships.map((r, i) => <div className="statItem" key={i}>
                        <div className="statLabel">
                            { r.name }
                        </div>
                        <div className="statValue">
                            { r.value }%
                        </div>
                    </div>)}   
                </div>
                <div className="statsButtons">
                    <Button onClick={() => this.props.showRelationships()}><Icon name="hubspot"/>Show Graph</Button>
                </div>
            </>)}
            </Modal.Content>
        <Modal.Actions>
        {/* <Button negative onClick={() => toggleModal()}>
            No
        </Button> */}
        <Button positive onClick={() => this.closeModal()}>
            Close
        </Button>
        </Modal.Actions>
    </Modal> : null
    }
}