import React from 'react'
import { Logo } from '../logo';
import { numerals } from '../../lib/constants'

export function TitleCard({ team, titleStage, getEnemyName }) {
    return (
    <div className="messageLog">
        <div className="titleCard">
            <Logo 
                size={ 300 }
                spinning={ true }
                className="bgLogo"
            />
            <div className={`mechCard top ${ titleStage > 0 && 'show '}`}>
                { team.mech.data.name || `${team.mech.data.make} ${team.mech.data.model} ${ numerals[team.mech.data.rank - 1]}` }
            </div>
            <div className={`mechCard middle ${ titleStage > 1 && 'show '}`}>
                VS
            </div>
            <div className={`mechCard bottom ${ titleStage > 2 && 'show '}`}>
                { getEnemyName() }
            </div>
        </div>
    </div>
    )
}