import { getRoomIcon } from '../../lib/academy'
import { flipCoin } from '../../lib/mathUtils'

export const infirmary = [
    (e) => {
        const p = e.pilot
        const o = e.patient

        if (!o) { return null }
        
        return {
            title: <div>{ getRoomIcon('infirmary') }INFIRMARY: Physical Therapy</div>,
            description: `While monitoring the infirmary, PNAME sees ONAME struggling with one of the physical therapy machines. OZE is grimacing and straining as oze tries to lift the weights with ozis legs. What should PNAME do?`,
            pilot: p,
            otherPerson: o,
            options: [
                { 
                    attr: 'emp',
                    skill: 'comm',
                    dc: 15,
                    level: 'hard',
                    text: `Coach ozir through the exercises and try to help ozir work through it`,
                    success: {
                        text: `With pzis encouraging words and funny PT anecdotes, PNAME is able to help ONAME finish ozis exercises.`,
                        attr: 'emp',
                        attrValue: 20,
                        relationship: o.id,
                        relValue: 1
                    },
                    fail: {
                        text: `Though pze meant well, PNAME only managed to annoy ONAME with pzis interference.`,
                        relationship: o.id,
                        relValue: -2
                    }
                },
                { 
                    attr: 'conf',
                    skill: 'repair',
                    dc: 10,
                    level: 'medium',
                    text: `Recalibrate the machine`,
                    success: {
                        text: `After tweaking the settings on the machine, PNAME is able to improve its responsiveness. The workout is still challenging, but not frustratingly so.`,
                        attr: 'conf',
                        attrValue: 10,
                        relationship: o.id,
                        relValue: flipCoin() ? 1 : 0
                    },
                    fail: {
                        text: `The machine seems simple enough, but PNAME isn't able to improve its function in a useful way.`
                    }
                },
                { 
                    text: `Give ONAME some water and anti-inflammatories`,
                    level: 'easy',
                    success: {
                        text: `The workout is still tough, but PNAME is able to help ONAME recover a little faster.`,
                        attr: 'wis',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot
        const o = e.otherWorker

        if (!o) { return null }
        return {
            title: <div>{ getRoomIcon('infirmary') }INFIRMARY: Anger Management</div>,
            description: `During PNAME's shift in the Infirmary, pze notices ONAME slamming cabinets shut as oze puts away supplies, grumbling indistinctly. What should PNAME do?`,
            pilot: p,
            otherPerson: o,
            options: [
                { 
                    attr: 'wis',
                    skill: 'per',
                    dc: 15,
                    level: 'hard',
                    text: `Observe ONAME but keep pzis distance`,
                    success: {
                        text: `PNAME quietly follows after ONAME, straightening instruments and containers that have been knocked over in ozis wake. Eventually, ONAME sighs and says "Why does the simulator hate me?" PNAME laughs and shares some of pzis own simulator stories as they continue cleaning together.`,
                        attr: 'wis',
                        attrValue: 20,
                        relationship: o.id,
                        relValue: 1
                    },
                    fail: {
                        text: `PNAME hovers nearby as ONAME stomps around the room. Eventually ONAME spins around and says "Back off!" PNAME goes back to cleaning and they don't speak for a while.`,
                        relationship: o.id,
                        relValue: -2
                    }
                },
                { 
                    attr: 'emp',
                    skill: 'comm',
                    dc: 10,
                    level: 'medium',
                    text: `Ask ONAME what's bothering ozir`,
                    success: {
                        text: `"Hey, how's it going?" asks PNAME. ONAME shrugs and says "The simulator schedule has been packed all week, and I need to get my hours in." PNAME nods and they commiserate over their shared simulator woes.`,
                        attr: 'emp',
                        attrValue: 10,
                        relationship: o.id,
                        relValue: flipCoin() ? 1 : 0
                    },
                    fail: {
                        text: `"Why the long face?" asks PNAME. There is no response.`,
                        relationship: o.id,
                        relValue: flipCoin() ? -1 : 0
                    }
                },
                { 
                    text: `Turn on some upbeat music to lighten the mood`,
                    level: 'easy',
                    success: {
                        text: `The infirmary fills with 40's spacewave remixes. PNAME and ONAME have an impromptu dance-off.`,
                        attr: 'conf',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot

        return {
            title: <div>{ getRoomIcon('infirmary') }INFIRMARY: The Melting Point</div>,
            description: `With no patients currently in the Infirmary, PNAME has been cleaning and organizing the various medical supplies. The Medical Chief enters the bay and suddenly rushes across the room. "What the hell happened here?" he shouts. PNAME sees that one of the cold storage units is ajar, near where pze had been moving boxes of bandages. "All of this serum is ruined," says the Chief. What should PNAME do?`,
            pilot: p,
            options: [
                { 
                    attr: 'hum',
                    skill: 'comm',
                    dc: 15,
                    level: 'hard',
                    text: `Take responsibility for ruining the serum`,
                    success: {
                        text: `"Oh no, I must have bumped the door when I was working over there," says PNAME. "I'll make sure I pay closer attention next time." The Chief shakes his head. "It could have happened to anyone," he says. "Lets get this unit cleaned out and then I can order some replacements."`,
                        attr: 'hum',
                        attrValue: 20
                    },
                    fail: {
                        text: `"I didn't touch that door," says PNAME. "It's not my fault!" The Chief narrows his eyes. "Blaming gremlins, are we? Go scrub the operating room while I take care of this mess."`,
                        metric: 'fame',
                        metricValue: -15
                    }
                },
                { 
                    attr: 'reas',
                    skill: 'repair',
                    dc: 10,
                    level: 'medium',
                    text: `Set up an alarm on the storage unit door`,
                    success: {
                        text: `PNAME examines the unit, and sees that it is set to flash an indicator on its display panel when the door is left open. With a little adjustment to the settings, pze is able to add an audible chime. Hopefully that will keep this from happening again in the future.`,
                        attr: 'reas',
                        attrValue: 10
                    },
                    fail: {
                        text: `It seems that the unit already has a flashing indicator that shows when it has been left ajar. PNAME isn't able to find any way to improve on that, so pze just makes a mental note to keep an eye on it in the future.`
                    }
                },
                { 
                    text: `Put a note on the door as a reminder`,
                    level: 'easy',
                    success: {
                        text: `The cold storage unit has a fairly non-descript door, so PNAME adds a "WARNING: COLD STORAGE" note to the outside. Hopefully that will prevent future accidents.`,
                        attr: 'wis',
                        attrValue: 5
                    }
                }
            ]
        }
    },
]