export const weaponList = {
    boltCannon: {
        name: 'Bolt Cannon',
        range: 'medium',
        acc: 10,
        burst: 3,
        dam: 3,
        speed: 10,
        status: 'online',
        sound: 'gunBurst'
    },
    plasmaBeam: {
        name: 'Plasma Beam',
        range: 'long',
        acc: 15,
        burst: 1,
        dam: 7,
        speed: 20,
        status: 'online',
        sound: 'zap'
    },
    thermalScythe: {
        name: 'Thermal Scythe',
        range: 'short',
        acc: 15,
        burst: 1,
        dam: 8,
        speed: 10,
        status: 'online',
        sound: 'slash'
    },
    particleBeam: {
        name: 'Particle Beam',
        range: 'long',
        acc: 18,
        burst: 2,
        dam: 4,
        speed: 20,
        status: 'online',
        sound: 'zapZap'
    }
}