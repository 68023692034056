// import { flipCoin } from '../../lib/mathUtils'
import { getPilotRank, getFirstName, getPronoun } from '../../lib/pilot'
import { findPlanet } from '../../lib/starMap'

export const mindControl = {
    name: 'Mind Control',
    completeText: `PNAME helped Dr. Hyun Ji-Min develop a new system for enhancing Gauntlet training, despite some serious personal risk.`,
    modalSize: 'large',
    stages: [
        {
            id: 0,
            location: ['repairBay'],
            data: (e) => {
                const p = e.pilot
                const m = e.mech
                
                if (!m) {
                    return null
                }
                
                return {
                    title: 'MIND CONTROL: An Unexpected Visitor',
                    description: [
                        `PNAME has been working on replacing armor plating on the ${m.model}'s torso. As it houses the fusion reactor and other critical systems, the central part of a Gauntlet requires extra protection. PZE climbs the scaffold up toward the head cockpit so that pze can verify range of motion.`,
                        `As pze reaches the hatch, PNAME sees that someone else is already in the cockpit. She is wearing a grey Academy jumpsuit, and her black hair is pulled back into a tight bun. A pilot would have a red uniform and close cropped hair, and an engineer would be wearing blue; PNAME isn't sure who this person might be. "Can I help you?" pze asks.`,
                        `"Oh yes," she says, not looking up from the console she's studying, "can you turn on the targeting array?"`,
                        `PNAME changes tack: "What are you doing in my cockpit?"`,
                        `The woman finally looks up. "Ah, sorry," she says, "I see you're new here." She sticks out her hand. "Dr. Hyun Ji-Min, R&D division, at your service." PNAME shakes the proffered hand, even though she didn't actually answer the question.`,
                        `"As for what I'm doing, I'm trying to make some improvements to the antiquated control interface in these things. Just because something works doesn't mean it can't work better!" A smile spreads across her face. "In fact, you would be the perfect candidate to help me with my research: familiar with the systems, but not too familiar, you know? What do you say, want to help make a scientific breakthrough?"`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    options: [
                        { 
                            attr: 'reas',
                            skill: 'repair',
                            dc: 15,
                            level: 'hard',
                            text: `Get some technical details about the experiment to determine if it's safe`,
                            success: {
                                text: [
                                    `PNAME thinks for a moment, then says, "I need to know more about your plan before I agree to anything."`,
                                    `Dr. Hyun nods. "Fair enough. I've developed a neural feedback device that will create a link between the user's brain and the Gauntlet control systems. To improve syncing with the brainwaves, I employ a mild psychotropic cocktail injected at the time of use."`,
                                    `She waves off PNAME's look of concern. "Oh, it's perfectly safe. I've tested it on myself plenty of times. What I don't have is training on the actual Gauntlet systems, so I don't know how it will affect pilot performance in the real world."`,
                                    `PNAME isn't entirely sure about Dr. Hyun's assurances of safety, but the possibility of enhanced performance is tempting. "Okay, I'm in."`,
                                    `Dr. Hyun claps her hands. "Excellent! I'll get things ready, and you can come by the Infirmary later for a test run."`
                                ],
                                attr: 'reas',
                                attrValue: 20,
                                nextStage: 1
                            },
                            fail: {
                                text: [
                                    `PNAME thinks for a moment, then says, "I need to know more about your plan before I agree to anything."`,
                                    `Dr. Hyun says some things about neural feedback and psychotropic injections, but PNAME isn't able to entirely follow the explanation. The upshot seems to be that it will make pzir a better pilot and not kill pzir.`,
                                    `While PNAME is still uneasy about the whole situation, pze can't think of a solid reason to refuse. "Sure, I guess that sounds okay."`,
                                    `Dr. Hyun claps her hands. "Excellent! I'll get things ready, and you can come by the Infirmary later for a test run."`
                                ],
                                metric: 'hap',
                                metricValue: -2,
                                nextStage: 1
                            }
                        },
                        { 
                            attr: 'wis',
                            skill: 'per',
                            dc: 10,
                            level: 'med',
                            text: `Consider the pros and cons of participating before making a decision`,
                            success: {
                                text: [
                                    `PNAME thinks for a moment, then says, "I need to know more about your plan before I agree to anything."`,
                                    `Dr. Hyun nods. "Fair enough. I've developed a neural feedback device that will create a link between the user's brain and the Gauntlet control systems. To improve syncing with the brainwaves, I employ a mild psychotropic cocktail injected at the time of use."`,
                                    `She waves off PNAME's look of concern. "Oh, it's perfectly safe. I've tested it on myself plenty of times. What I don't have is training on the actual Gauntlet systems, so I don't know how it will affect pilot performance in the real world."`,
                                    `PNAME isn't entirely sure about Dr. Hyun's assurances of safety, but the possibility of enhanced performance is tempting. "Okay, I'm in."`,
                                    `Dr. Hyun claps her hands. "Excellent! I'll get things ready, and you can come by the Infirmary later for a test run."`
                                ],
                                attr: 'wis',
                                attrValue: 10,
                                nextStage: 1
                            },
                            fail: {
                                text: [
                                    `PNAME thinks for a moment, then says, "I need to know more about your plan before I agree to anything."`,
                                    `Dr. Hyun nods. "Fair enough. I've developed a neural feedback device that will create a link between the user's brain and the Gauntlet control systems. To improve syncing with the brainwaves, I employ a mild psychotropic cocktail injected at the time of use."`,
                                    `She waves off PNAME's look of concern. "Oh, it's perfectly safe. I've tested it on myself plenty of times. What I don't have is training on the actual Gauntlet systems, so I don't know how it will affect pilot performance in the real world."`,
                                    `PNAME considers the risks and rewards. On the one hand, enhanced piloting would be great. On the other hand, experimental drugs could be dangerous. While pze is uneasy about the whole situation, pze also can't think of a solid reason to refuse. "Sure, I guess that sounds okay."`,
                                    `Dr. Hyun claps her hands. "Excellent! I'll get things ready, and you can come by the Infirmary later for a test run."`
                                ],
                                nextStage: 1
                            }
                        },
                        { 
                            text: `Get excited about the project and agree to help`,
                            level: 'easy',
                            success: {
                                text: [
                                    `PNAME grins. "That sounds awesome, let's do it."`,
                                    `Dr. Hyun pauses for a moment, having clearly been prepared to sell PNAME on the idea. "Well, just so you're aware, let me give you some details. I've developed a neural feedback device that will create a link between the user's brain and the Gauntlet control systems. To improve syncing with the brainwaves, I employ a mild psychotropic cocktail injected at the time of use."`,
                                    `"It's perfectly safe. I've tested it on myself plenty of times. What I don't have is training on the actual Gauntlet systems, so I don't know how it will affect pilot performance in the real world."`,
                                    `Dr. Hyun smiles. "I'm glad you're on board with this. I'll go get things ready, and you can meet me in the Infirmary for a test run.`
                                ],
                                attr: 'dar',
                                attrValue: 5,
                                nextStage: 1
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 1,
            location: ['infirmary'],
            hint: 'PNAME has been approached by Dr. Hyun Ji-Lin about assisting with developing an experimental Gauntlet control system. She is in the Infirmary, ready to get started.',
            data: (e) => {
                const p = e.pilot

                return {
                    title: 'MIND CONTROL: You May feel A Pinch',
                    description: [
                        `As PNAME enters the Infirmary, pze sees Dr. Hyun making adjustments to an apparatus attached to a padded metal chair in the middle of the room. Various wires and sensors hang from the arms, and a crown-like metal device is mounted on the headrest. A video display is mounted just in front of the chair. "Ah, perfect timing," says Dr. Hyun, waving PNAME forward. "Have a seat."`,
                        `Sitting in the surprisingly confortable chair, PNAME watches as Dr. Hyun starts attaching sensors to various parts of pzis body. "These are just to collect data," she explains, "they're not part of the system itself. That's all in here." She lowers the crown onto PNAME's head.`,
                        `"See the circle on the screen?" PNAME nods; the display shows a single white circle on a black background. "This neural link will allow you to move it by just thinking about it: up, down, left, right. Give it a try." Dr. Hyun goes to a nearby console as PNAME concentrates on the screen. The circle begins moving in a jittery, almost random way. Dr. Hyun makes adjustments on the console, and the circle's movements start following PNAME's mental instructions a little better. The response is still slow and erratic, however.`,
                        `Dr. Hyun watches the screen for a moment. "Okay, that's a good baseline. Now it's time for the interesting part." She takes hold of PNAME's arm with one hand and presents a small syringe full of orange liquid in the other. "This may feel a little... weird."`,
                        `The actual injection hardly hurts at all, but almost immediately PNAME begins to feel the effects. PZIS mind starts racing and it feels like pze's running down a steep hill. Dr. Hyun presses a button on the console and previously unseen restraints click into place on PNAME's wrists and ankles.`,
                        `What should PNAME do?`
                    ],
                    pilot: p,
                    options: [
                        { 
                            attr: 'fort',
                            skill: 'combat',
                            dc: 15,
                            level: 'hard',
                            text: `Endure the effects of the drugs`,
                            success: {
                                text: [
                                    `PNAME clenches pzis fists and concentrates pzis attention on the screen in from of pzir. After what feels like an hour but is probably only a minute, the wild racing feeling subsides, leaving behind only a mild tingling all over.`,
                                    `"How are you feeling?" asks Dr. Hyun.`,
                                    `"I'm fine," replies PNAME. "I think 'weird' may be a bit of an understatement, though."`,
                                    `Dr. Hyun chuckles. "You get used to it. Okay, now try moving the circle again."`,
                                    `PNAME looks at the screen and thinks about moving the circle. It moves smoothly and effortlessly wherever pze thinks about directing it. Loops and complicated patterns are as easy as straight lines."`,
                                    `"Marvelous!" cries Dr. Hyun. "I'm getting 95, 96 percent synchronization. This is better than I'd hoped for."`,
                                    `After a few more minutes of tests and measurements, Dr. Hyun releases PNAME from the chair. The tingling feeling is very faint now. "I think that was a very good first run. I'll work on getting the equipment installed in a Simulator pod and we can give it a more thorough test."`
                                ],
                                attr: 'fort',
                                attrValue: 20,
                                nextStage: 2
                            },
                            fail: {
                                text: [
                                    `PNAME clenches pzis fists and concentrates pzis attention on the screen in from of pzir. The feeling is overwhelming and pze struggles against the restraints.`,
                                    `"Don't fight it," says Dr. Hyun. "The initial phase will be over soon." After what feels like an hour but is probably only a minute, the wild racing feeling subsides, leaving behind only a mild tingling all over.`,
                                    `"How are you feeling?" asks Dr. Hyun.`,
                                    `"Like I've been in a fight," replies PNAME. PZIS muscles are sore and pze feels exhausted. "I think 'weird' may be a bit of an understatement."`,
                                    `Dr. Hyun chuckles. "You get used to it. Okay, now try moving the circle again."`,
                                    `PNAME looks at the screen and thinks about moving the circle. It moves smoothly and effortlessly wherever pze thinks about directing it. Loops and complicated patterns are as easy as straight lines."`,
                                    `"Marvelous!" cries Dr. Hyun. "I'm getting 95, 96 percent synchronization. This is better than I'd hoped for."`,
                                    `After a few more minutes of tests and measurements, Dr. Hyun releases PNAME from the chair. The tingling feeling is very faint now. "I think that was a very good first run. I'll work on getting the equipment installed in a Simulator pod and we can give it a more thorough test."`       
                                ],
                                metric: 'hp',
                                metricValue: -1,
                                nextStage: 2
                            }
                        },
                        { 
                            attr: 'conf',
                            skill: 'reaction',
                            dc: 10,
                            level: 'med',
                            text: `Trust in pzis body's ability to handle the effects`,
                            success: {
                                text: [
                                    `PNAME leans into the feeling, like a surfer riding a twenty foot wave. The rush is intense, but after a short time pze can feel it starting to wane. Soon the wild racing feeling subsides, leaving behind only a mild tingling all over.`,
                                    `"How are you feeling?" asks Dr. Hyun.`,
                                    `"Great," replies PNAME. "That's some potent stuff you've got there."`,
                                    `Dr. Hyun chuckles. "You get used to it. Okay, now try moving the circle again."`,
                                    `PNAME looks at the screen and thinks about moving the circle. It moves smoothly and effortlessly wherever pze thinks about directing it. Loops and complicated patterns are as easy as straight lines.`,
                                    `"Marvelous!" cries Dr. Hyun. "I'm getting 95, 96 percent synchronization. This is better than I'd hoped for."`,
                                    `After a few more minutes of tests and measurements, Dr. Hyun releases PNAME from the chair. The tingling feeling is very faint now. "I think that was a very good first run. I'll work on getting the equipment installed in a Simulator pod and we can give it a more thorough test."`                    
                                ],
                                attr: 'conf',
                                attrValue: 10,
                                nextStage: 2
                            },
                            fail: {
                                text: [
                                    `PNAME tries to ride the rush, but the feeling is too intense. PZE shuts pzis eyes and just waits for it to be over. After what feels like an hour but is probably only a minute the wild racing feeling subsides, leaving behind only a mild tingling all over.`,
                                    `"How are you feeling?" asks Dr. Hyun.`,
                                    `"Better now," replies PNAME. "That's some potent stuff you've got there."`,
                                    `Dr. Hyun chuckles. "You get used to it. Okay, now try moving the circle again."`,
                                    `PNAME looks at the screen and thinks about moving the circle. It moves smoothly and effortlessly wherever pze thinks about directing it. Loops and complicated patterns are as easy as straight lines.`,
                                    `"Marvelous!" cries Dr. Hyun. "I'm getting 95, 96 percent synchronization. This is better than I'd hoped for."`,
                                    `After a few more minutes of tests and measurements, Dr. Hyun releases PNAME from the chair. The tingling feeling is very faint now. "I think that was a very good first run. I'll work on getting the equipment installed in a Simulator pod and we can give it a more thorough test."`                    
                                ],
                                nextStage: 2
                            }
                        },
                        { 
                            level: 'easy',
                            text: `Let the process happen and wait it out`,
                            success: {
                                text: [
                                    `PNAME puts pzis faith in Dr. Hyun's abilities and tries to relax, shutting pzis eyes and just waiting for it to be over. After what feels like an hour but is probably only a minute the wild racing feeling subsides, leaving behind only a mild tingling all over.`,
                                    `"How are you feeling?" asks Dr. Hyun.`,
                                    `"Fine," replies PNAME. "That was definitely a unique experience."`,
                                    `Dr. Hyun chuckles. "You get used to it. Okay, now try moving the circle again."`,
                                    `PNAME looks at the screen and thinks about moving the circle. It moves smoothly and effortlessly wherever pze thinks about directing it. Loops and complicated patterns are as easy as straight lines."`,
                                    `"Marvelous!" cries Dr. Hyun. "I'm getting 95, 96 percent synchronization. This is better than I'd hoped for."`,
                                    `After a few more minutes of tests and measurements, Dr. Hyun releases PNAME from the chair. The tingling feeling is very faint now. "I think that was a very good first run. I'll work on getting the equipment installed in a Simulator pod and we can give it a more thorough test."`                    
                                ],
                                attr: 'hum',
                                attrValue: 5,
                                nextStage: 2
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 2,
            location: ['simulator'],
            hint: `After a successful initial test, Dr. Hyun wants PNAME to test her control system in the Simulator.`,
            data: (e) => {
                const p = e.pilot
                const starMap = e.starMap

                const planet = findPlanet(starMap, { danger: getPilotRank(p), minJumps: 2, closest: true })
                
                return {
                    title: 'MIND CONTROL: Almost Like The Real Thing',
                    description: [
                        `Dr. Hyun is waiting for PNAME as pze enters the Simulator bay. "Ah, you're here!" she says as she opens the hatch on one of the pods. "Go on in, I've got it all ready."`,
                        `PNAME climbs into the pod. The seat is the same as always, except for the familiar metal crown on the headrest and a device attached to one of the arms. Dr. Hyun follows pzir in and gets the crown seated on pzis head.`,
                        `She indicates the tubular device on the arm of the seat. "When you're ready, all you have to do is put your arm in here and it will deliver the injection automatically. It's a bit clunky, but this pod isn't built for two so I can't give it myself."`,
                        `After she leaves and seals the hatch, PNAME starts up a multi-position simulation, allowing pzir to control all parts of the Gauntlet. It's rarely used in actual battle, but it's often the first part of pilot training. Integrating as one part of a full team comes later.`,
                        `The scenario is an Argonaut vs. Fire Crab fight in a featureless desert, a typical no-frills sim. Dr. Hyun's voice comes over the comm: "I've got your readings on the pod sensors. Feel free to hit it whenever you're ready."`,
                        `PNAME slides pzis arm into the injector. The sting of the needle is followed by the same rushing feeling as before. Now that pze knows what to expect, it's much easier to handle. Soon there is only the background tingle and a feeling of mental clarity.`,
                        `What should pze do?`
                    ],
                    pilot: p,
                    planet,
                    options: [
                        { 
                            attr: 'prow',
                            skill: 'combat',
                            dc: 15,
                            level: 'hard',
                            text: `Attack the Fire Crab from a distance`,
                            success: {
                                text: [
                                    `PNAME tries to aim the Argonaut's plasma cannon using just pzis mind. The arm comes up, and the targeting reticule centers on the enemy. Another thought fires the weapon, scoring a glancing blow on the Fire Crab's carapace.`,
                                    `"Not bad," says Dr. Hyun, "See if you can move and fire at the same time."`,
                                    `Concentrating on the Gauntlet's legs, PNAME starts moving to the left, circling around the target. The movement is smooth and easy. As pze tries to target the Fire Crab again, pze is suddenly hit by a wave of nausea. The Gauntlet grinds to a halt as pze tries to recover.`,
                                    `Dr. Hyun's voice comes over the comm again. "Oh, I was afraid that might happen." The tingling feeling changes slightly, and the nausea recedes. "It looks like using multiple control channels simultaneously creates some... unfortunate harmonics. Let's try Plan B."`,
                                    `The tingling changes again, and suddenly PNAME feels a strange sort of awareness, like a sixth sense tht pze can't quite describe. "I've changed from control channels to sensory channels," says Dr. Hyun. "That should be easier for your brain to assimilate. Try it again."`,
                                    `PNAME starts moving the Gauntlet again, this time using the normal cockpit controls. However, that vague awareness soon becomes recognizable as the sensor data from the Gauntlet itself. Each footfall and move of the arm feels like it's PNAME's own body. Swinging around to target the Fire Crab again, pze fires the plasma cannon and hits it in the center of mass.`,
                                    `"Excellent. Not what I was aiming for, but this will still be a useful tool. When you're ready, I've scouted a spot on ${planet.name} where we can do a proper field test."`
                                ],
                                attr: 'prow',
                                attrValue: 20,
                                metric: 'xp',
                                metricValue: 10,
                                markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `PNAME tries to aim the Argonaut's plasma cannon using just pzis mind. The arm comes up, and the targeting reticule starts hovering around the enemy. Another thought fires the weapon, but the shot goes wide.`,
                                    `"Hm," says Dr. Hyun, "See if you can move and fire at the same time."`,
                                    `Concentrating on the Gauntlet's legs, PNAME starts moving to the left, circling around the target. The movement is jerky, but the machine remains upright. As pze tries to target the Fire Crab again, pze is suddenly hit by a wave of nausea. The Gauntlet grinds to a halt as pze tries to recover.`,
                                    `Dr. Hyun's voice comes over the comm again. "Oh, I was afraid that might happen." The tingling feeling changes slightly, and the nausea recedes. "It looks like using multiple control channels simultaneously creates some... unfortunate harmonics. Let's try Plan B."`,
                                    `The tingling changes again, and suddenly PNAME feels a strange sort of awareness, like a sixth sense tht pze can't quite describe. "I've changed from control channels to sensory channels," says Dr. Hyun. "That should be easier for your brain to assimilate. Try it again."`,
                                    `PNAME starts moving the Gauntlet again, this time using the normal cockpit controls. However, that vague awareness soon becomes recognizable as the sensor data from the Gauntlet itself. Each footfall and move of the arm feels like it's PNAME's own body. Swinging around to target the Fire Crab again, pze fires the plasma cannon and manages to at least score a glancing blow.`,
                                    `"Excellent. Not what I was aiming for, but this will still be a useful tool. When you're ready, I've scouted a spot on ${planet.name} where we can do a proper field test."`
                                ],
                                markPlanet: true,
                                nextStage: 3
                            }
                        },
                        { 
                            attr: 'dar',
                            skill: 'pilot',
                            dc: 15,
                            level: 'hard',
                            text: `Try to move around it and dodge its attacks`,
                            success: {
                                text: [
                                    `PNAME tries to move the Gauntlet using just pzis mind. The legs move smoothly and nimbly, as though pze and the machine were one. The Fire Crab unleashes a blast of fire, but pze is able to dodge it easily.`,
                                    `"Not bad," says Dr. Hyun, "See if you can move and fire at the same time."`,
                                    `Concentrating on the Gauntlet's arms, PNAME tries to target the Fire Crab with its plasma cannon. Suddenly pze is hit by a wave of nausea. The Gauntlet grinds to a halt as pze tries to recover.`,
                                    `Dr. Hyun's voice comes over the comm again. "Oh, I was afraid that might happen." The tingling feeling changes slightly, and the nausea recedes. "It looks like using multiple control channels simultaneously creates some... unfortunate harmonics. Let's try Plan B."`,
                                    `The tingling changes again, and suddenly PNAME feels a strange sort of awareness, like a sixth sense tht pze can't quite describe. "I've changed from control channels to sensory channels," says Dr. Hyun. "That should be easier for your brain to assimilate. Try it again."`,
                                    `PNAME starts moving the Gauntlet again, this time using the normal cockpit controls. However, that vague awareness soon becomes recognizable as the sensor data from the Gauntlet itself. Each footfall and move of the arm feels like it's PNAME's own body. Swinging around to target the Fire Crab again, pze fires the plasma cannon and hits it in the center of mass.`,
                                    `"Excellent. Not what I was aiming for, but this will still be a useful tool. When you're ready, I've scouted a spot on ${planet.name} where we can do a proper field test."`
                                ],
                                attr: 'dar',
                                attrValue: 20,
                                metric: 'xp',
                                metricValue: 10,
                               markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `PNAME tries to move the Gauntlet using just pzis mind. The legs move one at a tine and somewhat jerkily, but pze manages to remain upright.`,
                                    `"Well, that sort of works," says Dr. Hyun, "See if you can move and fire at the same time."`,
                                    `Concentrating on the Gauntlet's arms while still moving, PNAME tries to target the Fire Crab with its plasma cannon. Suddenly pze is hit by a wave of nausea. The Gauntlet grinds to a halt as pze tries to recover.`,
                                    `Dr. Hyun's voice comes over the comm again. "Oh, I was afraid that might happen." The tingling feeling changes slightly, and the nausea recedes. "It looks like using multiple control channels simultaneously creates some... unfortunate harmonics. Let's try Plan B."`,
                                    `The tingling changes again, and suddenly PNAME feels a strange sort of awareness, like a sixth sense tht pze can't quite describe. "I've changed from control channels to sensory channels," says Dr. Hyun. "That should be easier for your brain to assimilate. Try it again."`,
                                    `PNAME starts moving the Gauntlet again, this time using the normal cockpit controls. However, that vague awareness soon becomes recognizable as the sensor data from the Gauntlet itself. Each footfall and move of the arm feels like it's PNAME's own body. Swinging around to target the Fire Crab again, pze fires the plasma cannon and manages to score a glancing blow on its carapace.`,
                                    `"Excellent. Not what I was aiming for, but this will still be a useful tool. When you're ready, I've scouted a spot on ${planet.name} where we can do a proper field test."`
                                ],
                                markPlanet: true,
                                nextStage: 3
                            }
                        },
                        { 
                            attr: 'reas',
                            skill: 'per',
                            dc: 10,
                            level: 'med',
                            text: `Methodically go through the Gauntlet's features`,
                            success: {
                                text: [
                                    `Rather than trying to jump straight into battle, PNAME runs down a checklist of systems to test. Legs, check. Arms, check. Weapon targeting, check.`,
                                    `"Looks good so far," says Dr. Hyun, "See if you can move and fire at the same time."`,
                                    `Concentrating on the Gauntlet's arms while walking forward, PNAME tries to target the Fire Crab with its plasma cannon. Suddenly pze is hit by a wave of nausea. The Gauntlet grinds to a halt as pze tries to recover.`,
                                    `Dr. Hyun's voice comes over the comm again. "Oh, I was afraid that might happen." The tingling feeling changes slightly, and the nausea recedes. "It looks like using multiple control channels simultaneously creates some... unfortunate harmonics. Let's try Plan B."`,
                                    `The tingling changes again, and suddenly PNAME feels a strange sort of awareness, like a sixth sense tht pze can't quite describe. "I've changed from control channels to sensory channels," says Dr. Hyun. "That should be easier for your brain to assimilate. Try it again."`,
                                    `PNAME starts moving the Gauntlet again, this time using the normal cockpit controls. However, that vague awareness soon becomes recognizable as the sensor data from the Gauntlet itself. Each footfall and move of the arm feels like it's PNAME's own body. Swinging around to target the Fire Crab again, pze fires the plasma cannon and manages to score a solid hit on its carapace.`,
                                    `"Excellent. Not what I was aiming for, but this will still be a useful tool. When you're ready, I've scouted a spot on ${planet.name} where we can do a proper field test."`
                                ],
                                attr: 'reas',
                                attrValue: 10,
                                metric: 'xp',
                                metricValue: 5,
                                markPlanet: true,
                                nextStage: 3
                            },
                            fail: {
                                text: [
                                    `Rather than trying to jump straight into battle, PNAME just tries a bunch of random movements. As pze tries to walk backward and flap pzir arms at the same time, pze is suddenly hit by a wave of nausea. The Gauntlet grinds to a halt as pze tries to recover.`,
                                    `Dr. Hyun's voice comes over the comm again. "Oh, I was afraid that might happen." The tingling feeling changes slightly, and the nausea recedes. "It looks like using multiple control channels simultaneously creates some... unfortunate harmonics. Let's try Plan B."`,
                                    `The tingling changes again, and suddenly PNAME feels a strange sort of awareness, like a sixth sense tht pze can't quite describe. "I've changed from control channels to sensory channels," says Dr. Hyun. "That should be easier for your brain to assimilate. Try it again."`,
                                    `PNAME starts moving the Gauntlet again, this time using the normal cockpit controls. However, that vague awareness soon becomes recognizable as the sensor data from the Gauntlet itself. Each footfall and move of the arm feels like it's PNAME's own body. Swinging around to target the Fire Crab, pze fires the plasma cannon and manages to score a solid hit on its carapace.`,
                                    `"Excellent. Not what I was aiming for, but this will still be a useful tool. When you're ready, I've scouted a spot on ${planet.name} where we can do a proper field test."` 
                                ],
                                metric: 'fame',
                                metricValue: -20,
                                markPlanet: true,
                                nextStage: 3
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 3,
            location: ['planet'],
            hint: `Following the tests of Dr. Hyun's neural link system in the Simulator, PNAME travels to PLANETNAME to try it in the field.`,
            data: (e) => {
                const p = e.pilot
                const t = e.teammates
                const planet = e.planet
                
                return {
                    title: 'MIND CONTROL: Where The Rubber Meets The Road',
                    description: [
                        `After PNAME's team has cleared the surface of ${planet.name} of immediate dangers, Dr. Hyun is able to descend in a shuttle and begin setting up an obstacle course. This is a familiar sight to a young pilot.`,
                        `"I've outfitted all the cockpits in your Gauntlet with the neural link system in sensory mode," Dr. Hyun says over the comm. "I've given them each a test injection in the Infirmary to make sure there were no adverse side effects, but they haven't had any cockpit experience with the system yet. I'm curious to see how you all do with it."`,
                        `The obstacle course is set up with a variety of movement barriers and targets to shoot. The goal is to reach the end of the course as quickly as possible while also hitting the targets along the way.`,
                        `What should PNAME do?`
                    ],
                    pilot: p,
                    planet,
                    options: [
                        { 
                            attr: 'conf',
                            skill: 'comm',
                            dc: 15,
                            level: 'hard',
                            text: `Encourage the other pilots to work together`,
                            success: {
                                text: [
                                    `PNAME calls up the cockpit cameras for the other panels on pzis screen. "Okay everyone, we've got this. Hit the injectors on my mark. When you get past the rush, give the high sign and we'll run the course. Ready? 3... 2... 1... mark!"`,
                                    `Sliding pzis arm into the injector sleeve, PNAME feels the now-familiar needle and rush of chemicals entering pzis bloodstream. PZE watches the screen and sees the other pilots having the same experience. One by one, they recover and give a thumbs-up.`,
                                    `Having had the most experience with the neural link, PNAME is in the head position, coordinating the actions of the Gauntlet. The team moves well together, clearing the obstacles and scoring high accuracy marks on the targets.`,
                                    `At the end of the course, Dr. Hyun calls over the comm, "Two percent above baseline. Not a huge improvement, but good."`,
                                    `PNAME can still feel the link active in pzis brain. "Team, are we good to go again?" Everyone acknowledges, and they start the course again, leaping over barriers and blasting metal targets with plasma bolts.`,
                                    `"Four percent over baseline," says Dr. Hyun at the end of the run. Before PNAME can say anything else, she continues, "I have plenty of data here to work with; let's not push it. Good job, everyone. I'll get to work in the Factory refining the prototype and let you know when I have something ready for deployment."`
                                ],
                                attr: 'conf',
                                attrValue: 20,
                                metric: 'xp',
                                metricValue: 10,
                               nextStage: 4
                            },
                            fail: {
                                text: [
                                    `PNAME opens a comm channel to the rest of the team. "Let me know when you're ready and we'll run the course." After not hearing a response for a moment, pze turns on the cockpit cameras. PZE can see that two of the other pilots have already hit their injectors. "Everyone how hasn't hit their injectors, go now."`,
                                    `Sliding pzis arm into the injector sleeve, PNAME feels the now-familiar needle and rush of chemicals entering pzis bloodstream. PZE watches the screen and sees the other pilots having the same experience. They recover in staggered groups, but eventually everyone seems to be ready to go.`,
                                    `Having had the most experience with the neural link, PNAME is in the head position, coordinating the actions of the Gauntlet. The team moves well together, clearing the obstacles and scoring high accuracy marks on the targets.`,
                                    `At the end of the course, Dr. Hyun calls over the comm, "One percent above baseline. Not a huge improvement, but good."`,
                                    `PNAME can still feel the link active in pzis brain. ${getFirstName(t[0])} asks, "Do we want to go again?" Everyone acknowledges, and they start the course again, leaping over barriers and blasting metal targets with plasma bolts.`,
                                    `"Three percent over baseline," says Dr. Hyun at the end of the run. "I have plenty of data here to work with; let's call it a day. Good job, everyone. I'll get to work in the Factory refining the prototype and let you know when I have something ready for deployment."`
                                ],
                                metric: 'xp',
                                metricValue: 5,
                                nextStage: 4
                            }
                        },
                        { 
                            attr: 'emp',
                            skill: 'per',
                            dc: 15,
                            level: 'hard',
                            text: `Ease the others through the experience of the system`,
                            success: {
                                text: [
                                    `PNAME opens a comm channel to the rest of the team. "Since the rest of you haven't had the full neural link experience yet, let me walk you through what to expect. First you'll have the initial rush from the injection; you've been through that already. After that you'll feel the Gauntlet telemetry as a sensory overlay through the headset. Take a minute to get used to that and then we'll run the course. We'll hit the injectors on my mark. 3... 2... 1... mark!"`,
                                    `Sliding pzis arm into the injector sleeve, PNAME feels the now-familiar needle and rush of chemicals entering pzis bloodstream. PZE watches the screen and sees the other pilots having the same experience. One by one, they recover and do some experimental movements of the controls to feel the neural feedback. Soon they all give a thumbs up and they prepare to run the course.`,
                                    `Having had the most experience with the neural link, PNAME is in the head position, coordinating the actions of the Gauntlet. The team moves well together, clearing the obstacles and scoring high accuracy marks on the targets.`,
                                    `At the end of the course, Dr. Hyun calls over the comm, "Two percent above baseline. Not a huge improvement, but good."`,
                                    `PNAME can still feel the link active in pzis brain. "Team, are we good to go again?" Everyone acknowledges, and they start the course again, leaping over barriers and blasting metal targets with plasma bolts.`,
                                    `"Four percent over baseline," says Dr. Hyun at the end of the run. Before PNAME can say anything else, she continues, "I have plenty of data here to work with; let's not push it. Good job, everyone. I'll get to work in the Factory refining the prototype and let you know when I have something ready for deployment."`
                                ],
                                attr: 'emp',
                                attrValue: 20,
                                metric: 'xp',
                                metricValue: 10,
                               nextStage: 4
                            },
                            fail: {
                                text: [
                                    `PNAME opens a comm channel to the rest of the team. "Hey folks, you're going to love this. Hit your injectors and let's go for it."`,
                                    `Sliding pzis arm into the injector sleeve, PNAME feels the now-familiar needle and rush of chemicals entering pzis bloodstream. Calling up the cockpit cameras, pze sees that ${getFirstName(t[0])} hasn't started ${getPronoun(t[0], 'pos')} injector yet. "Come on, ${getFirstName(t[0])}, don't keep the rest of us waiting." Though ${getPronoun(t[0], 'sub')} looks apprehensive, after a moment ${getPronoun(t[0], 'sub')} hits the injector. Soon everyone is ready to run the course.`,
                                    `Having had the most experience with the neural link, PNAME is in the head position, coordinating the actions of the Gauntlet. The team moves well together, clearing the obstacles and scoring high accuracy marks on the targets.`,
                                    `At the end of the course, Dr. Hyun calls over the comm, "Two percent above baseline. Not a huge improvement, but good."`,
                                    `PNAME can still feel the link active in pzis brain. "Team, are we good to go again?"`,
                                    `${getFirstName(t[0])} says, "No, I think I've had enough."`,
                                    `"That's fine," says Dr. Hyun. "I have plenty of data here to work with; let's not push it. Good job, everyone. I'll get to work in the Factory refining the prototype and let you know when I have something ready for deployment."`
                                ],
                                metric: ['xp', 'fame'],
                                metricValue: [5, -20],
                                nextStage: 4
                            }
                        },
                        { 
                            attr: 'hum',
                            skill: 'comm',
                            dc: 20,
                            level: 'hard',
                            text: `Let the other pilots determine how fast they will try to take the course`,
                            success: {
                                text: [
                                    `PNAME opens a comm channel to the rest of the team. "Hey everyone, I don't want to rush anyone through this. We don't need to break any records out first time out. Go ahead and hit your injectors when you're ready, and we'll start when everyone has had a minute to get used to it."`,
                                    `Sliding pzis arm into the injector sleeve, PNAME feels the now-familiar needle and rush of chemicals entering pzis bloodstream. PZE watches the screen and sees the other pilots having the same experience. One by one, they recover and do some experimental movements of the controls to feel the neural feedback. Soon they all give a thumbs up and they prepare to run the course.`,
                                    `Having had the most experience with the neural link, PNAME is in the head position, coordinating the actions of the Gauntlet. The team moves well together, clearing the obstacles and scoring high accuracy marks on the targets.`,
                                    `At the end of the course, Dr. Hyun calls over the comm, "One percent above baseline. Not a huge improvement, but good."`,
                                    `PNAME can still feel the link active in pzis brain. "Okay team, now that we've had a first run, are we good to go again?" Everyone acknowledges, and they start the course again, leaping over barriers and blasting metal targets with plasma bolts.`,
                                    `"Four percent over baseline," says Dr. Hyun at the end of the run. Before PNAME can say anything else, she continues, "I have plenty of data here to work with; let's not push it. Good job, everyone. I'll get to work in the Factory refining the prototype and let you know when I have something ready for deployment."`
                                ],
                                attr: 'hum',
                                attrValue: 20,
                                metric: 'xp',
                                metricValue: 10,
                                nextStage: 4
                            },
                            fail: {
                                text: [
                                    `PNAME opens a comm channel to the rest of the team. "Hey folks, I've already done this in sim and it's no big deal. Just hit your injectors and let's go for it."`,
                                    `Sliding pzis arm into the injector sleeve, PNAME feels the now-familiar needle and rush of chemicals entering pzis bloodstream. PZE watches the screen and sees the other pilots having the same experience. Once they all look like they've receovered, PNAME gives the signal to start the course.`,
                                    `Having had the most experience with the neural link, PNAME is in the head position, coordinating the actions of the Gauntlet. The team moves slowly at first, but speeds up quickly, clearing the obstacles and scoring high accuracy marks on the targets after missing the first few.`,
                                    `At the end of the course, Dr. Hyun calls over the comm, "One percent under baseline. Let give it another run."`,
                                    `PNAME can still feel the link active in pzis brain. "Dont' make me look bad people, we can do better than that." Everyone acknowledges, and they start the course again, leaping over barriers and blasting metal targets with plasma bolts.`,
                                    `"Two percent over baseline," says Dr. Hyun at the end of the run. Before PNAME can say anything else, she continues, "I have plenty of data here to work with; let's not push it. Good job, everyone. I'll get to work in the Factory refining the prototype and let you know when I have something ready for deployment."`
                                ],
                                metric: ['xp', 'fame'],
                                metricValue: [5, -20],
                                nextStage: 4
                            }
                        }
                    ]
                }        
            }
        },
        {
            id: 4,
            location: ['factory'],
            hint: `After the successful field test of the control system on PLANETNAME, Dr. Hyun has instructed PNAME to meet her in the Factory to see the final product.`,
            data: (e) => {
                const p = e.pilot
                const planet = e.planet
                
                return {
                    title: 'MIND CONTROL: Apply Directly To The Forehead',
                    description: [
                        `PNAME checks in at the Factory to see if Dr. Hyun has finished the final neural link system yet. "Yes, it's done!" she says. "Come take a look."`,
                        `Compared to the sprawling mass of wires that was the original design, the production design is quite sleek. The brainwave interface is neatly integrated inside a modified flight helmet, while the injector is just a small module that attaches to the arm of a standard pilot's suit.`,
                        `"Based on the data I've collected, it doesn't look like the system does anything like what I originally expected it to. Even in sensory feedback mode, it doesn't significantly improve pilot performance." She holds up a hand to forestall any objection. "However, what it does do is heighten the experience and directly stimulate the amygdala, improving the creation and retention of muscle memory. In short, it helps the user learn faster and better while under its infuence." She pauses for a moment before adding, "The data also suggest that it's probably wise to limit its use until I'm able to make some more refinements to the formula."`,
                        `"In any case, the xenomaterials I need for the injection are hard to come by, so this is definitely not going to be standard issue anytime soon. Occasional use should be safe enough, and it will help you get more out of a challenging battle."`,
                        `How should PNAME respond?`
                    ],
                    pilot: p,
                    planet,
                    options: [
                        { 
                            attr: 'wis',
                            skill: 'per',
                            dc: 10,
                            level: 'med',
                            text: `Weigh the potential dangers of the system against the benefits`,
                            success: {
                                text: [
                                    `PNAME nods as Dr. Hyun explains. "I can see how it might not be a good idea to put a pilot through that too often. Sometimes it would be worth it to be at the top of your game for a big battle though. I'm sure we'll come up with a sensible protocol for it."`,
                                    `Dr. Hyun smiles. "I was really hoping I could crack the control system, but I think this will be a big asset as well. I'm sure you'll put it to good use."`,
                                    `"You can take this unit with you, just make sure to get some rest before you give it a spin."`
                                ],
                                attr: 'wis',
                                attrValue: 10,
                                module: { type: 'neuralLink', rank: 1 },
                                schematic: 'neuralLink',
                                nextStage: 'complete'
                            },
                            fail: {
                                text: [
                                    `PNAME listens as Dr. Hyun explains, then shrugs. "I don't know what your data says, but I feel fine. I would use that thing all the time if I could."`,
                                    `Dr. Hyun frowns. "Believe me when I say you shouldn't. I don't want you to come back and blame me when you start to develop memory loss and seizures."`,
                                    `"On second thought, maybe occasional use is best after all."`
                                ],
                                schematic: 'neuralLink',
                                nextStage: 'complete'
                            }
                        },
                        { 
                            attr: 'emp',
                            skill: 'comm',
                            dc: 10,
                            level: 'med',
                            text: `Commend Dr. Hyun on a job well done`,
                            success: {
                                text: [
                                    `PNAME smiles and shakes Dr. Hyun's hand. "This is an amazing technology, and I'm sure it will be greatly appreciated by everyone who uses it."`,
                                    `Dr. Hyun smiles. "I was really hoping I could crack the control system, but I think this will be a big asset as well. I'm sure you'll put it to good use."`,
                                    `"You can take this unit with you, just make sure to get some rest before you give it a spin."`
                                ],
                                attr: 'emp',
                                attrValue: 10,
                                module: { type: 'neuralLink', rank: 1 },
                                schematic: 'neuralLink',
                                nextStage: 'complete'
                            },
                            fail: {
                                text: [
                                    `PNAME shakes pzis head. "Personally, I'm just glad I made it through this in one piece. I'm a pilot, not a guinea pig."`,
                                    `Dr. Hyun frowns. "I'm sorry you feel that way. Science requires taking risks, but next time I'll find someone else to take them."`
                                ],
                                schematic: 'neuralLink',
                                nextStage: 'complete'
                            }
                        },
                        { 
                            attr: 'dar',
                            skill: 'pilot',
                            dc: 10,
                            level: 'med',
                            text: `Tell her pze looks forward to using the system in the future`,
                            success: {
                                text: [
                                    `PNAME claps Dr. Hyun on the shoulder. "I tell you what, this was a wild ride. I can't wait till I get to take this thing for a spin again."`,
                                    `Dr. Hyun smiles. "I'm glad you're excited about it. You can take this unit with you, just remember what I said about taking it easy."`
                                ],
                                attr: 'dar',
                                attrValue: 10,
                                module: { type: 'neuralLink', rank: 1 },
                                schematic: 'neuralLink',
                                nextStage: 'complete'
                            },
                            fail: {
                                text: [
                                    `PNAME shakes pzis head. "You don't have to worry about me, doc. It's going to be a while before I'm ready to go through that again."`,
                                    `Dr. Hyun frowns. "I hope it wasn't that bad an experience. Once I get a chance to collect more data and do some refinements, I suspect it will go easier in the future."`
                                ],
                                schematic: 'neuralLink',
                                nextStage: 'complete'
                            }
                        }
                    ]
                }        
            }
        }
    ]
}
