import { getRoomIcon } from '../../lib/academy'
import { flipCoin } from '../../lib/mathUtils'

export const recRoom = [
    (e) => {
        const p = e.pilot
        // const o = e.otherPilot

        return {
            title: <div>{ getRoomIcon('recRoom') }REC ROOM: Drinking Contest </div>,
            description: `Someone brought out a bottle of Supernova Moonshine and it sparked an impromptu drinking contest. What should PNAME do?`,
            pilot: p,
            options: [
                { 
                    attr: 'fort',
                    skill: 'combat',
                    dc: 15,
                    level: 'hard',
                    text: `Step up to the challenge`,
                    success: {
                        text: `As pzis fellow pilots cheer pzir on, PNAME knocks back several shots of Supernova and remains upright. The titanium stomach wins again!`,
                        attr: 'fort',
                        attrValue: 20
                    },
                    fail: {
                        text: `PNAME thinks pze is up to the Supernova challenge, but pze is wrong. PZIS night comes to a premature end.`,
                        metric: ['energy', 'fame'],
                        metricValue: [-1 * p.metrics.energy, -10]
                    }
                },
                { 
                    attr: 'wis',
                    skill: 'per',
                    dc: 10,
                    level: 'medium',
                    text: `Gauge pzis chances before committing`,
                    success: {
                        text: flipCoin() ? `Thinking back to previous Supernova experiences, PNAME decides that discretion is the better part of not spending the night in from of a zero-G toilet.` : `After checking out the competition, PNAME decides that pze has a good shot at winning. PZE proves that a wise pilot can accurately predict the outcome of a battle.`,
                        attr: 'wis',
                        attrValue: 10
                    },
                    fail: {
                        text: `PNAME decides to test pzis mettle against pzis fellow pilots, but the Supernova ends up defeating them all. This battle has no winners.`
                    }
                },
                { 
                    text: `Pass the bottle`,
                    level: 'easy',
                    success: {
                        text: `Not everything in life has to be a competiton. PNAME decides to let other people prove themselves this time.`,
                        attr: 'hum',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot
        const o = e.otherPilot

        if (!o) { return null }

        return {
            title: <div>{ getRoomIcon('recRoom') }REC ROOM: Arm Wrestling </div>,
            description: `ONAME is showing off ozis bulging biceps and challenging any takers to arm wrestle. What should PNAME do?`,
            pilot: p,
            otherPerson: o,
            options: [
                { 
                    attr: 'dar',
                    skill: 'comm',
                    dc: 15,
                    level: 'hard',
                    text: `Throw ONAME off ozis game with some trash talk`,
                    success: {
                        text: `"This is why you should stick to piloting legs," says PNAME, "You don't know how arms even work!" As ONAME opens ozis mouth to respond, PNAME slams ozis arm to the table. ONAME smiles and shakes ozis head. "Well played."`,
                        attr: 'dar',
                        attrValue: 20,
                        relationship: o.id,
                        relValue: 1
                    },
                    fail: {
                        text: `Shortly after the match begins, PNAME says "Your mom wrestles better." ONAME scowls, slams PNAME's arm to the table, and storms off.`,
                        relationship: o.id,
                        relValue: -2
                    }
                },
                { 
                    attr: 'prow',
                    skill: 'combat',
                    dc: 10,
                    level: 'medium',
                    text: `Roll up pzis sleeves and go for it`,
                    success: {
                        text: `In a pure test of strength, PNAME's focus and determination pays off. They slowly drive ONAME's arm to the table and emerge victorious.`,
                        attr: 'prow',
                        attrValue: 10,
                        relationship: o.id,
                        relValue: flipCoin() ? 1 : 0
                    },
                    fail: {
                        text: `It turns out those biceps were for real. ONAME easily pins PNAME's arm to the table.`
                    }
                },
                { 
                    text: `Give ozir props on the gains but don't engage`,
                    level: 'easy',
                    success: {
                        text: `PNAME gives ONAME a thumbs up and lets someone else get their arms torn off instead.`,
                        attr: 'emp',
                        attrValue: 5
                    }
                }
            ]
        }
    },
]