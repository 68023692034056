import Entity from './entity'
import { slotTypes } from "./constants";

export default class Combatant extends Entity {
    targetLocation = null
    
    fullHeal() {
        const fullArmor = { ...this.data.maxArmor }

        this.updateData({ armor: fullArmor })
    }

    async applyDamage(slot, damage, crit) {
        const armor = { ...this.data.armor }

        const currArmor = armor[slot]
        let blowthrough = false

        if (this.status.ablativeArmor) {
            // apply damage to ablative armor first if possible
            if (this.status.ablativeArmor < damage) {
                damage -= this.status.ablativeArmor
                this.status.ablativeArmor = 0
            } else {
                this.status.ablativeArmor -= damage
                return false
            }
        }

        let armorDamage = damage
        let critDamage = 0

        if (crit) {
            blowthrough = true
            critDamage = Math.ceil(armorDamage / 3)
            armorDamage -= critDamage
        }

        if (currArmor < armorDamage) {
            // this attack will blow through the slot armor
            blowthrough = true
            armor[slot] = 0
            armor.body = Math.max(0, armor.body - armorDamage)
        } else {
            armor[slot] = currArmor - armorDamage
        }

        armor.body = Math.max(0, armor.body - critDamage)

        // console.log(`doing ${damage} dmg to ${this.type} ${slot}: ${currArmor} -> ${armor[slot]} `)

        await this.updateData( { armor })

        return blowthrough
    }

    async fixDamage(slot, damage) {
        const armor = { ...this.data.armor }

        armor[slot] = Math.min(this.data.maxArmor[slot], armor[slot] + damage)

        await this.updateData( { armor })
    }

    getWorstDamage() {
        let worstSlot = null
        let maxDamage = 0

        for (const slot of slotTypes) {
            const damage = 1 - (this.data.armor[slot] / this.data.maxArmor[slot])
            if (damage > maxDamage) {
                maxDamage = damage
                worstSlot = slot
            }
        }

        return { slot: worstSlot, damage: maxDamage }
    }

    setTargetLocation(location) {
        this.targetLocation = location
    }
}