/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from "d3"
import React, { useEffect } from 'react'
import { randInt } from '../../lib/mathUtils'
import { addMinigameTimer } from './timer'

// import * as d3Base from 'd3'
// import { sineWave } from '../../lib/graphs/d3-sine-wave'

// attach all d3 plugins to the d3 library
// const d3 = Object.assign(d3Base, { sineWave })

export function WaveMatch ({ pilotData, playSoundEffect, gameOver }) {
    useEffect(() => {

        const width = 500;
        const height = 500;
   
        const maxTime = 30 + 5 * pilotData.skills.repair

        const marginX = 50;

        let wonGame = false
        let timer = null

        d3.select('#waveMatch > svg').remove()

        const stopGame = (outcome) => {
            if (timer) {
                timer.stop()
            }

            document.removeEventListener('keydown', handleKeydown)

            gameOver(wonGame || outcome)
        }

        const svg = d3.select('#waveMatch').append("svg")
            .attr('x', marginX)
            .attr("width", width)
            .attr("height", height)
    
        const waveWidth = width - 2 * marginX

        const svgTarget = svg.append("g")      

        const svgPlayer = svg.append("g")
      
        const n = 80

        const ampMin = 1
        const ampMax = 7

        const freqMin = 4
        const freqMax = 10

        const target = {
            freq: randInt(freqMin, freqMax),
            amp: randInt(ampMin, ampMax)
        }

        const player = {
            freq: randInt(freqMin, freqMax),
            amp: randInt(ampMin, ampMax)
        }

        while (player.freq === target.freq || player.amp === target.amp) {
            player.freq = randInt(freqMin, freqMax)
            player.amp = randInt(ampMin, ampMax)
        }

        const displayCircles = () => {
            displayTargetCircles()
            displayPlayerCircles()
        }

        const displayTargetCircles = () => {
            svgTarget.selectAll('.targetCircle').remove()

            const targetCircles = svgTarget.selectAll("circle").data(d3.range(n))
                .enter().append("circle")
                    .attr('class', 'targetCircle')
                    .attr("cx", function(d){ return marginX + (d + 0.5) * waveWidth / n; })
                    .attr("r", width / n / 2)
                    
            d3.timer(function (time){
                targetCircles.attr("cy", (d) => {
                    return (Math.sin(d / (target.freq) + time / 1000)) * height / (3 + 2 * target.amp) + height / 2;
                });
            });
        }

        const displayPlayerCircles = () => {
            svgPlayer.selectAll('.playerCircle').remove()

            const playerCircles = svgPlayer.selectAll("circle").data(d3.range(n))
                .enter().append("circle")
                    .attr('class', 'playerCircle')
                    .attr("cx", function(d){ return marginX + (d + 0.5) * waveWidth / n; })
                    .attr("r", width / n / 2)
          
            d3.timer(function (time){
                playerCircles.attr("cy", (d) => {
                    return (Math.sin(d / (player.freq) + time / 1000)) * height / (3 + 2 * player.amp) + height / 2;
                });
            });
        }

        const winGame = () => {
            // stop listening for keypresses
            document.removeEventListener('keydown', handleKeydown)
            timer.stop()

            wonGame = true

            d3.selectAll('.playerCircle')
                .attr('class', 'matchCircle')

            setTimeout(() => {
                gameOver(true)
            }, 500)
        }

        const handleKeydown = (e) => {
            switch( e.keyCode ) {
                case 37: // left arrow
                case 65: // A
                    if (player.freq > freqMin) {
                        player.freq--
                    }
                    break
                case 38: // up arrow
                case 87: // D
                    if (player.amp > ampMin) {
                        player.amp--
                    }
                    break
                case 39: // right arrow
                case 68: // W
                    if (player.freq < freqMax) {
                        player.freq++
                    }
                    break
                case 40: // down arrow
                case 83: // S
                    if (player.amp < ampMax) {
                        player.amp++
                    }
                    break
                default: 
                    break
            }    

            if (player.freq === target.freq && player.amp === target.amp) {
                playSoundEffect('success')
                winGame()
            } else {
                playSoundEffect('buttonPress')
            }
        }

        document.addEventListener('keydown', handleKeydown)

        //timer
        timer = addMinigameTimer(svg, maxTime, stopGame)

        displayCircles()
    }, [])

    return <div id="waveMatch"></div>
}