import { Icon } from 'semantic-ui-react'
import { getPilotRank }from '../lib/pilot'

export default function RankPips({ pilot, mech, className, size="small" }) {
    if (pilot) {
        return <div className={`rankPips ${className || ''}`}>
            { Array(getPilotRank(pilot)).fill().map((r, i) => <Icon key={i} className="pip" size={ size } name="star"/>) }
        </div>
    } else if (mech) {
        return <div className={`rankPips ${className || ''}`}>
            { Array(mech.rank).fill().map((r, i) => <Icon key={i} className="pip" size={ size } name="star"/>) }
        </div>
    } else {
        return null
    }
}