import React, { useState, useEffect } from 'react'
import { ItemTypes } from '../../lib/constants'
import { useDrop } from 'react-dnd'
import DragPortrait from '../dragPortrait'
import AttrBlock from '../attrBlock'
import SkillBlock from '../skillBlock'
import RankPips from '../rankPips'
import { Icon } from 'semantic-ui-react'
import { loadPilot } from '../../lib/pilot'

const slotHeaderName = {
  head: 'HEAD',
  leftArm: 'LEFT ARM',
  rightArm: 'RIGHT ARM',
  leftLeg: 'LEFT LEG',
  rightLeg: 'RIGHT LEG'
}

function PilotSlot({ db, user, pilotId, slotType, className, selectPilot, updatePilots, context }) {
  const makeAssignments = async (item) => {
    if (item.id && item.id !== pilotData?.id) {
      // moving from pilot list
      updatePilots({ action: 'add', pilotId: item.id, slotType, rank: item.rank })
    } else if (item.slotType) {
      // swapping from another slot
      updatePilots({ action: 'swap', slotA: slotType, slotB: item.slotType})
    }
  }
  
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.PILOT,
    drop: async (item, monitor) => {
      makeAssignments(item)
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  }))

  const [pilotData, setPilotData] = useState(null)

  useEffect(() => {
      const doUpdate = async () => {
        if (pilotId) {
          const pilotData = await loadPilot(db, user, pilotId)
          setPilotData(pilotData)
        } else {
          setPilotData(null)
        }
      }

      doUpdate()
  }, [ db, user, pilotId ])

  const removePilot = () => {
    updatePilots({ action: 'remove', slotType })
  }

  const hasPilot = !!pilotData

  return (
    <div
      className={`pilotSlot ${className}`}
      ref={drop}
    >
      <div className="pilotSlotHeader">
        { slotHeaderName[slotType] }
        { hasPilot && context !== 'combat' && <Icon key={0} className="iconBtn floatRight" name="close" onClick={() => removePilot()}/> }
        { hasPilot && <Icon key={1} className="iconBtn floatRight" name="info circle" onClick={() => selectPilot(pilotId)}/> }
      </div>
      <div className="pilotSlotContent">
        <div className="pilotPortraitFrame">
          <RankPips pilot={pilotData}/>
          <DragPortrait size={90} pilot={pilotData} slotType={slotType} context={context}/>
        </div>
        <div className="pilotSlotInfo">
          { hasPilot ? <div className="pilotData">
            <AttrBlock pilot={pilotData}/>
            <SkillBlock pilot={pilotData}/>
            </div> : null }
        </div>
      </div>
      <div className="pilotSlotName">
        { hasPilot ? pilotData.name : 'Drag pilot to assign' }
      </div>
      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'yellow',
          }}
        />
      )}
    </div>
  )
}

export default PilotSlot