export const commChatter= {
    crit: [
        `Got a good hit in!`,
        `That'll leave a mark!`,
        `Score one for the home team.`
    ],
    retreat: [
        `We could have won that.`,
        `Only cowards leave the field of battle.`,
        `Almost had that one.`
    ],
    damage: [
        `I'm taking damage!`,
        `Gonna need a medic.`,
        `Someone should have blocked that.`
    ],
    block: [
        `Can't get past me!`,
        `Attack intercepted.`,
        `Deflected that one.`
    ],
    dodge: [
        `That was close!`,
        `I'm too fast for you.`,
        `Taking evasive action!`
    ]
}