// import logo from './logo.svg';
import 'semantic-ui-css/semantic.min.css'
import './scss/App.scss';

import { initializeApp, getApps, getApp } from "firebase/app";
import { getDatabase } from 'firebase/database';

import ReactAudioPlayer from 'react-audio-player';

import { 
    GoogleAuthProvider
    , signInWithCredential
    , signInWithRedirect
    , getRedirectResult
    // , signInWithPopup
    , getAuth
    // , useDeviceLanguage 
} from "firebase/auth";

import { firebaseConfig } from './config/firebase'

import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import MainMenu from './components/mainMenu'
import RosterView from './views/roster'
import BattleView from './views/battle'
import AcademyView from './views/academy'

import * as authUtils from './lib/authUtils'

import { PilotInfoModal } from './components/modals/pilotInfoModal'
import { MechInfoModal } from './components/modals/mechInfoModal'
import { RelationshipsModal } from './components/modals/relationshipsModal'
import { MessageModal } from './components/modals/messageModal'
import { EventModal } from './components/modals/eventModal'
import { GuideModal } from './components/modals/guideModal'

import { soundFile } from './lib/constants'

import { Loading } from './components/loading'
import { Academy } from './lib/academy'
import { StarMap } from './lib/starMap'
import { tutorials } from './data/tutorials'
import { storyList } from './data/story'

class App extends Component {
    audioPlayer = null
    defaultVolume = 0.5

    constructor(props) {
        super(props);

        // Don't call this.setState() here!
        this.state = { 
            firebaseApp: null,
            user: null,
            academy: null,
            idToken: null,
            accessToken: null,
            db: null,
            location: '',
            message: null,
            isMessageModalOpen: false,
            event: null,
            isEventModalOpen: false,
            selectedPilot: null,
            pilotIds: null, 
            isPilotInfoModalOpen: false,
            selectedMech: null,
            isMechInfoModalOpen: false,
            isRelationshipsModalOpen: false,
            selectedGuide: {},
            isGuideModalOpen: false,
            isAdmin: false,
            playMusic: false,
            playSound: false,
            musicTrack: '',
            musicVolume: this.defaultVolume,
            eventsResolved: 0,
            update: Date.now()
        };

        this.startAudio = this.startAudio.bind(this);
      }

    componentDidMount() {
        this.initFirebase()

        // this.initAuth()

        this.setLocation(this.props.location)

        document.addEventListener("click", this.startAudio)

        // // Autoplay the video if application is visible
        // if (document.visibilityState === "visible") {
        //   this.playAudio()
        // }

        const thisClass = this

        // Handle page visibility change events
        function visibilityListener() {
          switch(document.visibilityState) {
            case "hidden":
              thisClass.pauseAudio();
              break;
            case "visible":
              thisClass.playAudio();
              break;
            default:
          }
        }

        document.addEventListener("visibilitychange", visibilityListener);
    }

    startAudio() {
      this.audioPlayer = document.getElementsByClassName('react-audio-player')[0]

      if (this.audioPlayer) {
        this.audioPlayer.muted = false
        this.audioPlayer.play()
      }

      // document.removeEventListener("click", this.startAudio)
    }

    playAudio() {
      if (this.audioPlayer) {
        try {
          this.audioPlayer.play()
        } catch (e) {
          console.log('play disabled', e)
        }
      }
    }

    pauseAudio() {
      if (this.audioPlayer) {
        this.audioPlayer.pause()
      }
    }

    setMusicSpeed(speed) {
      if (this.audioPlayer) {
        this.audioPlayer.playbackRate = speed
      }
    }

    setMusicVolume(musicVolume) {
      this.setState({ musicVolume })
    }

    setMusicTrack(musicTrack) {
      this.setState({ musicTrack })
    }

    toggleMusic() {
      const playMusic = this.state.academy.toggleMusic()

      this.setState({ playMusic })
    }

    toggleSound() {
      const playSound = this.state.academy.toggleSound()

      this.setState({ playSound })
    }

    playSoundEffect(name) {
      if (this.state.playSound) {
        const snd = new Audio(`/assets/audio/${soundFile[name]}`)
        snd.play()
      }
    }

    initFirebase() {
      const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

      this.setState({ firebaseApp }, this.initDB)
    }

    initDB() {
      // Get a reference to the database service
      const db = getDatabase(this.state.firebaseApp);
      this.setState({ db }, this.initAuth)
    }

    initAuth() {
      const provider = new GoogleAuthProvider();

      const suppressAdmin = false

      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

      const auth = getAuth();
      // auth.languageCode = 'it';
      // // To apply the default browser preference instead of explicitly setting it.
      // useDeviceLanguage(auth);

      const currToken = authUtils.getCookie('idToken')

      if (currToken) {
          // Build Firebase credential with the Google ID token.
          const credential = GoogleAuthProvider.credential(currToken);

          signInWithCredential(auth, credential)
              .then((result) => {
                  // This gives you a Google Access Token. You can use it to access the Google API.
                  const credential = GoogleAuthProvider.credentialFromResult(result);
                  const accessToken = credential.accessToken;
                  const idToken = credential.idToken

                  authUtils.createCookie('idToken', idToken)

                  // The signed-in user info.
                  const user = result.user;

                  const isAdmin = user.uid === 'gYuTy15fdhQvxkIOszSoHndhMtA2' && !suppressAdmin

                  this.setState({ user, idToken, accessToken, isAdmin }, () => {
                    this.loadAcademy()
                    this.loadStarMap()
                  })
              })
              .catch((error) => {
                  // Handle Errors here.
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  // The email of the user's account used.
                  const email = error.email;
                  // The AuthCredential type that was used.
                  const credential = GoogleAuthProvider.credentialFromError(error);

                  console.log({
                      errorCode,
                      errorMessage,
                      email,
                      credential
                  })

                  authUtils.createCookie('idToken', '')
                  
                  this.initAuth()
              });
      } else {
        getRedirectResult(auth)
          .then((redirectResult) => {
            if (redirectResult) {
              // This gives you a Google Access Token. You can use it to access the Google API.
              const credential = GoogleAuthProvider.credentialFromResult(redirectResult);
              const accessToken = credential.accessToken;
              const idToken = credential.idToken

              authUtils.createCookie('idToken', idToken)

              // The signed-in user info.
              const user = redirectResult.user;

              const isAdmin = user.uid === 'gYuTy15fdhQvxkIOszSoHndhMtA2' && !suppressAdmin

              this.setState({ user, idToken, accessToken, isAdmin }, () => {
                this.loadAcademy()
                this.loadStarMap()
              })
            } else {              
              signInWithRedirect(auth, provider)
                  .then((result) => {
                      // This gives you a Google Access Token. You can use it to access the Google API.
                      const credential = GoogleAuthProvider.credentialFromResult(result);
                      const accessToken = credential.accessToken;
                      const idToken = credential.idToken
    
                      authUtils.createCookie('idToken', idToken)
    
                      // The signed-in user info.
                      const user = result.user;
    
                      this.setState({ user, idToken, accessToken })
                  }).catch((error) => {
                      // Handle Errors here.
                      const errorCode = error.code;
                      const errorMessage = error.message;
                      // The email of the user's account used.
                      const email = error.email;
                      // The AuthCredential type that was used.
                      const credential = GoogleAuthProvider.credentialFromError(error);
    
                      console.log({
                          errorCode,
                          errorMessage,
                          email,
                          credential
                      })
                  });
              }
          })
      }
    }
    
    setLocation(location) {
      this.setState({ location })
    }

    hasSeenTutorial(name) {
      const { academy } = this.state
      return academy?.data?.tutorials[name]
    }

    showTutorial(name, section, item) {
      const { academy } = this.state

      if (name === 'guide') {
        const tag = item ? `${section}-${item}` : section

        if (!academy?.data?.tutorials[tag]) {
          this.showGuide(section, item)
          academy.markTutorialComplete(tag)
          return true
        } else {
          return false
        }
      } else {
        if (!academy?.data?.tutorials[name]) {
          this.showMessage(tutorials[name])
          academy.markTutorialComplete(name)
          return true
        } else {
          return false
        }
      }
    }

    async loadAcademy() {
      const { user, db } = this.state
      const thisClass = this

      const academy = new Academy(db, user)

      const loadInterval = setInterval(async () => {
        if (academy.data) {
          clearInterval(loadInterval)
          const payload= {
            lastLogin: Date.now()
          }
          await academy.updateData(payload)
          thisClass.setState({ 
            academy, 
            playMusic: academy.data.settings.playMusic, 
            playSound: academy.data.settings.playSound,
            musicVolume: academy.data.settings.volume 
          })
        }
      }, 300)
    }

    async loadStarMap() {
      const { user, db } = this.state
      const thisClass = this

      const starMap = new StarMap(db, user)

      const loadInterval = setInterval(async () => {
        if (starMap.data) {
          clearInterval(loadInterval)
          thisClass.setState({ starMap })
        }
      }, 300)
    }

    addCredits(amount) {
      this.state.academy.addCredits(amount)
      this.setState({ update: Date.now() })
    }

    selectPilot(pilotId, pilotIds = [ pilotId ]) {
      if (pilotId) {
        this.playSoundEffect('openBeep')

        this.setState({
          selectedPilot: pilotId,
          pilotIds,
          isPilotInfoModalOpen: true
        })
      } else {
        this.playSoundEffect('closeBeep')

        this.setState({
          selectedPilot: pilotId,
          pilotIds,
          isPilotInfoModalOpen: false
        })
      }
    }

    selectMech(mechId) {
      if (mechId) {
        this.playSoundEffect('openBeep')

        this.setState({
          selectedMech: mechId,
          isMechInfoModalOpen: true
        })
      } else {
        this.playSoundEffect('closeBeep')

        this.setState({
          selectedMech: mechId,
          isMechInfoModalOpen: false
        })
      }
    }

    showGuide(section, item) {
      if (section) {
        this.playSoundEffect('openBeep')

        this.setState({
          selectedGuide: { section, item },
          isGuideModalOpen: true
        })
      } else {
        this.playSoundEffect('closeBeep')

        this.setState({
          selectedGuide: {},
          isGuideModalOpen: false
        })
      }
    }

    showMessage(message) {
      if (message) {
        this.playSoundEffect('openBeep')

        this.setState({
          message,
          isMessageModalOpen: true
        })
      } else {
        this.playSoundEffect('closeBeep')

        this.setState({
          message,
          isMessageModalOpen: false
        })
      }
    }

    async showEvent(event, result) {
      if (event) {
        if (result) {
          if (event.isStory) {
            // process the story result
            const storyId = event.id
            const storyName = event.name
            const nextStageId = result.nextStage
            const planet = event.data.planet || event.planet || ''
            const storyData = event.data.storyData || ''
            let location = ''
            let hint = ''
            let reset = false

            if (nextStageId === 'complete') {
              hint = event.completeText
            } else if (nextStageId !== 'reset') {
              const nextStageData = storyList[storyId].stages[nextStageId]
              hint = nextStageData.hint
              location = nextStageData.location
            }

            if (planet && planet.starId && result.markPlanet) {
              const marked = await this.state.starMap.markStarKnown(planet.starId)

              if (marked) {
                this.loadStarMap()
              }
            }

            const storyState = {
              stageId: nextStageId,
              name: storyName,
              pilotId: event.pilot.id,
              pilotName: event.pilot.name,
              otherPilotId: event.data.otherPerson?.id || '',
              otherPilotName: event.data.otherPerson?.name || '',
              location,
              planet,
              storyData,
              reset, 
              hint
            }

            this.state.academy.setStoryState(storyId, storyState)
          }

          if (result.markRaiders) {
            const starId = result.markRaiders.planet.starId
            const planetId = result.markRaiders.planet.planetId
            const rank = result.markRaiders.rank

            const marked = await this.state.starMap.markRaiders(starId, planetId, rank)

            if (marked) {
              this.loadStarMap()
            }
          }

          // only close the modal when the event is resolved
          this.playSoundEffect('closeBeep')

          this.setState({
            event: null,
            eventsResolved: this.state.eventsResolved + 1,
            isEventModalOpen: false
          })
        } else {
          this.playSoundEffect('openBeep')

          this.setState({
            event,
            isEventModalOpen: true
          })
        }
      }
    }

    showRelationships() {
      this.playSoundEffect(this.state.isRelationshipsModalOpen ? 'closeBeep' : 'openBeep')

      this.setState({
        isRelationshipsModalOpen: !this.state.isRelationshipsModalOpen
      })
    }

    render() {
      const initialized = !!this.state.academy?.data && !!this.state.starMap?.data

      const commonProps = {
        db: this.state.db,
        user: this.state.user,
        isAdmin: this.state.isAdmin,
        academy: this.state.academy,
        starMap: this.state.starMap,
        setMusicTrack: this.setMusicTrack.bind(this),
        setMusicSpeed: this.setMusicSpeed.bind(this),
        setMusicVolume: this.setMusicVolume.bind(this),
        playSoundEffect: this.playSoundEffect.bind(this),
        selectPilot: this.selectPilot.bind(this),
        selectMech: this.selectMech.bind(this),
        showGuide: this.showGuide.bind(this),
        showMessage: this.showMessage.bind(this),
        showEvent: this.showEvent.bind(this),
        showRelationships: this.showRelationships.bind(this),
        showTutorial: this.showTutorial.bind(this),
        hasSeenTutorial: this.hasSeenTutorial.bind(this)
      }

      const modalProps = {
        db: this.state.db,
        user: this.state.user,
        isAdmin: this.state.isAdmin,
        academy: this.state.academy,
        starMap: this.state.starMap,
        setMusicTrack: this.setMusicTrack.bind(this),
        setMusicSpeed: this.setMusicSpeed.bind(this),
        playSoundEffect: this.playSoundEffect.bind(this),
        showGuide: this.showGuide.bind(this),
        showTutorial: this.showTutorial.bind(this),
        showMessage: this.showMessage.bind(this)
      }

      return (
      <DndProvider backend={HTML5Backend}>
        <Router>
          <MainMenu 
            credits={ this.state.academy?.credits } 
            addCredits={ this.addCredits.bind(this) } 
            update={ this.state.update } 
            toggleMusic={ this.toggleMusic.bind(this) }
            toggleSound={ this.toggleSound.bind(this) }
            playMusic={ this.state.playMusic }
            playSound={ this.state.playSound }
            { ... commonProps }
          />
          <ReactAudioPlayer
            // ref={(element) => { this.audioPlayer = element; }}
            src={ this.state.musicTrack }
            volume={ this.state.playMusic ? this.state.musicVolume : 0 }
            loop={ true }
          />
          { initialized ? 
            <Switch>
              <Route path="/roster" render={props => (
                <RosterView { ...props } { ...commonProps }/>
              )}/>
              <Route path="/battle" render={props => (
                <BattleView { ...props } { ...commonProps }/>
              )}/>
              <Route path="/academy" render={props => (
                <AcademyView 
                  { ...props } 
                  { ...commonProps }
                  eventsResolved={ this.state.eventsResolved }
                />
              )}/>
              <Route path="/" render={props => (
                <AcademyView { ...props } { ...commonProps }/>
              )}/>
            </Switch> : <Loading/>
          }
          <PilotInfoModal 
            pilotId={this.state.selectedPilot}
            pilotIds={this.state.pilotIds}
            isOpen={this.state.isPilotInfoModalOpen}
            toggleModal={this.selectPilot.bind(this)}
            showRelationships={ this.showRelationships.bind(this) }
            { ...modalProps }
            />
          <MechInfoModal 
            mechId={this.state.selectedMech}
            isOpen={this.state.isMechInfoModalOpen}
            toggleModal={this.selectMech.bind(this)}
            { ...modalProps }
          />
          <RelationshipsModal 
            isOpen={this.state.isRelationshipsModalOpen}
            toggleModal={this.showRelationships.bind(this)}
            { ...modalProps }
          />
          <EventModal
            event={this.state.event}
            isOpen={this.state.isEventModalOpen}
            toggleModal={this.showEvent.bind(this)}
            { ...modalProps }
          />
          <GuideModal
            selectedGuide={this.state.selectedGuide}
            isOpen={this.state.isGuideModalOpen}
            toggleModal={this.showGuide.bind(this)}
            { ...modalProps }
          />
          <MessageModal
            message={this.state.message}
            isOpen={this.state.isMessageModalOpen}
            toggleModal={this.showMessage.bind(this)}
            { ...modalProps }
          />
        </Router>
      </DndProvider>
      )
    }
  }

  export default App