import React, { useState, useEffect } from 'react'
import { Button, Grid, Modal, Menu, Image, Icon, Popup } from 'semantic-ui-react'
import { MechView } from '../mechView'
import { mechList, mechSpecials } from '../../data/mech'
import { numerals } from '../../lib/constants'
import { generateMech, getSpeedDesc } from '../../lib/mech'
import { camelCaseToTitleCase } from '../../lib/textUtils'
import { getRandomItem } from '../../lib/mathUtils'

export function MechPurchaseModal ({ isOpen, academy, toggleModal }) {
    const [make, setMake] = useState('Triton')
    const [model, setModel] = useState('')
    const [rank, setRank] = useState(1)
    const [leftWeapon, setLeftWeapon] = useState('boltCannon')
    const [rightWeapon, setRightWeapon] = useState('plasmaBeam')
    const [price, setPrice] = useState({ credits: 0, xenos: 0 })
    const [selectedMech, setSelectedMech] = useState(null)
    useEffect(() => {
        if (rank) {
            setPrice({ credits: rank * 3000, xenos: 2 * (rank - 1)})
        }
    }, [ rank ])
    
    const [showroom, setShowroom] = useState(['foo'])

    useEffect(() => {
        const buildMechs = async () => {
            const out = []
    
            for (const model in mechList[make].models) {
                const data = await generateMech(null, {}, make, model, rank, leftWeapon, rightWeapon)
    
                out.push(data)
            }
    
            setShowroom(out)
        }

        if (isOpen) {
            buildMechs()
        }
    }, [ make, rank, leftWeapon, rightWeapon, isOpen ])

    const closeModal = (makePurchase = false) => {
        if (makePurchase) {
            const out = {
                price,
                mech: selectedMech
            }
            toggleModal(false, out)
        } else {
            toggleModal(false)
        }
    }

    const holdingPen = () => {
        console.log(model)
        setModel('foo')
        setRank('foo')
        setLeftWeapon('foo')
        setRightWeapon('foo')
    }

    useEffect(() => {
        let theMech = showroom.find((m) => m.model === model)

        if (!theMech) {
            theMech = getRandomItem(showroom)
        }

        setSelectedMech(theMech)
    }, [ model, showroom ])

    if (false) {
        holdingPen()
    }
    
    const getSpecialInfo = (special) => {
        return <Popup
            trigger={ <Icon className="floatRight" name="info circle"/> }
            on='click'
            position="right center"
        >
            { mechSpecials[special] }
        </Popup>
    }

    return (
        <Modal
            className="mechPurchaseModal"
            size={'large'}
            open={isOpen}
            onClose={() => closeModal()}
        >
            <Modal.Header>
                <h1 className="ui">Purchase Gauntlet</h1>
            </Modal.Header>
            <Modal.Content>
                <Menu className="modalMenu">
                    <Menu.Item 
                        className="menuItem"
                        active={ make === 'Triton' }
                        onClick={ () => setMake('Triton') }
                    >
                        <div className="menuLogo">
                            <Image 
                                height={30}
                                src={`/assets/mechs/triton/logo/color.svg`}
                            />
                        </div>
                        Triton
                    </Menu.Item>
                    <Menu.Item 
                        className="menuItem"
                        active={ make === 'Orbus' }
                        onClick={ () => setMake('Orbus') }
                    >
                        <div className="menuLogo">
                            <Image 
                                height={30}
                                src={`/assets/mechs/orbus/logo/color.svg`}
                            />
                        </div>
                        Orbus
                    </Menu.Item>
                    <Menu.Item 
                        className="menuItem"
                        active={ make === 'Diablo' }
                        onClick={ () => setMake('Diablo') }
                    >
                        <div className="menuLogo">
                            <Image 
                                height={30}
                                src={`/assets/mechs/diablo/logo/color.svg`}
                            />
                        </div>
                        Diablo
                    </Menu.Item>
                    <Menu.Menu position="right">
                        Available: <div className="availFunds"><Icon name="cube"/>{ academy?.credits }</div> <div className="availFunds"><Icon name="gem outline"/>{ academy?.xenos }</div>
                    </Menu.Menu>
                </Menu>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="makeSpecials">
                                <p><b>Signature Abilities:</b></p>
                                <div><b>Head:</b> { camelCaseToTitleCase(mechList[make].special.head) } { getSpecialInfo(mechList[make].special.head) }</div>
                                <div><b>Arms:</b> { camelCaseToTitleCase(mechList[make].special.arm) } { getSpecialInfo(mechList[make].special.arm) }</div>
                                <div><b>Legs:</b> { camelCaseToTitleCase(mechList[make].special.leg) } { getSpecialInfo(mechList[make].special.leg) }</div>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div className="makeNotes">
                                { mechList[make].notes }
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            { showroom.map((s, i) => <div key={i} className="showroom" onClick={() => setModel(s.model)}>
                                <div className={`mechImage ${selectedMech?.model === s.model ? "selected" : ''}`}>
                                    <MechView subject={s} size={240} context="list"/>
                                </div>
                                <div className="mechModel">
                                    { s.model } { numerals[s.rank - 1] }
                                </div>
                                <div className="mechStats">
                                    <b>Armor:</b> { s.maxArmor?.head } / { s.maxArmor?.body }
                                </div>
                                <div className="mechStats">
                                    <b>Speed:</b> { s.speed?.toFixed(1) } ({ getSpeedDesc(s.speed)})
                                </div>
                            </div> )}
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <h3>Rank</h3>
                            <div className="rankButtons">
                                { Array(5).fill().map((n, i) => <Button key={i} className={ `rankBtn ${rank === i + 1 ? 'active' : ''}` } onClick={ () => setRank(i + 1) }>{ numerals[i] }</Button>)}
                            </div>
                            <div className="price">
                                <div><b>Total Cost: </b></div>
                                <div><Icon name="cube"/>{ price.credits }</div>
                                <div><Icon name="gem outline"/>{ price.xenos }</div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
            {/* <Button negative onClick={() => toggleModal()}>
                No
            </Button> */}
            <Button positive className={ academy.credits < price.credits || academy.xenos < price.xenos ? 'disabled' : ''} onClick={() => closeModal(true)}>
                Purchase
            </Button>
            <Button positive onClick={() => closeModal()}>
                Cancel
            </Button>
            </Modal.Actions>
        </Modal>
    )
}