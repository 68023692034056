import ReactDOM from "react-dom";

import { MinigameModal } from '../components/modals/minigameModal'
import { pickSlot } from './mech'
import { slotNames, slotClass, slotTypes } from './constants'
import { weaponList } from '../data/weapon'

import { randInt, checkRoll, getRankedValue, getRandomItem, flipCoin, oneIn } from './mathUtils'
import { sortByKey } from './arrayUtils'
import { camelCaseToTitleCase } from './textUtils'

export const doPilotAction = async (pilot, team, enemyObj, tick, timeFactor, relFactor, addStats, addComm, playSoundEffect, academy, noRetreat = false) => {
    const enemy = enemyObj.data ? enemyObj : enemyObj.mech
    const enemyPilots = enemyObj.mech ? enemyObj.pilots : null

    const isPlayer = !noRetreat

    const { pilots, mech } = team
    const pilotClass = slotClass[pilot.slot]

    const attrs = pilot.data.attributes
    const skills = pilot.data.skills

    let minigameChance = 0
    
    switch (academy.data.settings.minigames) {
        case 'sometimes':
            minigameChance = 4
            break
        case 'often':
            minigameChance = 2
            break
        default:
    }

    let actionDesc = 'Did nothing'
    let actionSkill = ''
    let retreat = false

    const enemyName = enemyObj.mech ? `${enemy.data.make} ${enemy.data.model}` : camelCaseToTitleCase(enemy.data.name)

    const getRanked = (baseValue, factor = 0.5) => {
        return Math.ceil(getRankedValue(baseValue, mech.data.rank, factor))
    }

    const playMinigame = (pilotData, gameType) => {
        return new Promise((resolve, reject) => {
            addDialog(pilotData, gameType, resolve);
        });
    }

    function addDialog(pilotData, gameType, resolve) {
        const body = document.getElementsByTagName("body")[0];
        const div = document.createElement("div");
        div.setAttribute("id", "minigame-container");
        body.appendChild(div);
        ReactDOM.render(
            <MinigameModal 
                gameType={gameType} 
                pilotData={pilotData} 
                playSoundEffect={playSoundEffect} 
                finishGame={resolve} 
            />,
            div
        );
    }

    function removeDialog() {
        const div = document.getElementById("minigame-container");
        const body = document.getElementsByTagName("body")[0];
        body.removeChild(div);
    }

    // const minigameResult = await playMinigame(pilot.data, 'circuitMaze')
    // removeDialog()

    const specialAction = async () => {
        const special = mech.data.special[pilotClass]

        let didSpecial = false

        if (pilotClass === 'arm' && mech.status.distract) {
            return didSpecial
        }

        switch (special) {
            case 'distract':
                let distractAmount = 1

                if (isPlayer && oneIn(minigameChance)) {
                    const minigameResult = await playMinigame(pilot.data, 'holoSync')
                    distractAmount = minigameResult ? 2 : 0
                    removeDialog()
                }

                if (distractAmount) {
                    enemy.applyStatus('distract', distractAmount)
                    actionDesc = `Distracted ${enemyName} with a holographic projection`
                    actionSkill = 'comm'
                    didSpecial = true
                    addStats(pilot.id, 'specials', 1)
                } else {
                    actionDesc = `Tried to distract ${enemyName} but failed`
                    actionSkill = 'comm'
                    didSpecial = true
                }
                break
            case 'fork':
                const attackSlot1 = pickSlot()
                const attackSlot2 = pickSlot(attackSlot1)

                const { totalDamage: damage1, isCrit: crit1 } = await doAttack(attackSlot1)
                const { totalDamage: damage2, isCrit: crit2 } = await doAttack(attackSlot2)

                actionDesc = `Attacked ${enemyName}'s ${slotNames[attackSlot1]} for ${damage1}${crit1 ? ' [CRITICAL]' : ''} and ${slotNames[attackSlot2]} for ${damage2}${crit2 ? ' [CRITICAL]' : ''}`
                actionSkill = 'combat'
                didSpecial = true
                addStats(pilot.id, 'specials', 1)
                break
            case 'coreRepair':
                const bodyDamaged = mech.data.armor.body < mech.data.maxArmor.body

                if (bodyDamaged) {
                    const repairAmount = await doRepair('body')
                    actionDesc = `Repaired Body for ${repairAmount}`
                    actionSkill = 'repair'
                    didSpecial = true
                    addStats(pilot.id, 'specials', 1)
                }
                break
            case 'overhaul': {
                let repairAmount = 0

                for (const slot of slotTypes) {
                    // repair all slots but only at half strength
                    repairAmount = await doRepair(slot, repairAmount, 0.5)
                }

                actionDesc = `Overhauled all armor locations for ${repairAmount}`
                actionSkill = 'repair'
                didSpecial = true
                addStats(pilot.id, 'specials', 1)
                break
            }
            case 'rush': {
                let bolsterAmount = 0

                for (const slot of slotTypes) {
                    bolsterAmount = bolsterPilot(slot, bolsterAmount)
                }

                actionDesc = `Invigorated all pilots by ${bolsterAmount}`
                actionSkill = 'comm'
                didSpecial = true
                addStats(pilot.id, 'specials', 1)
                break
            }
            case 'powerShot': {
                const attackSlot = targetSlot()

                let maxDamage = true
                let autoCrit = false

                if (isPlayer && oneIn(minigameChance)) {
                    const minigameResult = await playMinigame(pilot.data, 'circuitMaze')
                    maxDamage = minigameResult
                    autoCrit = minigameResult
                    removeDialog()
                }

                const { totalDamage, isCrit } = await doAttack(attackSlot, { maxDamage, autoCrit })

                actionDesc = `Power attacked ${enemyName}'s ${slotNames[attackSlot]} for ${totalDamage}${isCrit ? ' [CRITICAL]' : ''}`
                actionSkill = 'combat'
                didSpecial = true
                addStats(pilot.id, 'specials', 1)
                break
            }
            case 'pierce': {
                const attackSlot = targetSlot()

                let maxDamage = false
                let autoCrit = true

                if (isPlayer && oneIn(minigameChance)) {
                    const minigameResult = await playMinigame(pilot.data, 'centerTarget')
                    maxDamage = minigameResult
                    autoCrit = minigameResult
                    removeDialog()
                }

                const { totalDamage, isCrit } = await doAttack(attackSlot, { maxDamage, autoCrit })

                actionDesc = `Attacked ${enemyName}'s ${slotNames[attackSlot]} for ${totalDamage}${isCrit ? ' [CRITICAL]' : ''}`
                actionSkill = 'combat'
                didSpecial = true
                addStats(pilot.id, 'specials', 1)
                break
            }
            case 'stun': {
                let stunName = enemyName

                if (!enemyPilots && enemy.status.stun) {
                    // try to avoid stun lock on kaiju
                    actionDesc = `Tried to stun ${stunName} but failed`
                    actionSkill = 'comm'
                    didSpecial = true
                    addStats(pilot.id, 'specials', 1)
                } else {
                    if (enemyPilots) {
                        const thePilot = enemyPilots[getRandomItem(slotTypes)]
                        stunName = thePilot.data.name
                        thePilot.incrementNextAction()
                        thePilot.incrementNextSecondary()
                    } else {
                        enemy.incrementNextAction()
                        enemy.applyStatus('stun', 1)
                    }

                    actionDesc = `Stunned ${stunName} with a sonic burst`
                    actionSkill = 'comm'
                    didSpecial = true
                    addStats(pilot.id, 'specials', 1)
                }
                break
            }
            case 'staticPulse': {
                let targetWeapon = null

                let pulses = 1

                if (isPlayer && oneIn(minigameChance)) {
                    const minigameResult = await playMinigame(pilot.data, 'waveMatch')
                    pulses = minigameResult ? 2 : 0
                    removeDialog()
                }

                if (pulses > 0) {
                    let weaponName = ''

                    if (enemyPilots) {
                        if (pulses === 2) {
                            targetWeapon = enemy.data['leftWeapon']
                            enemy.setWeaponStatus('leftWeapon', 'offline')
                            weaponName = targetWeapon.name
                            targetWeapon = enemy.data['rightWeapon']
                            enemy.setWeaponStatus('rightWeapon', 'offline')
                            weaponName += ` and ${targetWeapon.name}`
                        } else {
                            const weaponKey = flipCoin() ? 'leftWeapon' : 'rightWeapon'
                            targetWeapon = enemy.data[weaponKey]
                            enemy.setWeaponStatus(weaponKey, 'offline')
                            weaponName = targetWeapon.name
                        }
                    } else {
                        const enemyWeapons = sortByKey(enemy.weapons, 'nextAttack')

                        // find the weapon that will fire next
                        if (pulses === 2) {
                            targetWeapon = enemyWeapons[0]
                            targetWeapon.nextAttack += targetWeapon.speed
                            weaponName = targetWeapon.name
                            targetWeapon = enemyWeapons[1]
                            targetWeapon.nextAttack += targetWeapon.speed
                            weaponName += ` and ${targetWeapon.name}`

                        } else {
                            targetWeapon = enemyWeapons[0]
                            targetWeapon.nextAttack += targetWeapon.speed
                            weaponName = targetWeapon.name
                        }
                    }
    
                    actionDesc = `Drained ${enemyName}'s ${weaponName} with a static pulse`
                    actionSkill = 'repair'
                    didSpecial = true
                    addStats(pilot.id, 'specials', 1)
                } else {
                    actionDesc = `Tried to drain ${enemyName}'s weapons with a static pulse but failed`
                    actionSkill = 'repair'
                    didSpecial = true
                    addStats(pilot.id, 'specials', 1)
                }
                break
            }
            default:
        }

        return didSpecial
    }

    const doAttack = async (attackSlot, options = {}) => {
        const weapon = pilot.slot === 'leftArm' ? mech.data.leftWeapon : mech.data.rightWeapon
        const attackMax = Math.ceil(((attrs.prow / 3) * skills.combat + getRanked(weapon.dam)) * mech.data.speed * relFactor)

        const critBonus = Math.ceil((attrs.dar / 2) + skills.per)
        const critRoll = randInt(1, 20) + critBonus
        const isCrit = options.autoCrit || critRoll >= 20

        if (isCrit) {
            if (oneIn(3)) {
                addComm(pilot, 'crit')
            }
            if (flipCoin()) {
                playSoundEffect(weaponList[weapon.type].sound)
            }
        }

        let attackDamage = 0
        let totalDamage = 0

        for (let i = 0; i < weapon.burst; i++) {
            attackDamage = options.maxDamage ? attackMax : randInt(Math.ceil(skills.combat / 2), attackMax)

            attackDamage = Math.round(attackDamage * timeFactor)

            if (mech.status.weaponBoost) {
                const statusFactor = 1.1 + (0.1 * mech.status.weaponBoost)
                attackDamage = Math.ceil(attackDamage * statusFactor)
            }

            totalDamage += attackDamage
            const blowthrough = await enemy.applyDamage(attackSlot, attackDamage, isCrit)
            if (blowthrough && enemyPilots) {
                const pilotAngry = await enemyObj.damagePilot(attackSlot, 1, addStats)
                if (pilotAngry) {
                    addComm(enemyObj.pilots[attackSlot], `damage`)
                }
            }
        }

        addStats(pilot.id, 'damageDone', totalDamage)
        if (isCrit) { addStats(pilot.id, 'crits', 1) }

        return { totalDamage, isCrit }
    }

    const doRepair = async (repairSlot, amount, repairFactor = 1) => {
        const repairMax = Math.ceil((attrs.reas / 2) * skills.repair * relFactor * repairFactor)
        let repairAmount = amount || getRanked(randInt(Math.ceil(repairMax / 3), repairMax))
        if (!amount && mech.status.computerBonus) {
            const statusFactor = 1.1 + (0.1 * mech.status.computerBonus)
            repairAmount = Math.ceil(repairAmount * statusFactor)
        }
        await mech.fixDamage(repairSlot, repairAmount)

        addStats(pilot.id, 'damageRepaired', repairAmount)

        return repairAmount
    }

    const targetSlot = (headTarget) => {
        // try to find the weak spot
        const weakSpot = enemy.getWorstDamage()
        const findWeakSpotDC = 20 - (10 * weakSpot.damage)
        let findWeakSpotBonus = Math.ceil((attrs.reas / 2) + skills.per)
        if (mech.status.computerBonus) {
            findWeakSpotBonus += mech.status.computerBonus
        }
        const findWeakSpotResult = checkRoll(findWeakSpotDC, findWeakSpotBonus)

        if (headTarget) {
            return {
                success: findWeakSpotResult.success,
                slot: weakSpot.slot || pickSlot()
            }
        } else {
            if (findWeakSpotResult.success) { addStats(pilot.id, 'findWeakSpot', 1) }

            return findWeakSpotResult.success && weakSpot.slot ? weakSpot.slot : pickSlot()
        }
    }

    const bolsterPilot = (slot, amount) => {
        const bolsterPilot = pilots[slot]
        const bolsterAmount = amount || Math.ceil((attrs.conf / 2) * skills.comm * relFactor)
        bolsterPilot.reduceNextAction(bolsterAmount)

        addStats(pilot.id, 'bolstered', bolsterAmount)

        return bolsterAmount
    }

    const checkForBlock = (weapon) => {
        const blockSlots = ['leftArm', 'rightArm', 'leftLeg', 'rightLeg']

        let blocker = null
        let slot = null

        for (const s of blockSlots) {
            const b = enemyPilots[s]

            if (b.nextSpecial >= 5) { // if the pilot has enough energy to block/dodge
                if (!blocker || b.nextSecondary <= tick) { // if they will go first
                    blocker = b
                    slot = s
                }
            }
        }

        if (blocker) {
            const attrs = blocker.data.attributes
            const skills = blocker.data.skills

            const blockDC = weapon.acc + skills.combat + Math.ceil(attrs.prow / 2)

            blocker.incrementNextSecondary()

            if (slotClass[slot] === 'arm') {
                let blockBonus = Math.ceil((attrs.wis / 2) + skills.reaction)
                if (mech.status.speedBoost) {
                    blockBonus += mech.status.speedBoost
                }
                const blockResult = checkRoll(blockDC, blockBonus)

                if (blockResult.success) {
                    actionDesc = `Attacked with ${weapon.name} but it was blocked by ${blocker.data.name}!`
                    actionSkill = 'reaction'

                    addStats(blocker.id, 'attacksBlocked', 1)

                    if (blockResult.crit) {
                        if (oneIn(3)) {
                            addComm(blocker, 'block')
                        }
                        actionDesc += ' [PERFECT BLOCK]'
                        addStats(blocker.id, 'perfectBlocks', 1)
                    }

                    return true
                } else {
                    // block failed!
                    return false
                }
            } else {
                let blockBonus = Math.ceil((attrs.dar / 2) + skills.pilot)

                if (mech.status.evasion) {
                    blockBonus += mech.status.evasion
                    mech.status.evasion = 0
                }

                const blockResult = checkRoll(blockDC, blockBonus)

                if (blockResult.success) {
                    actionDesc = `Attacked with ${weapon.name} but it was dodged by ${blocker.data.name}!`
                    actionSkill = 'pilot'

                    addStats(blocker.id, 'attacksDodged', 1)

                    if (blockResult.crit) {
                        if (oneIn(3)) {
                            addComm(blocker, 'dodge')
                        }
                        actionDesc += ' [PERFECT DODGE]'
                        addStats(blocker.id, 'perfectDodges', 1)
                    }

                    return true
                } else {
                    // block failed!
                    return false
                }
            }
        } else {
            // no one is available to block
            return false
        }
    }

    let doSpecial = false

    if (pilot.nextSpecial >= 10) {
        doSpecial = true
    }

    let didSpecial = false

    if (doSpecial) {
        didSpecial = await specialAction()
    }

    if (didSpecial) {
        pilot.reduceNextSpecial(10)
    } else {
        switch (pilot.slot) {
            case 'head':
                if (!noRetreat) {
                    // retreat check
                    const bodyDamage = 1 - (mech.data.armor.body / mech.data.maxArmor.body)
                    const worstPilot = team.getWorstPilot()

                    const threshold = (attrs.dar - attrs.wis) * 0.02

                    if (bodyDamage > 0.8 + threshold || (worstPilot.slot && worstPilot.damage > 0.7 + threshold)) {
                        actionDesc = `Called a retreat`
                        actionSkill = 'comm'
                        retreat = true

                        addStats(pilot.id, 'callRetreat', 1)

                        break
                    }
                }
                if (pilot.nextSecondary <= tick && !enemy.targetSlot) {
                    // target a location if you can
                    const weakSpot = targetSlot(true)
                    if (weakSpot.success) {
                        enemy.setTargetLocation(weakSpot.slot)
                        actionDesc = `Targeted ${enemyName}'s ${camelCaseToTitleCase(weakSpot.slot)} for attack`
                        actionSkill = 'per'

                        addStats(pilot.id, 'targetsFound', 1)
                    } else {
                        actionDesc = `Tried to spot a target on ${enemyName} but failed`
                        actionSkill = 'per'
                    }
                    pilot.incrementNextSecondary()
                } else {
                    // bolster
                    const bolsterSlot = pickSlot('head')
                    const bolsterAmount = bolsterPilot(bolsterSlot)
                    actionDesc = `Bolstered ${pilots[bolsterSlot].data.name} by ${bolsterAmount}`
                    actionSkill = 'comm'
                }
                break
            case 'leftArm':
            case 'rightArm':
                // attack
                const attackOptions = {}

                let attackSlot = ''

                const isDistracted = mech.status?.isDistracted

                const weaponKey = pilot.slot === 'leftArm' ? 'leftWeapon' : 'rightWeapon'
                const theWeapon = mech.data[weaponKey]

                if (theWeapon.status === 'offline') {
                    mech.setWeaponStatus(weaponKey, 'online')
                    const weaponName = camelCaseToTitleCase(theWeapon.name)
                    actionDesc = `Repaired the ${weaponName}`
                    actionSkill = 'repair'
                } else if (isDistracted) {
                    team.mech.applyStatus('distract', -1)
                    actionDesc = `Tried to attack ${enemyName} but was distracted`
                    actionSkill = 'combat'
                } else {
                    let blocked = false

                    if (enemyPilots) {
                        blocked = checkForBlock(theWeapon)
                    }

                    if (!blocked) {
                        if (enemy.targetLocation) {
                            attackSlot = enemy.targetLocation
                            enemy.setTargetLocation()
                            attackOptions.autoCrit = true
                        } else {
                            attackSlot = targetSlot()
                        }

                        const { totalDamage, isCrit } = await doAttack(attackSlot, attackOptions)

                        actionDesc = `Attacked ${enemyName}'s ${slotNames[attackSlot]} for ${totalDamage}${isCrit ? ' [CRITICAL]' : ''}`
                        actionSkill = 'combat'
                    }
                }
                break
            case 'leftLeg':
            case 'rightLeg':
                // repair
                const repairSlot = mech.getWorstDamage()
                if (repairSlot.slot) {
                    const repairAmount = await doRepair(repairSlot.slot)
                    actionDesc = `Repaired ${slotNames[repairSlot.slot]} for ${repairAmount}`
                    actionSkill = 'repair'
                } else {
                    mech.applyStatus('evasion', 1)
                    actionDesc = `Took evasive action`
                    actionSkill = 'pilot'
                }
                break
            default:
        }
    }

    return {
        source: pilot.slot,
        skill: actionSkill,
        special: didSpecial,
        retreat,
        name: pilot.data.name,
        text: actionDesc
    }
}

