import React from 'react'
import { Icon } from 'semantic-ui-react';

export function RewardsDisplay({ rewards }) {
    return (
    <div className="messageLog">
        <div className="rewardRow">
            <div className="rewardLabel">
                <Icon name="cube"/>Resources
            </div>
            <div className="rewardValue">
                +{ rewards.credits }
            </div>
        </div>
        <div className="rewardRow">
            <div className="rewardLabel">
                <Icon name="users"/>Relationships
            </div>
            <div className="rewardValue">
                { rewards.relValue > 0 && '+' }{ rewards.relValue }
            </div>
        </div>
    </div>
    )
}