import * as d3 from 'd3'

export function addMinigameTimer(svg, maxTime, stopGame) {
    const width = 500;
    const height = 500;

    const timerMargin = 20
    const timerWidth = width - 2 * timerMargin
    const timerHeight = 20
    const timerY = height - timerMargin - timerHeight
    const timerX = timerMargin

    let timer = null

    svg.append("rect")
        .attr('class', 'timerMax')
        .attr('x', timerX)
        .attr('y', timerY)
        .attr("rx", 10)
        .attr("ry", 10)
        .attr('height', timerHeight)
        .attr('width', timerWidth)

    const currTime = svg.append("rect")
        .attr('class', 'timerCurr')
        .attr('x', timerX)
        .attr('y', timerY)
        .attr("rx", 10)
        .attr("ry", 10)
        .attr('height', timerHeight)
        .attr('width', timerWidth)

    const stopTimer = () => {
        if (timer) {
            timer.stop()

            stopGame(false)
        }
    }
    
    timer = d3.interval(() => {
        const tickWidth = timerWidth / maxTime

        const newTimeWidth = parseInt(currTime.attr('width')) - tickWidth

        if (newTimeWidth <= 0) {
            stopTimer()
        } else {
            currTime.attr('width', newTimeWidth)
        }
    }, 100) 

    return timer
}