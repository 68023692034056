import React, { useState, useEffect } from 'react'
import { Button, Modal, Icon, Grid } from 'semantic-ui-react'
import { numerals } from '../../lib/constants';
import { camelCaseToTitleCase } from '../../lib/textUtils';

const moduleTypes = [
    {
        name: 'ablativeArmor',
        notes: 'This module adds a coating of unstable molecules to the exterior of the Gauntlet. This serves as an extra layer of defense against attacks, though it will quickly dissipate after the battle as the molecules decay.'
    },
    {
        name: 'crystalMatrix',
        notes: 'This device augments the computer systems of the Gauntlet, allowing much faster processing. Heat buildup will cause the crstals to lose alignment, but while the matrix is intact it will improve the effectiveness of the targeting and repair systems.'
    },
    {
        name: 'hydraulicCompressor',
        notes: `A supplemental pressure system temporarily boosts the response of the Gauntlet's limb actuators. This makes blocking and dodging attacks more effective.`
    },
    {
        name: 'neuralLink',
        notes: `An enhancement to the Gauntlet's control interface allows pilots to experience a higher level of connection to its systems. The combination of electronics and neurotransmitter injections allows the pilot to learn more from each battle.`
    },
    {
        name: 'plasmaCharger',
        notes: `High energy magnetic containment fields allow the Gauntlet to generate higher plasma levels. This allows greater performance from weapon systems while the containment holds.`
    },
]

export function FactoryQueueModal({ db, user, isOpen, toggleModal, academy, showGuide }) {
    const [currNotes, setCurrNotes] = useState(null)
    const [rank, setRank] = useState(0)
    const [available, setAvailable] = useState(academy?.data?.xenos || 0)
    const [modules, setModules] = useState(academy?.data?.modules || {})
    const [schematics, setSchematics] = useState(academy?.data?.schematics || [])
    const [factoryOrders, setFactoryOrders] = useState(academy?.data?.factoryOrders || [])
    
    const stackMax = 5
    const queueMax = 10

    const ranks = [ '1', '2', '3', '4', '5' ]

    useEffect(() => {
        if (academy?.data) {
            setModules(academy.data.modules || {})
            setSchematics(academy.data.schematics || {})
            setFactoryOrders(academy.data.factoryOrders || {})
            setAvailable(academy.data.xenos || 0)
        }
    }, [ academy?.data ])

    useEffect(() => {
        setFactoryOrders(academy.data.factoryOrders)
    }, [academy?.data?.factoryOrders])

    const makePurchase = (type, rank) => {
        console.log({ type, rank })
        
        academy.addModuleOrder(type, rank)

        setAvailable(available - rank)
        setModules(academy.data.modules)
    }

    const canAdd = (type, rank) => {
        const price = rank
        const canAfford = academy.data.xenos >= price

        let queued = 0
        for (const order of factoryOrders) {
            if (order.type === type && order.rank === rank) {
                queued++
            }
        }
        const owned = modules[type] ? modules[type][rank] : 0
        const full = queued + owned >= stackMax

        return canAfford && !full && factoryOrders.length < queueMax
    }

    const queueCount = {}

    for (const item of factoryOrders) {
        if (!queueCount[item.type]) {
            queueCount[item.type] = {}
        }
        if (!queueCount[item.type][item.rank]) {
            queueCount[item.type][item.rank] = 1
        } else {
            queueCount[item.type][item.rank]++
        }
    }

    return academy && academy.data ? (
        <Modal
            className="factoryQueueModal"
            size="large"
            open={isOpen}
            onClose={() => {
                this.props.toggleModal()
            }}>
            <Modal.Header>
                <h1 className="ui"><Icon className="iconBtn floatRight" name="question circle" onClick={() => showGuide('academy', 'factory')}/><Icon name="list"/>FACTORY QUEUE</h1>
            </Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Column width={10}>
                        <table className="modulesTable">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>I</th>
                                    <th>II</th>
                                    <th>III</th>
                                    <th>IV</th>
                                    <th>V</th>
                                    <th></th>
                                </tr>
                                { moduleTypes.map((m, i) => {
                                    if (schematics.includes(m.name)) {
                                        return (
                                        <tr 
                                            key={i} 
                                            className="moduleInfo" 
                                            onMouseEnter={() => setCurrNotes({ name: m.name, title: camelCaseToTitleCase(m.name), body: m.notes }) }                            
                                            onMouseLeave={() => setCurrNotes(null) }
                                        >                             
                                            <td className="moduleName">
                                                { camelCaseToTitleCase(m.name) }
                                            </td>
                                            { ranks.map((r) => {
                                                return (
                                                <td 
                                                    key={r} 
                                                    className={`moduleCount ${canAdd(m.name, r) ? '' : 'disabled'}`}
                                                    onMouseEnter={() => setRank(r) }                            
                                                    onMouseLeave={() => setRank(null) }
                                                    onClick={() => makePurchase(m.name, r)}        
                                                >
                                                    { queueCount[m.name] ? queueCount[m.name][r] || 0 : 0 }/{ modules[m.name] ? modules[m.name][r] || 0 : 0}
                                                </td>
                                                )}
                                            )}
                                            <td className="endCap"></td>
                                        </tr>
                                        )} else {
                                            return null
                                        }
                                    }
                                )}
                            </tbody>
                        </table>
                        <div className="moduleNotes">
                            { currNotes && <>
                            <div className="moduleNotesHeader">
                                { currNotes.title } {rank ? numerals[rank - 1] : ''}
                                { rank && <>
                                    <div className="cost">
                                        <Icon name="cube"/> {rank * 500}
                                    </div>
                                    <div className="cost">
                                        <Icon name="gem outline"/> {rank}
                                    </div>
                                </>}
                            </div>
                            <div className="moduleNotesBody">
                                { currNotes.body }
                            </div> </> }
                        </div>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <div className="queue">
                            <div className="queueHeader">QUEUED ITEMS</div>
                            { factoryOrders.map((o, i) => (
                                <div key={i} className="queueItem">
                                    { camelCaseToTitleCase(o.type) } {numerals[o.rank - 1]}
                                </div>
                            ))}
                        </div>
                        <div className="availableXenos">
                            Available: <Icon name="gem outline"/>{ available }
                        </div>
                    </Grid.Column>
                </Grid>                
            </Modal.Content>
            <Modal.Actions>
            {/* <Button negative onClick={() => toggleModal()}>
                No
            </Button> */}
            <Button positive onClick={() => toggleModal()}>
                Close
            </Button>
            </Modal.Actions>
        </Modal>
     ) : null
}