// import { getSkillIcon } from '../lib/pilot'
import { Icon, Image } from 'semantic-ui-react'

export const tutorials = {
    introduction: {
        title: 'The Year is 2168...',
        destination: '/roster',
        buttonText: 'RUN THE GAUNTLET',
        body: (
            <div className="tutorial">
                <p>
                    The discovery of gravitic transducers and wormholes allowed Humanity to strike out for the stars. They explored distant planets and encountered other races, both friendly and hostile. 
                </p>
                <p>
                    New challenges required new ways to meet them. Some threats were on a scale larger than conventional armies could face. A novel weapon system was designed, so complex that it required five people working in perfect sync to pilot it. As they would have to function as seamlessly as five fingers on a hand, the machine they brought to life was dubbed the <b>Gauntlet</b>.
                </p>
                <p>
                    Fifty years later, aspiring young pilots apply for training on star-faring Academy ships. There they learn not only the skills required to operate the great war machines, but also hone the qualities that will allow them to work together with their comrades.
                </p>
                <p>
                    As the AI brain running one of these Academy ships, you must guide these trainees. They must be both highly skilled and well rounded, flexible enough to take on any challenge. You must mold them into the perfect pilots, so that they will be able to...
                </p>
            </div>
        )
    }
    , rosterIntro: {
        title: 'Pilots and Gauntlets',
        body: (
            <div className="tutorial">
                <p>
                    Your first task is to recruit new pilots and purchase your first Gauntlet. The Academy's on-board Factory produces <b><Icon name="cube"/>Resources</b> that can be used to trade for Gauntlet frames and other needs.
                </p>
                <p>
                    Recruit five pilots and purchase a Rank I Gauntlet to build your first team. As you build your team, read the <b><Icon name="info circle"/>Briefings</b> to learn more about Skills, Attributes, and other metrics that will help you understand both pilots and Gauntlets.
                </p>
                <p>
                    Once you have built your team, click on the <b>Academy</b> menu option above.
                </p>
            </div>
        )
    }
    , academyIntro: {
        title: 'The Academy',
        body: (
            <div className="tutorial">
                <div className="floatRight">
                    <Image 
                        className="tutorialImg"
                        src="/assets/images/guide/dragPilot.png"
                    />
                    <Image 
                        className="tutorialImg"
                        src="/assets/images/guide/clickPlay.png"
                    />
                </div>
                <p>
                    When not in battle, pilots spend their time on board the Academy ship. This interface allows you to assign pilots and Gauntlets to various locations by dragging them. Once you are satisfied with your placement, you can hit the button in the upper left to allow time to progress.
                </p>
                <p>
                    Activities use a pilot's energy, indicated by a blue bar on the piot card. Working in the Infirmary or Repair Bay, or using the Simulator depletes energy while time is moving forward, while resting in the Barracks replenish it. Keep an eye on your pilot's energy levels; if they run out they will automatically return to the Barracks to rest.
                </p>
                <p>
                    When you're ready to send your pilots into battle, click the blue Star Map button on the left.
                </p>
            </div>
        )
    }
    , battleIntro: {
        title: 'The Field of Battle',
        body: (
            <div className="tutorial">
                <p>
                    A threat has emerged that requires a Gauntlet to face it. 
                </p>
            </div>
        )
    }
    , events: {
        title: 'Special Events',
        body: (
            <div className="tutorial">
                <p>
                    Sometimes a pilot will be involved in an Event that requires them to decide on a course of action. Your guidance will help them pick the best option. 
                </p>
                <p>
                    Most Event options will require the pilot to perform a challenge involving a Skill and an Attribute. The harder the challenge, the greater the reward, but likewise the greater the cost of failure. Easy challenges will result in a small reward but no chance of failure.
                </p>
                <p>
                    Every reward includes progress in the associated Attribute, but may also include other things. Attribute progress from events is not hindered by a pilot's Quirks. Events that involve another pilot offer a chance to improve (or harm) the relationship between the two pilots.
                </p>
                <p>
                    Succeeding at a Hard challenge will always reduce the level of the associated negative Quirk if the pilot has it. A Medium difficulty challenge may sometimes improve the Quirk.
                </p>
                <p>
                    If the pilot has enough accumulated Fame, they may spend some of it to greatly improve their chances of succeeding at a challenge, though success is never entirely guaranteed.
                </p>
            </div>
        )
    }
    , stories: {
        title: 'Stories',
        body: (
            <div className="tutorial">
                <p>
                    Occasionally, a pilot will find themselves beginning a Story. A Story is a special type of Event that has multiple stages and leads to a special reward at the end. The choices and outcomes of the pilot's decisions determine the course of the Story.
                </p>
                <p>
                    Once begun, a Story may only be completed by the pilot who started it. Some Stories may only be completed once, while others may be repeated again after they are done. The current state of a Story can be seen by clicking on the <b><Icon name="bookmark"/>Story</b> button on the left side of the Academy screen.
                </p>
                <p>
                    If a Story involves travel to a planet, the planet must be cleared of dangers before the Story can continue. The associated pilot must take part in at least one of the battles to clear the planet, though they need not win the battle.
                </p>
            </div>
        )
    }
    , factoryQueue: {
        title: 'Factory Queue',
        body: (
            <div className="tutorial">
                <p>
                    Now that you have discovered a schematic, you can start using the Factory to produce Modules. Click on the queue below the Factory to see what you can create.
                </p>
            </div>
        )
    }
}