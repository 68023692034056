import React, { Component } from 'react'
import { Button, Grid, Modal, Icon, Popup } from 'semantic-ui-react'
import { MechView } from '../mechView'
import { Mech } from '../../lib/mech'
import { mechList, mechSpecials } from '../../data/mech'
import { weaponList } from '../../data/weapon'
import { slotTypes, slotNames, numerals } from '../../lib/constants'
import { Slider } from 'react-semantic-ui-range'
import { camelCaseToTitleCase } from '../../lib/textUtils'

export class MechInfoModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mech: null,
            portraitContext: 'list'
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        const { mechId, db, user } = this.props

        const thisClass = this
        
        if (prevProps.mechId !== mechId && mechId) {
            const theMech = new Mech(db, user, mechId)
    
            const loadInterval = setInterval(() => {
                if (theMech && theMech.data) {
                    thisClass.setState({ mech: theMech })
                    clearInterval(loadInterval)
                }
            }, 300)
        }
    }

    chooseMechColor(mechColor) {
        const { mech } = this.state
        const thisClass = this

        mech.updateData({ color: mechColor, lastModified: Date.now() })

        setTimeout(() => {
            thisClass.setState({ mechColorPickerOpen: false })
        }, 300)
    }

    getSpecialInfo(special) {
        return <Popup
            trigger={ <Icon className="floatRight" name="info circle"/> }
            on='click'
            position="right center"
        >
            { mechSpecials[special] }
        </Popup>
    }

    getWeaponInfo(type) {
        const data = weaponList[type]

        return <Popup
            trigger={ <Icon className="floatRight" name="info circle"/> }
            on='click'
            position="right center"
        >
            <table className="weaponStatsTable">
                <tbody>
                    <tr>
                        <td className="statLabel">
                            Damage
                        </td>
                        <td className="statValue">
                            { data.dam } { data.burst > 1 ? `(x${data.burst})`: '' }
                        </td>
                    </tr>
                    <tr>
                        <td className="statLabel">
                            Accuracy
                        </td>
                        <td className="statValue">
                            { data.acc }
                        </td>
                    </tr>
                </tbody>
            </table>
        </Popup>
    }

    togglePortraitContext() {
        const { portraitContext } = this.state

        let newContext = ''

        switch(portraitContext) {
            case 'list':
                newContext = 'display'
                break
            case 'display':
                newContext = 'damage'
                break
            case 'damage':
                newContext = 'list'
                break
            default:
        }

        this.setState({ portraitContext: newContext })
    }

    closeModal() {
        this.state.mech.cleanup()
        this.setState({ mech: null })
        this.props.toggleModal()
    }

    render() {
        const { mech } = this.state
        const { isOpen } = this.props

        return mech  ? <Modal
            className="mechInfoModal"
            size={'large'}
            open={isOpen}
            onClose={() => this.closeModal()}
        >
        <Modal.Header><h1 className="ui">Gauntlet: { mech.data.name || `${mech.data.make} ${mech.data.model} ${ numerals[mech.data.rank - 1] }` }</h1></Modal.Header>
        <Modal.Content>
            { mech.data && <Grid>
                <Grid.Row>
                <Grid.Column width={4}>
                    <div className="infoSection">
                        <div className="sectionHeader">
                            Weapons                     
                            <Icon className="iconBtn" name="question circle" onClick={() => this.props.showGuide('gauntlets', 'weapons')}/>
                        </div>
                        <div className="metricLabel small">Left</div>
                        <div className="metricValue">{ camelCaseToTitleCase(mech.data.leftWeapon.type) }</div>
                        { this.getWeaponInfo(mech.data.leftWeapon.type) }
                        <div className="metricLabel small">Right</div>
                        <div className="metricValue">{ camelCaseToTitleCase(mech.data.rightWeapon.type) }</div>
                        { this.getWeaponInfo(mech.data.rightWeapon.type) }
                    </div>
                    <div className="infoSection">
                        <div className="sectionHeader">
                            Special Abilities                     
                            <Icon className="iconBtn" name="question circle" onClick={() => this.props.showGuide('gauntlets', 'specials')}/>
                        </div>
                        <div className="metricLabel small">Head</div>
                        <div className="metricValue">{ camelCaseToTitleCase(mechList[mech.data.make].special.head) }</div>
                        { this.getSpecialInfo(mechList[mech.data.make].special.head) }
                        <div className="metricLabel small">Arms</div>
                        <div className="metricValue">{ camelCaseToTitleCase(mechList[mech.data.make].special.arm) }</div>
                        { this.getSpecialInfo(mechList[mech.data.make].special.arm) }
                        <div className="metricLabel small">Legs</div>
                        <div className="metricValue">{ camelCaseToTitleCase(mechList[mech.data.make].special.leg) }</div>
                        { this.getSpecialInfo(mechList[mech.data.make].special.leg) }
                    </div>
               </Grid.Column>
                <Grid.Column width={4}>
                    <div className="infoSection">
                        <div className="sectionHeader">
                            Data                     
                        </div>
                        <div className="metricLabel">Make</div>
                        <div className="metricValue">{mech.data.make}</div>
                        <div className="metricLabel">Model</div>
                        <div className="metricValue">{mech.data.model}</div>
                        <div className="metricLabel">Rank</div>
                        <div className="metricValue">{mech.data.rank}</div>
                    </div>
                    <div className="infoSection">
                        <div className="sectionHeader">
                            Statistics                     
                        </div>
                        <div className="metricLabel">Sorties</div>
                        <div className="metricValue">{mech.data.stats.sorties || 0}</div>
                        <div className="metricLabel">Wins</div>
                        <div className="metricValue">{mech.data.stats.wins || 0}</div>
                        <div className="metricLabel">Losses</div>
                        <div className="metricValue">{mech.data.stats.losses || 0}</div>
                        <div className="metricLabel">Draws</div>
                        <div className="metricValue">{mech.data.stats.draws || 0}</div>
                        <div className="metricLabel">Recalls</div>
                        <div className="metricValue">{mech.data.stats.recalls || 0}</div>
                    </div>
                </Grid.Column>
                <Grid.Column width={4}>
                    <div className="sectionHeader">
                        Armor                     
                        <Icon className="iconBtn" name="question circle" onClick={() => this.props.showGuide('gauntlets', 'armor')}/>
                        { this.props.isAdmin && <Icon className="iconBtn" name="wrench" onClick={() => mech.fullHeal()}/> }
                    </div>
                    { slotTypes.map((s, i) => [
                        <div key={`l${i}`} className="metricLabel">{slotNames[s]}</div>,
                        <div key={`v${i}`} className="metricValue">{mech.data.armor[s]} / {mech.data.maxArmor[s]}</div>
                    ])}
                    <div className="metricLabel">Body</div>
                    <div className="metricValue">{mech.data.armor.body} / {mech.data.maxArmor.body}</div>
                </Grid.Column>
                <Grid.Column width={4}>
                    <div className="mechInfoPortrait">
                        <MechView 
                            subject={mech.data} 
                            size={300} 
                            context={this.state.portraitContext}
                            environment={this.state.portraitContext === 'display' ? 'mechBay' : null}
                        />
                    </div>
                    <div className="portraitControls">
                        <Button icon="eye" onClick={() => this.togglePortraitContext()}/>
                    </div>
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={10}>
                        { mech.data.unique ? mechList.unique[mech.data.unique].notes : mechList[mech.data.make].notes }
                    </Grid.Column>
                    <Grid.Column width={6}>
                        { !mech.data.unique && (<>
                        <div className="mechColorLabel">Color</div>
                        <div className="mechColorPicker">
                            <Slider 
                                value={mech.data.color} 
                                color="green" 
                                inverted
                                settings={{
                                    min: 0,
                                    max: 360,
                                    step: 30,
                                    onChange: (value) => {
                                        this.chooseMechColor(value)
                                    }
                                }} />
                        </div> </>)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
        </Modal.Content>
        <Modal.Actions>
        {/* <Button negative onClick={() => toggleModal()}>
            No
        </Button> */}
        <Button positive onClick={() => this.closeModal()}>
            Close
        </Button>
        </Modal.Actions>
    </Modal> : null
    }
}