import React from 'react'
import { Portrait } from '../portrait';
import { attrNames, slotTypes } from '../../lib/constants'
import { camelCaseToTitleCase } from '../../lib/textUtils';
import { getPilotAttrProgressPercent } from '../../lib/pilot';

export function StatsDisplay({ team, stats, metrics, progress }) {
    const statBlocks = []

    for (const slot of slotTypes) {
        const pilot = team.pilots[slot]
        statBlocks.push({
            pilot,
            slot,
            stats: stats[pilot.id],
            metrics: metrics[pilot.id],
            progress: progress[pilot.id]
        })
    }

    return (
    <div className="messageLog">
        { statBlocks.map((b, i) => 
            <div className="statBlock" key={i}>
                <div className="pilotPortrait">
                    <Portrait 
                        size={80} 
                        subject={ b.pilot.data.portrait } 
                        context="combat" 
                    />
                </div>
                <div className="pilotName">
                    { b.pilot.data.name } ({ camelCaseToTitleCase(b.slot) })
                </div>
                <div className="statRows">
                    <div className="statRow">
                        <div className="statLabel">
                            Expertise
                        </div>
                        <div className="statValue">
                            +{ b.metrics.xp }
                        </div>
                        <div className="statValue">
                            ({ b.pilot.data.metrics.xp })
                        </div>
                    </div>

                    <div className="statRow">
                        <div className="statLabel">
                            Fame
                        </div>
                        <div className="statValue">
                            +{ b.metrics.fame }
                        </div>
                        <div className="statValue">
                            ({ b.pilot.data.metrics.fame })
                        </div>
                    </div>

                    <div className="statRow">
                        <div className="statLabel">
                            Happiness
                        </div>
                        <div className="statValue">
                            +{ b.metrics.hap }%
                        </div>
                        <div className="statValue">
                            ({ b.pilot.data.metrics.hap }%)
                        </div>
                    </div>

                    <div className="statRow">
                        <div className="statLabel">
                            { attrNames[b.progress.attr] }
                        </div>
                        <div className="statValue">
                            +{ b.progress.value }
                        </div>
                        <div className="statValue">
                            ({ getPilotAttrProgressPercent(b.pilot.data, b.progress.attr)}%)
                        </div>
                    </div>

                    { Object.keys(b.stats).map((s, j) => (
                        <div className="statRow" key={j}>
                            <div className="statLabel">
                                { camelCaseToTitleCase(s) }
                            </div>
                            <div className="statValue">
                                +{ b.stats[s] }
                            </div>
                            <div className="statValue">
                                ({ b.pilot.data.stats[s] || b.stats[s] })
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )}
    </div>
    )
}