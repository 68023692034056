import React, { Component } from 'react';
import MechRow from './mechRow'

export class MechList extends Component {
    render() {
        const { emptySlots, db, user } = this.props

        return <div className="mechList">
            {this.props.mechs.map((m, i) => (
                <MechRow 
                    key={i} 
                    db={db}
                    user={user}
                    mech={m}
                    selectMech={this.props.selectMech}
                />            
            ))}
            { emptySlots > 0 && Array(emptySlots).fill().map((a, i) => (
                <div key={i} className="mechRow empty"/>
            ))}

        </div>
    }
  }