import React, { useState, useEffect } from 'react'
import { get, ref } from 'firebase/database';
import { Button, Modal, Icon } from 'semantic-ui-react'
import Graph from '../../lib/graphs/relationshipGraph';
import { pilotDefaults } from '../../lib/pilot';

export function RelationshipsModal({ db, user, isOpen, toggleModal, showGuide }) {
    const [pilots, setPilots] = useState([])
    useEffect(() => {
        const fetchPilots = async () => {
            const newPilots = []

            if (isOpen) {
                const pilotListRef = ref(db, `pilots/${user.uid}`);

                const querySnapshot = await get(pilotListRef)
                
                querySnapshot.forEach((doc) => {
                    const data = Object.assign({}, pilotDefaults, doc.val())
        
                    newPilots.push(data);    
                });
            }

            setPilots(newPilots)
        }

        if (isOpen) {
            fetchPilots()
        }
    }, [isOpen, db, user])

    const [graphData, setGraphData] = useState({ nodes: [], links: [] })
    useEffect(() => {
        const nodes = pilots.filter((p) => Object.keys(p.relationships).length >0)
            .map((p, i) => ({
                key: p.name,
                id: p.id, 
                index: i,
                size: 10,
                rels: p.relationships,
                portrait: p.portrait
        })) 

        const links = []

        nodes.forEach((node, i) => {
            for (const rel in node.rels) {
                const relValue = node.rels[rel]
                const nodeIndex = nodes.findIndex((n) => n.id === rel)

                if (nodeIndex > -1) {
                    links.push({
                        source: i,
                        target: nodeIndex,
                        key: `${i},${nodeIndex}`,
                        size: Math.sqrt((Math.abs(relValue - 50))) * (relValue < 50 ? -1 : 1)
                    })
                }
            }
        })

        setGraphData({ nodes, links })
    }, [ pilots ])

      
    return pilots && pilots.length > 0 ? (
        <Modal
            className="relationshipsModal"
            size="large"
            open={isOpen}
            onClose={() => {
                toggleModal()
            }}>
            <Modal.Header>
                
                <h1 className="ui"><Icon className="iconBtn floatRight" name="question circle" onClick={() => showGuide('pilots', 'relationships')}/><Icon name="users"/>RELATIONSHIPS</h1>
            </Modal.Header>
            <Modal.Content>
                { pilots && pilots.length > 0 && <Graph data={graphData} /> }
                
            </Modal.Content>
            <Modal.Actions>
            {/* <Button negative onClick={() => toggleModal()}>
                No
            </Button> */}
            <Button positive onClick={() => toggleModal()}>
                Close
            </Button>
            </Modal.Actions>
        </Modal>
     ) : null
}