import { Image } from 'semantic-ui-react'
import { getRoomIcon } from "../../lib/academy";
import { roomList, roomAttrs, attrNames } from "../../lib/constants";
import { camelCaseToTitleCase } from "../../lib/textUtils";

const roomNotes = {
    barracks: <p>
        When not engaged in other activities, pilots can spend time resting in the Barracks. This is a quiet place away from the din of battle and the hard work of Academy life.
    </p>,
    hangar: <p>
        Between battles, Gauntlets are stored in the Hangar. Sometimes pilots will come here to simply look at the great machines and contemplate.
    </p>,
    infirmary: <p>
        Pilots wounded in battle or in accidents may be brought to the Infirmary to heal. Other pilots are needed to help in the Infirmary, which will speed the healing of their comrades as well as give them valuable experience.
    </p>,
    repairBay: <>
        <p>
            Every battle takes its toll on the Gauntlets, and they will require time spent in the Repair Bay before being ready for combat again. Working here will give pilots useful insight into the inner workings of the machines they are dedicated to.
        </p>
        <p>
            Repairing Gauntlets costs Resources. The Academy has excellent credit, so repairs may cause the Resource count to become negative. Lack of Retouces will make it impossible to jump to a new system or make other purchases, so be sure to generate more Resources through battles or the Factory as soon as possible.
        </p>
    </>,
    simulator: <>
        <p>
            When there are no battles to fight, pilots can hone their skills in the Simulator, either alone or sparring with one of their comrades.
        </p>
        <p>
            Pilots will practice one of the skills they are most in need of improving, and will improve the attributes required for that skill.
        </p>
    </>,
    recRoom: <p>
        There is more to life than work and battle. The Rec Room allows pilots to blow off steam, find some entertainment, and compete with their fellows in sports and games.
    </p>,
    factory: <>
        <p>
            Operating Gauntlets and the Academy ship requires Resources. the on-board Factory converts cheap raw materials into valuable items that can be used directly or traded for other commodities. Pilots assigned to the Factory will generate Resources for the Academy.
        </p>
    </>,
    factoryQueue: <>
        <Image 
            className="guideImg floatRight"
            src="/assets/images/guide/factoryQueue.png"
        />
        <p>
            Once appropriate schematics have been found, the Factory can begin to produce Modules to augment the performance of your Gauntlets. Each Module remains in effect for only one battle, and may only be applied to a Gauntlet whose rank is at least equal to the rank of the Module. Higher rank Modules produce greater effects.
        </p>
        <p>
            Each Module added to the Factory queue requires an initial expenditure of Xenomaterials set aside for its construction. When the Factory has active workers, the Resources produced will be split between completing the active queue item and Resource storage. If storage is full, all Resources will be applied to the queue.
        </p>    
    </>
}

export const academy = {
    key: 'academy',
    title: 'Academy',
    body: <div>
        <div className="floatRight">
            <Image 
                className="guideImg"
                src="/assets/images/guide/dragPilot.png"
            />
            <Image 
                className="guideImg"
                src="/assets/images/guide/clickPlay.png"
            />
        </div>
        <p>
            When not in battle, pilots spend their time on board the Academy ship. This interface allows you to assign pilots and Gauntlets to various locations by dragging them. Once you are satisfied with your placement, you can hit the button in the upper left to allow time to progress.
        </p>
        <p>
            Activities use a pilot's energy, indicated by a blue bar on the piot card. Working in the Infirmary or Repair Bay, or using the Simulator depletes energy while time is moving forward, while resting in the Barracks replenish it. Keep an eye on your pilot's energy levels; if they run out they will automatically return to the Barracks to rest.
        </p>
        <p>
            When you're ready to send your pilots into battle, click the blue Star Map button on the left.
        </p>
    </div>,
    items: roomList.map((location) => ({
        key: location,
        title: <div>{getRoomIcon(location)} {camelCaseToTitleCase(location)}</div>,
        body: <div className="roomNotes">
          { roomAttrs[location] && (
            <div className="roomAttrs">
              Attributes Trained: { roomAttrs[location].map((a, i) => (
                <span key={i} className={`color-attr-${a}`}>{attrNames[a]}{i < roomAttrs[location].length - 1 && ', '}</span>
              ))}
            </div>
          )}
          { roomNotes[location] }
        </div>

    }))
}