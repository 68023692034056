import { getRoomIcon } from '../../lib/academy'
import { flipCoin } from '../../lib/mathUtils'

export const factory = [
    (e) => {
        const p = e.pilot

        return {
            title: <div>{ getRoomIcon('factory') }FACTORY: Jamming Fabricator</div>,
            description: `While running one of the 3D fabricators, PNAME finds that it keeps jamming in the same spot, requiring a manual restart each time. What should pze do?`,
            pilot: p,
            options: [
                { 
                    attr: 'reas',
                    skill: 'repair',
                    dc: 15,
                    level: 'hard',
                    text: `Analyze the shape file and try to find the flaw causing the fabricator to jam.`,
                    success: {
                        text: `With some careful study of the topology of the part, PNAME is able to smooth out the angle at the problem spot and get the fabricator past it consistently.`,
                        attr: 'reas',
                        attrValue: 20
                    },
                    fail: {
                        text: `PNAME edits the shape and starts the fabricator back up. After a few copies roll off the line, pze notices that they have a huge void in the problem spot and are totally unusable.`,
                        credits: -50
                    }
                },
                { 
                    attr: 'dar',
                    skill: 'per',
                    dc: 10,
                    level: 'medium',
                    text: `Hit the machine at the right moment to keep it from jamming`,
                    success: {
                        text: `Watching the fabricator carefully, PNAME gives it a sharp whack at the moment it reaches the problem area. The fabricator head moves past without a hitch. After that, the problem seems to go away.`,
                        attr: 'dar',
                        attrValue: 10
                    },
                    fail: {
                        text: `PNAME unsuccessfully attempts to apply percussive maintenance. The shop boss gives pzir a dirty look and pze stops, resigned to further hours of clearing jams.`
                    }
                },
                { 
                    text: `Develop a better method for clearing the jam`,
                    level: 'easy',
                    success: {
                        text: `On each iteration, PNAME improves the un-jamming process slightly. Soon it hardly takes any time at all.`,
                        attr: 'fort',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot

        return {
            title: <div>{ getRoomIcon('factory') }FACTORY: Micro Manager</div>,
            description: `One of the senior Factory technicians is being particularly critical of everyone's work, hovering over PNAME as pze is working at the fabricator station. It's making it difficult to concentrate on the work. What should pze do?`,
            pilot: p,
            options: [
                { 
                    attr: 'emp',
                    skill: 'per',
                    dc: 15,
                    level: 'hard',
                    text: `Figure out why the technician is in such a bad mood.`,
                    success: {
                        text: `While the fabricator is working through a long section of a component, PNAME leans over toward the technician. "How have things been down here? We really appreciate all the work you folks do to keep our machines running." The technician's scowl softens. "That's nice to hear," he says. "Sometimes it feels like we're always under the gun and can't take a moment to relax. You seem to have things in hand here, I'll go grab a coffee."`,
                        attr: 'emp',
                        attrValue: 20
                    },
                    fail: {
                        text: `PNAME leans away from the fabricator station as the technician is coming by for another inspection. "What's your problem?" pze asks, "Can't you give us a break?" The technician thrusts a finger in PNAME's face. "You think it's a walk in the park down here? If it weren't for us, you high and mighty pilots would have nothing to fly!"`,
                        metric: 'fame',
                        metricValue: -20
                    }
                },
                { 
                    attr: 'wis',
                    skill: 'repair',
                    dc: 10,
                    level: 'medium',
                    text: `Do everything exactly by the book to put the technician's mind at ease`,
                    success: {
                        text: `PNAME works like a robot, perfectly executing every process and technique. It's not difficult work, it just requires attention to detail. PZE can see the technician peering over pzis shoulder for a while, but eventually they get tired of not finding anything to criticize and go off in search of another target.`,
                        attr: 'wis',
                        attrValue: 10
                    },
                    fail: {
                        text: `PNAME carefully executes every process and technique, trying to perform in a way that can't be criticized. After a few minutes, the technician comes stomping up to the station. "Speed it up, spacer! We've got repairs to make!" It seems that there's always a fault to find.`
                    }
                },
                { 
                    text: `Listen to what the technician has to say and try to improve`,
                    level: 'easy',
                    success: {
                        text: `Once PNAME stops being annoyed at the critique and listens to the suggestions, pze finds that pzis work improves.`,
                        attr: 'hum',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot
        const o = e.otherPilot

        if (!o) { return null }

        return {
            title: <div>{ getRoomIcon('factory') }FACTORY: Assembly Line</div>,
            description: `PNAME is working with ONAME assembling the same parts from smaller components. It's boring and repetitive. What should pze do?`,
            pilot: p,
            otherPerson: o,
            options: [
                { 
                    attr: 'conf',
                    skill: 'comm',
                    dc: 15,
                    level: 'hard',
                    text: `Devise a way to divide the labor to make the process more efficient`,
                    success: {
                        text: `PNAME breaks the assembly process down into two phases, and splits the two phases up between pzirself and ONAME. The new process speeds up assembly, and the shop boss adds it to the manual.`,
                        attr: 'conf',
                        attrValue: 20,
                        relationship: o.id,
                        relValue: 1
                    },
                    fail: {
                        text: `PNAME tries to convince ONAME to "just do it this way", but doesn't sell the idea well enough. ONAME moves to another table where oze can work in peace.`,
                        relationship: o.id,
                        relValue: -2
                    }
                },
                { 
                    attr: 'prow',
                    skill: 'repair',
                    dc: 10,
                    level: 'medium',
                    text: `Compete to see who can assemble the parts fastest`,
                    success: {
                        text: `PNAME and ONAME start tallying up who makes the most parts per hour. ${flipCoin() ? 'PNAME' : 'ONAME'} comes out ahead, but they both increase their output.`,
                        attr: 'prow',
                        attrValue: 10,
                        relationship: o.id,
                        relValue: flipCoin() ? 1 : 0
                    },
                    fail: {
                        text: `PNAME tries to make a game of the assembly process, but in this case faster isn't better and they both start making mistakes. They slow down and try to just get the job done.`,
                        relationship: o.id,
                        relValue: flipCoin() ? -1 : 0
                    }
                },
                { 
                    text: `Tell stories to pass the time`,
                    level: 'easy',
                    success: {
                        text: `PNAME and ONAME start trading stories about jobs they had before joining the Academy. They both agree that they made the right decision.`,
                        attr: 'emp',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot

        return {
            title: <div>{ getRoomIcon('factory') }FACTORY: The Rush</div>,
            description: `As PNAME is working at a fabricator station, the work queue display suddenly starts lighting up with a long series of work orders. What should pze do?`,
            pilot: p,
            options: [
                { 
                    attr: 'wis',
                    skill: 'reaction',
                    dc: 15,
                    level: 'hard',
                    text: `Triage the orders so pze can finish the simple ones quickly and then concentrate on the more complicated requests`,
                    success: {
                        text: `PNAME quickly scans the list of orders and sorts it into a number of sub-queues by complexity and priority. Soon pze has a plan that will clear the queue quickly while still getting rush orders out in plenty of time.`,
                        attr: 'wis',
                        attrValue: 20
                    },
                    fail: {
                        text: `PNAME starts trying to reorder the queue, but more orders keep coming in. After a few minutes of scrambling, the Chief Technician tells pzir to quit messing around and get back to work.`,
                        metric: 'fame',
                        metricValue: -20
                    }
                },
                { 
                    attr: 'conf',
                    skill: 'comm',
                    dc: 10,
                    level: 'medium',
                    text: `Distribute the work among all the workers`,
                    success: {
                        text: `PNAME calls the other workers together and starts assigning tasks from the queue based on individual strengths and experience. Soon the display starts settling down as the tasks are quickly completed.`,
                        attr: 'conf',
                        attrValue: 10
                    },
                    fail: {
                        text: `PNAME tries to gather the other workers to spread the work out evenly, but everyone is too busy grabbing jobs off the queue to pay attention. PZE sighs and selects pzis own task, and they all slowly slog through the work.`
                    }
                },
                { 
                    text: `Use the rush as an opportunity to refine pzis process`,
                    level: 'easy',
                    success: {
                        text: `The rapid repetition of similar tasks allows PNAME to find more efficient ways to do the work. It still takes a long time to clear out the queue, but each iteration is slightly faster.`,
                        attr: 'reas',
                        attrValue: 5
                    }
                }
            ]
        }
    },
]