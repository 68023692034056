import React, { useState, useEffect } from 'react'
import { Button, Modal, Grid, Icon, Checkbox, Popup } from 'semantic-ui-react'
import { getRandomItem, checkRoll, flipCoin, oneIn, getRankedValue, randInt } from '../../lib/mathUtils'
import { eventList } from '../../data/event'
import { replaceEventText } from '../../lib/event'
import { attrNames, skillNames, metricNames, attrQuirkEffects, numerals } from '../../lib/constants'
import { getSkillIcon, changePilotMetric, improvePilotQuirk, getQuirkList, getPilotRank, applyStatsToPilot } from '../../lib/pilot'
import { generateMech } from '../../lib/mech'
import { Portrait } from '../portrait'
import SkillBlock from '../skillBlock'
import AttrBlock from '../attrBlock'
import { camelCaseToTitleCase } from '../../lib/textUtils'

export function EventModal({ db, user, event, isOpen, academy, toggleModal, showTutorial, playSoundEffect }) {
    const [eventData, setEventData] = useState(null)
    useEffect(() => {
        setResult(null)

        if (!event) {
            setEventData(null)
        } else {
            showTutorial('events')
        
            let eventData = null

            if (event.isStory) {
                showTutorial('stories')

                setEventData(event.data)
            } else {
                // guard against loops in case we don't have a valid event
                let attempt = 0

                // not every event will be valid for the current situation, so keep looking until one fits
                while (!eventData && attempt < 30) {
                    const eventBuilder = getRandomItem(eventList[event.location])
                    eventData = eventBuilder(event)

                    attempt++
                }

                setEventData(eventData)
            }
        }
    }, [event, showTutorial])

    const [ useFame, setUseFame ] = useState(false)

    const [result, setResult] = useState(null)
    useEffect(() => {
        if (event?.pilot?.id && result) {
            const pilotUpdate = {}

            if (result.metric) {
                if (Array.isArray(result.metric)) {
                    pilotUpdate.metrics = {}

                    result.metric.forEach((m, i) => {
                        pilotUpdate.metrics[m] = result.metricValue[i]
                    })
                } else {
                    pilotUpdate.metrics = { [result.metric] : result.metricValue }
                }
            } 
            
            if (result.attr) {
                if (result.quirk) {
                    improvePilotQuirk(db, user, event.pilot.id, result.quirk)
                }
                pilotUpdate.progress = { attr: result.attr, value: result.attrValue }
            } 
            
            if (result.relationship) {
                pilotUpdate.relationships = { [result.relationship]: result.relValue }
            } 
            
            if (result.credits) {
                academy.addCredits(result.credits)
            }

            if (result.xenos) {
                academy.addXenos(result.xenos)
            }

            applyStatsToPilot(db, user, event.pilot, null, pilotUpdate.metrics, pilotUpdate.progress, pilotUpdate.relationships)

            if (result.mech) {
                generateMech(db, user, 'unique', result.mech, getPilotRank(event.pilot))
            }

            if (result.schematic) {
                academy.addSchematic(result.schematic)
            }

            if (result.module) {
                academy.addModule(result.module.type, result.module.rank)
            }
        }
    }, [result, db, user, event?.pilot, event?.pilot?.id, academy, useFame])

    const toggleUseFame = (event) => {
        setUseFame(!useFame)
    }

    const chooseOption = (optionIndex) => {
        const option = eventData.options[optionIndex]
        const pilot = event.pilot
        const pilotRank = getPilotRank(event.pilot)

        let success = true
        let crit = false
        let usedFame = false

        if (option.dc) {
            const attrBonus = Math.ceil(pilot.attributes[option.attr] / 3)
            const skillBonus = Math.ceil(pilot.skills[option.skill] / 2)
            const fameBonus = useFame ? 10 : 0
            const result = checkRoll(option.dc, attrBonus + skillBonus + fameBonus)
            success = result.success
            crit = result.crit

            if (useFame) {
                usedFame = true
                changePilotMetric(db, user, pilot.id, 'fame', -40)
            }
        }

        playSoundEffect(success ? 'success' : 'failure')

        const outcome = success ? option.success : option.fail

        outcome.title = success ? <div className="outcome success">Success</div> : <div className="outcome failure">Failure</div>

        outcome.reward = []

        const addReward = (label, type, value) => {
            outcome.reward.push(<div key={type} className="reward">{label} {value > 0 ? '+' : ''}{value}</div>)
        }

        if (outcome.attrValue) {
            // progress is slightly randomized and also scaled by pilot rank
            const attrFactor = 1.2 + (randInt(1, 5) * 0.1)

            outcome.attrValue = Math.ceil(getRankedValue(outcome.attrValue, pilotRank, attrFactor))

            if (success) {
                const relevantQuirk = attrQuirkEffects[outcome.attr].malus

                if (pilot.quirks[relevantQuirk]) {
                    const flip = option.level === 'med' ? flipCoin() : oneIn(4)

                    if (crit || option.level === 'hard' || flip) {
                        outcome.quirk = relevantQuirk
                    }
                }
            }
            addReward (<span className={`color-attr-${outcome.attr}`}>{attrNames[outcome.attr]}:</span>, 'attr', outcome.attrValue)
        }
        
        if (outcome.metricValue) {
            if (Array.isArray(outcome.metric)) {
                outcome.metric.forEach((m, i) => {
                    const label = <span>{metricNames[outcome.metric[i]]}:</span>
                    addReward(label, `metric-${i}`, outcome.metricValue[i])
                })
            } else {
                const label = <span>{metricNames[outcome.metric]}:</span>
                addReward(label, 'metric', outcome.metricValue)
            }
        }

        if (outcome.relValue) {
            addReward(<span><Icon name="users"/>Relationship:</span>, 'rel', outcome.relValue)
        }
        
        if (outcome.credits) {
            addReward(<span><Icon name="cube"/>Resources:</span>, 'credits', outcome.credits)
        }

        if (outcome.xenos) {
            console.log('reward xenos', outcome.xenos)
            addReward(<span><Icon name="gem outline"/>Xenomaterials:</span>, 'xenos', outcome.xenos)
        }

        outcome.usedFame = usedFame

        setResult(outcome)
    }

    const getSkillTag = (option) => {
        if (option.level === 'easy') {
            return (
                <div className={`optionStats`}>
                    <span className={option.level}>{option.level.toUpperCase()} (<b className={`color-attr-${option.success.attr}`}>{attrNames[option.success.attr]}</b>)</span>
                </div>
            )    
        } else {
            return (
                <div className={`optionStats`}>
                    <span className={option.level}>{option.level.toUpperCase()}</span>: {getSkillIcon(option.skill)}{skillNames[option.skill]} / <b className={`color-attr-${option.attr}`}>{attrNames[option.attr]}</b>
                </div>
            )
        }
    }

    const closeModal = (event, result) => {
        setEventData(null)
        setUseFame(false)
        toggleModal(event, result)
    }

    const getOtherPersonPortrait = () => {
        return eventData.otherPerson && (
            <div className="otherPerson">
                <Portrait
                    size={70}
                    subject={ eventData.otherPerson.portrait }
                />
                <div className="otherName">
                    { eventData.otherPerson.name }
                </div>
            </div>
        )
    }

    return eventData ? <Modal
        className="eventModal"
        size={event.isStory ? event.modalSize || 'large' : 'small'}
        open={isOpen}
        onClose={() => {
            // toggleModal()
        }}
        >
        <Modal.Header><h1 className="ui">{ event.isStory && <Icon name="bookmark"/>} { eventData.title }</h1></Modal.Header>
        <Modal.Content>
            <Grid>
                <Grid.Column width={4} className="pilotInfo">
                    <Portrait
                        size={150}
                        subject={ eventData.pilot.portrait }
                    />
                    <div className="pilotName">
                        { eventData.pilot.name }
                    </div>
                    <div>
                        <AttrBlock
                            pilot={ eventData.pilot }
                        />
                    </div>
                    <div>
                        <SkillBlock
                            pilot={ eventData.pilot }
                        />
                    </div>
                    <div className="pilotQuirks">
                        { getQuirkList(eventData.pilot).sort().map((q, i) => (
                            <div key={i}>{ q }</div>
                        ))}
                    </div>
                    { eventData.pilot.metrics.fame >= 40 && <div className="useFame">
                        <Popup
                            position='left center'
                            trigger={ <Checkbox
                                toggle
                                label={<span>
                                    <Icon name={ useFame ? 'check square' : 'square' }/>Use Fame
                                </span>}
                                onChange={toggleUseFame}
                                checked={useFame}
                            /> }
                        >
                            Spending 40 Fame points will greatly improve the chances of success in a challenge.
                        </Popup>
                        <div><Icon size="small" name='trophy'/>{ eventData.pilot.metrics.fame }</div>
                    </div> }
                </Grid.Column>
                <Grid.Column width={12} className="eventBody">
                    { eventData ? result ? (
                        <>
                        <div>
                            { getOtherPersonPortrait() }
                            { result.title }
                            { replaceEventText(eventData, result.text) }
                            { result.reward }
                            { result.quirk && <div className="reward">{event.pilot.name} became less {result.quirk}</div>}
                            { result.usedFame && <div className="reward"><Icon name="trophy"/>Fame: -40</div>}
                            { result.mech && <div className="reward">Gauntlet Acquired: {camelCaseToTitleCase(result.mech)} </div>}
                            { result.schematic && <div className="reward"><Icon name="file text"/>Schematic Acquired: {camelCaseToTitleCase(result.schematic)} </div>}
                            { result.module && <div className="reward"><Icon name="cog"/>Module Acquired: {camelCaseToTitleCase(result.module.type)} {numerals[result.module.rank - 1]}</div>}
                        </div>
                        </>
                    ) : (
                        <>
                        <div className="eventDesc">
                            { getOtherPersonPortrait() }
                            { replaceEventText(eventData, eventData.description) }
                        </div>
                        <div className="optionSet">
                            { eventData.options.map((o, i) => {
                                return <div className="optionItem" key={i} onClick={() => chooseOption(i)}>
                                    { replaceEventText(eventData, o.text) }{getSkillTag(o)}
                                </div>
                            })}
                        </div>
                        </>
                    ) : null}
                </Grid.Column>
            </Grid>
        </Modal.Content>
        <Modal.Actions>
        {/* <Button negative onClick={() => toggleModal()}>
            No
        </Button> */}
        { result && <Button positive onClick={() => closeModal(event, result)}>
            Close
        </Button> }
        </Modal.Actions>
    </Modal> : null
}