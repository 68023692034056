import React from 'react'
import { Icon } from 'semantic-ui-react';
import { skillIcons, slotNames } from '../../lib/constants'

export function MessageLog({ team, tick, titleStage, inCombat, enemy, messageView, messages }) {
    return (
        <div className="messageLog">
            { messages.map((m, i) => m.source === 'end' ?
            <div className="endMessage" key={i}>
                <div className="messageHeader">
                    Combat Ended
                </div>
                <div className="messageText">
                    { m.text }
                </div>
            </div>
            :
            <div className={m.source === 'enemy' ? 'enemyMessage' : m.special ? 'specialMessage' : 'pilotMessage'} key={i}>
                <div className="messageHeader">
                    <Icon className="skillIcon" name={skillIcons[m.skill]}/>
                    {m.name}
                    <div className="floatRight">
                        { m.source === 'enemy' ? '' : slotNames[m.source]}
                    </div>
                </div>
                <div className="messageText">
                    { m.text }
                </div>
            </div>) } 
        </div>
    )
}