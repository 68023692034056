import { getSkillIcon, checkSkillSorties } from '../lib/pilot'
import { skillList }from '../lib/constants'

export default function SkillBlock({ pilot }) {
    const blocked = {}

    for (const skill of skillList) {
        blocked[skill] = !checkSkillSorties(pilot, skill)
    }

    return <div className="skillBlock">
        <div className="skillValues">
            { skillList.map((skill, i) => <div key={i} className={`skillValue ${ blocked[skill] ? 'blocked' : ''}`}>
                    { pilot.skills[skill] }
                </div>
            )}
        </div>
        <div className="skillIcons">
            { skillList.map((skill, i) => <div key={i} className={`skillIcon ${ blocked[skill] ? 'blocked' : ''}`}>
                    { getSkillIcon(skill) }
                </div>
            )}
        </div>
    </div>
}