import React, { useState, useEffect } from 'react'
import { useDrop } from 'react-dnd'
import { Icon } from 'semantic-ui-react'
import { MechView } from '../mechView'
// import RankPips from '../rankPips'
import { loadMech } from '../../lib/mech'
import { numerals, ItemTypes } from '../../lib/constants'

export default function MechSlot({ className, db, user, mechId, mechUpdated, context, selectMech, assignMech }) {
    const [{ isOver }, drop] = useDrop(() => ({
      accept: ItemTypes.MECH,
      drop: async (item, monitor) => {
          assignMech(item.id, item.rank)
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }))
  
    const [mechData, setMechData] = useState(null)
    const [hasMech, setHasMech] = useState(false)
    useEffect(() => {
      const updateMechData = async (id) => {
        if (db && id ) {
          const mechData = await loadMech(db, user, id)

          setMechData(mechData)
          setHasMech(true)
        } else {
          setMechData(null)
          setHasMech(false)
        }
      }

      updateMechData(mechId)
    }, [ mechId, mechUpdated, db, user ])

    const [viewContext, setViewContext] = useState(context === 'combat' ? 'damage' : 'display')
    useEffect(() => null, [ viewContext ])

    const toggleViewContext = () => {
      switch (viewContext) {
        case 'damage':
          setViewContext('display')
          break
        case 'display':
          setViewContext('damage')
          break
        default:
      }
    }

    return (
      
      <div
        className={`mechSlot ${className}`}
        ref={drop}
      >
        {isOver && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 1,
              opacity: 0.5,
              backgroundColor: 'yellow',
            }}
          />
        )}
        <div className="mechSlotHeader">
          GAUNTLET
          { hasMech && context !== 'combat' && [
             <Icon key={1} className="iconBtn floatRight" name="close" onClick={() => assignMech(null)}/>,
             <Icon key={3} className="iconBtn floatRight" name="eye" onClick={() => toggleViewContext()}/>
          ]}
          { hasMech &&  <Icon key={2} className="iconBtn floatRight" name="info circle" onClick={() => selectMech(mechId)}/>}

        </div>
        <div className="mechSlotContent">
          <div
            className={`mechDropSlot ${className}`}
          >
            {/* <RankPips mech={mechData}/> */}
            <MechView size={110} subject={mechData} context={viewContext}/>
          </div>
          <div className="mechSlotInfo">
            { hasMech ? <div className="mechData">
              { mechData.name ? (
                <div>{mechData.name} ({ numerals[mechData.rank - 1] })</div>
              ) : (<>
                <div>{mechData.make}</div>
                <div>{mechData.model} { numerals[mechData.rank - 1] }</div>
              </>)}
                <div className="weapon">
                  <div>L:{mechData?.leftWeapon.name}</div>
                  <div>R:{mechData?.rightWeapon.name}</div>
                </div>
              </div> : null }
          </div>
        </div>
      </div>
    )
  }
// }

// export default MechSlot