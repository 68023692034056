export const camelCaseToTitleCase = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}

export const camelCaseToLowerCase = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.toLowerCase();
    return finalResult
}

export const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
}