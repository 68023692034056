import { getRoomIcon } from '../../lib/academy'
import { camelCaseToLowerCase } from '../../lib/textUtils'
import { flipCoin, getRandomItem } from '../../lib/mathUtils'

export const repairBay = [
    (e) => {
        const p = e.pilot
        const m = e.mech

        if (!m) { return null }

        return {
            title: <div>{ getRoomIcon('repairBay') }REPAIR BAY: Hydraulic Line Rupture</div>,
            description: `A hydraulic line ruptures as PNAME is working on the ${m.model}'s ${camelCaseToLowerCase(e.slot)} actuator array. High pressure flammable fluid sprays from the leak, causing a risk of damage or fire. What should pze do?`,
            pilot: p,
            options: [
                { 
                    attr: 'dar',
                    skill: 'reaction',
                    dc: 15,
                    level: 'hard',
                    text: `Grab the broken line and apply an emergency patch`,
                    success: {
                        text: `PNAME snatches the line and slaps a patch on it, sealing the leak before any damage is done.`,
                        attr: 'dar',
                        attrValue: 20
                    },
                    fail: {
                        text: `PNAME tries to catch the flailing line, but the stream of pressurized fluid hits pzir in the hand instead.`,
                        metric: 'hp',
                        metricValue: -1
                    }
                },
                { 
                    attr: 'reas',
                    skill: 'repair',
                    dc: 10,
                    level: 'medium',
                    text: `Find the hydraulic shutoff valve`,
                    success: {
                        text: `Dodging the spraying fluid, PNAME reaches in and finds the shutoff valve to stop the flow. There's a little mess to clean up, but no damage done.`,
                        attr: 'reas',
                        attrValue: 10
                    },
                    fail: {
                        text: `PNAME searches for the shutoff valve but can't find it in the cramped compartment. PZE shuts the hatch and runs to find the Chief Engineer.`
                    }
                },
                { 
                    text: `Use the automated repair bot`,
                    level: 'easy',
                    success: {
                        text: `PNAME quickly closes the hatch and summons the repair bot to seal the leak. There will be some extra work do, but no harm done.`,
                        attr: 'wis',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot
        const m = e.mech
        const o = e.otherWorker

        if (!o || !m) { return null }

        return {
            title: <div>{ getRoomIcon('repairBay') }REPAIR BAY: Loose Plating</div>,
            description: `While PNAME is disassembling the ${m.model}'s ${camelCaseToLowerCase(e.slot)} with ONAME, a piece of armor plating drops down from above. PZE manages to catch the heavy plate before it hits the ground. What should pze do?`,
            pilot: p,
            otherPerson: o,
            options: [
                { 
                    attr: 'prow',
                    skill: 'repair',
                    dc: 15,
                    level: 'hard',
                    text: `Use the magnetic lifting arm to lift it back into place`,
                    success: {
                        text: `PNAME reaches out and attaches the lifting arm's magnetic grapple to the armor plate while balancing it on one shoulder. PZE hoists it back into place to reattach it.`,
                        attr: 'prow',
                        attrValue: 20,
                        relationship: o.id,
                        relValue: 1
                    },
                    fail: {
                        text: `While reaching for the lifting arm's magnetic grapple, PNAME loses pzis grip on the armor plate. It falls to the deck and ONAME has to leap out of the way.`,
                        relationship: o.id,
                        relValue: -2
                    }
                },
                { 
                    attr: 'hum',
                    skill: 'comm',
                    dc: 10,
                    level: 'medium',
                    text: `Ask ONAME for help with the plate`,
                    success: {
                        text: `"Could you please give me a hand with this?" asks PNAME. ONAME runs over and grabs the other end of the plate. Together they get it re-seated and secured.`,
                        attr: 'hum',
                        attrValue: 10,
                        relationship: o.id,
                        relValue: flipCoin() ? 1 : 0
                    },
                    fail: {
                        text: `PNAME shouts, "Come give me a hand with this!". ONAME adjusts their ear protection and continues grinding a weld. PNAME wrestles the plate down to the deck and goes to find the magnetic lifting arm.`,
                        relationship: o.id,
                        relValue: flipCoin() ? -1 : 0
                    }
                },
                { 
                    text: `Ease the plate down slowly`,
                    level: 'easy',
                    success: {
                        text: `PNAME carefully lowers the heavy plate to the ground, then goes to replace it using the magnetic lifting arm.`,
                        attr: 'fort',
                        attrValue: 5
                    }
                }
            ]
        }
    },
    (e) => {
        const p = e.pilot

        const items = [
            'processor core',
            'signal tuner',
            'crystal matrix',
            'heat sink',
            'focusing lens',
            'plasma nozzle'
        ]

        const problems = [
            'stray metal shaving',
            'worn gasket',
            'chipped crystal',
            'frayed wire'
        ]

        const item = getRandomItem(items)
        const problem = getRandomItem(problems)

        return {
            title: <div>{ getRoomIcon('repairBay') }REPAIR BAY: The Sum Of Its Parts</div>,
            description: `After overhauling one of the Gauntlets, PNAME is left with a ${item} that doesn't have any obvious damage but isn't working at all. What should pze do?`,
            pilot: p,
            options: [
                { 
                    attr: 'fort',
                    skill: 'repair',
                    dc: 15,
                    level: 'hard',
                    text: `Strip the ${item} down completely and search for the defect`,
                    success: {
                        text: `PNAME rolls up pzis sleeves and begins taking the ${item} apart piece by piece, laying each component out carefully. After some time inspecting each part for issues, pze eventually identifies a ${problem} as the root cause. PZE takes care of it and reassembles the unit, which performs up to spec once again.`,
                        attr: 'fort',
                        attrValue: 20
                    },
                    fail: {
                        text: `PNAME starts disassembling the ${item}, looking for the cause of the issue. Soon there is a jumble of parts strewn about the work table and pze starts losing track of what went where. Eventually pze gives up and hauls everything to the recycler.`,
                        credits: -50
                    }
                },
                { 
                    attr: 'conf',
                    skill: 'per',
                    dc: 10,
                    level: 'medium',
                    text: `Put it in the diagnostic scanner and do a deep inspection`,
                    success: {
                        text: `PNAME puts the ${item} in the scanner and starts examining the subcomponents for issues. Following a hunch, pze zooms in on a suspect location and discovers a ${problem} that wouldn't have been obvious without tearing the whole unit down. With a clear plan of attack, PNAME is able to fix the issue and get the ${item} ready to go again in a few minutes.`,
                        attr: 'conf',
                        attrValue: 10
                    },
                    fail: {
                        text: `Loading the ${item} into the scanner, PNAME tries to determine what the root cause of the problem is. Despite examining it from multiple angles with a variety of sensors, pze isn't able to learn any more than pze knew before. PZE leaves the unit out for the Chief Engineer to look at later.`
                    }
                },
                { 
                    text: `Break it down for spare parts`,
                    level: 'easy',
                    success: {
                        text: `Refurbished components are sometimes more prone to later breakage, so PNAME decides it's better to just recycle the ${item} and not take any chances.`,
                        attr: 'wis',
                        attrValue: 5
                    }
                }
            ]
        }
    },
]