import React from 'react'
import { ItemTypes, numerals } from '../../lib/constants'
import { useDrag } from 'react-dnd'
import SkillBlock from '../skillBlock'
import AttrBlock from '../attrBlock'
import RankPips from '../rankPips'
import HealthBar from '../healthBar'
import { Portrait } from '../portrait'
import { Popup, Icon } from 'semantic-ui-react'
import { getFirstName, getLastName } from '../../lib/pilot'

function DragPilotCard({pilot, location, selectPilot, number, enabled = false}) {
  const [{isDragging}, drag] = useDrag(() => {
    return {
    type: ItemTypes.PILOT,
    canDrag: enabled,
    item: { id: pilot?.id, pilotData: pilot, location },
    collect: (monitor, props) => {
      return {
        isDragging: !!monitor.isDragging(),
      }
    }
  }}, [enabled, pilot])

  const splitPilotName = (pilotData) => {
    return <span>{getFirstName(pilotData)}<br/>{getLastName(pilotData)}</span>
  }

  const card = <div 
    ref={drag}
    className="pilotCard"
    style={{
      pointerEvents: pilot ? 'auto' : 'none',
      opacity: isDragging ? 0.5 : 1
    }}
    onClick={() => selectPilot(pilot.id)}
  >
    { pilot.canAdvance.any && (
      <div className="advanceBadge">
        <Icon name="add" size="small"/>
      </div>
    )}
    <HealthBar 
        curr={pilot.metrics.hp}
        max={pilot.metrics.maxHp}
        className={`pilotHealth ${pilot.metrics.hp === pilot.metrics.maxHp && 'full'}`}
        dir="vert"
    />
    <HealthBar 
        curr={pilot.metrics.energy}
        max={pilot.metrics.maxEnergy}
        className={`pilotEnergy`}
        dir="vert"
    />
    <div className="portrait">
      <Portrait size={40} subject={pilot.portrait}/> 
    </div>
    <div className="pilotName">
      { splitPilotName(pilot) }
    </div>
    <RankPips pilot={pilot} size="tiny"/>
    <div className={`teamBadge bg-team-${pilot.team}`}>
      { numerals[pilot.team - 1] }
    </div>
  </div>

  // return card

  return <Popup
  key={number} 
  trigger={ card }
  basic
  mouseEnterDelay={300}
  position="right center"
  offset={[0, -15]}
  >
  <div>
    <AttrBlock pilot={pilot}/>
  </div>
  <div>
    <SkillBlock pilot={pilot}/>
  </div>
</Popup>
}

export default DragPilotCard