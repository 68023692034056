/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from "d3"
import React, { useEffect } from 'react'
import { randInt } from '../../lib/mathUtils'
import { addMinigameTimer } from './timer'

export function HoloSync ({ pilotData, playSoundEffect, gameOver }) {
    useEffect(() => {
        const width = 500;
        const height = 500;
   
        const maxTime = 100

        const marginX = 50;
        const marginY = 130;
        const spacingY = 80;

        const barHeight = 50;
        const barWidth = width - 2 * marginX

        const range = pilotData.skills.comm * 10

        const redTargetVal = randInt(range + 40, barWidth - range - 40)
        const greenTargetVal = randInt(range + 40, barWidth - range - 40)
        const blueTargetVal = randInt(range + 40, barWidth - range - 40)

        let currMarker = null
        let moveMarkerInterval = null
        let moveFactor = 1
        let phase = 1

        let timer = null

        d3.select('#holoSync > svg').remove()

        const stopGame = (outcome) => {
            if (timer) {
                timer.stop()
            }

            gameOver(outcome)
        }

        const checkMarker = () => {
            let targetVal = 0

            switch (phase) {
                case 1: 
                    targetVal = redTargetVal
                    break
                case 2: 
                    targetVal = greenTargetVal
                    break
                case 3: 
                    targetVal = blueTargetVal
                    break
                default:
            }

            const x = parseInt(currMarker.attr('x'))

            return Math.abs(x - (targetVal + marginX)) <= range
        }

        const startPhase = () => {
            let markerY = 0

            switch (phase) {
                case 1: 
                    markerY = marginY
                    break
                case 2: 
                    markerY = marginY + spacingY
                    break
                case 3: 
                    markerY = marginY + 2 * spacingY
                    break
                default:
            }

            currMarker = svg.append("rect")
                .attr('class', 'marker')
                .attr("x", marginX)
                .attr("y", markerY)
                .attr("width", 2)
                .attr("height", barHeight);    
        }

        const startTimer = () => {
            d3.timer(function (time){
                if (currMarker) {
                    const currX = parseInt(currMarker.attr('x'))
        
                    if (currX >= marginX + barWidth - 2) {
                        moveFactor = -1
                    }
                    if (currX <= marginX + 3) {
                        moveFactor = 1
                    }
        
                    currMarker.attr('x', currX + 3 * moveFactor)
                }
            });
        }

        const svg = d3.select('#holoSync').append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")      
      
        svg.append("rect")
            .attr("class", "redBar")
            .attr("x", marginX)
            .attr("y", marginY)
            .attr("rx", 15)
            .attr("ry", 15)
            .attr("width", barWidth)
            .attr("height", barHeight);    
      
        svg.append("rect")
            .attr("class", "greenBar")
            .attr("x", marginX)
            .attr("y", marginY + spacingY)
            .attr("rx", 15)
            .attr("ry", 15)
            .attr("width", barWidth)
            .attr("height", barHeight);    

        svg.append("rect")
            .attr("class", "blueBar")
            .attr("x", marginX)
            .attr("y", marginY + 2 * spacingY)
            .attr("rx", 15)
            .attr("ry", 15)
            .attr("width", barWidth)
            .attr("height", barHeight);    

        svg.append("rect")
            .attr("class", "redTarget")
            .attr("x", marginX + redTargetVal - range)
            .attr("y", marginY)
            .attr("width", range * 2)
            .attr("height", barHeight);    

        svg.append("rect")
            .attr("class", "greenTarget")
            .attr("x", marginX + greenTargetVal - range)
            .attr("y", marginY + spacingY)
            .attr("width", range * 2)
            .attr("height", barHeight);    

        svg.append("rect")
            .attr("class", "blueTarget")
            .attr("x", marginX + blueTargetVal - range)
            .attr("y", marginY + 2 * spacingY)
            .attr("width", range * 2)
            .attr("height", barHeight);    

        // click layer
        svg.append("rect")
            .attr("width", width)
            .attr("height", height)
            .style("fill", "none")
            .style("pointer-events", "all")
            .on("click", () => {
                clearInterval(moveMarkerInterval)

                if (checkMarker()) {
                    playSoundEffect('success')

                    phase++
                    if (phase === 4) {
                        stopGame(true)
                    } else {
                        startPhase()
                    }
                } else {
                    playSoundEffect('failure')

                    stopGame(false)
                }
            })   

        //timer
        timer = addMinigameTimer(svg, maxTime, stopGame)

        startPhase()

        startTimer()
    }, [])

    return <div id="holoSync"></div>
}