import { blackNemesis } from './stories/blackNemesis'
import { puzzleOfStone } from './stories/puzzleOfStone'
import { mindControl } from './stories/mindControl'
import { delivery } from './stories/delivery'

export const storyList = {
    blackNemesis,
    delivery,
    puzzleOfStone,
    mindControl
}