import React from 'react'
import { Portrait } from '../portrait';
import HealthBar from '../healthBar';
import { slotTypes } from '../../lib/constants';

export function PilotFrames({ team, inCombat }) {
    if (!team || !inCombat) {
        return null
    }

    if (inCombat) {
        return slotTypes.map((slot, i) => {
            const pilotData = team.pilots[slot] ? team.pilots[slot].data : {}
            const subject = pilotData.portrait
            const callsign = pilotData.callsign || pilotData.name ? pilotData.name.split(' ')[1].slice(0, 8) : ''

            return <div className={`pilotFrame ${slot}`} key={i}>
                <Portrait 
                    size={50} 
                    subject={ subject } 
                    context="combat" 
                    callsign={ callsign.toUpperCase() }
                />
                <div className="healthBars">
                    <HealthBar 
                        curr={Math.min(team.pilots[slot].nextSpecial, 10)}
                        max={10}
                        className="pilotSpecial"
                    />
                    <HealthBar 
                        curr={team.pilots[slot].data.metrics.hp}
                        max={team.pilots[slot].data.metrics.maxHp}
                        className="pilotHealth"
                    />
                    <HealthBar 
                        curr={team.mech.data.armor[slot]}
                        max={team.mech.data.maxArmor[slot]}
                        className="mechArmor"
                    />
                </div>
            </div>
        })
    }
}