import { loadMech, Mech, generateMech } from './mech'
import { generatePilot, loadPilot, Pilot } from './pilot'
import { relPairs, slotTypes } from './constants'
import { checkRoll, randInt } from './mathUtils'

export const loadTeam = async (db, user, teamData, context) => {
    const theTeam = new Team(db, user, teamData, context)

    await theTeam.generateTeam(teamData)

    return theTeam
}

export class Team {
    pilots = {
        head: null,
        leftArm: null,
        rightArm: null,
        leftLeg: null,
        rightLeg: null
    }
    mech = null
    number = 0
    ready = false

    constructor(db, user, teamData, context) {
        this.db = db
        this.user = user
        this.context = context
        this.number = teamData.number
    }

    async generateTeam(teamData) {
        const { db, user, context } = this

        if (context === 'combat') {
            this.mech = new Mech(db, user, teamData.mechId)
        } else if (context === 'sim') {
            let mechData = null

            if (teamData.mechId) {
                // load up a copy of the mech at max armor
                mechData = loadMech(db, user, teamData.mechId)
                mechData.armor = { ...mechData.maxArmor }
            } else {
                mechData = generateMech(null, null, null, null, teamData.rank)
                mechData.color = randInt(0, 360)
            }
            
            mechData.name = `${mechData.make} ${mechData.model}`

            this.mech = new Mech(null, null, null, mechData)
        }

        for (const slot of slotTypes) {
            if (context === 'combat') {
                // use live pilot data and persist to db
                this.pilots[slot] = new Pilot(db, user, teamData[slot], context, slot)
            } else if (context === 'sim') {
                let pilotData = null

                if (teamData[slot]) {
                    // use a copy of real pilot data (PVP)
                    pilotData = loadPilot(db, user, teamData[slot])
                } else {
                    // generate a random pilot (PVE)
                    pilotData = await generatePilot(null, null, { rank: teamData.rank, slot })
                }

                this.pilots[slot] = new Pilot(null, null, null, context, slot, pilotData)
            }
        }

        const loadInterval = setInterval(() => {
            if (this.mech.data 
                && this.pilots.head.data
                && this.pilots.leftArm.data
                && this.pilots.rightArm.data
                && this.pilots.leftLeg.data
                && this.pilots.rightLeg.data
            ) {
                clearInterval(loadInterval)
                this.resetPilotTimers()
                this.ready = true
            }
        })
    }

    resetPilotTimers() {
        for (const slot of slotTypes) {
            this.pilots[slot].calculateSpeed(this.mech.data)
        }
    }
    
    getAverageRelationship() {
        let totalRel = 0

        for (const pair of relPairs) {
            const pilot1 = this.pilots[pair[0]]
            const pilot2 = this.pilots[pair[1]]

            totalRel += pilot1.data.relationships[pilot2.id] || 50
        }

        return totalRel / relPairs.length
    }

    getAverageHappiness() {
        let totalHap = 0

        for (const slot of slotTypes) {
            totalHap += this.pilots[slot].data.metrics.hap
        }

        return totalHap / 5
    }

    fullHeal() {
        for (const slot of slotTypes) {
            const pilot = this.pilots[slot]
            const metrics = { ...pilot.data.metrics }
            metrics.hp = metrics.maxHp
            pilot.updateData({ metrics })
        }
    }

    async damagePilot(slot, damage = 1, addStats) {
        let pilotAngry = false

        const pilot = this.pilots[slot]
        const metrics = { ...pilot.data.metrics }
        metrics.hp = metrics.hp - damage
        const fortCheck = checkRoll(12 + damage, Math.ceil(pilot.data.attributes.fort / 2)) 
        if (!fortCheck.success) {
            pilotAngry = true
            metrics.hap = metrics.hap - 1
        }
        await pilot.updateData({ metrics })
        addStats(pilot.id, 'damageTaken', damage)

        return pilotAngry
    }

    getWorstPilot() {
        let worstSlot = null
        let maxDamage = 0

        for (const slot of slotTypes) {
            const damage = 1 - (this.pilots[slot].hp / this.pilots[slot].maxHp)
            if (damage > maxDamage) {
                maxDamage = damage
                worstSlot = slot
            }
        }

        return { slot: worstSlot, damage: maxDamage }
    }

    cleanup() {
        this.mech.cleanup()
        for (const slot of slotTypes) {
            this.pilots[slot].cleanup()
        }
    }
}