import React from 'react';
import { Logo } from './logo'

export const Loading = ({ className = '' }) => {
    return <div className={`loading ${className}`}>
        <Logo
            className="loadingLogo"
            size={ 80 }
            spinning={ true }
        />
    </div>
}