// import { collection, doc, setDoc, getDoc } from "firebase/firestore"
import Combatant from './combatant'
import { kaijuList } from '../data/kaiju'
import { getRankedValue, getRandomItem } from "./mathUtils"

export const generateKaiju = (rank = 1, environment = 'any') => {
    let data = null
    if (environment === 'any') {
        data = getRandomItem(kaijuList)
    } else {
        const validKaiju = kaijuList.filter((k) => k.environments.includes(environment))
        data = getRandomItem(validKaiju)
    }

    const rankFactor = 0.75

    const armor = {}
    for (const slot in data.armor) {
        armor[slot] = getRankedValue(data.armor[slot], rank, rankFactor)
    }

    const kaijuData = {
        type: 'kaiju',
        name: data.name,
        rank,
        speed: data.speed,
        armor: { ...armor },
        maxArmor: { ...armor },
        weapons: [ ...data.weapons ]
    }

    return kaijuData
}

// export const loadKaiju = async (db, id) => {
//     const kaijuRef = doc(db, "kaijus", id);

//     const res = await getDoc(kaijuRef);

//     const kaijuData = {
//         id,
//         ...res.data()
//     }

//     return kaijuData
// }

export class Kaiju extends Combatant {
    nextAction = 0
    status = {}

    constructor(data) {
        // kaijus are not persisted, so we just hand them data to initialize with
        super(null, null, null, null, data)
    }

    initData() {
        // console.log('kaiju initData')
        this.calculateStats()
    }

    changeRank(newRank) {
        // change rank and adjust armor levels accordingly
        this.data.rank = newRank

        const rankFactor = 0.75

        const baseData = kaijuList.find((k) => k.name === this.data.name)

        const armor = {}
        for (const slot in baseData.armor) {
            armor[slot] = getRankedValue(baseData.armor[slot], newRank, rankFactor)
        }
    
        this.data.armor = { ...armor }
        this.data.maxArmor = { ...armor }
    }

    calculateStats() {
        this.speed = this.data.speed
        this.nextAction = this.speed

        const rankFactor = 0.75
        this.maxRage = getRankedValue(100, this.data.rank, rankFactor)
        this.rage = 0
    
        this.weapons = JSON.parse(JSON.stringify(this.data.weapons))
        this.weapons.forEach((w) => {
            w.nextAttack = w.speed
        })
    }

    async applyDamage(slot, damage, crit) {
        this.rage += damage

        return super.applyDamage(slot, damage, crit)
    }

    getRageLevel() {
        if (!this.maxRage) {
            return 'none'
        }

        const rageLevel = this.rage / this.maxRage
        if (rageLevel > 0.75) {
            return 'highRage'
        }
        if (rageLevel > 0.5) {
            return 'medRage'
        }
        if (rageLevel > 0.25) {
            return 'lowRage'
        }
        return 'none'
    }

    incrementNextAction() {
        this.nextAction += this.speed
    }

    applyStatus(type, amount) {
        if (!this.status[type]) {
            this.status[type] = amount
        } else {
            this.stats[type] += amount
        }
    }

}