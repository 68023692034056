export const nameParts = {
    enby: [
        'Adair',
        'Aeden',
        'Agel',
        'Amethyst',
        'Aphelion',
        'Apple',
        'Apri',
        'Apricot',
        'Argyle',
        'Ari',
        'Ash',
        'Asher',
        'Aspen',
        'Aster',
        'Auxin',
        'Avery',
        'Aviar',
        'Ax',
        'Axe',
        'Axis',
        'Axialis',
        'Azimuth',
        'Bae',
        'Berri',
        'Berry',
        'Biblo',
        'Bibloo',
        'Birch',
        'Bird',
        'Blu',
        'Blue',
        'Book',
        'Borage',
        'Bracket',
        'Brynn',
        'Cadmium',
        'Canary',
        'Canter',
        'Carve',
        'Checkers',
        'Cherry',
        'Chey',
        'Chickory',
        'Clia',
        'Clio',
        'Cliu',
        'Clover',
        'Copper',
        'Coron',
        'Cosmo',
        'Cranberry',
        'Crater',
        'Crow',
        'Cupcake',
        'Cuperton',
        'Cuprum',
        'Curve',
        'Day',
        'Deck',
        'Dendron',
        'Doom',
        'Doomberry',
        'Dyle',
        'East',
        'Easy',
        'Eclipse',
        'Elm',
        'Ember',
        'Encyclover',
        'Extra',
        'Faven',
        'Fig',
        'Fir',
        'Flair',
        'Flaire',
        'Flare',
        'Fur',
        'Future',
        'Garnet',
        'Gibbous',
        'Goby',
        'Gray',
        'Hallemutton',
        'Hark',
        'Harper',
        'Hart',
        'Haven',
        'Heart',
        'Helium',
        'Hickory',
        'Hive',
        'Honey',
        'Hum',
        'Hyperbola',
        'Hypernova',
        'Ibringly',
        'Idgy',
        'In',
        'Indigo',
        'Ink',
        'Jae',
        'Jay',
        'Jey',
        'Juniper',
        'Justice',
        'Kap',
        'Kapree',
        'Kay',
        'Keelan',
        'Kelp',
        'Kestrel',
        'Kilo',
        'Kremmy',
        'Lair',
        'Landish',
        'Lane',
        'Lapis',
        'Lark',
        'Lavender',
        'Leaf',
        'Lean',
        'Leen',
        'Legion',
        'Lev',
        'Lime',
        'Locust',
        'Loop',
        'Luck',
        'Lumen',
        'Lutra',
        'Lyric',
        'Maple',
        'March',
        'Mare',
        'Mars',
        'Mass',
        'Mauve',
        'Mercury',
        'Milli',
        'Muni',
        'Moo',
        'Moon',
        'Neutrix',
        'Nibtucket',
        'Nine',
        'Noon',
        'North',
        'Nova',
        'Oak',
        'Oar',
        'Onive',
        'Onyx',
        'Opal',
        'Orange',
        'Packett',
        'Parallax',
        'Parker',
        'Parsec',
        'Pearl',
        'Peridot',
        'Perihelion',
        'Petri',
        'Phoenix',
        'Pigeon',
        'Pine',
        'Pith',
        'Planetoidiath',
        'Plothika',
        'Plum',
        'Plume',
        'Probe',
        'Pulsar',
        'Purple',
        'Pyramid',
        'Pyro',
        'Quasar',
        'Quimble',
        'Quinn',
        'Quint',
        'Raine',
        'Rallyum',
        'Ram',
        'Reagan',
        'Reese',
        'Riley',
        'Rin',
        'Roan',
        'Rook',
        'Root',
        'Rowan',
        'Rub',
        'Ruby',
        'Rune',
        'Sage',
        'Sapphire',
        'Silver',
        'Sky',
        'South',
        'Sphere',
        'Square',
        'Squid',
        'Squids',
        'Star',
        'Stream',
        'Sun',
        'Talliamitar',
        'Tayler',
        'Taylor',
        'Terabithia',
        'Thyme',
        'Time',
        'Tobi',
        'Trapezoid',
        'Tree',
        'Tri',
        'Triangle',
        'Tuber',
        'Tucket',
        'Twinkle',
        'Tyad',
        'Ubty',
        'Umbra',
        'Under',
        'Undertomb',
        'Usera',
        'Valor',
        'Value',
        'Vapor',
        'Vio',
        'Vonturbola',
        'Wane',
        'Wax',
        'West',
        'Wick',
        'Willow',
        'Wothen',
        'Wren',
        'Xap',
        'Yabbit',
        'Yam',
        'Yarn',
        'Yarrow',
        'Yellow',
        'Yield',
        'Yorn',
        'Zap',
        'Zappi',
        'Zappy',
        'Zenith',
        'Zev',
        'Zibiliath',
        'Zylith'
    ]
}