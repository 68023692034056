import { Icon } from 'semantic-ui-react'
import { storyList } from '../data/story'
import { getRandomItem, flipCoin } from './mathUtils'

export const findStory = (academy, event) => {
    const currentStories = academy.data.stories || {}

    // todo: check for valid active stories
    const pilotId = event.pilot.id

    let pilotHasStory = false

    for (let storyId in currentStories) {
        const theStory = currentStories[storyId]

        if (theStory.pilotId === pilotId && theStory.stageId !== 'complete') {
            pilotHasStory = true

            const nextStage = storyList[storyId].stages[theStory.stageId]

            if (nextStage.location.includes(event.location)) {
                // location matches
                if (event.location !== 'planet' || event.planetCode === theStory.planet.code) {
                    // planet matches (if applicable)
                    event.planet = theStory.planet
                    event.storyData = theStory.storyData
                    const storyEvent = nextStage.data(event)

                    if (storyEvent) {
                        // if the story is valid for this person, add it to the options
                        return {
                            ...event,
                            isStory: true,
                            id: storyId,
                            name: theStory.name,
                            completeText: storyList[storyId].completeText,
                            modalSize: theStory.modalSize,
                            data: storyEvent
                        }
                    }
                }
            }
        }
    }

    if (!pilotHasStory && flipCoin()) {
        const storyOptions = []

        for (let storyId in storyList) {
            if (!currentStories[storyId]) { // skip stories already in progress
                const storyOption = storyList[storyId].stages[0]
                if (storyOption.location.includes(event.location)) {
                    // only stories valid for this location
                    const storyData = storyOption.data(event)

                    if (storyData) {
                        // if the story is valid for this person, add it to the options
                        storyOptions.push({
                            ...event,
                            isStory: true,
                            id: storyId,
                            name: storyList[storyId].name,
                            modalSize: storyList[storyId].modalSize,
                            data: storyData
                        })
                    }
                }
            }
        }

        // pick one of the valid options
        return getRandomItem(storyOptions)
    }
}

const getStoryHint = (story) => {
    const { planet, pilotName, otherPilotName, hint } = story

    let out = hint.replace('PNAME', pilotName)
    out = out.replace('ONAME', otherPilotName)

    if (planet) {
        return out.replace('PLANETNAME', planet.name)
    } else {
        return out
    }
}

export const getCurrentStoryList = (academy) => {
    const stories = Object.keys(academy.data.stories || {}).map((sKey) => academy.data.stories[sKey])

    const activeStories = stories.filter((s) => s.stageId !== 'complete')
    const completedStories = stories.filter((s) => s.stageId === 'complete')

    const messageBody = stories.length > 0 ? (
        <div className="storyList">
            { activeStories.length > 0 && <>
                <div className="storySectionHeader">Active</div>
                <table className="storyTable">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Pilots</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        { activeStories.map((s, i) => (
                            <tr key={i}>
                                <td className="storyName">
                                    { s.name }
                                </td>
                                <td className="pilotName">
                                    { s.pilotName }
                                </td>
                                <td>
                                    { getStoryHint(s) }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>}
            { completedStories.length > 0 && <>
                <div className="storySectionHeader">Completed</div>
                <table className="storyTable">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Pilots</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        { completedStories.map((s, i) => (
                            <tr key={i}>
                                <td className="storyName">
                                    { s.name }
                                </td>
                                <td className="pilotName">
                                    { s.pilotName }
                                </td>
                                <td>
                                    { getStoryHint(s) }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>}
        </div>
    ) : (
        <div className="noData">
            No Stories Begun
        </div>
    )

    return {
        title: <span><Icon name="bookmark"/> Stories</span>,
        size: 'large',
        body: messageBody
    }
}