/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from "d3"
import React, { useEffect } from 'react'
import { randInt } from '../../lib/mathUtils'
import { addMinigameTimer } from './timer'

export function CenterTarget ({ pilotData, playSoundEffect, gameOver }) {
    useEffect(() => {
        const width = 500;
        const height = 500;
   
        const maxTime = 30 + 5 * pilotData.skills.combat

        const goalX = randInt(100, 400)
        const goalY = randInt(100, 400)

        const gapX = 500 - goalX
        const gapY = 500 - goalY

        let targetX = randInt(100, 400)
        let targetY = randInt(100, 400)
        let crosshairX = randInt(100, 400)
        let crosshairY = randInt(100, 400)

        let winGame = false

        let timer = null

        const stopGame = (outcome) => {
            if (timer) {
                timer.stop()
            }

            gameOver(outcome)
        }

        d3.select('#centerTarget > svg').remove()

        const svg = d3.select('#centerTarget').append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")

        const target = svg.append('svg:image')
            .attr('class', 'storyToggle')
            .attr("xlink:href", `/assets/images/targetSilhouette.png`)        
            .attr('x', targetX - 30)
            .attr('y', targetY - 30)
            .attr('width', 60)
            .attr('height', 60)

        const crosshair = svg.append('g')
            .attr('class', 'crosshair')

        const drawCrosshair = (crosshairX, crosshairY) => {
            d3.select('.crosshair').html(null)

            crosshair.append('circle')
                .attr('cx', crosshairX)
                .attr('cy', crosshairY)
                .attr('r', 30)
            crosshair.append('line')
                .attr('x1', crosshairX + 10)
                .attr('y1', crosshairY)
                .attr('x2', crosshairX + 50)
                .attr('y2', crosshairY)
            crosshair.append('line')
                .attr('x1', crosshairX + 50)
                .attr('y1', crosshairY - 10)
                .attr('x2', crosshairX + 50)
                .attr('y2', crosshairY + 10)
            crosshair.append('line')
                .attr('x1', crosshairX - 10)
                .attr('y1', crosshairY)
                .attr('x2', crosshairX - 50)
                .attr('y2', crosshairY)
            crosshair.append('line')
                .attr('x1', crosshairX - 50)
                .attr('y1', crosshairY - 10)
                .attr('x2', crosshairX - 50)
                .attr('y2', crosshairY + 10)
            crosshair.append('line')
                .attr('x1', crosshairX)
                .attr('y1', crosshairY + 10)
                .attr('x2', crosshairX)
                .attr('y2', crosshairY + 50)
            crosshair.append('line')
                .attr('x1', crosshairX - 10)
                .attr('y1', crosshairY + 50)
                .attr('x2', crosshairX + 10)
                .attr('y2', crosshairY + 50)
            crosshair.append('line')
                .attr('x1', crosshairX)
                .attr('y1', crosshairY - 10)
                .attr('x2', crosshairX)
                .attr('y2', crosshairY - 50)
            crosshair.append('line')
                .attr('x1', crosshairX - 10)
                .attr('y1', crosshairY - 50)
                .attr('x2', crosshairX + 10)
                .attr('y2', crosshairY - 50)            
        }

        const drawObjects = (event) => {
            const x = event.offsetX
            const y = event.offsetY

            const dx = goalX - x
            const dy = goalY - y

            if ( Math.abs(dx) < 10 && Math.abs(dy) < 10) {
                winGame = true

                target.attr('x', 250 - 30)
                    .attr('y', 250 - 30)

                drawCrosshair(250, 250)

                crosshair.attr('class', 'crosshair animate-flicker')

                playSoundEffect('success')

                timer.stop()

                d3.timeout(() => stopGame(true), 1000)
            } else {
                const xFactor = dx < 0 ? goalX / 200 : gapX / 200
                const yFactor = dy < 0 ? goalY / 200 : gapY / 200

                targetX = 250 + (dx * xFactor)
                if (targetX < 50) { targetX = 50 }
                if (targetX > 450) { targetX = 450 }
                targetY = 250 - (dy * yFactor)
                if (targetY < 50) { targetY = 50 }
                if (targetY > 450) { targetY = 450 }

                crosshairX = 250 - (dx * xFactor)
                if (crosshairX < 50) { crosshairX = 50 }
                if (crosshairX > 450) { crosshairX = 450 }
                crosshairY = 250 + (dy * yFactor)
                if (crosshairY < 50) { crosshairY = 50 }
                if (crosshairY > 450) { crosshairY = 450 }

                target.attr('x', targetX - 30)
                    .attr('y', targetY - 30)

                drawCrosshair(crosshairX, crosshairY)
            }
        }

        // click layer
        svg.append("rect")
            .attr("width", width)
            .attr("height", height)
            .style("fill", "none")
            .style("pointer-events", "all")
            .on('mousemove', (event, d) => {
                if (!winGame) {
                    drawObjects(event, d)
                }
            })

            //timer
        timer = addMinigameTimer(svg, maxTime, stopGame)

    }, [])

    return <div id="centerTarget"></div>
}