/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from "d3"
import React, { useEffect } from 'react'
import { oneIn, flipCoin } from '../../lib/mathUtils'
import { addMinigameTimer } from './timer'

// import * as d3Base from 'd3'
// import { sineWave } from '../../lib/graphs/d3-sine-wave'

// attach all d3 plugins to the d3 library
// const d3 = Object.assign(d3Base, { sineWave })

export function CircuitMaze ({ pilotData, playSoundEffect, gameOver }) {
    useEffect(() => {

        const width = 500;
        const height = 500;
   
        const maxTime = 30 + 5 * pilotData.skills.repair

        const marginX = 50;
        const marginY = 50;

        let wonGame = false
        let timer = null

        d3.select('#circuitMaze > svg').remove()

        const stopGame = (outcome) => {
            if (timer) {
                timer.stop()
            }

            flipTimer.stop()

            document.removeEventListener('keydown', handleKeydown)

            gameOver(wonGame || outcome)
        }

        const svg = d3.select('#circuitMaze').append("svg")
            .attr('x', marginX)
            .attr("width", width)
            .attr("height", height)
            .append("g")

        const n = 6
        const deadChance = 4
      
        const circuitGrid = []
        const path = []

        path.push({
            x: flipCoin() ? 0 : n - 1,
            y: flipCoin() ? 0 : n - 1
        })

        const target = {
            x: path[0].x === 0 ? n - 1 : 0,
            y: path[0].y === 0 ? n - 1 : 0
        }

        const gridWidth = width - 2 * marginX
        const cellWidth = gridWidth / n

        for (let y = 0; y < n; y++) {
            circuitGrid[y] = []

            for (let x = 0; x < n; x++) {
                circuitGrid[y][x] = {
                    state: x === target.x && y === target.y ? 'target' : oneIn(deadChance) ? 'dead' : 'off'
                }
            }
        }

        const getCX = (x) => {
            return marginX + cellWidth * x + cellWidth / 2
        }
        
        const getCY = (y) => {
            return marginY + cellWidth * y + cellWidth / 2
        }

        const updateCircuits = () => {
            svg.selectAll('.circuit').remove()
            svg.selectAll('.path').remove()

            path.forEach((curr, i) => {
                circuitGrid[curr.y][curr.x].state = 'path'

                const prev = path[i - 1]

                if (prev) {
                    const x1 = getCX(prev.x)
                    const y1 = getCY(prev.y)
                    const x2 = getCX(curr.x)
                    const y2 = getCY(curr.y)

                    svg.append("line")
                        .attr('class', 'path')
                        .attr('x1', x1)
                        .attr('y1', y1)
                        .attr('x2', x2)
                        .attr('y2', y2)
                }
            })

            const currNode = path[path.length - 1]

            circuitGrid.forEach((row, y) => {
                row.forEach((c, x) => {
                    const cx = getCX(x)
                    const cy = getCY(y)

                    let nodeClass = `circuit ${c.state}`

                    if (currNode.x === x && currNode.y === y) {
                        nodeClass += ' current'
                    }

                    svg.append("circle")
                        .attr('class', nodeClass)
                        .attr('cx', cx)
                        .attr('cy', cy)
                        .attr('r', 5)
                })
            })
        }

        const flipCircuits = () => {
            circuitGrid.forEach((row, y) => {
                row.forEach((c, x) => {
                    const node = circuitGrid[y][x]
                    if (node.state === 'off' || node.state === 'dead') {
                        node.state = oneIn(deadChance) ? 'dead' : 'off'
                    }
                })
            })

            updateCircuits()
        }

        const flipTimer = d3.interval(flipCircuits, 1000)

        const winGame = () => {
            // stop listening for keypresses
            document.removeEventListener('keydown', handleKeydown)
            timer.stop()

            wonGame = true

            // d3.selectAll('.playerCircle')
            //     .attr('class', 'matchCircle')

            setTimeout(() => {
                gameOver(true)
            }, 500)
        }

        const handleKeydown = (e) => {
            const currNode = path[path.length - 1]

            let nextNode = null

            switch( e.keyCode ) {
                case 37: // left arrow
                case 65: // A
                    if (currNode.x === 0) {
                        playSoundEffect('failure')
                    } else {
                        nextNode = {
                            x: currNode.x - 1,
                            y: currNode.y
                        }
                    }
                    break
                case 38: // up arrow
                case 87: // D
                    if (currNode.y === 0) {
                        playSoundEffect('failure')
                    } else {
                        nextNode = {
                            x: currNode.x,
                            y: currNode.y - 1
                        }
                    }
                    break
                case 39: // right arrow
                case 68: // W
                    if (currNode.x === n - 1) {
                        playSoundEffect('failure')
                    } else {
                        nextNode = {
                            x: currNode.x + 1,
                            y: currNode.y
                        }
                    }
                break
                case 40: // down arrow
                case 83: // S
                    if (currNode.y === n - 1) {
                        playSoundEffect('failure')
                    } else {
                        nextNode = {
                            x: currNode.x,
                            y: currNode.y + 1
                        }
                    }
                break
                default: 
                    break
            }    

            if (nextNode) {
                const state = circuitGrid[nextNode.y][nextNode.x].state

                switch (state) {
                    case 'off':
                        playSoundEffect('buttonPress')
                        path.push(nextNode)
                        break
                    case 'path':
                        const penultimate = path[path.length - 2]
                        if (nextNode.x === penultimate.x && nextNode.y === penultimate.y) {
                            path.pop()
                            circuitGrid[currNode.y][currNode.x].state = 'dead'
                            playSoundEffect('buttonPress')
                        } else {
                            playSoundEffect('failure')
                        }
                        break
                    case 'dead':
                        playSoundEffect('failure')
                        break
                    case 'target':
                        playSoundEffect('success')
                        path.push(nextNode)
                        winGame()
                        break
                    default:
                }

                updateCircuits()
            }

            // if (player.freq === target.freq && player.amp === target.amp) {
            //     playSoundEffect('success')
            //     winGame()
            // } else {
            //     playSoundEffect('buttonPress')
            // }
        }

        document.addEventListener('keydown', handleKeydown)

        //timer
        timer = addMinigameTimer(svg, maxTime, stopGame)

        updateCircuits()
    }, [])

    return <div id="circuitMaze"></div>
}