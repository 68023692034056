/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'
import { Image } from 'semantic-ui-react'
import { slotTypes } from '../lib/constants'

export class MechView extends Component {
    getArmorColor(slot) {
        const { subject } = this.props

        const statusColors = [
            { WebkitFilter: `brightness(0.75) hue-rotate(-130deg)` }, 
            { WebkitFilter: `brightness(1.0) hue-rotate(-115deg)` }, 
            { WebkitFilter: `brightness(1.5) hue-rotate(-90deg)` }
        ]

        if (this.props.options?.ablativeArmor) {
            const armorLevel = Math.min(this.props.options.ablativeArmor, 100)
            const armorColor = (armorLevel / 100) * 60
            return { WebkitFilter: `brightness(1.0) hue-rotate(${armorColor}deg)` }
        }

        switch (slot) {
            case 'leftWeapon':
            case 'rightWeapon':
                return subject[slot].status === 'offline' ? statusColors[0] : {}
            default:
                const maxArmor = subject.maxArmor[slot]
                const armor = subject.armor[slot]
        
                if (!maxArmor) {
                    return {}
                }
        
                const statusLevel = Math.floor((armor / maxArmor) * 3)
                
                if (isNaN(armor)) {
                    console.log('SOMETHING WENT WRONG')
                }
                if (armor === 0) {
                    return { WebkitFilter: `saturate(0) brightness(0.5)` }
                }
                if (armor === maxArmor) {
                    return { WebkitFilter: `contrast(1.25)` }
                }
        
                return statusColors[statusLevel]
        }
    }

    render() {
        const subject = this.props.subject

        let imgPath = ''
        let weaponPath = ''
        let weaponColorPath = ''
        let unique = ''

        if (subject && subject.type) {
            if (subject.type === 'mech') {
                const make = subject.make
                const model = subject.model
                unique = subject.unique
                imgPath = subject ? `/assets/mechs/${make.toLowerCase()}/${model.toLowerCase()}` : ''
                weaponPath = subject ? `/assets/mechs/${make.toLowerCase()}/weapons` : ''

                if (unique) {
                    weaponColorPath = subject ? `/assets/mechs/${make.toLowerCase()}/weapons/${unique}` : ''
                } else {
                    weaponColorPath = subject ? `/assets/mechs/${make.toLowerCase()}/weapons` : ''
                }
            } else if (subject.type === 'kaiju') {
                const name = subject.name
                imgPath = subject ? `/assets/kaiju/${name}` : ''
            }
        }

        const context = subject ? this.props.context : ''
        const environment = subject ? this.props.environment ? `${this.props.environment}.png` : 'grid.svg' : 'grid.svg'

        const ratio = 360 / 420
        const width = this.props.size * ratio
        const height = this.props.size

        const frameStyle = this.props.size ? { width: `${width}px`, height: `${height}px`} : {}

        switch(context) {
            case 'list':
                return <div className="portraitFrame" style={ { ...frameStyle, backgroundColor: '#000' } }>
                    <Image
                        className="portraitLayer"
                        src={`/assets/backgrounds/grid.svg`}
                    />
                    { subject.type === 'mech' && [
                        <Image
                            key={1}
                            className="portraitLayer flipH"
                            src={`${weaponColorPath}/${subject.leftWeapon.type}Color.svg`}
                            style={{ WebkitFilter: `hue-rotate(${ subject.color }deg)` }}
                        />,
                        <Image
                            key={2}
                            className="portraitLayer"
                            src={`${weaponColorPath}/${subject.rightWeapon.type}Color.svg`}
                            style={{ WebkitFilter: `hue-rotate(${ subject.color }deg)` }}
                        />
                    ] }
                    <Image
                        className="portraitLayer"
                        style={{ WebkitFilter: `hue-rotate(${ subject.color }deg)` }}
                        src={`${imgPath}/${unique || 'fullColor'}.svg`}
                    />
                </div>
            case 'display':
                return <div className="portraitFrame" style={ { ...frameStyle, backgroundColor: '#000' } }>
                    <Image
                        className="portraitLayer"
                        style={{ WebkitFilter: `blur(1px)` }}
                        src={`/assets/backgrounds/${environment}`}
                    />
                    { subject.type === 'mech' && [
                        <Image
                            key={1}
                            className="portraitLayer flipH"
                            src={`${weaponColorPath}/${subject.leftWeapon.type}Color.svg`}
                            style={{ WebkitFilter: `hue-rotate(${ subject.color }deg)` }}
                        />,
                        <Image
                            key={2}
                            className="portraitLayer"
                            src={`${weaponColorPath}/${subject.rightWeapon.type}Color.svg`}
                            style={{ WebkitFilter: `hue-rotate(${ subject.color }deg)` }}
                        />
                    ] }
                    <Image
                        className="portraitLayer"
                        style={{ WebkitFilter: `hue-rotate(${ subject.color }deg)` }}
                        src={`${imgPath}/${unique || 'fullColor'}.svg`}
                    />
                </div>
            case 'damage':
                return <div className="portraitFrame" style={ { ...frameStyle, backgroundColor: '#000' } }>
                    <Image
                        className="portraitLayer"
                        style={{ opacity: 0.5 }}
                        src={`/assets/backgrounds/grid.svg`}
                        />
                    { subject.type === 'mech' && [
                        <Image
                            key={1}
                            className="portraitLayer flipH"
                            src={`${weaponPath}/${subject.leftWeapon.type}.svg`}
                            style={this.getArmorColor('leftWeapon')}
                        />,
                        <Image
                            key={2}
                            className="portraitLayer"
                            src={`${weaponPath}/${subject.rightWeapon.type}.svg`}
                            style={this.getArmorColor('rightWeapon')}
                        />
                    ] }
                    <Image
                        className="portraitLayer"
                        src={`${imgPath}/body.svg`}
                        style={this.getArmorColor('body')}
                    /> 
                    { slotTypes.map((slot, i) => <Image key={i}
                        className="portraitLayer"
                        src={`${imgPath}/${slot}.svg`}
                        style={this.getArmorColor(slot)}
                    />) }
                </div>
            default:
                // empty slot
                return <div className="portraitFrame" style={ { ...frameStyle, backgroundColor: '#000' } }>
                    <Image
                        className="portraitLayer"

                        style={{ opacity: 0.5 }}
                        src={`/assets/backgrounds/grid.svg`}
                    />
                </div>    

        }
    }
  }